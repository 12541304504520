import { useEffect, useState } from "react";

import Radio from "@mui/material/Radio";

import { alertSuccess } from "../../../../../actions/AlertActions";
import { getProspectById, updateProspect } from "../../../../../api/Api";
import MaterialForm from "../../../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../../../utils/StateUtils";

type DeclarationTabProps = {
  prospectId: number;
  setCurrentApplicationTab: React.Dispatch<React.SetStateAction<string>>;
};

export function DeclarationTab({
  prospectId,
  setCurrentApplicationTab,
}: DeclarationTabProps) {
  const [declarations, setDeclarations] = useState({
    isEverEvicted: false,
    isEverEvictedNotes: "",
    isEverFelony: false,
    isEverFelonyNotes: "",
    isEverBankrupt: false,
    isEverBankruptNotes: "",
    isSmoker: false,
    isAgreed: false,
    signerName: "",
  });

  useEffect(() => {
    getProspectById(prospectId).then((data) => {
      if (data.declaration !== null) setDeclarations(data.declaration);
    });
  }, [prospectId]);

  const handleChange = (value: string | boolean, name: string) => {
    setDeclarations((data) => deepUpdate(name, value, data));
  };

  const onChangeInfo = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setDeclarations((data) => deepUpdate(name, value, data));
  };

  const onSave = () => {
    updateProspect(prospectId, [
      {
        op: "replace",
        path: "/declaration",
        value: declarations,
      },
    ]).then(() => {
      setCurrentApplicationTab("applicationListing");
      alertSuccess("Prospect updated Successfully");
    });
  };

  return (
    <div className="w-full p-4 ">
      <div className="flex flex-col items-start self-center ">
        <div className="w-full">
          <div className="text-xl font-semibold text-gray-900 my-2">
            Have you ever been evicted ?
          </div>
          <div className="flex flex-row items-center mb-2">
            <Radio
              checked={declarations.isEverEvicted}
              onChange={() => handleChange(true, "isEverEvicted")}
              value="yes"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <label className="text-sm font-normal mr-10">yes</label>
            <Radio
              checked={!declarations.isEverEvicted}
              onChange={() => handleChange(false, "isEverEvicted")}
              value="no"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              placeholder="test"
            />
            <label className="text-sm font-normal ">no</label>
          </div>
          <MaterialForm
            className=""
            data={declarations}
            onChange={onChangeInfo}
            fieldClassName="w-full"
            renderArray={[
              {
                name: "isEverEvictedNotes",
                variant: "OUTLINED",
                inputType: "AREA",
                label: "Notes",
              },
            ]}
          />
        </div>
        <div className="w-full">
          <div className="text-xl font-semibold text-gray-900 my-2">
            Have you ever been convicted of a felony?
          </div>
          <div className="flex flex-row items-center mb-2">
            <Radio
              checked={declarations.isEverFelony}
              onChange={() => handleChange(true, "isEverFelony")}
              value="yes"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <label className="text-sm font-normal mr-10">yes</label>
            <Radio
              checked={!declarations.isEverFelony}
              onChange={() => handleChange(false, "isEverFelony")}
              value="no"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              placeholder="test"
            />
            <label className="text-sm font-normal ">no</label>
          </div>
          <MaterialForm
            className=""
            data={declarations}
            onChange={onChangeInfo}
            fieldClassName="w-full"
            renderArray={[
              {
                name: "isEverFelonyNotes",
                variant: "OUTLINED",
                inputType: "AREA",
                label: "Notes",
              },
            ]}
          />
        </div>
        <div className="w-full">
          <div className="text-xl font-semibold text-gray-900 my-2">
            Have you ever filed for bankruptcy
          </div>
          <div className="flex flex-row items-center mb-2">
            <Radio
              checked={declarations.isEverBankrupt}
              onChange={() => handleChange(true, "isEverBankrupt")}
              value="yes"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <label className="text-sm font-normal mr-10">yes</label>
            <Radio
              checked={!declarations.isEverBankrupt}
              onChange={() => handleChange(false, "isEverBankrupt")}
              value="no"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              placeholder="test"
            />
            <label className="text-sm font-normal ">no</label>
          </div>
          <MaterialForm
            className=""
            data={declarations}
            onChange={onChangeInfo}
            fieldClassName="w-full"
            renderArray={[
              {
                name: "isEverBankruptNotes",
                variant: "OUTLINED",
                inputType: "AREA",
                label: "Notes",
              },
            ]}
          />
        </div>
        <div className="w-full">
          <div className="text-xl font-semibold text-gray-900 my-2">
            Do you Smoke?
          </div>
          <div className="flex flex-row items-center mb-2">
            <Radio
              checked={declarations.isSmoker}
              onChange={() => handleChange(true, "isSmoker")}
              value="yes"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <label className="text-sm font-normal mr-10">yes</label>
            <Radio
              checked={!declarations.isSmoker}
              onChange={() => handleChange(false, "isSmoker")}
              value="no"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              placeholder="test"
            />
            <label className="text-sm font-normal ">no</label>
          </div>
        </div>
        <div className="w-full">
          <div className="text-black text-sm font-normal my-2">
            Agreement & Consent to Background Check
          </div>
          <div className="text-newGray-800 text-sm font-normal">
            I believe that the statements I have made are true and correct. I
            hereby authorize the verification of information I provided,
            communication with any and all names listed on this application and
            for the issuer of this form to conduct a background check to obtain
            additional information on credit history, criminal history and all
            Unlawful Detainers. I understand that any discrepancy or lack of
            information may result in the rejection of this application. I
            understand that this is an application for a home or apartment and
            does not constitute a rental or lease agreement in whole or in part.
            I further understand that there is a non-refundable fee to cover the
            cost of processing my application and I am not entitled to a refund.
          </div>

          <MaterialForm
            className="flex flex-row mt-4"
            data={declarations}
            onChange={onChangeInfo}
            fieldClassName="w-60"
            renderArray={[
              {
                name: "isAgreed",
                variant: "OUTLINED",
                inputType: "CHECKBOX",
                label: "I agree and Consent",
              },
              {
                name: "signerName",
                variant: "OUTLINED",
                label: "Name of signer",
              },
            ]}
          />
        </div>
        <div className="flex flex-row self-end mt-4 mr-4 ">
          <button
            className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
            bg-gray-300
            text-gray-800 duration-200 ease-in-out 
            transition"
            onClick={() => setCurrentApplicationTab("applicationListing")}
          >
            Cancel
          </button>
          <button
            className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
            bg-newBlue-400  w-40
            text-white border-newBlue-400
            border"
            onClick={onSave}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
