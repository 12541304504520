import { useEffect, useState } from "react";

import Radio from "@mui/material/Radio";

import { alertError, alertSuccess } from "../../../../../actions/AlertActions";
import {
  getLeaseById,
  newBuildingGet,
  updateLease,
} from "../../../../../api/Api";
import MaterialForm from "../../../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../../../utils/StateUtils";

type LeaseTabProps = {
  leaseId: number;
  buildingId: number;
  onBackClick: React.Dispatch<React.SetStateAction<string>>;
};

export default function LateFeesTab({
  leaseId,
  onBackClick,
  buildingId,
}: LeaseTabProps) {
  const [feeDetails, setFeeDetails] = useState({
    daysAfterDueDate: 0,
    frequency: "",
    type: "",
    amount: 0,
  });

  const [leaseTerms, setLeaseTerms] = useState("");

  const [selectedValue, setSelectedValue] = useState("propertyDefault");

  useEffect(() => {
    if (leaseId !== null && leaseId !== 0)
      getLeaseById(leaseId).then((data) => {
        setLeaseTerms(data.leaseTerms);
        if (data.lateFeesDetails === null) {
          newBuildingGet(buildingId).then((building) => {
            if (building?.profile?.lateFeesDetails !== null)
              setFeeDetails(building.profile.lateFeesDetails);
          });
        }
        setFeeDetails({
          amount: data.lateFeesDetails?.amount,
          daysAfterDueDate: data.lateFeesDetails?.daysAfterDueDate,
          frequency: data.lateFeesDetails?.frequency,
          type: data.lateFeesDetails?.type,
        });
        setSelectedValue(
          data.lateFeesDetails ? "customFee" : "propertyDefault"
        );
      });
  }, [leaseId, buildingId]);

  const handleChange = (value: string) => {
    setSelectedValue(value);
  };

  const onChange = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setFeeDetails((data) => deepUpdate(name, value, data));
  };

  const onSave = () => {
    if (leaseId === null) {
      alertError("Please add lease");
      return;
    }
    updateLease(leaseId, [
      {
        op: "replace",
        path: "/lateFeesDetails",
        value: selectedValue === "customFee" ? feeDetails : null,
      },
    ]).then(() => {
      alertSuccess("Late fee Details saved successfully");
    });
  };
  return (
    <div className="w-fit p-4">
      <div className="flex flex-col items-start self-center ">
        <div className="text-lg font-medium text-black">Lease Details</div>
        <div className="flex flex-row mt-4 my-1">
          <div className="text-base text-newGray-800 font-normal mr-1">
            {`Lease term:`}
          </div>
          <div className="text-base text-black font-semibold">{leaseTerms}</div>
        </div>
        <div className="flex flex-row my-1 mb-4">
          <div className="text-base text-newGray-800 font-normal mr-1">
            {`Lease Status:`}
          </div>
          <div className="text-base text-black font-semibold">---</div>
        </div>
        <div className="text-lg font-medium text-black mt-4">
          Add Late Fee Details
        </div>
      </div>

      <div className="my-4">
        <div className="flex flex-row items-center mb-4">
          <Radio
            checked={selectedValue === "propertyDefault"}
            onChange={(event) => handleChange(event.target.value)}
            value="propertyDefault"
            name="radio-buttons"
            inputProps={{ "aria-label": "A" }}
          />
          <label className="text-sm font-normal mr-10">
            Use Property Default
          </label>
          <Radio
            checked={selectedValue === "customFee"}
            onChange={(event) => handleChange(event.target.value)}
            value="customFee"
            name="radio-buttons"
            inputProps={{ "aria-label": "B" }}
            placeholder="test"
          />
          <label className="text-sm font-normal ">Set Custom Fee</label>
        </div>
        {selectedValue === "customFee" && (
          <div className="my-4">
            <MaterialForm
              className="w-full flex flex-row "
              data={feeDetails}
              onChange={onChange}
              fieldClassName="mr-4"
              renderArray={[
                {
                  name: "daysAfterDueDate",
                  variant: "OUTLINED",
                  inputType: "NUMBER",
                  label: "Days Overdue Trigger",
                },
                {
                  name: "frequency",
                  inputType: "SELECT",
                  variant: "OUTLINED",

                  options: [
                    { label: "One-Time", value: "One-Time" },
                    { label: "Daily", value: "Daily" },
                    { label: "Weekly", value: "Weekly" },
                    { label: "Bi-Weekly", value: "Bi-Weekly" },
                    { label: "Monthly", value: "Monthly" },
                  ],
                },
                {
                  name: "type",
                  variant: "OUTLINED",
                  label: "Fee Type",
                },
                {
                  name: "amount",
                  inputType: "NUMBER",
                  variant: "OUTLINED",
                  label: "Amount ($)",
                },
              ]}
            />
          </div>
        )}
        <div className="flex flex-row-reverse mt-4 mr-4 ">
          <button
            className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
        bg-newBlue-400  w-40
        text-white border-newBlue-400
        border"
            onClick={onSave}
          >
            Save
          </button>
          <button
            className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
        bg-gray-300
        text-gray-800 duration-200 ease-in-out 
        transition"
            onClick={() => onBackClick("unitListing")}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
