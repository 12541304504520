import { useState } from "react";

import { Tab } from "@headlessui/react";

import { ApplicationDetailsTab } from "./ApplicationDetailsTab";
import { DeclarationTab } from "./DeclarationTab";
import { IncomeDetailsTab } from "./IncomeDetailsTab";
import { MiscellaneousTab } from "./MiscellaneousTab";
import { RentalHistoryTab } from "./RentalHistoryTab";

type ApplicationDetailsScreenProps = {
  prospectId: number;
  setCurrentApplicationTab: React.Dispatch<React.SetStateAction<string>>;
};

export function ApplicationDetailsScreen({
  prospectId,
  setCurrentApplicationTab,
}: ApplicationDetailsScreenProps) {
  const prospectTabs = [
    { label: "Application Details", value: "applicationDetails" },
    { label: "Rental History", value: "rentalHistory" },
    { label: "Income Details", value: "incomeDetails" },
    { label: "Miscellaneous", value: "miscellaneous" },
    { label: "Declaration", value: "declaration" },
  ];

  const [selectedTab, setSelectedTab] = useState("applicationDetails");
  return (
    <div className="w-full">
      <Tab.Group
        selectedIndex={prospectTabs.findIndex(
          (item) => item.value === selectedTab
        )}
        onChange={(index) => {
          setSelectedTab(prospectTabs[index].value);
        }}
      >
        <div className="flex flex-row w-full border-b">
          <div className="p-4">
            <div className=" border-gray-200">
              <Tab.List className="-mb-px flex space-x-8" aria-label="Tabs">
                {prospectTabs.map((item) => (
                  <Tab key={item.value} className={"focus:outline-none"}>
                    {({ selected }) => (
                      <span
                        className={
                          "cursor-pointer border-transparent text-base hover:text-gray-700  whitespace-nowrap py-4 px-1 border-b-2 font-medium " +
                          (selected
                            ? "text-newBlue-400 border-b-4 border-newBlue-400"
                            : "text-newGray-800")
                        }
                      >
                        {item.label}
                      </span>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>
          </div>
        </div>
        <Tab.Panels>
          <Tab.Panel>
            <ApplicationDetailsTab
              prospectId={prospectId}
              setCurrentApplicationTab={setCurrentApplicationTab}
              setDetailsTab={setSelectedTab}
            />
          </Tab.Panel>
          <Tab.Panel>
            <RentalHistoryTab
              prospectId={prospectId}
              setCurrentApplicationTab={setCurrentApplicationTab}
              setDetailsTab={setSelectedTab}
            />
          </Tab.Panel>
          <Tab.Panel>
            <IncomeDetailsTab
              prospectId={prospectId}
              setCurrentApplicationTab={setCurrentApplicationTab}
              setDetailsTab={setSelectedTab}
            />
          </Tab.Panel>
          <Tab.Panel>
            <MiscellaneousTab
              prospectId={prospectId}
              setCurrentApplicationTab={setCurrentApplicationTab}
              setDetailsTab={setSelectedTab}
            />
          </Tab.Panel>
          <Tab.Panel>
            <DeclarationTab
              prospectId={prospectId}
              setCurrentApplicationTab={setCurrentApplicationTab}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
