import { useEffect, useState } from "react";

import { getInvoiceById, updateInvoice } from "../../api/Api";
import { AdditionalItem, InvoiceData } from "./InvoiceFormUtils";

const headings = ["PRODUCT", "DESCRIPTION", "QTY", "RATE", "AMOUNT", ""];

type AdditionalItemsTableProps = {
  additionalData: AdditionalItem[] | [];
  invoiceId: number;
  invoiceStatus: string;
};

export default function AdditionalItemsTable({
  additionalData,
  invoiceId,
  invoiceStatus,
}: AdditionalItemsTableProps) {
  const [additionalRows, setAdditionalRows] = useState<AdditionalItem[]>([]);

  useEffect(() => {
    const rows = additionalData.map((item: AdditionalItem) => ({
      id: item.id,
      title: item.title,
      description: item.description,
      quantity: item.quantity,
      rate: item.rate,
      amount: item.amount,
    }));

    setAdditionalRows(rows);
  }, [additionalData]);

  const handleChange = (index: number, name: string, value: any) => {
    const tempData = additionalRows;
    const list = [...tempData];
    if (name === "rate" || name === "quantity") {
      if (value.match(/^\d*\.?\d*$/)) {
        list[index][name] = value;

        setAdditionalRows(list);
      }
    } else {
      list[index][name] = value;

      setAdditionalRows(list);
    }
  };

  const updateInvoiceItem = (row: AdditionalItem) => {
    const filteredItems = additionalRows.filter(
      (item: AdditionalItem) => item.id !== row.id && item.id !== 0
    );
    updateInvoice(invoiceId, [
      {
        op: "replace",
        path: "/additionalItems",
        value: [
          ...filteredItems,
          {
            id: row.id === 0 ? Date.now() : row.id,
            title: row.title,
            description: row.description,
            quantity: row.quantity,
            rate: row.rate,
            amount: Number(row.rate) * Number(row.quantity),
          },
        ],
      },
    ]).then(() => {
      getInvoiceById(invoiceId).then((data: InvoiceData) => {
        const rows = data.additionalItems.map((item: AdditionalItem) => ({
          id: item.id,
          title: item.title,
          description: item.description,
          quantity: item.quantity,
          rate: item.rate,
          amount: item.amount,
        }));

        setAdditionalRows(rows);
      });
    });
  };

  return (
    <>
      <div>
        {additionalRows.length > 0 && (
          <div className="w-full mt-5 shadow-md border border-gray-100 rounded">
            <div className="text-gray-600 px-3 font-semibold text-sm mt-1 py-2 border-b border-gray-300 w-full">
              Additional Items
            </div>
            <div className="flex flex-row items-center w-full p-3 px-5"></div>
            <div className={"shadow rounded overflow-auto w-full"}>
              <div className="flex flex-row w-full p-3 px-5 items-start">
                <span className="pr-5 py-2 font-semibold text-gray-500 text-sm w-12">
                  #
                </span>
                <div className={"flex flex-grow w-full grid gap-2 grid-cols-8"}>
                  {headings.map((val, index) => (
                    <span
                      className={
                        "p-1 py-2 font-semibold text-gray-500 text-sm " +
                        (index === 1 || index === 0 ? "col-span-2" : "") +
                        " " +
                        (index > 1 ? "text-right" : "")
                      }
                    >
                      {val}
                    </span>
                  ))}
                </div>
              </div>
              <hr />
              {additionalRows.map((row, row_index) => (
                <>
                  <div className={"flex flex-row w-full items-center pl-1 "}>
                    <span
                      className={
                        "p-2 text-gray-700 text-sm break-words w-12 mr-2 "
                      }
                    >
                      <span className="">{row_index + 1}</span>
                    </span>
                    <div
                      className={
                        "w-full grid gap-2 items-center grid-cols-8 pr-2 "
                      }
                    >
                      <span className="p-1 py-2 break-all text-gray-700 text-sm col-span-2 whitespace-pre-line ">
                        <input
                          className={
                            "p-1 py-2 mr-3 break-all text-gray-700 text-sm  "
                          }
                          disabled={invoiceStatus === "PAID"}
                          type="text"
                          onChange={(e) => {
                            handleChange(row_index, "title", e.target.value);
                          }}
                          value={row.title}
                          onBlur={() => updateInvoiceItem(row)}
                        />
                      </span>
                      <span className="p-1 py-2 break-all text-gray-700 text-sm col-span-2 whitespace-pre-line">
                        <input
                          className={
                            "p-1 py-2 mr-3 break-all text-gray-700 text-sm estimate-quantity "
                          }
                          disabled={invoiceStatus === "PAID"}
                          type="text"
                          onChange={(e) => {
                            handleChange(
                              row_index,
                              "description",
                              e.target.value
                            );
                          }}
                          value={row.description}
                          onBlur={() => updateInvoiceItem(row)}
                        />
                      </span>
                      {/* Qty Input */}
                      <input
                        className={
                          "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right estimate-quantity "
                        }
                        disabled={invoiceStatus === "PAID"}
                        type="text"
                        onChange={(e) => {
                          handleChange(row_index, "quantity", e.target.value);
                        }}
                        value={row.quantity}
                        onBlur={() => updateInvoiceItem(row)}
                      />
                      {/* Rate Input */}
                      <input
                        className={
                          "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right caret-transparent estimate-rate "
                        }
                        disabled={invoiceStatus === "PAID"}
                        onChange={(e) => {
                          handleChange(
                            row_index,
                            "rate",
                            e.target.value.replace("$", "")
                          );
                        }}
                        value={"$" + row.rate}
                        onBlur={() => updateInvoiceItem(row)}
                      />
                      {/* Amount Span */}
                      <span className="p-1 py-2 break-all text-gray-700 text-sm  text-right">
                        {`$${row.amount}`}
                      </span>
                      <button
                        className={`break-all w-max items-center px-3 py-0.5 rounded text-xs font-medium 
                  ${
                    invoiceStatus === "PAID"
                      ? "bg-newGray-700 text-newGray-800"
                      : "bg-red-500 text-red-100"
                  }`}
                        onClick={() => {
                          const filteredItems = additionalRows.filter(
                            (item: AdditionalItem) => item.id !== row.id
                          );
                          updateInvoice(invoiceId, [
                            {
                              op: "replace",
                              path: "/additionalItems",
                              value: filteredItems,
                            },
                          ]).then(() => {
                            getInvoiceById(invoiceId).then(
                              (data: InvoiceData) => {
                                const rows = data.additionalItems.map(
                                  (item: AdditionalItem) => ({
                                    id: item.id,
                                    title: item.title,
                                    description: item.description,
                                    quantity: item.quantity,
                                    rate: item.rate,
                                    amount: item.amount,
                                  })
                                );

                                setAdditionalRows(rows);
                              }
                            );
                          });
                        }}
                        disabled={invoiceStatus === "PAID"}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        )}

        {invoiceStatus !== "PAID" && (
          <div className=" my-4">
            <button
              className="w-max text-green-600 font-medium border px-2 rounded border-green-600"
              onClick={() => {
                const newItems = [
                  ...additionalRows,
                  {
                    id: 0,
                    title: "",
                    description: "",
                    quantity: 0,
                    rate: 0,
                    amount: 0,
                  },
                ];
                setAdditionalRows(newItems);
              }}
            >
              ADD ADDITIONAL ITEM
            </button>
          </div>
        )}
      </div>
    </>
  );
}
