// import UnmuteIcon from "@mui/icons-material/VolumeUp";
// import MuteIcon from "@mui/icons-material/VolumeOff";
// import { previewText } from "../../utils/StringUtils";
// import TaskRouterUI from "./TaskRouterUI";
// import SMSIcon from "@mui/icons-material/Textsms";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import CachedIcon from "@mui/icons-material/Cached";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import DialpadIcon from "@mui/icons-material/Dialpad";
import DoneIcon from "@mui/icons-material/Done";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PauseIcon from "@mui/icons-material/Pause";
import ReplayIcon from "@mui/icons-material/Replay";
// import CallIcon from "@mui/icons-material/Call";
import RingVolumeIcon from "@mui/icons-material/RingVolume";
import RouterIcon from "@mui/icons-material/Router";
import NoSignalIcon from "@mui/icons-material/SignalCellularConnectedNoInternet0Bar";
import { Button } from "@mui/material";
import { Avatar } from "@mui/material";
import { navigate, usePath } from "raviger";
import { Device } from "twilio-client";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import { openChat } from "../../actions/ChatActions";
import {
  subscribeTopic,
  unsubscribeTopic,
} from "../../actions/NotificationActions";
import { callEnded } from "../../actions/VoiceActions";
import {
  addNotes,
  getCapabilityToken,
  getChatToken,
  getPhoneNumbers,
  identifyCaller,
  pauseRecording,
  resumeRecording,
  sendMessage,
} from "../../api/Api";
import dispatcher from "../../dispatcher";
import { deepUpdate } from "../../utils/StateUtils";
import { renderPhone } from "../../utils/StateUtils";
import {
  AcceptCallIcon,
  CallIcon,
  MarketingCampaignIcon,
  MessageIconCRMNew,
  MicroPhoneIcon,
  MuteMicroPhoneIcon,
  NoteIconCRM,
  ProfileIcon,
  TelePhone,
} from "../common/AppIcons";
import MaterialModal from "../common/MaterialModal";
import PopOverClick from "../common/PopOverClick";
import MaterialForm from "../common/form/MaterialForm";
import MaterialUiPhoneNumber from "../common/phonePicker/index";
import Dialpad from "./Dialpad";
import MarketingCampaign from "./MarketingCampaign";
import useCheckMobileScreen from "./chat/utils/useMatchMedia";

// import { Popover } from "@mui/material";

const baseState = {
  muted: false,
  log: "Connected",
  onPhone: false,
  caller: null,
  phoneNumber: "",
  isValidNumber: true,
  profilePicture: null,
};

const addEncodedFile = (setState, file) =>
  function (upload) {
    setState((state) => {
      return {
        ...state,
        attachments: [
          ...(state.attachments ?? []),
          {
            fileName: file.name,
            contentType: file.type,
            encodedFile: upload.target.result,
          },
        ],
      };
    });
  };

const startChat = async (setTwilioChatToken) => {
  getChatToken().then(setTwilioChatToken).catch(console.log);
};

const TEXT_TEMPLATE = `Hello! 
Thanks for reaching out to Help Squad.
We need your full name/address/email address, a list of items along with photos so that we can provide a rough estimate.

Thanks. `;

export default function CRMControls({ crm, setCRM, setCallWidgetCB }) {
  const isDesktopResolution = !useCheckMobileScreen();

  const [draggableStatus, setDraggableStatus] = React.useState(
    isDesktopResolution ? false : true
  );

  const [device, setDevice] = useState(new Device());
  const [muted, setMuted] = useState(false);
  const [recording, setRecording] = useState(true);
  const [showDialpad, toggleDialpad] = useState(false);
  const [activeConnection, setActiveConnection] = useState();
  const currentPath = usePath();
  const fileRef = useRef();
  const [state, setState] = useState({
    ...baseState,
    log: "Connecting",
    // TODO: Add activeUser.firstName at the end of the TextContent
    textContent: TEXT_TEMPLATE,
  });
  const [twilioChatToken, setTwilioChatToken] = useState();
  const [messageReceived, setMessageReceived] = useState(false);
  const [openChatBox, setOpenChatBox] = useState(false);
  const [associateCall, setAssociateCall] = useState(false);

  const [phoneNumberData, setPhoneNumberData] = useState([]);

  const [defaultStyle, setDefaultStyle] = useState(true);

  const [pos, setPos] = useState({ x: 0, y: 0 });

  const [callPopUp, setCallPopUp] = useState(false);

  useEffect(() => {
    setPos({ x: 0, y: 0 });
  }, [defaultStyle]);

  const handleDrag = (e, data) => {
    setPos({ x: data.x, y: data.y });
  };

  const handleDragStop = () => {
    setPos({ x: 0, y: 0 });
  };

  useEffect(() => {
    const otherRenderStates = [
      "Incoming Call",
      "Connecting",
      "Calling",
      "Unable to Connect",
    ];
    if (otherRenderStates.includes(state.log)) {
      setDefaultStyle(false);
    } else {
      setDefaultStyle(true);
    }
  }, [state.log]);

  useEffect((_) => {
    startChat(setTwilioChatToken);

    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;
  }, []);

  useEffect(() => {
    if (twilioChatToken) {
      subscribeTopic({
        topic: "/notifications/public_chat",
      });
      subscribeTopic({
        topic: "/notifications/conversation_joined",
      });
      return () => {
        unsubscribeTopic({
          topic: "/notifications/public_chat",
        });
        unsubscribeTopic({
          topic: "/notifications/conversation_joined",
        });
      };
    }
  }, [twilioChatToken]);

  const handleAction = (action) => {
    switch (action.actionType) {
      case "NOTIFICATION":
        if (action.topic.includes("/public_chat")) {
          setCRM(true);

          if (!openChatBox) setMessageReceived(true);
        }
        break;
      case "MESSAGE_RECIEVE":
        setCRM(true);

        if (!openChatBox) {
          setMessageReceived(true);
        }
        break;
      case "CLOSE_CHAT":
        setOpenChatBox(false);
        setMessageReceived(false);

        break;
      case "CHAT":
        setCRM(true);
        setOpenChatBox(true);
        setMessageReceived(false);
        break;
      case "VOICE":
        setCRM(true);
        handleToggleCall(action.callerInfo);
        break;
      case "ASSOCIATE_VOICE":
        setCRM(true);
        setAssociateCall(true);
        handleToggleCall(action.callerInfo);

        break;
      case "INCOMING_TASK":
        if (action.callerInfo.direction === "inbound") {
          setCRM(true);
          getPhoneNumbers(0, 100, action.callerInfo.called).then((data) => {
            setPhoneNumberData(data.content);
          });
        }
        setState((state) => {
          return {
            ...state,
            callerInfo: action.callerInfo,
            phoneNumber: action.callerInfo.caller,
          };
        });
        break;
      case "TASK_TIMEOUT":
      case "TASK_CANCELLED":
        rejectCall();
        break;
      default:
        break;
    }
  };

  const dispatchMessage = () =>
    sendMessage({
      channel: state.attachments ? "MMS" : "SMS",
      toActual: state.phoneNumber.replace(/[^0-9/+]/g, ""),
      content: state.textContent,
      attachments: state.attachments,
    })
      .then((_) => {
        //   updateMessages();
        alertSuccess("Message Sent");
        setDraggableStatus(false);

        setState({
          ...state,
          modal: false,
          onPhone: false,
          textContent: TEXT_TEMPLATE,
          notes: "",
          phoneNumber: "",
          log: "Call Ended",
          caller: null,
        });
      })
      .catch((_) => alertError("Error Sending Message"));

  const connect = (_) => {
    setState({ ...state, log: "Connecting" });
    getCapabilityToken()
      .then((data) => {
        device.setup(data.value);
      })
      .catch((err) => {
        setState({ ...state, log: "Could not fetch token, see console.log" });
      });
  };

  const rejectCall = () => {
    try {
      if (device.activeConnection() !== undefined)
        device.activeConnection().reject();
      setState(baseState);
    } catch (e) {
      console.log("Error Rejecting Task Cancel", e);
    }
  };
  useEffect(() => {
    if (state.log === "Calling" || state.log === "Incoming Call") {
      setCallWidgetCB(true);
    } else {
      setCallWidgetCB(false);
    }

    state.caller === null &&
      (state.log === "Calling" || state.log === "Incoming Call") &&
      state.phoneNumber &&
      !state.caller &&
      identifyCaller(state.phoneNumber.replace(/[^0-9/+]/g, ""))
        .then((callerId) => {
          setState((state) => {
            return {
              ...state,
              caller: `${callerId.contactFirstName} ${callerId.contactLastName}`,
              profilePicture: callerId.profilePicture,
              navigatePath: callerId.associateId
                ? `/associates/${callerId.associateId}/messages`
                : callerId.supplierId
                ? `/suppliers/${callerId.supplierId}/messages`
                : callerId.clientId
                ? `/commercial/${callerId.clientId}/messages/${callerId.contactId}`
                : `/individual/${callerId.contactId}/messages/${callerId.contactId}`,
            };
          });
        })
        .catch((err) => {
          setState((state) => {
            return {
              ...state,
              caller: "New Client",
              navigatePath: `/clients/new/${state.phoneNumber}`,
            };
          });
          alertInfo("New Client");
        });

    if (
      (state.log === "Calling" || state.log === "Incoming Call") &&
      state.navigatePath
    ) {
      if (
        !currentPath.includes(state.navigatePath) &&
        associateCall === false
      ) {
        navigate(state.navigatePath);
      }
    }

    state.caller === null &&
      (state.log === "Calling" || state.log === "Incoming Call") &&
      setRecording(true);
    if (state.log === "Unable to Connect") connect();
  }, [state]);

  useEffect(() => {
    if (activeConnection) {
      console.log("Updated Connection");
    }
  }, [activeConnection]);

  useEffect(() => {
    console.log("Mount Controls");
    // Fetch Twilio capability token from server
    connect();

    // Configure event handlers for Twilio device
    device.on("disconnect", function (e) {
      console.log("Call Disconnected", e);
      callEnded();
      setState((state) => {
        if (state.notes) {
          addNotes({ twilioSID: e.parameters.CallSid, notes: state.notes })
            .then((_) => {
              alertInfo("Call Ended. Saved Notes");
            })
            .catch((_) => {
              alertError("Call Ended. Couldn't Save Notes");
            });
        }
        return {
          ...state,
          onPhone: false,
          notes: "",
          phoneNumber: "",
          log: "Call Ended",
          caller: null,
        };
      });
    });
    device.on("cancel", function (e) {
      console.log("Call Cancelled", e);
      setState((state) => {
        return {
          ...state,
          onPhone: false,
          notes: "",
          phoneNumber: "",
          log: "Call Ended",
          caller: null,
        };
      });
    });

    device.on("ready", function () {
      setState((state) => {
        return { ...state, log: "Connected", caller: undefined };
      });
    });

    device.on("error", function (error) {
      setState({ ...state, log: "Unable to Connect" });
      console.log("Twilio Voice; Connection Lost; ", JSON.stringify(error));
    });

    device.on("connect", function (connection) {
      console.log("Device Connected", connection);
      setState((state) => {
        return { ...state, callSid: connection.parameters["CallSid"] };
      });
      console.log("Call Connected");
    });

    device.on("incoming", function (connection) {
      console.log("Incoming Call", connection);
      setState((state) => {
        return {
          ...state,
          log: "Incoming Call",
          phoneNumber: undefined,
          caller: null,
        };
      });
      setActiveConnection(connection);
    });

    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;

    // Testing static Capability Token
    // device.setup("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBQzhkZTkyZTY4Mzc2Mjg0MTkzMzI5Zjg0YjEzNzFhMTMwIiwiZXhwIjoxNTk5Mzg3NDgzLCJzY29wZSI6InNjb3BlOmNsaWVudDppbmNvbWluZz9jbGllbnROYW1lPXJhamFwcGFuIHNjb3BlOmNsaWVudDpvdXRnb2luZz9hcHBTaWQ9QVBmNjQ5NTVkNGJkODcwOTJmZTFhY2YzZGVhYWU2N2NmYSJ9.Gz47g1-jT7BQ7eXgKXe_GB0OWtdfh9UqU0A8UVHiuv4");
  }, []);

  // Handle number input
  const handleChangeNumber = (value) => {
    setState({
      ...state,
      phoneNumber: value,
      isValidNumber: /^\+([0-9]|#|\*)+$/.test(value.replace(/[-()\s]/g, "")),
    });
  };

  // Handle muting
  const handleToggleMute = () => {
    var updatedMuted = !muted;
    setMuted(updatedMuted);
    device.activeConnection().mute(updatedMuted);
  };

  // Handle muting
  const handleToggleRecording = () => {
    recording
      ? pauseRecording({ value: state.callSid })
          .then((_) => setRecording(false))
          .catch((_) => alertError("Couldn't Pause Recording"))
      : resumeRecording({ value: state.callSid })
          .then((_) => setRecording(true))
          .catch((_) => alertError("Couldn't Resume Recording"));
  };

  // Make an outbound call with the current number,
  // or hang up the current call
  const handleToggleCall = (callInfo) => {
    const phoneNumber = callInfo?.number ?? state.phoneNumber;
    const callerId = callInfo?.callerId ?? null;
    const profilePicture = callInfo?.profilePicture ?? null;

    console.log("Fsafsafsafsdafsdaf", callInfo);

    if (!state.onPhone) {
      // make outbound call with current number
      let payload = {
        number: phoneNumber.replace(/[^0-9/+]/g, ""),
        phoneNumberId: Number(
          JSON.parse(localStorage.getItem("marketingCampaignState"))
            ?.phoneNumberId
        ),
      };

      if (callInfo?.twilio === false) {
        payload = {
          number: phoneNumber.replace(/[^0-9/+]/g, ""),
        };
      }

      console.log("Fsadfsadfsdaffdsaf", payload);

      setActiveConnection(device.connect(payload));

      if (callInfo) {
        getPhoneNumbers(0, 100, callInfo.called || callInfo.number).then(
          (data) => {
            setPhoneNumberData(data.content);
          }
        );
      }
      setState({
        ...state,
        phoneNumber: phoneNumber,
        caller: callerId,
        log: "Calling",
        muted: false,
        onPhone: true,
        twilio: callInfo?.twilio === false ? false : true,
        profilePicture: profilePicture,
      });
    } else if (!callInfo) {
      // hang up call in progress
      device.disconnectAll();
    }
  };

  const iconControls = () => {
    switch (state.log) {
      case "Unable to Connect":
        return <CloseIcon style={{ color: "red" }} />;
      case "Calling":
        return <CallIcon />;
      case "Connecting":
        return <CachedIcon style={{ color: "yellow" }} />;
      case "Connected":
        return <DoneIcon style={{ color: "green" }} />;
      case "Incoming Call":
        return <RingVolumeIcon style={{ color: "green" }} />;
      default:
        return "";
    }
  };

  const renderControls = () => {
    switch (state.log) {
      case "Incoming Call":
        return (
          <>
            <div className="text-left flex gap-2">
              <div className="flex gap-1 md:gap-2 md:w-full items-center text-white justify-between flex-col md:flex-row">
                <div className="flex flex-row gap-4 items-center">
                  <div>
                    {phoneNumberData.length === 1 && (
                      <div className="">
                        <button className="bg-newGray-1000 text-white font-semibold py-1 px-4 rounded-full inline-flex items-center cursor-default">
                          <div className="flex flex-row items-center">
                            <div>
                              <MarketingCampaignIcon />
                            </div>
                            <div className="flex flex-col px-2 items-start">
                              <div className="">
                                {phoneNumberData[0].friendlyName}
                              </div>
                              <div className="">
                                <span
                                  className="text-white bg-newGray-1000 inline-flex items-center rounded px-2 py-1 text-xs font-medium estimateStatus"
                                  style={{}}
                                >
                                  {renderPhone(phoneNumberData[0].phone)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="">
                    <button className="bg-newGray-1000 text-white font-semibold py-1 px-4 rounded-full inline-flex items-center cursor-default">
                      <div className="flex flex-row items-center">
                        <div>
                          <Avatar
                            src={state.profilePicture}
                            variant="square"
                            className="h-8 w-8 rounded"
                          ></Avatar>
                        </div>
                        <div className="flex flex-col px-2 items-start">
                          <div className="">
                            {state.caller ?? "Identifying"}
                          </div>
                          <div className="">
                            {state.phoneNumber && (
                              <span
                                className="text-white bg-newGray-1000 inline-flex items-center rounded px-2 py-1 text-xs font-medium estimateStatus"
                                style={{}}
                              >
                                {renderPhone(state.phoneNumber)}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>

                  <div>
                    <button
                      className="px-2 py-2 rounded-full mx-auto bg-green-600 items-center text-xs md:text-base flex gap-2 text-white"
                      onClick={(_) => {
                        activeConnection.accept();
                        setState((state) => {
                          return { ...state, onPhone: true, log: "Calling" };
                        });
                      }}
                    >
                      {
                        <p className="flex md:gap-1 items-center justify-center px-2">
                          <AcceptCallIcon />{" "}
                          <span className="px-1">Accept</span>
                        </p>
                      }
                    </button>
                  </div>
                  <div className="px-2">
                    <button
                      className="rounded-full py-2 px-2 mx-auto bg-newRed-400 items-center text-xs md:text-base flex gap-2 text-white"
                      onClick={rejectCall}
                    >
                      {
                        <p className="flex md:gap-1 items-center justify-center px-2">
                          <CallEndIcon /> <span className="px-1">Reject</span>
                        </p>
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </>
        );
      case "Connecting...":
        return (
          <p>
            <RouterIcon className="h-4 w-4" /> Connecting{" "}
          </p>
        );
      case "Calling": {
        // handleDragStop();
        return (
          <div className="text-left flex gap-2">
            <div className="flex gap-1 md:gap-2 md:w-full items-center text-white justify-between flex-col md:flex-row">
              <div className="flex flex-row gap-4 items-center">
                <div>
                  {phoneNumberData.length === 1 && state.twilio && (
                    <div className="">
                      <button className="bg-newGray-1000 text-white font-semibold py-1 px-4 rounded-full inline-flex items-center cursor-default">
                        <div className="flex flex-row items-center">
                          <div>
                            <MarketingCampaignIcon className="h-4 w-4" />
                          </div>
                          <div className="flex flex-col px-2 items-start">
                            <div className="">
                              {phoneNumberData[0].friendlyName}
                            </div>
                            <div className="">
                              <span
                                className="text-white bg-newGray-1000 inline-flex items-center rounded px-2 py-1 text-xs font-medium estimateStatus"
                                style={{}}
                              >
                                {renderPhone(phoneNumberData[0].phone)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                  )}
                </div>
                <div className="">
                  <button className="bg-newGray-1000 text-white font-semibold py-1 px-4 rounded-full inline-flex items-center cursor-default">
                    <div className="flex flex-row items-center">
                      <div>
                        <Avatar
                          src={state.profilePicture}
                          variant="square"
                          className="h-8 w-8 rounded"
                        ></Avatar>
                      </div>
                      <div className="flex flex-col px-2 items-start">
                        <div className="">{state.caller ?? "Identifying"}</div>
                        <div className="">
                          {state.phoneNumber && (
                            <span
                              className="text-white bg-newGray-1000 inline-flex items-center rounded px-2 py-1 text-xs font-medium estimateStatus"
                              style={{}}
                            >
                              {renderPhone(state.phoneNumber)}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div>
                <button
                  className=" rounded-full px-2 py-2 mx-auto bg-newRed-400  items-center whitespace-nowrap text-xs md:text-base flex gap-2 text-white"
                  onClick={(_) => {
                    device.disconnectAll();
                  }}
                  disabled={!state.isValidNumber}
                >
                  <p className="flex md:gap-1 items-center justify-center">
                    <CallEndIcon className="h-4 w-4" /> End Call{" "}
                  </p>
                </button>
              </div>
              <div>
                <PopOverClick
                  bgColor="black"
                  renderPopOver={(_) => <Dialpad twilioDevice={device} />}
                >
                  <div>
                    <span className="py-2 px-2 bg-newGray-1000 rounded-full items-center   text-xs md:text-base flex gap-2 text-white">
                      <p className="mx-auto">
                        <DialpadIcon className="h-4 w-4" />
                      </p>
                    </span>
                  </div>
                </PopOverClick>
              </div>
              <div>
                <button
                  className={
                    "py-2 px-2  rounded-full items-center text-xs md:text-base flex gap-2 text-white " +
                    (muted ? " bg-newRed-400" : "bg-newGray-1000")
                  }
                  onClick={(_) => {
                    handleToggleMute();
                  }}
                >
                  {muted ? (
                    <p className="mx-auto">
                      <MuteMicroPhoneIcon className="h-4 w-4" />
                    </p>
                  ) : (
                    <p className="mx-auto">
                      <MicroPhoneIcon className="h-4 w-4" />
                    </p>
                  )}
                </button>
              </div>
              <div>
                <button
                  className="py-2 px-2 bg-newGray-1000 rounded-full items-center flex gap-2 text-white"
                  onClick={(_) => {
                    handleToggleRecording();
                  }}
                >
                  {recording ? (
                    <p className="has-tooltip mx-auto">
                      <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-700 -mt-8 delay-500">
                        Pause Recording
                      </span>
                      <PauseIcon className="h-4 w-4" />
                    </p>
                  ) : (
                    <p className="has-tooltip mx-auto">
                      <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-700 -mt-8 delay-500">
                        Resume Recording
                      </span>
                      <FiberManualRecordIcon className="h-4 w-4" />
                    </p>
                  )}
                </button>
              </div>
              <div>
                <PopOverClick
                  className="bg-newGray-1000 text-white cursor-pointer rounded-full px-4 py-2"
                  renderPopOver={(_) => (
                    <textarea
                      value={state.notes}
                      onChange={(e) =>
                        setState({ ...state, notes: e.target.value })
                      }
                    />
                  )}
                >
                  <div className="flex flex-row items-center">
                    <div>
                      <NoteIconCRM className="h-4 w-4" />
                    </div>
                    <div className="px-1">Notes</div>
                  </div>
                </PopOverClick>
              </div>
            </div>
          </div>
        );
      }
      case "Unable to Connect":
        return (
          <div className="text-left flex">
            <div className="flex gap-2 p-2 w-full justify-between">
              <p>
                <NoSignalIcon className="h-4 w-4" /> Unable to Connect{" "}
              </p>
            </div>
            <button
              className="px-2 bg-green-600 rounded-lg items-center w-56 flex gap-2 text-white"
              onClick={(_) => connect()}
              disabled={!state.isValidNumber}
            >
              <p className="mx-auto">
                <ReplayIcon className="h-4 w-4" /> Retry{" "}
              </p>
            </button>
          </div>
        );
      default:
        return (
          <div className="flex flex-row items-center">
            <div className="px-2">
              <div className="flex flex-row items-center">
                <div>
                  {/* <TaskRouterUI isDesktopResolution={isDesktopResolution} /> */}
                </div>
              </div>
            </div>

            <>
              <div className="flex gap-2 bg-white p-1 px-2 rounded-full">
                {!state.onPhone && (
                  <>
                    <MaterialUiPhoneNumber
                      id="outlined-basic"
                      placeholder="Enter Phone Number"
                      defaultCountry={"us"}
                      value={state.phoneNumber}
                      onChange={handleChangeNumber}
                      fullWidth
                    />
                    <button
                      onClick={(_) => {
                        setCallPopUp(true);
                        // handleToggleCall();
                      }}
                      disabled={!state.isValidNumber}
                    >
                      <CallIcon className="h-4 w-4" />
                    </button>
                    <button
                      onClick={(_) => {
                        setDraggableStatus(true);
                        setState({
                          ...state,
                          textContent: state.textContent ?? TEXT_TEMPLATE,
                          modal: true,
                        });
                      }}
                      disabled={!state.isValidNumber}
                    >
                      <MessageIconCRMNew className="h-4 w-4" />
                    </button>
                  </>
                )}
              </div>
            </>

            <div className="px-2">
              <ChatIcon
                className={
                  "cursor-pointer text-white " +
                  (messageReceived && !openChatBox
                    ? "animate-bounce"
                    : "animate-none")
                }
                onClick={(_) => {
                  openChat();
                }}
              />
            </div>
          </div>
        );
    }
  };

  const handleSelectFile = (event) => {
    // console.log("Uploading to S3");
    var files = event.target.files;
    Array.from(files).forEach((file) => {
      // console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = addEncodedFile(setState, file);
      reader.readAsDataURL(file);
    });
  };

  const onChange = (update) => {
    const { name, value } = update;
    setState((data) => deepUpdate(name, value, data));
  };

  return crm ? (
    <Draggable
      className="cursor-move"
      disabled={draggableStatus}
      onDrag={(e, data) => handleDrag(e, data)}
      position={state.log === "Incoming Call" ? { x: 0, y: 0 } : pos}
    >
      <div
        className={
          "fixed flex items-center  py-2  pr-10 bottom-0 " +
          (defaultStyle
            ? "justify-end right-0"
            : "justify-center w-full left-0")
        }
      >
        <div
          className={
            "cursor-move p-2 rounded-full " +
            (defaultStyle ? "bg-newBlue-1200" : "bg-black")
          }
        >
          {renderControls()}

          <MaterialModal
            containerClassName="max-w-md w-full"
            className="pb-12"
            open={state.modal ?? false}
            setOpen={(_) => {
              setDraggableStatus(false);

              setState({
                ...state,
                textContent: state.textContent,
                modal: false,
              });
            }}
          >
            <MaterialForm
              className="w-full flex flex-col "
              data={state}
              onChange={onChange}
              renderArray={[
                { name: "phoneNumber", inputType: "PHONE" },
                { name: "textContent", inputType: "AREA", variant: "OUTLINED" },
              ]}
            />
            <div className="flex flex-wrap gap-2 py-2">
              {state.attachments?.map((attachment) => (
                <p className="rounded-full bg-gray-300 flex p-2">
                  {attachment.fileName}
                  <CloseIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={(e) =>
                      setState({ ...state, attachments: undefined })
                    }
                  />
                </p>
              ))}
            </div>
            <div>
              <span
                className="flex items-center"
                onClick={(_) => fileRef.current.click()}
              >
                <AttachFileIcon className="h-4 w-4 cursor-pointer" /> Add
                Attachment
              </span>
              <input
                type="file"
                className="hidden"
                onChange={handleSelectFile}
                ref={fileRef}
                multiple
              />
            </div>
            <Button
              color="primary"
              className="float-right"
              onClick={dispatchMessage}
            >
              Send
            </Button>
            <Button
              style={{ color: "red" }}
              // color="danger"
              className="float-right"
              onClick={(_) => {
                setState({
                  ...state,
                  phoneNumber: "",
                  textContent: "",
                });
              }}
            >
              Clear
            </Button>
          </MaterialModal>
          <MaterialModal
            // containerClassName="max-w-md w-full"
            className="pb-12"
            open={callPopUp}
            setOpen={(_) => {
              setCallPopUp(false);
            }}
          >
            <span className="font-semibold text-lg">
              Select the number you would like to call from
            </span>

            <div className="py-2">
              <MarketingCampaign
                // classes={"bg-gray-300"}
                stateChangeCB={(selected) => {}}
              />
            </div>
            <div className="flex flex-row justify-end py-4">
              <div className="px-2">
                <button
                  className="px-2 py-1 rounded text-newGray-300 border border-newGray-200"
                  onClick={() => {
                    setCallPopUp(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="px-2 py-1 bg-newBlue-400 rounded"
                  onClick={(_) => {
                    handleToggleCall();
                    setCallPopUp(false);
                  }}
                  disabled={!state.isValidNumber}
                >
                  <div className="flex flex-row items-center">
                    <div className="px-2">
                      <TelePhone className="h-4 w-4" />
                    </div>
                    <div className="text-white font-semibold">Call Now</div>
                  </div>
                </button>
              </div>
            </div>
          </MaterialModal>
        </div>
      </div>
    </Draggable>
  ) : (
    <></>
  );
}
