import parsePhoneNumber from "libphonenumber-js";

export const PreviewJSON = ({ data }) => {
  return (
    <span className="text-gray-800 whitespace-pre-wrap">
      {JSON.stringify(data, null, 2)}
    </span>
  );
};

export const previewText = (someString, sliceLength = 50) => {
  if (someString.length <= sliceLength) return someString;
  else return `${someString.slice(0, sliceLength)}..`;
};

export const properString = (someString) => {
  const notNull = someString || "";
  return notNull
    .split("_")
    .map((word) => (word.length > 0 ? capitalizeFirst(word) : word))
    .join(" ");
};

export const reverseString = (someString) => {
  const notNull = someString || "";
  return notNull
    .split(" ")
    .map((word) => (word.length > 0 ? word.toUpperCase() : word))
    .join("_");
};

export const capitalizeFirst = (string) => {
  if (string)
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  else return "";
};

export const estimateAddress = (obj) => {
  const fields = [
    obj?.streetAddress1 || "",
    obj?.unitNumber ? obj.unitNumber : obj?.streetAddress2 || "",
    `${obj?.city || ""}, ${obj?.state || ""} ${obj?.zip || ""}`,
  ];

  let str = "";

  fields.forEach((field, index) => {
    if (field.length > 1) {
      str += field + "\n";
    }
  });

  return str;
};

export const renderAddressWithoutCity = (obj) => {
  const fields = [obj?.streetAddress1 || "", obj?.streetAddress2 || ""];
  return (
    fields
      ?.filter((field) => field !== null && field !== undefined && field !== "")
      .reduce(
        (acc, curr, i, arr) => acc + curr + (i < arr.length - 1 ? ", " : ""),
        ""
      ) || ""
  );
};

export const renderAddressUnitNumber = (obj) => {
  const fields = [
    obj?.streetAddress1 || "",
    obj?.unitNumber || "",
    obj?.streetAddress2 || "",
    obj?.city || "",
    obj?.zip || "",
  ];
  return (
    fields
      ?.filter((field) => field !== null && field !== undefined && field !== "")
      .reduce(
        (acc, curr, i, arr) => acc + curr + (i < arr.length - 1 ? ", " : ""),
        ""
      ) || ""
  );
};

export const renderAddress = (obj) => {
  const fields = [
    obj?.streetAddress1 || "",
    obj?.streetAddress2 || "",
    obj?.city || "",
    obj?.zip || "",
  ];
  return (
    fields
      ?.filter((field) => field !== null && field !== undefined && field !== "")
      .reduce(
        (acc, curr, i, arr) => acc + curr + (i < arr.length - 1 ? ", " : ""),
        ""
      ) || ""
  );
};

export const properString2 = (someString) => {
  const notNull = someString || "";
  return notNull
    .split("_")
    .map((word) => word)
    .join(" ");
};

export const properStrings2 = (stringArr) =>
  stringArr
    .map((text) => properString2(text))
    .filter((text) => text !== null && text !== undefined && text !== "")
    .reduce(
      (acc, curr, i, arr) => acc + curr + (i < arr.length - 1 ? ", " : ""),
      ""
    ) || "";

export const properStrings = (stringArr) =>
  stringArr
    .map((text) => properString(text))
    .filter((text) => text !== null && text !== undefined && text !== "")
    .reduce(
      (acc, curr, i, arr) => acc + curr + (i < arr.length - 1 ? " " : ""),
      ""
    ) || "";

export const renderPhone = (number) => {
  if (number === null || number === undefined || number === "") return "";

  const phoneNumber = parsePhoneNumber(number);

  if (phoneNumber?.country === "US") return phoneNumber?.formatNational();
  else return phoneNumber?.formatInternational();
};

export const lowerCase = (str) => {
  if (str === null || str === undefined || str === "") return "";
  return str.toLowerCase();
};

export const singularText = (text) => {
  if (text === null || text === undefined || text === "") return "";
  if (text.endsWith("s")) return text.slice(0, -1);
  else return text;
};
