import { useEffect, useState } from "react";

import { Tab } from "@headlessui/react";

import { getUnitById } from "../../../../api/Api";
import { DocumentsTab } from "./DocumentsTab";
import { LeaseTab } from "./LeaseTab";
import ScreeningTab from "./ScreeningTab";
import { SpareTab } from "./SpareTab";
import ApplicationTab from "./application/ApplicationTab";

type TenantScreeningProps = {
  unitId: number;
  leaseId?: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  buildingName: string;
};

export function TenantScreening({
  unitId,
  leaseId,
  setCurrentTab,
  buildingName,
}: TenantScreeningProps) {
  const tenantScreenTabs = [
    { label: "Application", value: "application" },
    { label: "Screening", value: "screening" },
  ];

  const [unitNumber, setUnitNumber] = useState("");

  useEffect(() => {
    getUnitById(unitId).then((data) => {
      setUnitNumber(data.unitNumber);
    });
  }, [unitId]);

  const [selectedTab, setSelectedTab] = useState("application");

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row p-2 w-max rounded bg-gray-200  text-xs font-normal text-gray-700 mb-4">
        <button
          className="hover:text-newBlue-400 focus:outline-none hover:underline mr-4"
          onClick={() => setCurrentTab("unitListing")}
        >
          {buildingName}
        </button>
        {`>`}
        <button
          className="hover:text-newBlue-400 focus:outline-none hover:underline mx-4"
          onClick={() => {
            setCurrentTab("unitDetails");
          }}
        >{`Unit #${unitNumber}`}</button>
        {`>`}
        <button
          className="hover:text-newBlue-400 focus:outline-none hover:underline mx-4"
          onClick={() => {
            setCurrentTab("rentLeaseDetails");
          }}
        >
          Rent/Lease
        </button>
        {`>`}
        <div className="ml-4">
          {`${
            tenantScreenTabs.find((item) => item.value === selectedTab)?.label
          }`}
        </div>
      </div>
      <div className="text-2xl font-medium text-gray-900">
        Manage Rent/Lease
      </div>
      <Tab.Group
        selectedIndex={tenantScreenTabs.findIndex(
          (item) => item.value === selectedTab
        )}
        onChange={(index) => {
          setSelectedTab(tenantScreenTabs[index].value);
        }}
      >
        <div className="flex flex-row  w-full  mt-6">
          <div className="w-32 ">
            <div className=" border-gray-200">
              <Tab.List className=" flex flex-col " aria-label="Tabs">
                {tenantScreenTabs.map((item) => (
                  <Tab
                    key={item.value}
                    className={"flex focus:outline-none w-32 border-r "}
                  >
                    {({ selected }) => (
                      <span
                        className={
                          "cursor-pointer border-transparent text-base hover:text-gray-700  whitespace-nowrap py-1 my-1 pl-2 font-medium " +
                          (selected
                            ? "text-newBlue-400 border-l-4 border-newBlue-400 "
                            : "text-newGray-800 ml-1")
                        }
                      >
                        {item.label}
                      </span>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>
          </div>
          <div className="w-full">
            <Tab.Panels>
              <Tab.Panel>
                <ApplicationTab
                  unitId={unitId}
                  leaseId={leaseId}
                  setScreeningTab={setSelectedTab}
                  setCurrentTab={setCurrentTab}
                />
              </Tab.Panel>
              <Tab.Panel>
                <ScreeningTab
                  unitId={unitId}
                  leaseId={leaseId}
                  setCurrentTab={setCurrentTab}
                />
              </Tab.Panel>
              <Tab.Panel>
                <LeaseTab />
              </Tab.Panel>
              <Tab.Panel>
                <SpareTab />
              </Tab.Panel>
              <Tab.Panel>
                <DocumentsTab />
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </div>
      </Tab.Group>
    </div>
  );
}
