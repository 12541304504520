import { useEffect, useState } from "react";

import { navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import { getPhoneNumbers, getVendorA2P } from "../../api/Api";
import MaterialTable from "../../components/common/MaterialTable";
import { renderPhone } from "../../utils/StringUtils";
import MaterialModal from "../../components/common/MaterialModal";
import ComplianceStatusModal from "./ComplianceStatusModal";

type PhoneNumber = {
  id: number;
  phone: string;
  vendorId: number;
  friendlyName: string;
};

export type ComplainceData = {
  businessName: string,
  websiteUrl: string,
  businessType: string,
  businessEmail: string,
  idType: string,
  businessIndustry: string,
  jobPosition: string,
  lastName: string,
  phoneNumber: string,
  firstName: string,
  email: string,
  businessTitle: string,
  businessRegistrationNumber: string,
  brandStatus: string,
  campaignStatus: string,
  customerProfileStatus: string
}

export default function ListPhoneNumbers() {
  const [phoneNumbersData, setPhoneNumbersData] = useState<PhoneNumber[]>([]);
  const [filteredNumbers, setFilteredNumbers] = useState<PhoneNumber[]>([]);

  const [searchText, setSearchText] = useState("");
  const [complianceModal, setComplianceModal] = useState(false);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const [complainceData, setComplainceData] = useState<ComplainceData>();

  const [edit, setEdit] = useState({
    display: false,
    skill: "",
    vendorId: 0,
    isEdit: false,
  });

  useEffect(() => {
    getPhoneNumbers(page, size)
      .then((data) => {
        setPhoneNumbersData(data.content as PhoneNumber[]);
        setEdit({
          display: false,
          skill: "",
          vendorId: data.id,
          isEdit: false,
        });
      })
      .catch(() => alertError("Error while fetching Numbers"));
  }, [page, size]);

  const getTableHead = () => {
    return [
      { id: "number", label: "Number" },
      { id: "friendlyName", label: "Friendly Name" },
    ];
  };

  const handleOnClickA2P = () => {


    getVendorA2P().then((data) => {
      setComplainceData(data)
      if (data.customerProfileStatus === null)
        navigate("/phone_number/compliance");
      else {
        setComplianceModal(true)
      }
    })
  }

  useEffect(() => {
    if (searchText === "") setFilteredNumbers(phoneNumbersData);
    else {
      const filteredData = phoneNumbersData?.filter(
        (item) =>
          item.friendlyName.includes(searchText) ||
          item.phone.includes(searchText)
      );
      setFilteredNumbers(filteredData);
    }
  }, [phoneNumbersData, searchText]);

  const getTableData = () => {
    let data = [];
    data = filteredNumbers?.map((item: PhoneNumber) => {
      return {
        id: item.id,
        data: [
          {
            render: (
              <div className="text-newBlue-400 font-semibold text-sm">
                {renderPhone(item.phone)}
              </div>
            ),
          },
          {
            render: (
              <div className="font-medium text-sm">{item.friendlyName}</div>
            ),
          },
        ],
      };
    });
    return data;
  };

  return (
    <div className=" flex  flex-col justify-between items-center pt-6 px-4 h-fit">
      <div className="flex flex-row w-full justify-between items-center ">
        <div className="relative w-2/6">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-newGray-300 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-800"
            placeholder="Search..."
            required
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
        <div>
          <button
            className="border rounded text-sm px-2 py-1 border-newBlue-400 text-newBlue-400 mr-4"
            onClick={() => {
              handleOnClickA2P()
            }}
          >
            <div className="px-2 flex">
              <div className="font-medium">Twilio A2P Compliance</div>
            </div>
          </button>
          <button
            className="border rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400"
            onClick={() => {
              navigate("/settings/buy_new_number");
            }}
          >
            <div className="px-2 flex">
              <div className="font-medium">Buy New Phone Number</div>
            </div>
          </button>
        </div>
      </div>
      <div className=" py-4 w-full h-min">
        <div className="border-t ">
          <MaterialTable
            data={getTableData()}
            head={getTableHead()}
            headRenderColor="text-newGray-300"
            defaultOrder="desc"
            onClickCB={(selection: number) => {
              setEdit({
                display: true,
                skill: selection.toString(),
                vendorId: edit.vendorId,
                isEdit: true,
              });
            }}
            pageRows={size}
            loading={undefined}
            defaultOrderBy={undefined}
            multiSelect={undefined}
            setSelected={undefined}
            currentPage={page}
            totalData={undefined}
            searchText={undefined}
            paginationCB={(value: number) => {
              setPage(value);
            }}
            rowChangeCB={(value: number) => {
              setSize(value);
            }}
          />
        </div>
      </div>
      {complianceModal && (
        <MaterialModal
          open={complianceModal}
          setOpen={(_) =>
            setComplianceModal(false)
          }
          label="compliance"
          describedBy="compliance_modal"
        >
          <ComplianceStatusModal
            brandStatus={complainceData?.brandStatus ?? ""}
            campaignStatus={complainceData?.campaignStatus ?? ""}
            profileStatus={complainceData?.customerProfileStatus ?? ""}
            onClose={() => setComplianceModal(false)}
            onEdit={() => navigate("/phone_number/compliance")} />
        </MaterialModal>
      )}
    </div>
  );
}
