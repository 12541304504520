import React, { useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Link, navigate } from "raviger";

import {
  alertInfo,
  alertSuccess,
  alertError,
} from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import { updateBuildingNewApi, removeBuilding } from "../../api/Api";
import { deepUpdate } from "../../utils/StateUtils";
import { properString, renderAddress } from "../../utils/StringUtils";
import { buildingTypes } from "../common/form/constants";
import { CaretRight } from "./ClientAppIcons";
import { BuildingIcon } from "./ClientAppIcons";
import RenderAddressComponent from "./RenderAddressComponent";
import BuildingFormFields from "./forms/BuildingFormFields";

export default function BuildingInfo({
  clientData,
  selectedBuilding,
  context = "external",
  refreshCB,
}) {
  const [edit, setEdit] = useState(false);
  const [buildingData, setBuildingData] = useState({});
  const [, setSubAddressOptions] = useState([]);

  const { withConfirmation } = useAppActions();

  const onChange = (update) => {
    if (update.name === "address.streetAddress1") {
      if (update.subOptions[0]) setSubAddressOptions(update.subOptions);
    }

    let { name, value } = update;
    setBuildingData((data) => deepUpdate(name, value, data));
  };

  useEffect(() => {
    if (selectedBuilding) {
      setBuildingData({
        coverImage: selectedBuilding?.coverImage,
        territory: selectedBuilding?.territory,
        buildingId: selectedBuilding?.buildingId,

        buildingType: selectedBuilding?.buildingType,

        accessCode: selectedBuilding?.accessCode,
        propertyShortName: selectedBuilding?.propertyShortName,
        address: {
          streetAddress1: selectedBuilding?.address?.streetAddress1,
          streetAddress2: selectedBuilding?.address?.streetAddress2,
          city: selectedBuilding?.address?.city,
          state: selectedBuilding?.address?.state,
          zip: selectedBuilding?.address?.zip,
          addressId: selectedBuilding?.address?.addressId,
          melissaBaseMak: selectedBuilding?.address?.melissaBaseMak,
        },
      });
    }
  }, [selectedBuilding]);

  return (
    <div className="flex flex-col border border-newGray-1500 w-full md:w-80 rounded self-start">
      {edit ? (
        <>
          <BuildingFormFields data={buildingData} onChange={onChange} />

          <div className="flex flex-row items-center py-4 justify-end">
            <div className="">
              <button
                className="px-2 py-1 rounded bg-newGray-1400"
                onClick={() => {
                  setEdit(false);
                }}
              >
                Cancel
              </button>
            </div>
            <div className="px-2">
              <button
                className="rounded px-2 py-1 bg-newBlue-500 text-newBlue-400"
                onClick={() => {
                  alertInfo("Please hold on while we save your changes.");
                  updateBuildingNewApi(selectedBuilding.buildingId, {
                    ...buildingData,
                    address: {
                      ...buildingData.address,
                      streetAddress2: buildingData.address.streetAddress2 || "",
                    },
                  })
                    .then(({ value }) => {
                      const updatedBuildingId = value;
                      if (selectedBuilding.buildingId !== updatedBuildingId) {
                        alertInfo(
                          "Existing Building Found. Merging both buildings."
                        );
                        alertSuccess("Building Info updated successfully.");
                        navigate(`/building/${updatedBuildingId}/summary`);
                        return;
                      }

                      setEdit(false);
                      refreshCB({ type: "building" });
                      alertSuccess("Building Info updated successfully.");
                    })
                    .catch((_err) => {
                      alertInfo(
                        "Sorry, we could not save your changes. Please try again later."
                      );
                    });
                }}
              >
                Save
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-row justify-between items-center p-4 border-b border-newGray-1500">
            <div className="font-semibold text-sm">Building Info</div>
            <div
              className="text-newBlue-400 font-semibold text-sm cursor-pointer"
              onClick={() => {
                setEdit(true);
              }}
            >
              Edit
            </div>
          </div>
          {selectedBuilding?.coverImage ? (
            <img
              src={selectedBuilding?.coverImage}
              className="w-full h-40 object-cover border border-gray-300"
              alt="client"
            />
          ) : (
            <div className="flex flex-row items-center px-4 py-4">
              <div className="bg-newGray-1300 px-4 py-4 rounded">
                <BuildingIcon color={"#fff"} />
              </div>
              <div>
                <span className="text-newGray-200 text-sm px-2">
                  No Image Available
                </span>
              </div>
            </div>
          )}
          {[
            {
              label: "Address",
              value: selectedBuilding?.address,
            },

            {
              label: "Building Type",
              value: buildingTypes.find(
                (item) => item.value === selectedBuilding?.buildingType
              )?.label,
            },
            {
              label: "Territory",
              value: selectedBuilding?.territory
                ? properString(selectedBuilding?.territory)
                : "----",
            },
            {
              label: "Access Code",
              value: selectedBuilding?.accessCode
                ? selectedBuilding?.accessCode
                : "----",
            },
            {
              label: "Property Short Name",
              value: selectedBuilding?.propertyShortName
                ? selectedBuilding?.propertyShortName
                : "----",
            },
          ].map((item, index) => (
            <div className="mt-2 border-b p-1 px-4 border-newGray-1500">
              <div className="font-semibold text-sm">{item.label}</div>
              {item.label === "Address" ? (
                <>
                  <RenderAddressComponent
                    address={item.value}
                    unitNumber={`${selectedBuilding.unitNumbers.join(" | ")}`}
                    className="text-newGray-800"
                  />
                </>
              ) : (
                <div className="text-newGray-800 text-sm">{item.value}</div>
              )}
            </div>
          ))}
          <div className="border-b py-4 px-4 flex flex-col justify-center">
            {context === "external" && (
              <Link
                href={`/building/${selectedBuilding.buildingId}/summary`}
                className="text-newBlue-400 bg-newBlue-500 px-2 py-1 rounded text-sm"
              >
                <div className="flex flex-row items-center">
                  Open Building Profile
                  <div className="px-1">
                    <CaretRight />
                  </div>
                </div>
              </Link>
            )}
            {context === "profile" && (
              <button
                className="text-newBlue-400 bg-newBlue-500 px-2 py-1 rounded text-sm"
                onClick={() => {
                  alertInfo(
                    "Coming Soon",
                    "Emergency Info is not yet available in SquadHub"
                  );
                }}
              >
                <div className="flex flex-row items-center">
                  Emergency Info
                  <div className="px-1">
                    <CaretRight />
                  </div>
                </div>
              </button>
            )}
            {clientData?.clientVariant === "commercial" && (
              <button
                className="border rounded text-sm px-2 py-1 my-2 bg-newGray-700 text-newGray-800"
                onClick={() => {
                  withConfirmation({
                    title: "Remove Building",
                    description: `Are you sure you want to remove ${renderAddress(
                      selectedBuilding.address
                    )}
                  This will remove this building and the client/manager will no longer be able to access the building.
                  
                  This action cannot be undone.`,
                    onConfirm: () =>
                      removeBuilding(
                        selectedBuilding.buildingId,
                        clientData.clientId
                      )
                        .then((_res) => {
                          alertSuccess("Building removed successfully");
                          refreshCB();
                        })
                        .catch((_err) => {
                          alertError("Failed to remove building");
                        }),
                  });
                }}
              >
                <div className="flex flex-row items-center">
                  <div>
                    <DeleteIcon className="h-4 w-4" />
                  </div>
                  <div className="px-2">Remove Building</div>
                </div>
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
