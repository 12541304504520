import { useEffect, useState } from "react";

import {
  getInvoiceById,
  removeSR,
  updateMaterial,
  updateSr,
} from "../../api/Api";
import { properString } from "../../utils/StringUtils";
import { InvoiceData, ServiceRequestData } from "./InvoiceFormUtils";

const headings = ["PRODUCT", "DESCRIPTION", "QTY", "RATE", "AMOUNT", ""];

type InvoiceTableProps = {
  invoiceData: InvoiceData | undefined;
  setInvoiceData: React.Dispatch<React.SetStateAction<InvoiceData | undefined>>;
};

export default function InvoiceTable({
  invoiceData,
  setInvoiceData,
}: InvoiceTableProps) {
  const [invoiceSrs, setInvoiceSrs] = useState<ServiceRequestData[]>([]);

  useEffect(() => {
    setInvoiceSrs(invoiceData?.srs ?? []);
  }, [invoiceData]);

  const handleChange = (index: number, name: string, value: any) => {
    const tempData = invoiceSrs;
    const list = [...tempData];

    if (name === "invoiceQuantity") {
      if (!isNaN(value)) {
        list[index][name] = value;

        setInvoiceSrs(list);
      }
    } else if (value.match(/^\d*\.?\d*$/)) {
      list[index][name] = value;

      setInvoiceSrs(list);
    }
  };

  const [edit, setEdit] = useState(false);

  const [materialElement, setMaterialElement] = useState<
    {
      quantity: number;
      rate: number;
      amount: number;
    }[]
  >([]);

  const updateServiceRequest = (
    srId: number,
    quantity: number,
    rate: number,
    amount: number
  ) => {
    updateSr(srId, [
      {
        op: "replace",
        path: "/invoiceQuantity",
        value: quantity,
      },
      {
        op: "replace",
        path: "/invoiceRate",
        value: rate,
      },
      {
        op: "replace",
        path: "/invoiceAmount",
        value: amount,
      },
    ]).then(() => {
      getInvoiceById(invoiceData?.id).then((data: InvoiceData) => {
        setInvoiceData(data);
      });
    });
  };

  return (
    <div className={"shadow rounded overflow-auto w-full"}>
      <div className="flex flex-row w-full p-3 px-5 items-start">
        <span className="pr-5 py-2 font-semibold text-gray-500 text-sm w-12">
          #
        </span>
        <div className={"flex flex-grow w-full grid gap-2 grid-cols-7"}>
          {headings.map((val, index) => (
            <span
              className={
                "p-1 py-2 font-semibold text-gray-500 text-sm " +
                (index === 1 ? "col-span-2" : "") +
                " " +
                (index > 1 ? "text-right" : "")
              }
            >
              {val}
            </span>
          ))}
        </div>
      </div>
      <hr />
      {invoiceSrs.map((row, row_index) => (
        <>
          <div className={"flex flex-row w-full items-center pl-1 "}>
            <span
              className={"p-2 text-gray-700 text-sm break-words w-12 mr-2 "}
            >
              <span className="">{row_index + 1}</span>
            </span>
            <div className={"w-full grid gap-2 items-center grid-cols-7 pr-2 "}>
              <span className="p-1 py-2 break-all text-gray-700 text-sm  ">
                {row.subCategory
                  ? properString(row.subCategory)
                  : properString(row.jobType)}
              </span>
              <span className="p-1 py-2 break-all text-gray-700 text-sm col-span-2 whitespace-pre-line">
                {row.description}
              </span>
              {/* Qty Input */}
              <input
                className={
                  "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right estimate-quantity "
                }
                disabled={invoiceData?.status === "PAID"}
                type="text"
                onChange={(e) => {
                  handleChange(
                    row_index,
                    "invoiceQuantity",
                    Number(e.target.value)
                  );
                }}
                onBlur={() => {
                  updateServiceRequest(
                    row.serviceRequestId,
                    row.invoiceQuantity,
                    row.invoiceRate,
                    Number(row.invoiceQuantity) * Number(row.invoiceRate)
                  );
                }}
                value={row.invoiceQuantity}
              />
              {/* Rate Input */}
              <input
                className={
                  "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right caret-transparent estimate-rate "
                }
                disabled={invoiceData?.status === "PAID"}
                onChange={(e) => {
                  handleChange(
                    row_index,
                    "invoiceRate",
                    e.target.value.replace("$", "")
                  );
                }}
                onBlur={() => {
                  updateServiceRequest(
                    row.serviceRequestId,
                    row.invoiceQuantity,
                    row.invoiceRate,
                    Number(row.invoiceQuantity) * Number(row.invoiceRate)
                  );
                }}
                value={"$" + row.invoiceRate}
              />
              {/* Amount Span */}
              <span className="p-1 py-2 break-all text-gray-700 text-sm  text-right">
                {`$${
                  row.invoiceAmount !== null && row.invoiceAmount.toFixed(2)
                }`}
              </span>
              <button
                className={`break-all w-max items-center px-3 py-0.5 rounded text-xs font-medium 
                  ${
                    invoiceData?.status === "PAID"
                      ? "bg-newGray-700 text-newGray-800"
                      : "bg-red-500 text-red-100"
                  }`}
                onClick={() => {
                  removeSR(invoiceData?.id, row.serviceRequestId).then(() => {
                    getInvoiceById(invoiceData?.id).then(
                      (data: InvoiceData) => {
                        setInvoiceData(data);
                      }
                    );
                  });
                }}
                disabled={invoiceData?.status === "PAID"}
              >
                Delete
              </button>
            </div>
          </div>

          {row.materials.length > 0 && (
            <div className="w-full flex flex-col p-3 px-2 opacity-70">
              <div className="text-gray-600 font-semibold text-sm pt-1 w-full">
                Materials
              </div>
              {row.materials.map((material, material_index) => {
                return (
                  <div className={"flex flex-row w-full items-center pl-1 "}>
                    <span className="p-2 text-gray-700 text-sm break-words w-12 mr-2">
                      {row_index + 1}.{material_index + 1}
                    </span>
                    <div
                      className={
                        "w-full grid gap-2 items-center grid-cols-7 pr-2"
                      }
                    >
                      <span className="p-1 py-2 break-all text-gray-700 text-sm  ">
                        {material.name}
                      </span>
                      <span className="p-1 py-2 break-all text-gray-700 text-sm col-span-2 ">
                        {material.description}
                      </span>
                      <input
                        className={
                          "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right material-quantity "
                        }
                        value={
                          edit
                            ? materialElement[material.materialId].quantity
                            : material.invoiceQuantity ?? ""
                        }
                        onChange={(e) => {
                          setEdit(true);
                          materialElement[material.materialId] = {
                            quantity: 0,
                            rate: 0,
                            amount: 0,
                          };
                          const element = materialElement;
                          element[material.materialId].quantity = Number(
                            e.target.value
                          );
                          element[material.materialId].amount =
                            material.invoiceRate * Number(e.target.value);
                          element[material.materialId].rate =
                            material.invoiceRate;
                          setMaterialElement(element);
                        }}
                        onBlur={() => {
                          updateMaterial(material.materialId, [
                            {
                              op: "replace",
                              path: "/invoiceQuantity",
                              value:
                                materialElement[material.materialId].quantity,
                            },
                            {
                              op: "replace",
                              path: "/invoiceRate",
                              value: materialElement[material.materialId].rate,
                            },
                            {
                              op: "replace",
                              path: "/invoiceAmount",
                              value:
                                materialElement[material.materialId].amount,
                            },
                          ]).then(() => {
                            getInvoiceById(invoiceData?.id).then(
                              (data: InvoiceData) => {
                                setInvoiceData(data);
                                setEdit(false);
                              }
                            );
                          });
                        }}
                        disabled={invoiceData?.status === "PAID"}
                      />
                      <input
                        className={
                          "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right caret-transparent material-rate "
                        }
                        disabled={invoiceData?.status === "PAID"}
                        value={
                          edit
                            ? "$" + materialElement[material.materialId].rate
                            : "$" + material.invoiceRate ?? ""
                        }
                        onChange={(e) => {
                          setEdit(true);
                          materialElement[material.materialId] = {
                            quantity: 0,
                            rate: 0,
                            amount: 0,
                          };
                          const element = materialElement;
                          element[material.materialId].rate = Number(
                            e.target.value.replace("$", "")
                          );
                          element[material.materialId].amount =
                            material.invoiceQuantity *
                            Number(e.target.value.replace("$", ""));
                          element[material.materialId].quantity =
                            material.invoiceQuantity;
                          setMaterialElement(element);
                        }}
                        onBlur={() => {
                          updateMaterial(material.materialId, [
                            {
                              op: "replace",
                              path: "/invoiceQuantity",
                              value:
                                materialElement[material.materialId].quantity,
                            },
                            {
                              op: "replace",
                              path: "/invoiceRate",
                              value: materialElement[material.materialId].rate,
                            },
                            {
                              op: "replace",
                              path: "/invoiceAmount",
                              value:
                                materialElement[material.materialId].amount,
                            },
                          ]).then(() => {
                            getInvoiceById(invoiceData?.id).then(
                              (data: InvoiceData) => {
                                setInvoiceData(data);
                                setEdit(false);
                              }
                            );
                          });
                        }}
                      />
                      <span className="p-1 py-2 break-all text-gray-700 text-sm  text-right">
                        {`$${
                          material.invoiceAmount !== null &&
                          material.invoiceAmount?.toFixed(2)
                        }`}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      ))}
    </div>
  );
}
