import { useEffect, useState } from "react";



import Radio from "@mui/material/Radio";

import { alertError, alertSuccess } from "../../../../../actions/AlertActions";
import {
  createLease,
  endLease,
  getLeaseById,
  getUnitById,
  setCurrentLease,
  updateLease,
} from "../../../../../api/Api";
import { CloseIcon } from "../../../../../components/clients/ClientAppIcons";
import { AddIconBlue } from "../../../../../components/common/AppIcons";
import MaterialForm from "../../../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../../../utils/StateUtils";

type RentLeaseTabProps = {
  unitId: number;
  leaseId?: number;
  onBackClick: React.Dispatch<React.SetStateAction<string>>;
  buildingId: number;
  setLeaseId: React.Dispatch<React.SetStateAction<number>>;
  setNewLeaseId: React.Dispatch<React.SetStateAction<number>>;
};

export default function RentLeaseTab({
  leaseId,
  unitId,
  onBackClick,
  buildingId,
  setLeaseId,
  setNewLeaseId,
}: RentLeaseTabProps) {
  const [details, setDetails] = useState({
    leaseTerm: "",
    leaseStartDate: "",
    leaseEndDate: "",
    rolloverMonthToMonth: false,
    rentStartDate: "",
    rentAmount: 0,
    rentFrequency: "Monthly",
    setRentIncrease: false,
    increaseInPercentage: false,
    newRent: 0,
  });

  const [selectedValue, setSelectedValue] = useState("increaseInAmount");

  const [additionalCharges, setAdditionalCharges] = useState([
    {
      category: "",
      amount: 0,
      description: "",
    },
  ]);

  const handleChange = (value: string) => {
    setSelectedValue(value);
  };

  const [currentLeaseId, setCurrentLeaseId] = useState();

  const refreshLease = () => {
    getLeaseById(leaseId).then((data) => {
      setDetails({
        leaseTerm: data.leaseTerms,
        leaseStartDate: data.startDate,
        leaseEndDate: data.endDate,
        rolloverMonthToMonth: data.rentDetails?.rolloverMonthToMonth,
        rentStartDate: data.rentDetails?.startDate,
        rentAmount: data.rentDetails?.amount,
        rentFrequency: data.rentDetails?.frequency,
        setRentIncrease:
          data.rentDetails !== null ? data.rentDetails.rentIncrease : false,
        increaseInPercentage:
          data.rentDetails?.increaseAmount === null ? true : false,
        newRent:
          data.rentDetails?.increaseAmount ||
          data.rentDetails?.increasePercentage,
      });
      setSelectedValue(
        data.rentDetails?.increaseAmount === null
          ? "increaseInPercentage"
          : "increaseInAmount"
      );
      if (
        data.rentDetails !== null &&
        data.rentDetails?.additionalCharges !== null
      )
        setAdditionalCharges(data.rentDetails.additionalCharges);
    });
  };

  useEffect(() => {
    getUnitById(unitId).then((data) => {
      setCurrentLeaseId(data.currentLeaseId);
    });
    if (leaseId !== null && leaseId !== 0) refreshLease();
  }, [leaseId, unitId]);

  const onChangeCharge = (
    update: { name: "category" | "amount" | "description"; value: any },
    index: number
  ) => {
    const { name, value } = update;
    const test = [...additionalCharges];
    test[index][name] = value;

    setAdditionalCharges(test);
  };

  const onDeleteCharge = (index: number) => {
    const rows = [...additionalCharges];
    rows.splice(index, 1);
    setAdditionalCharges(rows);
  };
  const onChange = (update: { name: string; value: string }) => {
    const { name, value } = update;

    calculateLeaseDates(name, value);
    setDetails((data) => deepUpdate(name, value, data));
  };

  const frequencyOptions = [
    { value: "Monthly", label: "Monthly" },
    { value: "Annually", label: "Annually" },
    { value: "Weekly", label: "Weekly" },
  ];

  const onSave = () => {
    if (leaseId === null || leaseId === 0) {
      const rentLeaseRequest = {
        unitId: unitId,
        leaseTerms: details.leaseTerm,
        startDate: details.leaseStartDate,
        endDate: details.leaseEndDate,
        rentDetails: {
          startDate: details.rentStartDate,
          amount: details.rentAmount,
          frequency: details.rentFrequency,
          rolloverMonthToMonth: details.rolloverMonthToMonth,
          rentIncrease: details.setRentIncrease,
          increaseAmount:
            selectedValue === "increaseInPercentage" ? null : details.newRent,
          increasePercentage:
            selectedValue === "increaseInPercentage" ? details.newRent : null,
          additionalCharges: additionalCharges,
        },
      };
      createLease(rentLeaseRequest)
        .then((data) => {
          setLeaseId(data.id);
          setNewLeaseId(data.id);
          alertSuccess("Lease added successfully");
        })
        .catch(() => {
          alertError("Error while creating lease");
        });
    } else {
      updateLease(leaseId, [
        {
          op: "replace",
          path: "/leaseTerms",
          value: details.leaseTerm,
        },
        {
          op: "replace",
          path: "/startDate",
          value: details.leaseStartDate,
        },
        {
          op: "replace",
          path: "/endDate",
          value: details.leaseEndDate,
        },
        {
          op: "replace",
          path: "/rentDetails",
          value: {
            startDate: details.rentStartDate,
            amount: details.rentAmount,
            frequency: details.rentFrequency,
            rolloverMonthToMonth: details.rolloverMonthToMonth,
            rentIncrease: details.setRentIncrease,
            increaseAmount:
              selectedValue === "increaseInPercentage" ? null : details.newRent,
            increasePercentage:
              selectedValue === "increaseInPercentage" ? details.newRent : null,
            additionalCharges: additionalCharges,
          },
        },
      ])
        .then(() => {
          alertSuccess("Lease updated successfully");
        })
        .catch(() => {
          alertError("Error while updating lease");
        });
    }
  };

  const onSetLease = () => {
    setCurrentLease(unitId, leaseId).then(() => {
      alertSuccess("Successfully set as current Lease");
      getUnitById(unitId).then((data) => {
        setCurrentLeaseId(data.currentLeaseId);
        refreshLease();
      });
    });
  };

  const onEndLease = () => {
    endLease(unitId).then(() => {
      alertSuccess("Successfully end current Lease");
      getUnitById(unitId).then((data) => {
        setCurrentLeaseId(data.currentLeaseId);
        refreshLease();
      });
    });
  };
  return (
    <div className="w-full p-4">
      <div className="flex flex-col items-start self-center ">
        <div className="w-full flex flex-row items-center justify-between mb-10">
          <div className="text-lg font-medium text-black">
            Add Lease Details
          </div>
          {leaseId !== null && leaseId !== 0 && (
            <>
              {currentLeaseId === leaseId ? (
                <button
                  className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
        bg-newBlue-400  w-max
        text-white border-newBlue-400
        border"
                  onClick={onEndLease}
                >
                  End Lease
                </button>
              ) : (
                <button
                  className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
      bg-newBlue-400  w-max
      text-white border-newBlue-400
      border"
                  onClick={onSetLease}
                >
                  Set as Current Lease
                </button>
              )}
            </>
          )}
        </div>
        <div className="mt-4 pb-4">
          <MaterialForm
            className="w-full flex flex-row justify-evenly"
            data={details}
            onChange={onChange}
            fieldClassName="w-60 mr-4"
            renderArray={[
              { name: "leaseTerm", variant: "OUTLINED", inputType: "NUMBER" },
              {
                name: "leaseStartDate",
                inputType: "DATE",
                variant: "OUTLINED",
              },
              {
                name: "leaseEndDate",
                inputType: "DATE",
                variant: "OUTLINED",
              },
            ]}
          />
          <MaterialForm
            className="mt-6"
            data={details}
            onChange={onChange}
            fieldClassName=" mr-20"
            renderArray={[
              {
                name: "rolloverMonthToMonth",
                inputType: "CHECKBOX",
                variant: "OUTLINED",
                label: "Rollover Month-to-Month",
              },
            ]}
          />
        </div>
        <div className="text-lg font-medium text-black mt-4">
          Add Rent Details
        </div>
        <div className="mt-4 pb-4">
          <MaterialForm
            className="w-full flex flex-row justify-between"
            data={details}
            onChange={onChange}
            fieldClassName="w-80 mr-4"
            renderArray={[
              {
                name: "rentStartDate",
                inputType: "DATE",
                variant: "OUTLINED",
                label: "Start Date",
              },
              {
                name: "rentAmount",
                variant: "OUTLINED",
                inputType: "NUMBER",
                label: "Rent Amount($)",
              },
              {
                name: "rentFrequency",
                variant: "OUTLINED",
                inputType: "SELECT",
                label: "Frequency",
                options: frequencyOptions,
              },
            ]}
          />
          <MaterialForm
            className=""
            data={details}
            onChange={onChange}
            fieldClassName=" mr-20"
            renderArray={[
              {
                name: "setRentIncrease",
                inputType: "CHECKBOX",
                variant: "OUTLINED",
              },
            ]}
          />
        </div>
        {details.setRentIncrease && (
          <div className="mt-4 pb-4 ">
            <div className="flex flex-row items-center mb-4">
              <Radio
                checked={selectedValue === "increaseInAmount"}
                onChange={(event) => handleChange(event.target.value)}
                value="increaseInAmount"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
              <label className="text-sm font-normal mr-10">
                Increase in Amount
              </label>
              <Radio
                checked={selectedValue === "increaseInPercentage"}
                onChange={(event) => handleChange(event.target.value)}
                value="increaseInPercentage"
                name="radio-buttons"
                inputProps={{ "aria-label": "B" }}
                placeholder="test"
              />
              <label className="text-sm font-normal ">
                Increase in Percentage
              </label>
            </div>
            <MaterialForm
              className=""
              data={details}
              onChange={onChange}
              fieldClassName=" mr-20"
              renderArray={[
                {
                  name: "newRent",
                  variant: "OUTLINED",
                  inputType: "NUMBER",
                  label:
                    selectedValue === "increaseInPercentage"
                      ? "% increase"
                      : "New Rent Amount($)",
                },
              ]}
            />
          </div>
        )}
        <div className="text-lg font-medium text-black mt-4">
          Additional Charges
        </div>

        {additionalCharges?.map((item, index) => {
          return (
            <div className="flex flex-row items-center">
              <MaterialForm
                className="w-full flex flex-row "
                data={item}
                onChange={(update: {
                  name: "category" | "amount" | "description";
                  value: string;
                }) => onChangeCharge(update, index)}
                fieldClassName="mr-2 my-4"
                renderArray={[
                  {
                    name: "category",
                    variant: "OUTLINED",
                    key: `${item.category}`,
                  },
                  {
                    name: "amount",
                    inputType: "NUMBER",
                    variant: "OUTLINED",
                    label: "Amount ($)",
                  },
                  {
                    name: "description",
                    variant: "OUTLINED",
                  },
                ]}
              />
              {index !== 0 && (
                <button
                  className="focus:outline-none"
                  onClick={() => onDeleteCharge(index)}
                >
                  <CloseIcon />
                </button>
              )}
            </div>
          );
        })}
        <button
          className="border w-max h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 my-4"
          onClick={() =>
            setAdditionalCharges([
              ...additionalCharges,
              { category: "", amount: 0, description: "" },
            ])
          }
        >
          <div className="flex flex-row items-center ">
            <div>
              <AddIconBlue className="h-4 w-4" />
            </div>
            <div className="px-2 flex">
              <div className="font-medium">Add New Charge</div>
            </div>
          </div>
        </button>

        <div className="flex flex-row self-end mt-4 mr-4 ">
          <button
            className="w-max text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
        bg-gray-300
        text-gray-800 duration-200 ease-in-out 
        transition"
            onClick={() => onBackClick("unitListing")}
          >
            Cancel
          </button>

          <button
            className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
        bg-newBlue-400  w-40
        text-white border-newBlue-400
        border"
            onClick={onSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );

  function calculateLeaseDates(name: string, value: string) {
    if (name === "leaseTerm" && value && details.leaseStartDate) {
      const endDate = new Date(details.leaseStartDate);
      endDate.setMonth(endDate.getMonth() + parseInt(value));
      endDate.setDate(endDate.getDate() - 1);
      setDetails((data) =>
        deepUpdate("leaseEndDate", endDate.toISOString(), data)
      );
    } else if (name === "leaseStartDate") {
      setDetails((data) => deepUpdate("rentStartDate", value, data));
      if (details.leaseTerm) {
        const endDate = new Date(value);
        endDate.setMonth(endDate.getMonth() + parseInt(details.leaseTerm));
        endDate.setDate(endDate.getDate() - 1);
        setDetails((data) =>
          deepUpdate("leaseEndDate", endDate.toISOString(), data)
        );
      }
    }
  }
}