import { useEffect, useState } from "react";

import { getCommericalClients, getIndividualClients } from "../api/Api";
import MaterialForm from "../components/common/form/MaterialForm";
import ListInvoices from "../components/pipelines/components/ListInvoices";

export default function Invoice() {
  const [filter, setFilter] = useState({
    client: "",
  });

  const [clients, setClients] = useState([]);

  const [selectedClient, setSelectedClient] = useState({
    client: "",
    id: 0,
    type: "",
  });

  useEffect(() => {
    getIndividualClients(filter.client).then((data) => {
      const individualClients = data.content.map(
        (contact: {
          firstName: string;
          lastName: string;
          contactId: number;
        }) => {
          const name = `${contact.firstName} ${contact.lastName}`;
          return {
            ...contact,
            clientClass: "INDIVIDUAL",
            name,
            value: contact.contactId,
          };
        }
      );

      setClients((clients) =>
        clients
          .filter(
            (client: { clientClass: string }) =>
              client.clientClass !== "INDIVIDUAL"
          )
          .concat(individualClients)
      );
    });
    getCommericalClients("ALL", filter.client).then((data) => {
      const commercialClients = data.content.map((client: any) => {
        const name = `${client.clientName}`;
        return { ...client, name, value: client.clientId };
      });
      setClients((clients) =>
        clients
          .filter(
            (client: { clientClass: string }) =>
              client.clientClass === "INDIVIDUAL"
          )
          .concat(commercialClients)
      );
    });
  }, [filter.client]);

  return (
    <div className="max-w-6xl mx-auto bg-white rounded-md p-4">
      <div className="flex flex-row justify-between my-6">
        <div className="text-2xl font-bold">Invoices </div>
        <MaterialForm
          className="w-60"
          noSelect={true}
          data={selectedClient}
          renderArray={[
            {
              name: "client",
              inputType: "SELECT",
              variant: "AUTOCOMPLETE",
              defaultValue: filter.client,
              options: clients.map(
                (item: {
                  name: string;
                  value: number;
                  clientClass: string;
                }) => ({
                  label: item.name,
                  value: item.value,
                  groupBy: item.clientClass,
                })
              ),
              label: "Client",
            },
          ]}
          onChange={(update: any) => {
            const { value } = update;

            const selected = clients.find(
              (item: { name: string; value: number; clientClass: string }) =>
                item.value === value
            );
            setSelectedClient({
              id: selected?.value,
              type: selected?.clientClass,
              client: selected?.name,
            });

            setFilter({ client: value });
          }}
        />
      </div>
      <ListInvoices
        flow="LAYOUT"
        clientId={
          selectedClient.type === "INDIVIDUAL"
            ? undefined
            : selectedClient.id !== 0
            ? selectedClient.id
            : undefined
        }
        contactId={
          selectedClient.type === "INDIVIDUAL"
            ? selectedClient.id !== 0
              ? selectedClient.id
              : undefined
            : undefined
        }
      />
    </div>
  );
}
