import { useEffect, useState } from "react";

import { navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import { getAvailablePhoneNumbers } from "../../api/Api";
import { LeftArrowWithTailIcon } from "../../components/common/AppIcons";
import MaterialModal from "../../components/common/MaterialModal";
import MaterialTable from "../../components/common/MaterialTable";
import { renderPhone } from "../../utils/StringUtils";
import ConfirmPhoneNumber from "./ConfirmPhoneNumber";

type AvailableNumbers = {
  friendlyName?: string;
  phoneNumber: string;
  locality: string;
  rateCenter: string;
  latitude: number;
  longitude: number;
  region: string;
  zip: string;
  country: string;
  addressRequirements: string;
};

export default function BuyPhoneNumber() {
  const [availableNumbersData, setAvailableNumbersData] = useState<
    AvailableNumbers[]
  >([]);

  const [selectedNumber, setSelectedNumber] = useState({
    phone: "",
    fee: "",
  });

  const [filter, setFilter] = useState("number");
  const [matchTo, setMatchTo] = useState("any");
  const [searchText, setSearchText] = useState("");

  const [buy, setBuy] = useState(false);

  const [edit, setEdit] = useState({
    display: false,
    skill: "",
    vendorId: 0,
    isEdit: false,
  });

  useEffect(() => {
    getAvailablePhoneNumbers()
      .then((data) => {
        setAvailableNumbersData(data as AvailableNumbers[]);
        setEdit({
          display: false,
          skill: "",
          vendorId: data.id,
          isEdit: false,
        });
      })
      .catch(() => alertError("Error while fetching vendor"));
  }, []);

  useEffect(() => {
    if (filter === "number") {
      const pathParam = `?searchType=number&searchTerm=${searchText}&searchFilter=${
        matchTo === "any" ? "" : matchTo
      }`;
      getAvailablePhoneNumbers(pathParam).then((data) => {
        setAvailableNumbersData(data as AvailableNumbers[]);
      });
    } else {
      const pathParam = `?searchType=locality&searchTerm=${searchText}`;
      getAvailablePhoneNumbers(pathParam).then((data) => {
        setAvailableNumbersData(data as AvailableNumbers[]);
      });
    }
  }, [searchText, filter, matchTo]);

  const getTableHead = () => {
    return [
      { id: "number", label: "Number" },
      { id: "type", label: "Type" },
      { id: "monthlyFee", label: "Monthly Fee" },
      { id: "buyNow", label: "" },
    ];
  };

  const getTableData = () => {
    let data = [];

    data = availableNumbersData?.map((item: AvailableNumbers) => {
      return {
        id: item.phoneNumber,
        data: [
          {
            render: (
              <div className="flex flex-col">
                <div className="text-newBlue-400 font-semibold text-sm">
                  {renderPhone(item.phoneNumber)}
                </div>
                <div className="text-newGray-800 text-sm font-normal">{`${item.locality}, ${item.region}, ${item.country}`}</div>
              </div>
            ),
          },
          { render: <div className="font-medium text-sm">Local</div> },
          { render: <div className="font-medium text-sm">$1.15</div> },
          {
            render: (
              <button
                className=" p-2 items-center border rounded border-newGray-700"
                onClick={() => {
                  setSelectedNumber({ phone: item.phoneNumber, fee: "$1.15" });
                  setBuy(true);
                }}
              >
                <span className="pl-2 text-newBlue-400 text-sm font-medium">
                  Buy Now
                </span>
              </button>
            ),
          },
        ],
      };
    });
    return data;
  };

  return (
    <div className="flex flex-col bg-white p-4">
      <button
        className="flex flex-row items-center my-4 hover:bg-newBlue-500 px-2 py-1 rounded w-24"
        onClick={() => navigate("/settings")}
      >
        <LeftArrowWithTailIcon className="h-4 w-4 text-newBlue-400" />
        <div className="text-sm font-medium text-newBlue-400 ml-2">
          Settings
        </div>
      </button>
      <div className=" flex flex-col justify-between items-center pt-6  h-fit w-full">
        <div className="w-full h-min rounded border border-newGray-700">
          <div className="flex items-center  h-16 border-b-2 border-newGray-700">
            <div className=" pl-4 text-black text-base font-semibold">
              Buy New Phone Number
            </div>
          </div>
          <div className="flex flex-row items-center p-4">
            <div className="text-newGray-800 font-normal text-sm">Filters</div>
            <select
              className="bg-white  p-2 flex border border-newGray-700 rounded ml-2 w-40"
              name="timeZone"
              value={filter}
              onChange={(input) => {
                setFilter(input.target.value);
                setSearchText("");
                setMatchTo("any");
              }}
            >
              <option value="number">Number</option>
              <option value="locality">Locality</option>
            </select>
            <div className="bg-white p-1 flex border border-newGray-700 rounded ml-2 w-40">
              <input
                className="p-1 px-2 appearance-none outline-none w-full text-newGray-800"
                name="territory"
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(input) => {
                  setSearchText(input.target.value);
                }}
              />
            </div>
            {filter === "number" && (
              <>
                <div className="text-newGray-800 font-normal text-sm ml-2">
                  Match to
                </div>
                <select
                  className="bg-white  p-2 flex border border-newGray-700 rounded ml-2 w-60"
                  name="timeZone"
                  value={matchTo}
                  onChange={(input) => setMatchTo(input.target.value)}
                >
                  <option value="left">First part of number</option>
                  <option value="any">Anywhere in number</option>
                  <option value="right">Last part of number</option>
                </select>
              </>
            )}
          </div>
          <div className="w-full h-min">
            <div className="border-t ">
              <MaterialTable
                data={getTableData()}
                head={getTableHead()}
                headRenderColor="text-newGray-300"
                defaultOrder="desc"
                onClickCB={(selection: number) => {
                  setEdit({
                    display: true,
                    skill: selection.toString(),
                    vendorId: edit.vendorId,
                    isEdit: true,
                  });
                }}
                loading={undefined}
                defaultOrderBy={undefined}
                multiSelect={undefined}
                setSelected={undefined}
                currentPage={undefined}
                totalData={undefined}
                searchText={undefined}
                paginationCB={undefined}
                rowChangeCB={undefined}
              />
            </div>
            <MaterialModal
              open={buy}
              setOpen={() => {
                setBuy(false);
              }}
            >
              <ConfirmPhoneNumber
                fee={selectedNumber.fee}
                phone={selectedNumber.phone}
                onSuccess={() => {
                  setBuy(false);
                  getAvailablePhoneNumbers().then((data) => {
                    setAvailableNumbersData(data as AvailableNumbers[]);
                    setEdit({
                      display: false,
                      skill: "",
                      vendorId: data.id,
                      isEdit: false,
                    });
                  });
                }}
                onCancel={() => setBuy(false)}
              />
            </MaterialModal>
          </div>
        </div>
      </div>
    </div>
  );
}
