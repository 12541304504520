import { useState } from "react";



import {
  AddIconBlue,
  CrossIcon,
  DownloadIcon,
  UploadIcon,
} from "../../components/common/AppIcons";
import { timeZones } from "../../utils/constants";
import Territories from "../vendors/utils/Territories.csv";
import { Territory, VendorStages } from "./CreateVendor";


type AddZipCodesProps = {
  nextStage: () => void;
  prevStage: () => void;
  setTerritory: React.Dispatch<React.SetStateAction<Territory[]>>;
  territory: Territory[];
};
export default function AddZipCodes({
  nextStage,
  prevStage,
  territory,
  setTerritory,
}: AddZipCodesProps) {
  const onAddTerritory = () => {
    setTerritory([
      ...territory,
      {
        territory: "",
        timeZone: timeZones[0].value,
        zipCode: "",
        zipCodes: [],
      },
    ]);
  };

  const onDeleteTerritory = (index: number) => {
    const rows = [...territory];
    rows.splice(index, 1);
    setTerritory(rows);
  };

  const [file, setFile] = useState();
  const [upload, setUpload] = useState(false);

  const getRows = (length: number) => {
    const count = length / 4;
    return Math.ceil(count);
  };

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const fileReader = new FileReader();

  const csvFileToArray = (string: string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string
      .slice(string.indexOf("\n") + 1)
      .split("\n")
      .filter((item) => item !== "");

    const header = csvHeader.map((item) =>
      item.split("\r")[0].toLocaleLowerCase()
    );

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = header.reduce((object, header, index) => {
        if (values[index] !== undefined && values[index] !== "")
          object[header] = values[index].split("\r")[0];
        return object;
      }, {});
      return obj;
    });

    array.forEach((item) => {
      const checkElement = territory.filter(
        (ter) => ter.territory === item?.territory
      );

      if (checkElement.length === 0) {
        const newTerritory = {
          territory: item?.territory,
          zipCodes: [item?.zipcode],
          timeZone: timeZones.find((zone) => zone.label === item?.timezone)
            ?.value,
          zipCode: "",
        };

        const current = territory;

        current.push(newTerritory);
        setTerritory(current);
      } else {
        const presentItem = checkElement[0];
        presentItem.zipCodes.push(String(item.zipcode));
        const current = territory;

        const filteredItem = current.filter(
          (ter) => ter.territory !== presentItem.territory
        );

        filteredItem.push(presentItem);
        setTerritory(filteredItem);
      }
    });
  };

  const handleOnSubmit = (e) => {
    setUpload(false);
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        csvFileToArray(csvOutput);
      };
      const temp = fileReader.readAsText(file);
    }
  };

  const handleChangeInput = (index: number, name: string, value: any) => {
    const list = [...territory];
    list[index][name] = value;

    setTerritory(list);
  };

  const addZipCode = (index: number, value: any) => {
    const list = [...territory];

    const temp = list[index]["zipCodes"];
    temp.push(value);

    list[index]["zipCodes"] = temp;
    setTerritory(list);
  };

  const deleteZipCode = (index: number, value: string) => {
    const list = [...territory];

    const temp = list[index]["zipCodes"];
    const removedList = temp.filter((item) => item !== value);

    list[index]["zipCodes"] = removedList;
    setTerritory(list);
  };

  return (
    <div className="mt-20 mx-20">
      <div className="flex flex-row justify-start ">
        <a
          href={Territories}
          download="Territories"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="flex w-52 min-w-max items-center justify-center text-sm rounded border border-newGray-800 px-2 h-12 mr-4">
            <DownloadIcon />
            <div className="ml-2">Download CSV Template</div>
          </button>
        </a>
        {upload ? (
          <div className="flex flex-col  ml-20">
            <input
              type={"file"}
              id={"csvFileInput"}
              accept={".csv"}
              onChange={handleOnChange}
            ></input>
            <button
              className="flex w-20 items-center justify-center rounded border border-newBlue-400 mt-2 text-newBlue-400 text-md font-medium"
              onClick={handleOnSubmit}
            >
              Submit
            </button>
          </div>
        ) : (
          <button
            className="flex w-32 items-center text-sm justify-between rounded border border-newGray-800 h-12 px-2"
            onClick={() => setUpload(true)}
          >
            <UploadIcon />
            Upload CSV
          </button>
        )}
      </div>

      <div className="">
        {territory.map((data, index) => {
          return (
            <div
              className="border-b-4 border-newGray-700 pb-4 mt-10"
              key={index}
            >
              <div className="flex flex-row justify-between items-center">
                <div className="font-medium text-newGray text-base leading-8 h-6  mt-3">
                  Territory
                </div>
                <button
                  className="rounded border border-newGray-800 px-2 focus:outline-none"
                  onClick={() => onDeleteTerritory(index)}
                >
                  Delete
                </button>
              </div>
              <div className="w-full flex-1 mr-4 ">
                <div className="bg-white my-2 p-1 flex border border-newGray-700 rounded ">
                  <input
                    className="p-1 px-2 appearance-none outline-none w-full text-newGray-800"
                    name="territory"
                    type="text"
                    value={data.territory}
                    onChange={(input) =>
                      handleChangeInput(
                        index,
                        input.target.name,
                        input.target.value
                      )
                    }
                  />
                </div>
              </div>
              <div className="font-medium text-newGray text-base leading-8 h-6  mt-3">
                Time Zone
              </div>
              <div className="w-full flex-1 mr-4">
                <select
                  className="w-full bg-white my-2 p-1 flex border border-newGray-700 rounded"
                  name="timeZone"
                  value={data.timeZone}
                  onChange={(input) =>
                    handleChangeInput(
                      index,
                      input.target.name,
                      input.target.value
                    )
                  }
                >
                  {timeZones.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
              </div>
              <div className="font-medium text-newGray text-base leading-8 h-6  mt-3">
                Zipcodes
              </div>
              <div className="w-full flex-1 mr-4 ">
                <div className="bg-white my-2 p-1 flex border border-newGray-700 rounded ">
                  <input
                    className="p-1 px-2 appearance-none outline-none w-full text-newGray-800"
                    name="zipCode"
                    type="text"
                    value={data.zipCode}
                    onChange={(input) =>
                      handleChangeInput(
                        index,
                        input.target.name,
                        input.target.value
                      )
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        addZipCode(index, data.zipCode);
                        handleChangeInput(index, "zipCode", "");
                      }
                    }}
                  />
                </div>
                <div
                  className={`grid grid-flow-row-dense grid-rows-${getRows(
                    data.zipCodes.length
                  )} grid-cols-4 mt-3`}
                >
                  {data.zipCodes.map((item) => (
                    <div className="flex flex-row  justify-between bg-newBlue-500 m-1 p-1 rounded">
                      <div className=" text-sm text-newBlue-400 font-normal">
                        {item}
                      </div>
                      <button
                        className="focus:outline-none"
                        onClick={() => {
                          deleteZipCode(index, item);
                        }}
                      >
                        <CrossIcon />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <button
        className="border rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 mt-8"
        onClick={() => {
          onAddTerritory();
        }}
      >
        <div className="flex flex-row items-center w-44">
          <div>
            <AddIconBlue className="h-4 w-4" />
          </div>
          <div className="px-2 flex">
            <div className="font-medium">Add Another Territory</div>
          </div>
        </div>
      </button>
      <div className="flex-auto flex flex-row-reverse mt-4">
        <button
          className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
          bg-newBlue-400  w-80
          text-white border-newBlue-400
          border"
          onClick={() => nextStage()}
        >
          Continue
        </button>
        <button
          className="text-base flex justify-center items-center px-4 py-2 rounded font-medium cursor-pointer 
          bg-white
          text-newBlue-400 border-newBlue-400
          border duration-200 ease-in-out 
          transition"
          onClick={() => prevStage()}
        >
          Go Back
        </button>
      </div>
    </div>
  );
}