import React, { useRef } from "react";
import Draggable from "react-draggable";

import { Modal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useOnClickOutside from "use-onclickoutside";

import { ModalDragIcon } from "./AppIcons";

const getModalStyle = (width: number | undefined) => {
  const top = 50;
  const left = 50;

  if (width) {
    return {
      width: `${width}%`,
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  } else {
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
};

const useStyles = makeStyles((theme: any) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3)
  },
}));

export default function MaterialModal({
  className,
  containerClassName,
  children,
  open,
  setOpen,
  extended,
  label,
  describedBy,
  width,
}: {
  className?: string;
  containerClassName?: string;
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  extended?: boolean;
  label?: string;
  describedBy?: string;
  width?: number;
}) {
  const ref = useRef(null);

  const [draggableStatus, setDraggableStatus] = React.useState(true);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle(width));
  return (
    <Draggable disabled={draggableStatus}>
      <Modal
        // hideBackdrop={true}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        style={{
          backgroundColor: "transparent",
        }}
        id="modal"
        open={open ? true : false}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby={label}
        aria-describedby={describedBy}
      >
        <>
          <div
            style={modalStyle}
            className={`${classes.paper} ${containerClassName ?? "max-h-screen overflow-y-auto"
              }`}
          >
            <div
              onMouseEnter={() => {
                setDraggableStatus(false);
              }}
              onMouseLeave={() => {
                setDraggableStatus(true);
              }}
              className=" cursor-move  hover:border-b-2 hover:bg-newGray-900 p-4 items-center flex justify-center"
              style={{
                height: "20px",
              }}
            >
              <ModalDragIcon />
            </div>
            <div className={`${className} px-8 py-4`}>{open && children}</div>
          </div>
        </>
      </Modal>
    </Draggable>
  );
}
