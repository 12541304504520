import React, { useEffect, useState } from "react";

import BuildIcon from "@mui/icons-material/Build";
import BusinessIcon from "@mui/icons-material/Business";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Button, Typography } from "@mui/material";
import moment from "moment";
import { navigate } from "raviger";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import {
  createAssociate,
  getAssociate,
  getTerritory,
  getThirdPartyVendors,
  getMyVendor,
} from "../../api/Api";
import states from "../../assets/states";
import { conditionalArrayEntry } from "../../utils/ObjectUtills";
import { deepUpdate } from "../../utils/StateUtils";
import { lowerCase, properString } from "../../utils/StringUtils";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import {
  electricianSubCategory,
  handymanSubCategory,
  plumberSubCategory,
  specialistSubCategory,
  supplierSubCategory,
} from "../common/form/constants";
import AssociateContactsAccordion from "./AssociateContactsAccordion";

const formAssociateAddress = (subAddressOptions) => {
  return [
    {
      name: "address",
      inputType: "ADDRESS",
      variant: "STANDARD",
    },
  ];
};

const formAssociateSchedule = [
  {
    name: "workdayStart",
    defaultValue: "08:00",
    inputType: "TIME",
  },
  {
    name: "workdayEnd",
    defaultValue: "17:00",
    inputType: "TIME",
  },
  {
    name: "saturdayStart",
    inputType: "TIME",
  },
  {
    name: "saturdayEnd",
    inputType: "TIME",
  },
  {
    name: "sundayStart",
    inputType: "TIME",
  },
  {
    name: "sundayEnd",
    inputType: "TIME",
  },
];

export const getSubCategory = (skill) => {
  switch (skill) {
    case "HANDYMAN":
      return handymanSubCategory;
    case "SPECIALIST":
      return specialistSubCategory;
    case "ELECTRICIAN":
      return electricianSubCategory;
    case "ELECTRICAL":
      return electricianSubCategory;
    case "PLUMBER":
      return plumberSubCategory;
    case "PLUMBING":
      return plumberSubCategory;
    default:
      return supplierSubCategory;
  }
};

export default function CreateAssociate({ associate, editAssociate }) {
  const [data, setData] = useState(
    editAssociate ? editAssociate : associate ?? {}
  );
  const [tpv, setTPV] = useState();
  const [primaryContact, setPrimaryContact] = useState(
    associate?.primaryContactId || ""
  );

  const [subAddressOptions, setSubAddressOptions] = useState([]);
  const [territories, setTerritories] = useState([]);
  const [skillData, setSkillData] = useState([]);

  const isThirdPartyVendor = data.type === "THIRD_PARTY_VENDOR";

  useEffect(() => {
    getTerritory()
      .then((res) => {
        setTerritories((_) =>
          res.map((item) => ({
            value: item.name,
            label: properString(item.name),
          }))
        );
      })
      .catch((err) => {
        alertError("Error getting territories");
      });
    getMyVendor()
      .then((data) => {
        setSkillData(data.categories);
      })
      .catch(() => alertError("Error while fetching vendor"));
  }, []);

  useEffect(() => {
    console.log("data changed");
  }, [data]);

  const getContacts = () => {
    let contact = [];

    data.contacts?.forEach((c) => {
      contact.push({
        label: `${c.firstName} ${c.lastName}`,
        value: c.contactId,
      });
    });

    return contact;
  };

  const getSkills = () => {
    return skillData.map((item) => {
      return { label: properString(item.skill), value: item.skill };
    });
  };

  const getSubCategories = (skill) => {
    const selectedSkill = skillData.find((item) => item.skill === skill);

    return selectedSkill?.subCategories?.map((item) => {
      return { label: properString(item), value: item };
    });
  };

  const metaData = (data) => {
    return [
      {
        name: "metadata.contractorLicense",
        inputType: "SELECT",
        defaultValue: "false",
        options: [
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ],
      },
      ...((data?.metadata?.contractorLicense ?? "") === "true"
        ? [
            {
              name: "metadata.contractorLicenseNumber",
            },
          ]
        : []),
      ...((data?.metadata?.contractorLicense ?? "") === "true"
        ? [
            {
              name: "metadata.contractorLicenseExpiry",
              inputType: "DATE",
              defaultValue: moment(),
            },
          ]
        : []),

      {
        name: "metadata.liabilityInsurance",
        inputType: "SELECT",
        defaultValue: "false",
        options: [
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ],
      },
      ...((data?.metadata?.liabilityInsurance ?? "") === "true"
        ? [
            {
              name: "metadata.liabilityInsuranceExpiry",
              inputType: "DATE",
              defaultValue: moment(),
            },
          ]
        : []),
      {
        name: "metadata.workersCompensationInsurance",
        inputType: "SELECT",
        defaultValue: "false",
        options: [
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ],
      },
      ...((data?.metadata?.workersCompensationInsurance ?? "") === "true"
        ? [
            {
              name: "metadata.workersCompensationInsuranceExpiry",
              inputType: "DATE",
              defaultValue: moment(),
            },
          ]
        : []),
    ];
  };

  const formAssociate = (data) => {
    return [
      {
        name: "type",
        inputType: "SELECT",
        options: [
          { label: "Squad Associate", value: "SQUAD_ASSOCIATE" },
          // { label: "Contractor", value: "CONTRACTOR" },
          { label: "Supervisor", value: "SUPERVISOR" },
          { label: "Third Party Vendor", value: "THIRD_PARTY_VENDOR" },
        ],
      },
      ...((data.type ?? "") === "THIRD_PARTY_VENDOR"
        ? [
            {
              name: "companyName",
            },
          ]
        : []),
      { name: "firstName" },
      { name: "lastName" },
      {
        name: "territories",
        inputType: "SELECT",
        variant: "MULTISELECT",
        defaultValue: [],
        options: territories,
      },
      { name: "homeCity" },
      { name: "alias" },
      {
        name: "email",
        inputType: "EMAIL",
      },
      {
        name: "phone",
        inputType: "PHONE",
      },

      ...(associate && data.type === "THIRD_PARTY_VENDOR"
        ? [
            {
              name: "primaryContactId",
              inputType: "SELECT",
              options: getContacts(),
            },
          ]
        : []),

      {
        name: "skill",
        inputType: "SELECT",
        options: getSkills(),
      },
      ...conditionalArrayEntry(data.skill, {
        name: "subCategory",
        inputType: "SELECT",
        variant: "MULTISELECT",
        defaultValue: [],
        options: getSubCategories(data.skill) ?? [],
      }),

      ...((data.type ?? "") !== "THIRD_PARTY_VENDOR"
        ? [
            {
              name: "dateOfBirth",
              inputType: "DATE",
              defaultValue: "1969-12-31",
            },
          ]
        : []),

      {
        name: "usesApp",
        inputType: "SELECT",
        defaultValue: "false",
        options: [
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ],
      },
      {
        name: "notifyBy",
        inputType: "SELECT",
        defaultValue: "both",
        options: [
          { label: "Text and Email", value: "TEXT_AND_EMAIL" },
          { label: "Text", value: "TEXT" },
          { label: "Email", value: "EMAIL" },
        ],
      },
      ...((data.type ?? "") !== "THIRD_PARTY_VENDOR"
        ? [
            { name: "driversLicense" },
            {
              name: "driversLicenseExpiry",
              inputType: "DATE",
              variant: "DISABLEPAST",
              defaultValue: new Date(),
            },
          ]
        : []),
    ];
  };

  const fetchThirdPartyVendors = () => {
    getThirdPartyVendors()
      .then((data) => {
        data.sort((a, b) => {
          return data.sort(function (a, b) {
            var x = a["companyName"];
            var y = b["companyName"];
            return x < y ? -1 : x > y ? 1 : 0;
          });
        });

        setTPV(data);
      })
      .catch((error) => {
        alertError("Error getting Associates");
      });
  };
  const onChange = (update) => {
    let { name, value } = update;

    if (update.name === "address.streetAddress1") {
      if (update.subOptions[0]) setSubAddressOptions(update.subOptions);
    }

    if (name === "email") {
      value = lowerCase(value);
    }

    setData((data) => deepUpdate(name, value, data));
  };
  const handleSubmit = () => {
    if (data["thirdPartyCompanyId"])
      data["thirdPartyCompany"] = { id: data["thirdPartyCompanyId"] };
    createAssociate({
      ...data,
      subCategory: data.subCategory,
    })
      .then(({ value }) => {
        if (associate?.associateId) {
          alertSuccess("Successfully Updated Associate");
          getAssociate({ associateId: data.associateId }).then((data) => {
            setPrimaryContact(data.primaryContactId);
            setData(data);
          });
        } else {
          alertSuccess("Successfully Created Associate");
          navigate(`/associates/${value}/details`);
        }
      })
      .catch((e) => {
        const errorMessage =
          e.readableMessage ?? data.associateId
            ? "Error Updating Associate"
            : "Error Creating Associate";
        alertError(errorMessage);
      });
  };

  useEffect(() => {
    // fetchThirdPartyVendors();
  }, []);
  return (
    <div className="w-full block">
      <div className={"flex max-w-6xl flex-col md:flex-row mx-auto"}>
        <div className="p-4 max-w-md w-full">
          <div className="flex gap-2">
            <BuildIcon />
            <Typography variant="h5">FSO Information</Typography>
          </div>
          <MaterialForm
            data={data}
            renderArray={formAssociate(data)}
            onSubmit={handleSubmit}
            onChange={onChange}
          />
        </div>
        <div className="p-4 max-w-md w-full">
          <div className="flex gap-2">
            <BusinessIcon />
            <Typography variant="h5">Address</Typography>
          </div>
          <MaterialForm
            data={data}
            renderArray={formAssociateAddress(subAddressOptions)}
            onSubmit={handleSubmit}
            onChange={onChange}
          />
          <br />

          {data.type === "THIRD_PARTY_VENDOR" && (
            <>
              <div className="flex gap-2">
                <Typography variant="h5">MetaData</Typography>
              </div>
              <MaterialForm
                data={data}
                renderArray={metaData(data)}
                onSubmit={handleSubmit}
                onChange={onChange}
              />
            </>
          )}
        </div>
        {associate && data.type === "THIRD_PARTY_VENDOR" && (
          <div className="p-4 max-w-md w-full">
            <div className="flex gap-2">
              <Typography variant="h5">Contacts</Typography>
            </div>
            <AssociateContactsAccordion
              primaryContact={primaryContact}
              associateId={data?.associateId}
              data={data.contacts}
              withLink
              syncCB={() => {
                getAssociate({ associateId: data.associateId }).then((data) => {
                  setData(data);
                });
              }}
            />
          </div>
        )}
        {!isThirdPartyVendor && (
          <div className="p-4 max-w-md">
            <div className="flex gap-2">
              <ScheduleIcon />
              <Typography variant="h5">Work Schedule</Typography>
            </div>
            <MaterialForm
              data={data}
              renderArray={formAssociateSchedule}
              onSubmit={handleSubmit}
              onChange={onChange}
            />
          </div>
        )}
      </div>

      <div className="flex flex-row-reverse w-full">
        <Button onClick={(_) => window.history.back()} className="p-4">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          className="p-4 create-associate"
        >
          Save
        </Button>
      </div>
    </div>
  );
}
