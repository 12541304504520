import { useEffect, useState } from "react";

import { alertSuccess } from "../../../../../actions/AlertActions";
import { getProspectById, updateProspect } from "../../../../../api/Api";
import { AddIconBlue } from "../../../../../components/common/AppIcons";
import MaterialForm from "../../../../../components/common/form/MaterialForm";

type RentalHistoryTabProps = {
  prospectId: number;
  setCurrentApplicationTab: React.Dispatch<React.SetStateAction<string>>;
  setDetailsTab: React.Dispatch<React.SetStateAction<string>>;
};

export function RentalHistoryTab({
  prospectId,
  setCurrentApplicationTab,
  setDetailsTab,
}: RentalHistoryTabProps) {
  const [rentalHistory, setRentalHistory] = useState([
    {
      streetAddress1: "",
      unit: "",
      city: "",
      state: "",
      zip: 0,
      fromDate: "",
      toDate: "",
      referenceName: "",
      referencePhone: "",
    },
  ]);

  useEffect(() => {
    getProspectById(prospectId).then((data) => {
      if (data.rentalHistory !== null) setRentalHistory(data.rentalHistory);
    });
  }, [prospectId]);

  const onChangeEmployment = (
    update: { name: string; value: any },
    index: number
  ) => {
    const { name, value } = update;
    const test = [...rentalHistory];
    test[index][name] = value;

    setRentalHistory(test);
  };

  const onSave = () => {
    updateProspect(prospectId, [
      {
        op: "replace",
        path: "/rentalHistory",
        value: rentalHistory,
      },
    ]).then(() => {
      setDetailsTab("incomeDetails");
      alertSuccess("Prospect updated Successfully");
    });
  };
  return (
    <div className="w-full p-4 ">
      <div className="flex flex-col items-start self-center ">
        <div className="text-xl font-semibold text-gray-900 my-4">
          Please list your three most recent addresses or from past five years
        </div>
        <div className="border-b-2 border-newGray-700">
          {rentalHistory.map((item, index) => (
            <MaterialForm
              className={`w-full grid grid-flow-row-dense grid-rows-1 grid-cols-4 py-4 ${
                rentalHistory.length === index + 1
                  ? ""
                  : "border-b-2 border-newGray-700"
              }`}
              data={item}
              onChange={(update: { name: string; value: any }) =>
                onChangeEmployment(update, index)
              }
              fieldClassName="px-2 my-2"
              renderArray={[
                {
                  name: "streetAddress1",
                  variant: "OUTLINED",
                },
                {
                  name: "unit",
                  variant: "OUTLINED",
                },
                {
                  name: "city",
                  variant: "OUTLINED",
                },
                {
                  name: "state",
                  variant: "OUTLINED",
                },
                {
                  name: "zip",
                  variant: "OUTLINED",
                  inputType: "NUMBER",
                },
                {
                  name: "fromDate",
                  inputType: "DATE",
                  variant: "OUTLINED",
                  label: "From",
                },
                {
                  name: "toDate",
                  inputType: "DATE",
                  variant: "OUTLINED",
                  label: "To",
                },

                {
                  name: "referenceName",
                  variant: "OUTLINED",
                },
                {
                  name: "referencePhone",
                  inputType: "PHONE",
                  variant: "OUTLINED",
                },
              ]}
            />
          ))}
          <button
            className="border w-max h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 ml-2 my-4"
            onClick={() =>
              setRentalHistory([
                ...rentalHistory,
                {
                  streetAddress1: "",
                  unit: "",
                  city: "",
                  state: "",
                  zip: 0,
                  fromDate: "",
                  toDate: "",
                  referenceName: "",
                  referencePhone: "",
                },
              ])
            }
          >
            <div className="flex flex-row items-center ">
              <div>
                <AddIconBlue className="h-4 w-4" />
              </div>
              <div className="px-2 flex">
                <div className="font-medium">Add Previous tenancy</div>
              </div>
            </div>
          </button>
        </div>
        <div className="flex flex-row self-end mt-4 mr-4 ">
          <button
            className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
        bg-gray-300
        text-gray-800 duration-200 ease-in-out 
        transition"
            onClick={() => setCurrentApplicationTab("applicationListing")}
          >
            Cancel
          </button>
          <button
            className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
        bg-newBlue-400  w-40
        text-white border-newBlue-400
        border"
            onClick={onSave}
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
}
