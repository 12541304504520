import React, { useEffect } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import Fuse from "fuse.js";
import PropTypes from "prop-types";

function descendingComparator(a, b, orderBy) {
  a =
    typeof a.data[orderBy] === "object"
      ? a.data[orderBy]?.sortBy
      : a.data[orderBy];
  b =
    typeof b.data[orderBy] === "object"
      ? b.data[orderBy]?.sortBy
      : b.data[orderBy];

  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function stableSort(array, comparator) {
//   console.log("kkkkkk", array);

//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Dessert (100g serving)",
  },
  { id: "calories", numeric: true, disablePadding: false, label: "Calories" },
  { id: "fat", numeric: true, disablePadding: false, label: "Fat (g)" },
  { id: "carbs", numeric: true, disablePadding: false, label: "Carbs (g)" },
  { id: "protein", numeric: true, disablePadding: false, label: "Protein (g)" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableHead,
    multiSelect,
    headRenderColor,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow id="row-head" className="p-2 border">
        {multiSelect && (
          <TableCell color="primary" padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {tableHead.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label !== "" && (
              <TableSortLabel
                className={headRenderColor ? headRenderColor : ""}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  tableHead: PropTypes.array,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" size="large">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" size="large">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const searchOptions = {
  includeScore: true,
  keys: ["searchParam"],
};

const strictSearchEngine = (list, options) => {
  return {
    search: (text) => {
      return list
        .filter((item) => {
          return text
            .trim()
            .toLowerCase()
            .split(" ")
            .reduce(
              (containsFlag, term) =>
                containsFlag && item.searchParam.includes(term),
              true
            );
        })
        .map((data) => {
          return {
            item: data,
          };
        });
    },
  };
};

export default function MaterialTable({
  loading,
  data,
  currentPage,
  head,
  totalData,
  pageRows = 10,
  defaultOrderBy,
  defaultOrder,
  multiSelect,
  searchText,
  onClickCB,
  setSelected,
  selected = [],
  fuzzySearch = false,
  headRenderColor = "",
  paginationCB,
  rowChangeCB,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState(defaultOrder || "asc");
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageRows);

  const controlledPagination = paginationCB ? true : false;
  const coalescedPage = controlledPagination ? currentPage : page;

  const [searchEngine, setSearchEngine] = React.useState(
    new Fuse([], searchOptions)
  );

  // TODO: useEffect to call rowChangeCB on Component Mount

  function stableSort(array, comparator) {
    let stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    if (multiSelect) {
      let reversed = selected.reverse();
      let final = [...stabilizedThis];

      selected.forEach((item, index) => {
        final.forEach((stab, i) => {
          if (stab[0].id === item) {
            final.splice(i, 1);
          }
        });
      });

      let aa = [];

      selected.forEach((item, index) => {
        stabilizedThis.forEach((stab) => {
          if (stab[0].id === item) {
            aa.push(stab);
          }
        });
      });

      final = aa.concat(final);

      return final.map((el) => el[0]);
    }

    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(
    (_) => {
      setRowsPerPage(pageRows);
    },
    [pageRows]
  );

  useEffect(
    (_) => {
      const flatData = loading
        ? []
        : data.map((row) => {
            return {
              data: row,
              searchParam: row.data.reduce((acc, curr) => {
                const raw =
                  curr !== null
                    ? (typeof curr === "object"
                        ? String(curr.sortBy).toLowerCase()
                        : String(curr).toLowerCase()) ?? ""
                    : "";
                return `${acc} ${raw}`;
              }, ""),
            };
          });
      const dataSearch = fuzzySearch
        ? new Fuse(flatData, searchOptions)
        : strictSearchEngine(flatData, searchOptions);
      setSearchEngine(dataSearch);
    },
    [loading, data, fuzzySearch]
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const filteredData = searchText
    ? searchEngine.search(searchText).map((result) => result.item.data)
    : data;

  const dataRows = loading
    ? []
    : stableSort(
        filteredData,
        getComparator(
          order,
          head.findIndex((headCell) => headCell.id === orderBy)
        )
      )
        // Slice data for pagination, if not controlled
        .slice(
          controlledPagination ? 0 : page * rowsPerPage,
          controlledPagination
            ? filteredData.length
            : page * rowsPerPage + rowsPerPage
        )
        .map((row, rowIndex) => {
          const isItemSelected = multiSelect && isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${rowIndex}`;

          return (
            <TableRow
              id={`data-row-${row.id}`}
              hover
              className={
                (onClickCB ? " cursor-pointer " : "") +
                (row.className ?? "") +
                ` material-table-row ${row.rowStyle}`
              }
              onClick={(event) =>
                onClickCB && !multiSelect
                  ? onClickCB(row.id)
                  : handleClick(event, row.id)
              }
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={`${row.id}`}
              selected={isItemSelected}
            >
              {multiSelect && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    color="primary"
                    inputProps={{ "aria-labelledby": labelId }}
                    onClick={(event) => handleClick(event, row.id)}
                  />
                </TableCell>
              )}
              {/* Using a filter to take only as many columns as tableHeads exists. The rest would be used only for Searching and Filtering */}
              {row.data
                .filter((_, index) => index < head.length)
                .map((cell, index) =>
                  index ? (
                    <TableCell
                      key={`row${row.id}|${index}`}
                      align={head[index].numeric && "right"}
                    >
                      {cell?.render ? cell.render : cell}
                    </TableCell>
                  ) : (
                    <TableCell
                      key={`row${row.id}|${index}`}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding={head[index].disablePadding ? "none" : "normal"}
                    >
                      {cell?.render ? cell.render : cell}
                    </TableCell>
                  )
                )}
            </TableRow>
          );
        });

  const emptyRows = loading ? 5 : rowsPerPage - dataRows.length;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              headRenderColor={headRenderColor}
              numSelected={selected.length}
              order={order}
              multiSelect={multiSelect}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredData.length}
              tableHead={head}
            />
            <TableBody>
              {loading ? (
                <TableRow colSpan={headCells.length}>
                  <td colSpan={headCells.length} className="py-24 text-center">
                    <div className="flex flex-col gap-4">
                      <CircularProgress className="mx-auto" />
                      <p>Loading</p>
                    </div>
                  </td>
                </TableRow>
              ) : (
                dataRows
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={
            controlledPagination
              ? []
              : [
                  5,
                  10,
                  25,
                  ...([5, 10, 25].includes(parseInt(pageRows))
                    ? []
                    : [rowsPerPage]),
                ]
          }
          component="div"
          count={totalData ? parseInt(totalData) : filteredData.length}
          rowsPerPage={rowsPerPage}
          page={coalescedPage}
          // !(currentPage === undefined || currentPage === null)
          //   ? currentPage
          //   : page
          onPageChange={(e, page) => {
            handleChangePage(e, page);
            paginationCB && paginationCB(page);
          }}
          onRowsPerPageChange={(e) => {
            if (rowChangeCB) {
              rowChangeCB(parseInt(e.target.value));
            } else {
              handleChangeRowsPerPage(e);
            }
          }}
        />
      </Paper>
    </div>
  );
}
