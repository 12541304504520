import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import CloseIcon from "@mui/icons-material/Close";
import { TextField, Avatar } from "@mui/material";
import { navigate } from "raviger";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import { startAssociateCall, startCall } from "../../actions/VoiceActions";
import {
  assignOwner,
  getClientsForContact,
  getTicket,
  saveInternalNotes,
  sendMessage,
} from "../../api/Api";
import { useCopyTo } from "../../hooks/useCopyTo";
import { findClient } from "../../utils/ModelUtils";
import { conditionalArrayEntry } from "../../utils/ObjectUtills";
import { deepUpdate } from "../../utils/StateUtils";
import { renderPhone } from "../../utils/StringUtils";
import { renderAddress } from "../../utils/StringUtils";
import { AddIconBlue } from "../common/AppIcons";
import HeadlessDropdown from "../common/HeadlessDropdown";
import MaterialModal from "../common/MaterialModal";
import { NewMediaDragIn } from "../common/NewMediaDragIn";
import MaterialForm from "../common/form/MaterialForm";
import SelectOwner from "../settings/SelectOwner";
import {
  PhoneCallIcon,
  ProfileIconSmall,
  SendIconPlaneIcon,
} from "./ClientAppIcons";
import { RefreshIcon } from "./ClientAppIcons";
import MessagesList from "./MessagesList";
import { clientClassOptions } from "./forms/ClientFormFields";
import ReactMention from "./reactMention";

export const addEncodedFile = (setContent, file) =>
  function (upload) {
    setContent((content) => {
      return {
        ...content,
        attachments: [
          ...(content.attachments ?? []),
          {
            fileName: file.name,
            contentType: file.type,
            encodedFile: upload.target.result,
          },
        ],
      };
    });
  };

export default function NewMessagesView({
  clientId,
  title,
  subtext,
  phone,
  contactId,
  contacts,
  tickets,
  // Buildings to allow Building Tickets
  buildings,
  messages,
  addAttachmentToSR,
  updateMessages,
  fetchNextPage,
  associateMessaging = false,
  showApp = false,
  selected,
  ticketId,
  checkBoxForMedia,
  onClickCheckBoxCB,
  selectedContact,
  type,
}) {
  const fileRef = useRef();
  const [channel, setChannel] = useState("SMS");
  const [content, setContent] = useState("");
  const [editNotesFlag, triggerEditNotes] = useState(false);
  const [mediaUrls, setMediaUrls] = useState([]);
  const [displayChannel, setDisplayChannel] = useState("SMS");

  const [changeOwner, setChangeOwner] = useState({
    ticket: null,
    ownerId: null,
    display: false,
  });

  const [clients, setClients] = useState([]);

  const [contactClients, setContactClients] = useState([]);

  useEffect(() => {
    if (selectedContact) {
      getClientsForContact(selectedContact.contactId)
        .then((data) => {
          setClients(data);
        })
        .catch((_) => alertError("Couldn't fetch Clients"));
    }
  }, [selectedContact]);

  const [data, setData] = useState({});

  const copytoFields = useCopyTo(clientId, type);

  useEffect(() => {
    setData(selected);
  }, [selected]);

  const fetchTicketDetail = (ticketId) =>
    getTicket(ticketId)
      .then((data) => {
        setData(data);
      })
      .catch((_) => alertError("Couldn't fetch Ticket Details"));

  const onChangeCB = (channel) => {
    setDisplayChannel(channel);
    channel === "APP" ? setChannel("INTERNAL") : setChannel(channel);
  };

  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  const onDropCB = (file) => {
    alertInfo("Uploading file...");

    let httpsURL = "https:" + file.url.split("http:")[1];

    toDataURL(httpsURL, function (dataUrl) {
      setMediaUrls((current) => {
        return [...current, { url: file.url, base: dataUrl }];
      });
      alertInfo("Upload Complete");
    });
  };

  const OnDeleteCB = (data) => {
    setMediaUrls(data);
  };

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    Array.from(acceptedFiles).forEach((file) => {
      // console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = addEncodedFile(setContent, file);
      reader.readAsDataURL(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (messages.content.length > 0) {
      const lastMessage = messages.content[0];
      if (lastMessage.channel === "EMAIL") {
        setChannel("EMAIL");
        setDisplayChannel("EMAIL");

        setContent((content) => {
          return {
            ...content,
            subject: `Re: ${lastMessage.subject}`,
          };
        });
      } else if (lastMessage.channel === "INTERNAL") {
        setChannel("INTERNAL");
        setDisplayChannel("INTERNAL");
      } else {
        setChannel("SMS");
        setDisplayChannel("SMS");
      }
    }
  }, [messages]);

  const handleSelectFile = (event) => {
    // console.log("Uploading to S3");
    var files = event.target.files;
    Array.from(files).forEach((file) => {
      // console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = addEncodedFile(setContent, file);
      reader.readAsDataURL(file);
    });
  };

  async function parseMediaUrls(attachments, mediaUrls) {
    let data = attachments ? attachments : [];

    mediaUrls.forEach((mediaUrl) => {
      data.push({
        fileName: mediaUrl.url.split("/").pop(),
        contentType: mediaUrl.base.split("data:")[1].split(";")[0],
        encodedFile: mediaUrl.base,
      });
    });

    return data;
  }

  async function dispatchMessage(content, foreground) {
    let messageChannel = channel;
    if (channel === "SMS") {
      if (content.attachments || mediaUrls.length > 0) {
        messageChannel = "MMS";
      }
    }
    sendMessage({
      channel: messageChannel,
      contactId: content.contactId ?? contactId,
      content: content.text,
      notes: content.notes,
      subject: channel === "EMAIL" ? content.subject : null,
      attachments: await parseMediaUrls(content.attachments, mediaUrls),
    })
      .then((_) => {
        if (foreground) {
          updateMessages();
          alertSuccess("Sent Message");
          setContent({ subject: "", text: "", notes: "" });
          setMediaUrls([]);
        }
      })
      .catch((err) => {
        console.log("Fasfsdfasfasfsfd", err);
        alertError("Error Sending Message");
      });
  }
  return (
    <div className={"border flex flex-col w-full max-h-lscreen align-middle"}>
      {/* <!-- Header --> */}
      {title && (
        <div className="py-2 px-3 border-b flex flex-row justify-between items-center">
          <div className="flex items-center">
            <div>
              <Avatar
                src={selectedContact?.profilePicture}
                variant="square"
                className="h-8 w-8 rounded"
              ></Avatar>
            </div>
            <div className="ml-4">
              <div className="flex flex-row items-center">
                <div>
                  <p className="text-grey-darkest">{title}</p>
                </div>

                {selectedContact?.position === "TENANT" && (
                  <div className="px-4">
                    <p className="text-gray-700	">{`${
                      selectedContact?.unitNumber ? "#" : ""
                    } ${selectedContact?.unitNumber || ""}`}</p>
                  </div>
                )}

                <div className="px-4">
                  <p className="text-gray-700	">
                    {selectedContact?.position === "TENANT" &&
                    selectedContact?.buildings?.length > 0
                      ? renderAddress({
                          streetAddress1:
                            selectedContact.buildings[0].address.streetAddress1,
                          streetAddress2:
                            selectedContact.buildings[0].address.streetAddress2,
                          city: selectedContact.buildings[0].address.city,
                        })
                      : ""}
                  </p>
                </div>
              </div>
              <p className="text-grey-darker text-xs mt-1">
                {subtext && renderPhone(subtext)}
              </p>
            </div>
            {clients.length > 1 && (
              <div>
                <HeadlessDropdown
                  onChange={(value) => {
                    const [clientType, clientId] = findClient(value, clients);

                    if (clientType === "INDIVIDUAL") {
                      navigate(`/individual/${clientId}/messages`);
                    } else {
                      navigate(
                        `/commercial/${clientId}/messages/${selectedContact?.contactId}`
                      );
                    }
                  }}
                  options={clients.map((item) => {
                    const clientClassText =
                      clientClassOptions.find(
                        (option) => option.value === item.clientClass
                      )?.label || "Individual";
                    return {
                      label: `${item.clientName}`,
                      tag: clientClassText,
                      value: item.contactId
                        ? `contact|${item.contactId}`
                        : `client|${item.clientId}`,
                    };
                  })}
                  value={undefined}
                >
                  <span className="text-gray-700 text-xs p-2 bg-gray-200 rounded">
                    Switch Clients
                  </span>
                </HeadlessDropdown>
              </div>
            )}
          </div>

          <div className="flex gap-2 pr-4">
            {phone && (
              <div
                className="rounded px-1 py-2 bg-newGray-1400 cursor-pointer"
                onClick={(_) => {
                  if (associateMessaging) {
                    startAssociateCall({
                      number: phone,
                    });
                  } else {
                    startCall({
                      twilio: false,
                      number: phone,
                    });
                  }
                }}
              >
                <PhoneCallIcon />
              </div>
            )}
            <div
              className="rounded px-1 py-2 bg-newGray-1400 cursor-pointer"
              onClick={(_) => updateMessages()}
            >
              <RefreshIcon />
            </div>
          </div>
        </div>
      )}

      {/* <!-- Messages --> */}
      {/* <div className="flex-1 overflow-auto bg-white"> */}
      {
        <MessagesList
          messages={messages}
          contacts={contacts}
          // Buildings to allow Building Tickets
          buildings={buildings}
          tickets={tickets}
          refreshCB={updateMessages}
          fetchNextPage={fetchNextPage}
          addAttachmentToSR={addAttachmentToSR}
          title={title}
          draggableMedia
          ticketId={ticketId}
          associateMessaging={associateMessaging}
          checkBoxForMedia={checkBoxForMedia}
          onClickCheckBoxCB={onClickCheckBoxCB}
        />
      }
      {/* </div> */}

      {/* <!-- Input --> */}
      {contactId && (
        <div className=" px-4 py-4 flex gap-2 items-center ">
          <div
            className="flex-1 flex flex-col gap-2 mx-4"
            {...getRootProps()}
            onClick={(_) => {}}
          >
            {content.copyTo && (
              <MaterialForm
                className="p-1"
                data={content}
                renderArray={copytoFields}
                onChange={(update) => {
                  const { name, value } = update;
                  setContent((data) => deepUpdate(name, value, data));
                }}
              />
            )}
            {displayChannel === "EMAIL" && (
              <input
                className="w-full border rounded px-2 py-2"
                placeholder="Subject"
                value={content.subject}
                onChange={(e) =>
                  setContent({ ...content, subject: e.target.value })
                }
              />
            )}
            <NewMediaDragIn
              urls={mediaUrls}
              editMedia={true}
              emptyScreen={false}
              onDropCB={onDropCB}
              OnDeleteCB={OnDeleteCB}
            >
              {editNotesFlag ? (
                <ReactMention
                  value={content.notes || ""}
                  onChangeCB={(text, mentions, plainText) => {
                    setContent({
                      ...content,
                      notes: text,
                      mentionIds: mentions,
                      notesWithNames: plainText,
                    });
                  }}
                />
              ) : (
                <TextField
                  variant="standard"
                  className={
                    "w-full border rounded px-4 py-2 messages-textfield text-sm " +
                    (editNotesFlag ? "bg-yellow-200" : "bg-newGray-1400")
                  }
                  multiline
                  minRows={3}
                  maxRows={8}
                  placeholder={"New Message"}
                  value={content.text}
                  onChange={(e) =>
                    setContent({ ...content, text: e.target.value })
                  }
                />
              )}
            </NewMediaDragIn>
            {content.attachments?.map((attachment) => (
              <p>
                File: {attachment.fileName}
                <CloseIcon
                  className="cursor-pointer"
                  onClick={(e) =>
                    setContent({ ...content, attachments: undefined })
                  }
                />
              </p>
            ))}
            <div className="flex flex-row items-center gap-2 justify-between">
              <div className="flex flex-row items-center">
                <div>
                  <button
                    className="border border-newGray-1500 rounded py-2 px-1 text-sm text-gray-700 "
                    onClick={(_) => triggerEditNotes((current) => !current)}
                  >
                    {editNotesFlag
                      ? "Switch to Messaging"
                      : content.notes
                      ? "Edit Notes"
                      : "Add Notes"}
                  </button>
                </div>
                <div className="px-1">
                  {content.copyTo ? (
                    <button
                      className="border border-newGray-1500 rounded py-2 px-1 text-sm text-gray-700 cursor-pointer"
                      onClick={(_) => {
                        setContent((content) => {
                          return { ...content, copyTo: undefined };
                        });
                      }}
                    >
                      Remove Bcc
                    </button>
                  ) : (
                    <button
                      className="border border-newGray-1500 rounded py-2 px-1 text-sm text-gray-700 cursor-pointer"
                      onClick={(_) => {
                        setContent((content) => {
                          return { ...content, copyTo: [] };
                        });
                      }}
                    >
                      Add Bcc
                    </button>
                  )}
                </div>
                {selectedContact.preferredContactMethod && (
                  <div>
                    <span className="border border-newGray-1500 rounded py-2 px-1 text-sm text-gray-700 cursor-pointer">
                      Prefers: {selectedContact.preferredContactMethod}
                    </span>
                  </div>
                )}
                <div>
                  <HeadlessDropdown
                    value={displayChannel}
                    align="bottom"
                    options={[
                      "SMS",
                      "EMAIL",
                      // For Associate Messaging
                      ...conditionalArrayEntry(showApp, "APP"),
                      // For Client Messaging
                      ...conditionalArrayEntry(!showApp, "DASHBOARD"),
                    ]}
                    defaultValue={{
                      channel:
                        selectedContact.preferredContactMethod ||
                        displayChannel,
                    }}
                    onChange={onChangeCB}
                  >
                    <span className="border border-newGray-1500 rounded py-2 px-1 text-sm text-gray-700 cursor-pointer">
                      {displayChannel}
                    </span>
                  </HeadlessDropdown>
                </div>
                <div className="rounded border px-2 py-2 mx-2 border-newGray-1400">
                  <div onClick={(_) => fileRef.current.click()}>
                    <AddIconBlue className="h-4 w-4 cursor-pointer" />
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    onChange={handleSelectFile}
                    ref={fileRef}
                    multiple
                  />
                  <input className="hidden" {...getInputProps()} />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <button
                  className="rounded px-2 py-1 bg-newBlue-400 text-white cursor-pointer"
                  onClick={
                    editNotesFlag
                      ? (_) =>
                          saveInternalNotes({
                            contactId: contactId,
                            notes: content.notes,
                            attachments: content.attachments,
                            mentionIds: content.mentionIds || [],
                            notesWithNames: content.notesWithNames,
                          })
                            .then((_) => {
                              updateMessages();
                              alertSuccess("Saved Notes");
                              setContent({ subject: "", text: "", notes: "" });
                            })
                            .catch((_) => alertError("Error Saving Notes"))
                      : (_) => {
                          alertInfo("Sending Message", undefined, {
                            loading: true,
                          });
                          dispatchMessage(content, true);
                          if (content.copyTo?.length > 0)
                            content.copyTo.forEach((copy) =>
                              dispatchMessage({
                                ...content,
                                contactId: Number(copy),
                              })
                            );
                        }
                  }
                >
                  <div className="flex flex-row items-center">
                    <div className="text-sm">
                      {editNotesFlag ? "Save" : "Send"}
                    </div>
                    {!editNotesFlag && (
                      <div className="px-1">
                        <SendIconPlaneIcon />
                      </div>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      )}

      {changeOwner.display && (
        <MaterialModal
          open={changeOwner.display !== false}
          setOpen={(_) =>
            setChangeOwner((current) => {
              return {
                ...current,
                display: false,
              };
            })
          }
          label="new-user-modal"
          describedby="create-new-user"
        >
          <SelectOwner
            ticketId={changeOwner.ticket}
            currentOwner={changeOwner.ownerId}
            territory={data.territory}
            changeOwnerCB={(data) => {
              assignOwner(data).then(() => {
                fetchTicketDetail(changeOwner.ticket);
                setChangeOwner((current) => {
                  return {
                    ...current,
                    display: false,
                  };
                });
              });
            }}
          />
        </MaterialModal>
      )}
    </div>
  );
}

const DynamicIcon = (props) => {
  const Icon = props.icon;
  return <Icon {...props} />;
};
