import React, { useEffect, useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button, TextField, Typography } from "@mui/material";
import { Avatar, IconButton, InputAdornment } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { OutlinedInput } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import { navigate } from "raviger";

import { alertError, alertSuccess } from "../../actions/AlertActions.js";
import {
  adminResetPassword,
  createUpdateTwilioPhoneNumber,
  createUser,
  disableUser,
  editAddTerritory,
  editUser,
  getTerritory,
  listUsers,
  twilioPhoneNumber,
  getPhoneNumbers,
  getVendors,
  getMyVendor,
} from "../../api/Api";
import ListPhoneNumbers from "../../pages/phoneNumbers/ListPhoneNumbers";
import SalesScreen from "../../pages/sales/SalesScreen";
import EditMySubscriber from "../../pages/vendors/EditMySubscriber";
import EditTerritory from "../../pages/vendors/EditTerritory";
import ListSkills from "../../pages/vendors/ListSkills";
import ListVendors from "../../pages/vendors/ListVendors";
// import activeUserStore from "../../store/ActiveUserStore";
import ActiveUserStore from "../../store/ActiveUserStore.js";
import activeUserStore from "../../store/ActiveUserStore.js";
import { deepUpdate, renderPhone } from "../../utils/StateUtils";
import { lowerCase } from "../../utils/StringUtils.js";
import { properString } from "../../utils/StringUtils.js";
import { timeZones } from "../../utils/constants.js";
import { AddIconBlue } from "../common/AppIcons";
import MaterialModal from "../common/MaterialModal";
import MaterialTable from "../common/MaterialTable";
import MaterialForm from "../common/form/MaterialForm";
import StripePayments from "./StripePayments";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
}));

const getRoleText = (role) => {
  switch (role) {
    case "ROLE_ADMIN":
      return "Admin";
    case "ROLE_CSR":
      return "CSR";
    case "ROLE_ASSOCIATE":
      return "Associate";
    default:
      return "User";
  }
};

const getRolesArray = (roles) => {
  let roleArray = [];
  roles.map((item) => {
    roleArray.push(item.name);
  });
  return roleArray;
};

export default function SelectedListItem({ tab, vendorId }) {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [formState, setFormState] = useState();
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState();

  const [filteredUsers, setFilteredUsers] = useState([]);

  const [editZip, setEditZip] = useState({
    display: false,
    territoryId: 0,
    territoryName: "",
  });

  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [openTerModal, setOpenTerModal] = useState(false);

  const [vendorList, setVendorList] = useState([]);

  const [filter, setFilter] = useState(-1);
  const [current, setCurrent] = useState({ vendorName: "" });

  const [openDisableModal, setOpenDisableModal] = useState(false);

  const [editUserPayload, setEditUserPayload] = useState({});
  const [vendorData, setVendorData] = useState({});

  const [showPassword, setShowPassword] = useState(false);

  const [selectedTab, setTab] = useState("clients");

  const [territories, setTerritories] = useState([]);
  const [selectedTerritory, setSelectedTerritory] = useState();
  const [editTerritoryPayload, setEditTerritoryPayload] = useState({});

  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [phoneNumbersData, setPhoneNumbersData] = useState([]);
  const [phoneNumberModal, setPhoneNumberModal] = useState({
    display: false,
    mode: "create",
  });
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState({});
  const [territoryModal, setTerritoryModal] = useState({
    display: false,
    selected: "",
  });

  useEffect(() => {
    getMyVendor().then((data) => {
      setVendorData(data);
    });
  }, []);

  useEffect(() => {
    if (tab !== undefined) setTab(tab);
    if (vendorId !== undefined) {
      setFilter(Number(vendorId));
      setCurrent((current) => deepUpdate("vendorName", vendorId, current));
    }
  }, [tab, vendorId]);

  const loadUsers = () => {
    listUsers().then((res) => {
      setUsers(res);
      setFilteredUsers(res);
    });

    if (activeUserStore.isSuper()) {
      getVendors({ page: 0, size: 100 })
        .then((data) => {
          setVendorList(
            data.content.map((item) => ({ value: item.id, label: item.name }))
          );
        })
        .catch((_) =>
          alertError("Couldn't Connect to Server, Check your Connection")
        );
    }
  };

  const fetchTerritory = () => {
    getTerritory().then((res) => {
      setTerritories(res);
    });
  };

  useEffect(() => {
    if (filter !== -1) {
      listUsers(filter).then((res) => {
        setFilteredUsers(res);
      });
    } else setFilteredUsers(users);
  }, [filter]);

  const fetchPhoneNumbers = () => {
    twilioPhoneNumber().then((data) => {
      setPhoneNumbers(data);
    });
  };

  const fetchUserPhoneNumbers = () => {
    getPhoneNumbers(0, 100)
      .then((data) => {
        setPhoneNumbersData(data.content);
      })
      .catch(() => alertError("Error while fetching Numbers"));
  };

  useEffect(() => {
    if (!activeUserStore.isSuper() && !activeUserStore.isAdmin()) navigate("/");
    else {
      loadUsers();
      fetchTerritory();
      fetchPhoneNumbers();
      fetchUserPhoneNumbers();
    }
  }, []);

  useEffect(() => {
    fetchTerritory();
  }, [selectedTerritory]);

  useEffect(() => {
    if (activeUserStore.isSuper() || activeUserStore.isAdmin()) loadUsers();
  }, [selected]);

  useEffect(() => {
    if (selectedTerritory)
      setEditTerritoryPayload({
        id: selectedTerritory.id,
        name: properString(selectedTerritory.name),
        timezone: selectedTerritory.timezone,
      });
  }, [selectedTerritory]);

  useEffect(() => {
    if (selected)
      setEditUserPayload({
        id: selected.id,
        firstName: selected.firstName,
        lastName: selected.lastName,
        email: selected.email,
        phoneNumber: selected.phoneNumber,
        vendorType: selected.vendorType,
        roleNames: getRolesArray(selected.roles),
      });
  }, [selected]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const handleSubmit = (event) => {
    createUser(editUserPayload)
      .then((_) => {
        loadUsers();
        setOpenModal(false);
      })
      .catch((_) => alertError("Error Creating User"));
  };
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const gettableHead = () => {
    let head = [];
    if (selectedTab === "clients") {
      if (activeUserStore.isSuper())
        head = [
          { id: "firstName", label: "First Name" },
          { id: "lastName", label: "Last Name" },
          { id: "email", label: "Email" },
          { id: "phoneNumber", label: "Phone Number" },
          { id: "createdAt", label: "Created At" },
          {
            id: "vendorName",
            label: "Subscriber Name",
          },
          { id: "role", label: "Role" },
        ];
      else
        head = [
          { id: "firstName", label: "First Name" },
          { id: "lastName", label: "Last Name" },
          { id: "email", label: "Email" },
          { id: "phoneNumber", label: "Phone Number" },
          { id: "createdAt", label: "Created At" },
          { id: "role", label: "Role" },
        ];
    } else if (selectedTab === "territories") {
      head = [
        { id: "name", label: "Name" },
        { id: "phoneNumbers", label: "Phone Number" },
        { id: "timezone", label: "Time Zone" },
        { id: "viewZip", label: "" },
      ];
    } else if (selectedTab === "MarketingCampaign") {
      head = [
        { id: "marketingCampaign", label: "MARKETING CAMPAIGN" },
        { id: "phoneNumber", label: "PHONE NUMBER" },
        { id: "hsaAd", label: "HSA AD" },
        { id: "utm", label: "UTM" },
        { id: "startedOn", label: "STARTED ON" },
        { id: "endedOn", label: "ENDED ON" },
        { id: "location", label: "LOCATION" },
      ];
    }

    return head;
  };

  useEffect(() => {
    if (editZip.display)
      setTerritoryModal({
        display: false,
        selected: territories.map((item) => ({
          value: item.name,
          label: properString(item.name),
        })),
      });
  }, [editZip]);

  const getTabs = () => {
    const adminTabs = [
      { label: "Users", value: "clients" },
      { label: "Territories", value: "territories" },
      { label: "Marketing Campaigns", value: "MarketingCampaign" },
      { label: "Pipelines", value: "pipelines" },
      { label: "Categories", value: "skills" },
      { label: "Phone Numbers", value: "phoneNumbers" },
      { label: "Subscriber Details", value: "subscriberDetails" },
      { label: "Stripe Payments", value: "stripe" },
    ];

    if (activeUserStore.isSuper() && activeUserStore.isAdmin())
      return [...adminTabs, { label: "Subscribers", value: "vendors" }];
    if (activeUserStore.isSuper())
      return [
        { label: "Users", value: "clients" },
        { label: "Subscribers", value: "vendors" },
      ];
    if (activeUserStore.isAdmin()) return adminTabs;
    else return [];
  };
  const getTableData = () => {
    let data = [];
    if (selectedTab === "clients") {
      data = filteredUsers?.map((user) => {
        if (activeUserStore.isSuper)
          return {
            id: user.id,
            data: [
              user.firstName,
              user.lastName,
              user.email,
              renderPhone(user.phoneNumber),
              new Date(user.createdAt).toLocaleString(),
              user.vendorName,
              user.roles.find((role) => role.name === "ROLE_ADMIN")
                ? "Admin"
                : "User",
            ],
          };
        else
          return {
            id: user.id,
            data: [
              user.firstName,
              user.lastName,
              user.email,
              renderPhone(user.phoneNumber),
              new Date(user.createdAt).toLocaleString(),
              user.roles.find((role) => role.name === "ROLE_ADMIN")
                ? "Admin"
                : "User",
            ],
          };
      });
    } else if (selectedTab === "territories") {
      data = territories?.map((territory) => {
        return {
          id: territory.id,
          data: [
            properString(territory.name),
            {
              render: (
                <div className="flex flex-row">
                  <div>{territory.marketingCampaignName}</div>
                  <div className="px-2">
                    <span
                      className="inline-flex items-center rounded px-2 py-0.5 text-xs font-medium estimateStatus"
                      style={{
                        color: "rgba(18, 37, 157, 1)",
                        backgroundColor: "rgba(229, 231, 244, 1)",
                      }}
                    >
                      {territory.phoneNumber !== null
                        ? renderPhone(territory.phoneNumber.phone)
                        : ""}
                    </span>
                  </div>
                </div>
              ),
            },

            territory.timezone,
            {
              render: (
                <button
                  className="flex flex-row p-2 items-center border rounded border-newGray-700"
                  onClick={() => {
                    setEditZip({
                      display: true,
                      territoryId: territory.id,
                      territoryName: territory.name,
                    });
                  }}
                >
                  <span className="pl-2 text-newBlue-400 text-sm font-medium">
                    View Zip Codes
                  </span>
                </button>
              ),
            },
          ],
        };
      });
    } else if (selectedTab === "MarketingCampaign") {
      data = phoneNumbers?.map((number) => {
        return {
          id: number.marketingCampaignId,
          data: [
            number.marketingCampaignName,
            renderPhone(number.phoneNumber?.phone),
            number.hsaAd,
            number.utm,
            number.startedOn
              ? moment(number.startedOn).format("MM/DD/YY hh:mm A")
              : "-",
            number.endedOn
              ? moment(number.endedOn).format("MM/DD/YY hh:mm A")
              : "-",
            properString(number.location),
          ],
        };
      });
    }

    return data;
  };
  return (
    <div className="">
      <div className=" m-4 bg-white p-4 rounded-lg border-2">
        <div className="flex flex-col border-2 ">
          <div className="flex flex-row w-full border-b overflow-auto overflow-y-hidden">
            <div className="px-4">
              <div className=" border-gray-200">
                <nav className="-mb-px flex space-x-8 " aria-label="Tabs">
                  {getTabs().map((item) => (
                    <button
                      className={
                        "border-transparent text-base hover:text-gray-700  whitespace-nowrap py-4 px-1 border-b-2 font-medium focus:outline-none " +
                        (selectedTab === item.value
                          ? "text-newBlue-400 border-b-4 border-newBlue-400"
                          : "text-newGray-800")
                      }
                      onClick={() => {
                        setSearchText("");
                        setFilter(-1);
                        setCurrent((current) =>
                          deepUpdate("vendorName", -1, current)
                        );
                        setTab(item.value);
                        navigate("/settings");
                      }}
                    >
                      {item.label}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          {selectedTab === "pipelines" ? (
            <SalesScreen />
          ) : (
            <>
              {selectedTab === "vendors" ||
              selectedTab === "skills" ||
              selectedTab === "phoneNumbers" ||
              selectedTab === "stripe" ||
              selectedTab === "subscriberDetails" ? (
                selectedTab === "vendors" ? (
                  <ListVendors />
                ) : selectedTab === "skills" ? (
                  <ListSkills />
                ) : selectedTab === "subscriberDetails" ? (
                  <EditMySubscriber />
                ) : selectedTab === "stripe" ? (
                  <StripePayments />
                ) : (
                  <ListPhoneNumbers />
                )
              ) : (
                <>
                  <div className="flex flex-row justify-between items-center pt-6 px-4 h-max">
                    <div className="flex flex-row items-center w-full">
                      <p className="font-semibold text-newBlue-400 w-full">
                        <div className="relative w-2/6">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-newGray-300 dark:text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                              ></path>
                            </svg>
                          </div>
                          <input
                            type="search"
                            id="default-search"
                            className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-800"
                            placeholder="Search..."
                            required
                            value={searchText}
                            onChange={(e) => {
                              setSearchText(e.target.value);
                            }}
                          />
                        </div>
                      </p>
                    </div>
                    {selectedTab === "clients" && activeUserStore.isSuper() && (
                      <div className="w-60">
                        <MaterialForm
                          data={current}
                          renderArray={[
                            {
                              name: "vendorName",
                              label: "Filter by Subscriber",
                              inputType: "SELECT",
                              options: [
                                { value: -1, label: "All" },
                                ...(vendorList ?? []),
                              ],
                            },
                          ]}
                          onChange={({ value, name }) => {
                            setFilter(value);
                            setCurrent((current) => {
                              return deepUpdate(name, value, current);
                            });
                          }}
                        ></MaterialForm>
                      </div>
                    )}
                    <div className="flex flex-row items-center gap-4">
                      <div className="">
                        <div
                          className="flex flex-row items-center cursor-pointer"
                          onClick={() => {
                            if (selectedTab === "clients") {
                              setOpenModal(true);
                            } else if (selectedTab === "territories") {
                              setSelectedTerritory(true);
                            } else {
                              setPhoneNumberModal({
                                display: true,
                                mode: "create",
                              });
                            }
                          }}
                        >
                          <AddIconBlue className="h-4 w-4" />
                          <span className="px-1 whitespace-nowrap text-newBlue-400">
                            {selectedTab === "clients" &&
                              ActiveUserStore.isAdmin() &&
                              "New User"}
                            {selectedTab === "territories" && "New Territory"}
                            {selectedTab === "MarketingCampaign" &&
                              ActiveUserStore.isAdmin() &&
                              "New Marketing Campaign"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="py-4">
                    <div className="border-t ">
                      <MaterialTable
                        data={getTableData()}
                        head={gettableHead()}
                        headRenderColor="text-newGray-300"
                        defaultOrderBy={"createdAt"}
                        defaultOrder="desc"
                        onClickCB={(selection) => {
                          if (selectedTab === "clients") {
                            setSelected(
                              users.find((user) => user.id === selection)
                            );
                          } else if (selectedTab === "territories") {
                            const terr = territories.find(
                              (territory) => territory.id === selection
                            );

                            setTerritoryModal({
                              display: true,
                              selected: terr,
                            });
                          } else {
                            setPhoneNumberModal({
                              display: true,
                              mode: "edit",
                            });
                            const select = phoneNumbers.find(
                              (phoneNumber) =>
                                phoneNumber.marketingCampaignId === selection
                            );
                            select.phoneNumberId = select.phoneNumber.id;
                            setSelectedPhoneNumber(select);
                          }
                        }}
                        searchText={searchText}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <MaterialModal
        open={editZip.display}
        setOpen={() =>
          setEditZip({ display: false, territoryId: 0, territoryName: "" })
        }
      >
        <EditTerritory
          territoryId={editZip.territoryId}
          territoryName={editZip.territoryName}
        />
      </MaterialModal>
      <MaterialModal open={openModal} setOpen={setOpenModal}>
        <MaterialForm
          data={editUserPayload}
          renderArray={[
            {
              name: "firstName",
            },
            {
              name: "lastName",
            },
            {
              name: "email",
              inputType: "EMAIL",
            },
            {
              name: "password",
            },
            {
              name: "phoneNumber",
              inputType: "PHONE",
            },
            {
              name: "roleNames",
              inputType: "SELECT",
              variant: "MULTISELECT",
              defaultValue: [],
              options: [
                { label: "Supervisor", value: "ROLE_SUPERVISOR" },
                { label: "Admin", value: "ROLE_ADMIN" },
                {
                  label: vendorData?.type === "PMC" ? "Manager" : "CSR",
                  value: "ROLE_CSR",
                },
              ],
            },
          ]}
          onChange={(update) => {
            const { name, value } = update;
            console.log(name, value);
            setEditUserPayload((data) => deepUpdate(name, value, data));
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          Create
        </Button>
      </MaterialModal>
      <MaterialModal
        open={selectedTerritory ? true : false}
        setOpen={(_) => {
          setSelectedTerritory();
        }}
        className={""}
      >
        {selectedTerritory && (
          <>
            <div className="flex">
              <Typography variant="h6" className="flex-1">
                {selectedTerritory === true
                  ? "New Territory"
                  : "Update Territory"}
              </Typography>
              <div className="mr-4 flex-shrink-0"></div>
              <div></div>
            </div>
            <MaterialForm
              className="w-80"
              data={editTerritoryPayload}
              renderArray={[
                {
                  name: "name",
                },
                {
                  name: "timezone",
                  inputType: "SELECT",
                  options: timeZones,
                },
                {
                  name: "phoneNumberId",
                  inputType: "SELECT",
                  label: "Default Phone Number",
                  options: phoneNumbersData.map((phoneNumber) => {
                    return {
                      value: phoneNumber.id,
                      label: `${renderPhone(phoneNumber.phone)} | ${
                        phoneNumber.friendlyName
                      }`,
                    };
                  }),
                },
              ]}
              onChange={(update) => {
                const { name, value } = update;
                setEditTerritoryPayload((data) =>
                  deepUpdate(name, value, data)
                );
              }}
            />
            <Button
              color="primary"
              className="float-right p-4"
              onClick={() => {
                editTerritoryPayload["name"] = editTerritoryPayload.name
                  .toUpperCase()
                  .split(" ")
                  .join("_");

                editAddTerritory(editTerritoryPayload)
                  .then(() => {
                    setSelectedTerritory();
                    alertSuccess("Territory Updated Successfully");
                  })
                  .catch((_) => {
                    alertError("Territory Update Failed");
                  });
              }}
            >
              {selectedTerritory === true
                ? "Add Territory"
                : "Update Territory"}
            </Button>
          </>
        )}
      </MaterialModal>
      <MaterialModal
        open={selected ? true : false}
        setOpen={(_) => {
          setSelected();
          setFormState();
          setEditUserPayload();
        }}
      >
        {selected && (
          <>
            <div className="flex">
              <div className="mr-4 flex-shrink-0">
                <Avatar
                  className="h-16 w-16 border border-gray-300 bg-white text-gray-800"
                  src={selected.profilePic}
                >
                  {editUserPayload.firstName
                    ? editUserPayload.firstName[0]
                    : "U"}
                </Avatar>
              </div>
              <div>
                <h4 className="text-lg font-bold">{`${editUserPayload.firstName} ${editUserPayload.lastName}`}</h4>
                <p className="mt-1">
                  {editUserPayload.roleNames &&
                    editUserPayload.roleNames.map((role, index, roles) => {
                      const roleText = getRoleText(role);

                      return ` ${roleText} ${
                        index < roles.length - 1 ? "|" : ""
                      }`;
                    })}
                </p>
                {selected.resetPassword ? (
                  <div className=" mt-4 flex gap-2">
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="component-outlined">
                        New Password
                      </InputLabel>

                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        value={formState?.resetPasswordString}
                        onChange={handleChange}
                        name="resetPasswordString"
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                    <Button
                      id="reset-password-submit"
                      onClick={(_) =>
                        adminResetPassword({
                          userId: selected.id,
                          password: formState.resetPasswordString,
                        })
                          .then((_) => {
                            alertSuccess("Password Reset Successfully");
                          })
                          .catch((_) => {
                            alertError("Couldn't Reset Password");
                          })
                      }
                    >
                      Save
                    </Button>
                  </div>
                ) : (
                  <Button
                    id="reset-password"
                    onClick={(_) =>
                      setSelected({ ...selected, resetPassword: true })
                    }
                  >
                    Reset Password
                  </Button>
                )}
              </div>
            </div>
            <MaterialForm
              data={editUserPayload}
              renderArray={[
                {
                  name: "firstName",
                },
                {
                  name: "lastName",
                },
                {
                  name: "email",
                  inputType: "EMAIL",
                },
                {
                  name: "phoneNumber",
                  inputType: "PHONE",
                },
                {
                  name: "roleNames",
                  inputType: "SELECT",
                  variant: "MULTISELECT",
                  defaultValue: editUserPayload.roles,
                  options: [
                    { label: "Associate", value: "ROLE_ASSOCIATE" },
                    { label: "Supervisor", value: "ROLE_SUPERVISOR" },
                    { label: "Admin", value: "ROLE_ADMIN" },
                    {
                      label:
                        editUserPayload.vendorType === "PMC"
                          ? "Manager"
                          : "CSR",
                      value: "ROLE_CSR",
                    },
                  ],
                },
              ]}
              onChange={(update) => {
                const { name, value } = update;

                setEditUserPayload((data) => deepUpdate(name, value, data));
              }}
            />
            <Button
              color="primary"
              className="float-right p-4 update-user-button"
              onClick={() => {
                editUser(editUserPayload)
                  .then(() => {
                    alertSuccess("User Updated Successfully");
                  })
                  .catch((_) => {
                    alertError("User Update Failed");
                  });
              }}
            >
              Update User
            </Button>
            <Button
              className="float-right p-4 text-red-500"
              onClick={() => {
                setOpenDisableModal(true);
              }}
            >
              Disable
            </Button>
          </>
        )}
      </MaterialModal>
      <MaterialModal
        open={openDisableModal ? true : false}
        setOpen={(_) => {
          setOpenDisableModal();
        }}
      >
        <p>Do you want to disable the user?</p>
        <Button
          className="float-right p-4 text-red-500"
          onClick={() => {
            disableUser(editUserPayload.id)
              .then(() => {
                setSelected();
                setOpenDisableModal(false);
                alertSuccess("User Disabled Successfully");
              })
              .catch((_) => {
                alertError("User Disable Failed");
              });
          }}
        >
          Yes
        </Button>
        <Button
          className="float-right p-4 "
          onClick={() => {
            setOpenDisableModal(false);
          }}
        >
          No
        </Button>
      </MaterialModal>
      <MaterialModal
        open={phoneNumberModal.display ? true : false}
        setOpen={(_) => {
          setPhoneNumberModal({ display: false, mode: "create" });
          setSelectedPhoneNumber({});
        }}
        className={""}
      >
        <>
          <div className="flex">
            <Typography variant="h6" className="flex-1 px-4">
              {selectedPhoneNumber &&
              Object.keys(selectedPhoneNumber).length === 0
                ? "New Marketing Campaign"
                : "Update Marketing Campaign"}
            </Typography>
            <div className="mr-4 flex-shrink-0"></div>
            <div></div>
          </div>
          <MaterialForm
            data={selectedPhoneNumber}
            renderArray={[
              {
                name: "marketingCampaignName",
              },
              {
                name: "phoneNumberId",
                label: "Phone Number",
                inputType: "SELECT",
                value: territoryModal.selected,
                options: phoneNumbersData.map((phoneNumber) => {
                  return {
                    value: phoneNumber.id,
                    label: `${renderPhone(phoneNumber.phone)} | ${
                      phoneNumber.friendlyName
                    }`,
                  };
                }),
              },
              { name: "hsaAd" },
              { name: "utm" },
              {
                name: "startedOn",
                inputType: "DATETIME",
                variant: "STEP30",
              },
              {
                name: "endedOn",
                inputType: "DATETIME",
                variant: "STEP30",
              },

              {
                name: "location",
                inputType: "SELECT",
                options: territories.map((item) => ({
                  value: item.name,
                  label: properString(item.name),
                })),
              },
            ]}
            onChange={(update) => {
              const { name, value } = update;
              setSelectedPhoneNumber((data) => deepUpdate(name, value, data));
            }}
          />
          <Button
            color="primary"
            className="float-right p-4"
            onClick={() => {
              createUpdateTwilioPhoneNumber(selectedPhoneNumber).then(
                (data) => {
                  if (phoneNumberModal.mode === "create") {
                    alertSuccess("Marketing Campaign Created Successfully");
                  } else {
                    alertSuccess("Marketing Campaign Updated Successfully");
                  }
                  setPhoneNumberModal({
                    display: false,
                    mode: "create",
                  });
                  setSelectedPhoneNumber({});
                  fetchPhoneNumbers();
                }
              );
            }}
          >
            {Object.keys(selectedPhoneNumber).length === 0
              ? "Create"
              : "Update"}
          </Button>
        </>
      </MaterialModal>
      <MaterialModal
        open={territoryModal.display ? true : false}
        setOpen={(_) => {
          setTerritoryModal({
            display: false,
            selected: "",
          });
        }}
        containerClassName="max-w-md w-full"
        className={""}
      >
        <Typography variant="h6" className="px-4">
          {properString(territoryModal.selected.name)}
        </Typography>
        <div className="py-2">
          <MaterialForm
            data={territoryModal.selected}
            renderArray={[
              {
                name: "phoneNumber",
                inputType: "SELECT",
                value: territoryModal.selected,
                options: phoneNumbersData.map((phoneNumber) => {
                  return {
                    value: phoneNumber.id,
                    label: `${renderPhone(phoneNumber.phone)} | ${
                      phoneNumber.friendlyName
                    }`,
                  };
                }),
              },
            ]}
            onChange={(update) => {
              const { value } = update;
              setTerritoryModal((data) => {
                return {
                  ...data,
                  selected: deepUpdate("phoneNumberId", value, data.selected),
                };
              });
            }}
          />
        </div>

        <Button
          color="primary"
          className="float-right p-4"
          onClick={() => {
            let payload = { ...territoryModal.selected };

            editAddTerritory(payload)
              .then(() => {
                fetchTerritory();

                setSelectedTerritory();
                alertSuccess("Territory Updated Successfully");
              })
              .catch((_) => {
                alertError("Territory Update Failed");
              });

            setTerritoryModal({ display: false, selected: "" });
          }}
        >
          Update
        </Button>
      </MaterialModal>
    </div>
  );
}
