import { useEffect, useState } from "react";

import { Tab } from "@headlessui/react";
import { Link, navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import { getAssociate, getAssociateHistory } from "../../api/Api";
import AssociateMessages from "../../components/associates/AssociateMessages";
import {
  AssociateIcon,
  MapIcon,
  SkillsIcon,
  TicketBagIcon,
} from "../../components/clients/ClientAppIcons";
import { LeftArrowWithTailIcon } from "../../components/common/AppIcons";
import { SuspenseBoundary } from "../../components/common/core/SuspenseBoundary";
import { capitalizeFirst, properString } from "../../utils/StringUtils";
import PageLayout from "../Common/PageLayout";
import AssociateAppointments from "./AssociateAppointments";
import AssociateDetails from "./AssociateDetails";
import { AssociateDetailsData, AssociateHistoryData } from "./Service";

type AssociatesHomeScreenProps = {
  associateId: number;
  page: string;
  defaultContactId: number;
};
type ContentProps = {
  tab: string;
  associateDetails?: AssociateDetailsData;
  associateId: number;
  defaultContactId: number;
};

type HeaderLeftProps = {
  firstName: string;
  lastName: string;
  FsoType: string;
  noOfTickets: number;
  skills: string;
  territories: string[];
  companyName: string;
};
function headerLeft({
  FsoType,
  firstName,
  lastName,
  noOfTickets,
  skills,
  territories,
  companyName,
}: HeaderLeftProps) {
  const territoriesList = territories.map((item) =>
    properString(item.toLowerCase())
  );

  return (
    <div className="mb-5">
      <Link
        href={`/associates`}
        className=" flex flex-row items-center hover:bg-gray-200 rounded-md flex-grow-0 flex-shrink"
      >
        <div>
          <LeftArrowWithTailIcon className="h-3 w-3 fill-newBlue-400" />
        </div>
        <div className="px-1 text-sm text-newBlue-400"> All FSOs</div>
      </Link>
      <div className="text-gray-900 text-2xl mt-6 mb-5">
        {companyName ? `${companyName}` : `${firstName} ${lastName}`}
      </div>
      <div className="flex flex-row">
        <div className="flex flex-row">
          <AssociateIcon />
          <div className="text-base text-gray-800 mr-7 ml-2">
            {`FSO Type: ${properString(FsoType)}`}
          </div>
        </div>
        <div className="flex flex-row items-center">
          <TicketBagIcon />
          <div className="text-base text-gray-800 ml-2">{`No. of Appointments: ${noOfTickets}`}</div>
        </div>
      </div>
      <div className="flex flex-row mt-4">
        <div className="flex flex-row ">
          <SkillsIcon />
          <div className="text-base text-gray-800 mr-7 ml-2">{`Categories: ${capitalizeFirst(
            skills
          )}`}</div>
        </div>
        <div className="flex flex-row">
          <MapIcon />
          <div className="text-base text-gray-800 ml-2">
            {`Territories: ${territoriesList.join(", ")}`}
          </div>
        </div>
      </div>
    </div>
  );
}

const AssociateHomeTabs = [
  { label: "Details", value: "details" },
  { label: "Appointments", value: "appointments" },
  { label: "Messages", value: "messages" },
];

function content({
  tab,
  associateDetails,
  associateId,
  defaultContactId,
}: ContentProps) {
  return (
    // <SuspenseBoundary
    //   name="Client Home"
    //   waitFor=""
    //   fallback={undefined}
    //   fallbackClassName={undefined}
    // >
    <Tab.Group
      selectedIndex={AssociateHomeTabs.findIndex((item) => item.value === tab)}
      onChange={(index) => {
        console.log(AssociateHomeTabs[index].value);

        navigate(
          `/associates/${associateId}/${AssociateHomeTabs[index].value}`
        );
      }}
    >
      <div className="flex flex-row w-full border-b">
        <div className="p-4">
          <div className=" border-gray-200">
            <Tab.List className="-mb-px flex space-x-8" aria-label="Tabs">
              {AssociateHomeTabs.map((item, _index) => {
                return (
                  <Tab key={item.value} className={"focus:outline-none"}>
                    {({ selected }) => (
                      <span
                        className={
                          "cursor-pointer border-transparent text-base hover:text-gray-700  whitespace-nowrap py-4 px-1 border-b-2 font-medium " +
                          (selected
                            ? "text-newBlue-400 border-b-4 border-newBlue-400"
                            : "text-newGray-800")
                        }
                      >
                        {item.label}
                      </span>
                    )}
                  </Tab>
                );
              })}
            </Tab.List>
          </div>
        </div>
      </div>
      {/* Ensuring that the components are not rendered when a client switch happens */}
      {/* Otherwise internal effects will trigger redirects */}

      <Tab.Panels>
        <Tab.Panel>
          <SuspenseBoundary
            name="Associate Details"
            waitFor={associateDetails}
            fallback={undefined}
            fallbackClassName={undefined}
          >
            <AssociateDetails associateId={associateId} />
          </SuspenseBoundary>
        </Tab.Panel>
        <Tab.Panel>
          <SuspenseBoundary
            name="Associate Appointments"
            waitFor={tab === "appointments"}
            fallback={undefined}
            fallbackClassName={undefined}
          >
            <AssociateAppointments associateId={associateId} />
          </SuspenseBoundary>
        </Tab.Panel>

        <Tab.Panel>
          {/* <SuspenseBoundary
              name="Associate Messages"
              waitFor={tab === "messages"}
              fallback={undefined}
              fallbackClassName={undefined}
            > */}
          <AssociateMessages
            associateId={associateId}
            defaultContactId={defaultContactId}
          />
          {/* </SuspenseBoundary> */}
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}

export default function AssociatesHomeScreen({
  associateId,
  page,
  defaultContactId,
}: AssociatesHomeScreenProps) {
  const [associateDetails, setAssociateDetails] =
    useState<AssociateDetailsData>();

  const [history, setHistory] = useState<AssociateHistoryData[]>();
  useEffect(() => {
    getAssociate({ associateId: associateId })
      .then((data) => setAssociateDetails(data as AssociateDetailsData))
      .catch((_) => alertError("Error getting Associate Details"));
    getAssociateHistory(associateId)
      .then((data) => setHistory(data as AssociateHistoryData[]))
      .catch((_) => alertError("Couldn't get Job History"));
  }, [associateId]);

  return (
    <PageLayout
      content={content({
        associateDetails,
        tab: page,
        associateId,
        defaultContactId,
      })}
      headerLeft={headerLeft({
        firstName: associateDetails?.firstName ?? "",
        lastName: associateDetails?.lastName ?? "",
        FsoType: associateDetails?.type ?? "",
        noOfTickets: history?.length ?? 0,
        skills: associateDetails?.skill ?? "",
        territories: associateDetails?.territories ?? [],
        companyName: associateDetails?.companyName ?? "",
      })}
      headerRight={<div />}
    ></PageLayout>
  );
}
