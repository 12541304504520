import { useEffect, useState } from "react";

import { alertError, alertSuccess } from "../../../../../actions/AlertActions";
import { getLeaseById, updateLease } from "../../../../../api/Api";
import { CloseIcon } from "../../../../../components/clients/ClientAppIcons";
import { AddIconBlue } from "../../../../../components/common/AppIcons";
import MaterialForm from "../../../../../components/common/form/MaterialForm";

type DepositTabProps = {
  leaseId: number;
  onBackClick: React.Dispatch<React.SetStateAction<string>>;
};

export default function DepositTab({ leaseId, onBackClick }: DepositTabProps) {
  const [deposits, setDeposits] = useState([
    {
      category: "",
      amount: 0,
      description: "",
      isRefundable: false,
    },
  ]);

  const [leaseTerms, setLeaseTerms] = useState("");

  useEffect(() => {
    if (leaseId !== null && leaseId !== 0)
      getLeaseById(leaseId).then((data) => {
        setLeaseTerms(data.leaseTerms);
        if (data.deposits !== null) setDeposits(data.deposits);
      });
  }, [leaseId]);

  const onChangeInfo = (
    update: {
      name: "category" | "amount" | "description" | "isRefundable";
      value: any;
    },
    index: number
  ) => {
    const { name, value } = update;
    const test = [...deposits];
    test[index][name] = value;

    setDeposits(test);
  };

  const onDeleteDeposit = (index: number) => {
    const rows = [...deposits];
    rows.splice(index, 1);
    setDeposits(rows);
  };

  const onSave = () => {
    if (leaseId === null) {
      alertError("Please add lease");
      return;
    }
    updateLease(leaseId, [
      {
        op: "replace",
        path: "/deposits",
        value: deposits,
      },
    ]).then(() => {
      alertSuccess("Deposits saved successfully");
    });
  };

  return (
    <div className="w-full p-4">
      <div className="flex flex-col items-start self-center ">
        <div className="text-lg font-medium text-black">Lease Details</div>
        <div className="flex flex-row mt-4 my-1">
          <div className="text-base text-newGray-800 font-normal mr-1">
            {`Lease term:`}
          </div>
          <div className="text-base text-black font-semibold">{leaseTerms}</div>
        </div>
        <div className="flex flex-row my-1 mb-4">
          <div className="text-base text-newGray-800 font-normal mr-1">
            {`Lease Status:`}
          </div>
          <div className="text-base text-black font-semibold">---</div>
        </div>
        <div className="text-lg font-medium text-black mt-4">
          Add Deposit Details
        </div>
        {deposits?.map((item, index) => {
          return (
            <div className="flex flex-row items-center">
              <MaterialForm
                className="w-full flex flex-row "
                data={item}
                onChange={(update: {
                  name: "category" | "amount" | "description" | "isRefundable";
                  value: string;
                }) => onChangeInfo(update, index)}
                fieldClassName="mr-2 my-4"
                renderArray={[
                  {
                    name: "category",
                    variant: "OUTLINED",
                    key: `${item.category}`,
                  },
                  {
                    name: "amount",
                    inputType: "NUMBER",
                    variant: "OUTLINED",
                    label: "Amount ($)",
                  },
                  {
                    name: "description",
                    variant: "OUTLINED",
                  },
                  {
                    name: "isRefundable",
                    inputType: "CHECKBOX",
                    variant: "OUTLINED",
                  },
                ]}
              />
              {index !== 0 && (
                <button
                  className="focus:outline-none"
                  onClick={() => onDeleteDeposit(index)}
                >
                  <CloseIcon />
                </button>
              )}
            </div>
          );
        })}
        <button
          className="border w-max h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 my-4"
          onClick={() =>
            setDeposits([
              ...deposits,
              { category: "", amount: 0, description: "", isRefundable: false },
            ])
          }
        >
          <div className="flex flex-row items-center ">
            <div>
              <AddIconBlue className="h-4 w-4" />
            </div>
            <div className="px-2 flex">
              <div className="font-medium">Add New Deposit</div>
            </div>
          </div>
        </button>
        <div className="flex flex-row self-end mt-4 mr-4 ">
          <button
            className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
        bg-gray-300
        text-gray-800 duration-200 ease-in-out 
        transition"
            onClick={() => onBackClick("unitListing")}
          >
            Cancel
          </button>
          <button
            className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
        bg-newBlue-400  w-40
        text-white border-newBlue-400
        border"
            onClick={onSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
