import { useEffect, useState } from "react";

import { alertSuccess } from "../../../../../actions/AlertActions";
import { getProspectById, updateProspect } from "../../../../../api/Api";
import {
  AddIconBlue,
  LeftArrowIcon,
} from "../../../../../components/common/AppIcons";
import MaterialForm from "../../../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../../../utils/StateUtils";

type Roommates = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  socialSecurity: string;
  phone: string;
  email: string;
  driversLicense: string;
  state: string;
  relationship: string;
  tenantClass: string;
};

type EmergencyContact = {
  firstName: string;
  lastName: string;
  phone: string;
  relationship: string;
  emergencyContactAddress: string;
};

type ApplicationDetailsTabProps = {
  prospectId: number;
  setCurrentApplicationTab: React.Dispatch<React.SetStateAction<string>>;
  setDetailsTab: React.Dispatch<React.SetStateAction<string>>;
};

export function ApplicationDetailsTab({
  prospectId,
  setCurrentApplicationTab,
  setDetailsTab,
}: ApplicationDetailsTabProps) {
  const [roommates, setRoommates] = useState<Roommates[] | never[]>([]);
  const [emergencyContact, setEmergencyContact] = useState<EmergencyContact>({
    firstName: "",
    lastName: "",
    phone: "",
    relationship: "",
    emergencyContactAddress: "",
  });
  const [prospectDetails, setProspectDetails] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    socialSecurity: "",
    phone: "",
    email: "",
    driversLicense: "",
    state: "",
  });

  const tenantOptions = [
    { value: "SUBTENANT", label: "Sub Tenant" },
    { value: "ROOMMATE", label: "Roommate" },
    { value: "GUEST", label: "Guest" },
    { value: "AUTHORIZED_OCCUPANT", label: "Authorized Occupant" },
  ];

  useEffect(() => {
    if (prospectId !== 0)
      getProspectById(prospectId).then((data) => {
        setProspectDetails(data);
        if (data.emergencyContact !== null)
          setEmergencyContact(data.emergencyContact);
        if (data.roommates !== null) setRoommates(data.roommates);
      });
  }, [prospectId]);
  const onChangeRoommates = (
    update: { name: string; value: any },
    index: number
  ) => {
    const { name, value } = update;
    const test = [...roommates];
    test[index][name] = value;

    setRoommates(test);
  };

  const onChange = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setProspectDetails((data) => deepUpdate(name, value, data));
  };

  const onChangeContact = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setEmergencyContact((data) => deepUpdate(name, value, data));
  };

  const onSave = () => {
    updateProspect(prospectId, [
      {
        op: "replace",
        path: "/firstName",
        value: prospectDetails.firstName,
      },
      {
        op: "replace",
        path: "/lastName",
        value: prospectDetails.lastName,
      },
      {
        op: "replace",
        path: "/dateOfBirth",
        value: prospectDetails.dateOfBirth,
      },
      {
        op: "replace",
        path: "/socialSecurity",
        value: prospectDetails.socialSecurity,
      },
      {
        op: "replace",
        path: "/email",
        value: prospectDetails.email,
      },
      {
        op: "replace",
        path: "/phone",
        value: prospectDetails.phone,
      },
      {
        op: "replace",
        path: "/driversLicense",
        value: prospectDetails.driversLicense,
      },
      {
        op: "replace",
        path: "/state",
        value: prospectDetails.state,
      },
      {
        op: "replace",
        path: "/roommates",
        value: roommates,
      },
      {
        op: "replace",
        path: "/emergencyContact",
        value: emergencyContact,
      },
    ]).then(() => {
      setDetailsTab("rentalHistory");
      alertSuccess("Prospect updated Successfully");
    });
  };
  return (
    <div className="w-full p-4 ">
      <div className="flex flex-col items-start self-center ">
        <button
          className="flex items-center focus:outline-none justify-self-start w-max"
          onClick={() => setCurrentApplicationTab("applicationListing")}
        >
          <LeftArrowIcon className="h-4 w-4 text-newBlue-400" />
          <div className="text-sm font-semibold text-newBlue-400">Back</div>
        </button>
        <div className="border-b-2 border-newGray-700">
          <MaterialForm
            className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-4 mt-3 border-b-2 border-newGray-700"
            data={prospectDetails}
            onChange={onChange}
            fieldClassName="px-2 my-2"
            renderArray={[
              {
                name: "firstName",
                variant: "OUTLINED",
              },
              {
                name: "lastName",
                variant: "OUTLINED",
              },
              {
                name: "dateOfBirth",
                inputType: "DATE",
                variant: "OUTLINED",
                label: "Birth Date",
              },
              {
                name: "socialSecurity",
                variant: "OUTLINED",
              },
              {
                name: "phone",
                inputType: "PHONE",
                variant: "OUTLINED",
                label: "Mobile Number",
              },
              {
                name: "email",
                inputType: "EMAIL",
                variant: "OUTLINED",
              },
              { name: "driversLicense", variant: "OUTLINED" },
              { name: "state", variant: "OUTLINED" },
            ]}
          />
          {roommates.length !== 0 && (
            <div>
              {roommates.map((item, index) => (
                <MaterialForm
                  className={`w-full grid grid-flow-row-dense grid-rows-1 grid-cols-4 mt-3 ${
                    roommates.length === index + 1
                      ? ""
                      : "border-b-2 border-newGray-700"
                  } `}
                  data={item}
                  onChange={(update: { name: string; value: any }) =>
                    onChangeRoommates(update, index)
                  }
                  fieldClassName="px-2 my-2"
                  renderArray={[
                    {
                      name: "firstName",
                      variant: "OUTLINED",
                    },
                    {
                      name: "lastName",
                      variant: "OUTLINED",
                    },
                    {
                      name: "dateOfBirth",
                      inputType: "DATE",
                      variant: "OUTLINED",
                      label: "Birth Date",
                    },
                    {
                      name: "socialSecurity",
                      variant: "OUTLINED",
                    },
                    {
                      name: "phone",
                      inputType: "PHONE",
                      variant: "OUTLINED",
                      label: "Mobile Number",
                    },
                    {
                      name: "email",
                      inputType: "EMAIL",
                      variant: "OUTLINED",
                    },
                    { name: "driversLicense", variant: "OUTLINED" },
                    { name: "state", variant: "OUTLINED" },
                    { name: "relationship", variant: "OUTLINED" },
                    {
                      name: "tenantClass",
                      variant: "OUTLINED",
                      inputType: "SELECT",
                      options: tenantOptions,
                      label: "Occupant Class",
                    },
                  ]}
                />
              ))}
            </div>
          )}
          <button
            className="border w-max h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 ml-2 my-4"
            onClick={() =>
              setRoommates([
                ...roommates,
                {
                  firstName: "",
                  lastName: "",
                  dateOfBirth: "",
                  socialSecurity: "",
                  phone: "",
                  email: "",
                  driversLicense: "",
                  state: "",
                  relationship: "",
                  tenantClass: "",
                },
              ])
            }
          >
            <div className="flex flex-row items-center ">
              <div>
                <AddIconBlue className="h-4 w-4" />
              </div>
              <div className="px-2 flex">
                <div className="font-medium">
                  Add Roommate or Other Occupant
                </div>
              </div>
            </div>
          </button>
        </div>
        <div>
          <div className="text-xl font-semibold text-gray-900 my-4">
            Emergency Contact Information
          </div>
          <MaterialForm
            className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-4 mt-3"
            data={emergencyContact}
            onChange={onChangeContact}
            fieldClassName="px-2 my-2"
            renderArray={[
              {
                name: "firstName",
                variant: "OUTLINED",
              },
              {
                name: "lastName",
                variant: "OUTLINED",
              },
              {
                name: "relationship",
                variant: "OUTLINED",
              },
              {
                name: "phone",
                inputType: "PHONE",
                variant: "OUTLINED",
              },

              { name: "emergencyContactAddress", variant: "OUTLINED" },
            ]}
          />
        </div>
        <div className="flex flex-row self-end mt-4 mr-4 ">
          <button
            className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
        bg-gray-300
        text-gray-800 duration-200 ease-in-out 
        transition"
            onClick={() => setCurrentApplicationTab("applicationListing")}
          >
            Cancel
          </button>
          <button
            className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
        bg-newBlue-400  w-40
        text-white border-newBlue-400
        border"
            onClick={onSave}
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
}
