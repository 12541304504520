import { useState, useEffect } from "react";

import { navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import { getVendors } from "../../api/Api";
import {
  AddIconBlue,
  SearchIcon,
  UserIcon,
} from "../../components/common/AppIcons";
import MaterialModal from "../../components/common/MaterialModal";
import MaterialTable from "../../components/common/MaterialTable";
import MaterialForm from "../../components/common/form/MaterialForm";
import EditVendor from "./EditVendor";
import { VendorData } from "./service";

export default function ListVendors() {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [edit, setEdit] = useState({ display: false, vendorId: 0 });
  const [totalElements, setTotalElements] = useState(0);
  const [selectedSubscriber, setSelectedSubscriber] = useState({
    subscriberType: "ALL",
  });

  useEffect(() => {
    getVendors({ page, size }, selectedSubscriber.subscriberType, searchText)
      .then((data) => {
        setTableData(data.content);
        setTotalElements(data.totalElements);
      })
      .catch((_) =>
        alertError("Couldn't Connect to Server, Check your Connection")
      );
  }, [page, size, selectedSubscriber.subscriberType, searchText]);

  const getTableHead = () => {
    return [
      { id: "vendorName", label: "Subscriber Name" },
      { id: "PrimaryContact", label: "Primary Contact Number" },
      { id: "supportEmail", label: "Support Email" },
      { id: "address", label: "Address" },
      { id: "ContractorLicense", label: "Contractor License Expiry" },
      { id: "viewUsers", label: "" },
    ];
  };

  console.log({ selectedSubscriber });

  const getTableData = () => {
    let data = [];
    data = tableData?.map((item: VendorData) => {
      return {
        id: item.id,
        data: [
          item.name,
          item.primaryContactNumber,
          item.supportEmail,
          `${item.address.streetAddress1}, ${
            item.address.streetAddress2 ? `${item.address.streetAddress2},` : ""
          } ${item.address.city}, ${item.address.state}, ${item.address.zip}`,
          item.contractorLicenseExpiry?.split("T")[0],
          {
            render: (
              <button
                className="flex flex-row p-2 items-center border rounded border-newGray-700"
                onClick={() => navigate(`/settings/clients/${item.id}`)}
              >
                <UserIcon />
                <span className="pl-2 text-newBlue-400 text-sm font-medium">
                  View Users
                </span>
              </button>
            ),
          },
        ],
      };
    });
    return data;
  };

  return (
    <div className=" flex  flex-col justify-between items-center pt-6 px-4 h-fit">
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row items-center w-full">
          <p className="font-semibold text-newBlue-400 w-full">
            <div className="relative w-2/6">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <SearchIcon />
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-800"
                placeholder="Search..."
                required
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
          </p>
          <div className="flex flex-row items-center">
            <div className="w-60">
              <MaterialForm
                data={selectedSubscriber}
                renderArray={[
                  {
                    name: "subscriberType",
                    label: "Filter by type",
                    inputType: "SELECT",
                    options: [
                      { value: "ALL", label: "All" },
                      { value: "CONTRACTOR", label: "Contractor" },
                      { value: "PMC", label: "PMC" },
                    ],
                  },
                ]}
                onChange={(update: { value: string }) => {
                  setSelectedSubscriber({ subscriberType: update.value });
                }}
              ></MaterialForm>
            </div>
            <button
              className="border h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400"
              onClick={() => {
                navigate(`/vendor/new`);
              }}
            >
              <div className="flex flex-row items-center w-44">
                <div>
                  <AddIconBlue className="h-4 w-4" />
                </div>
                <div className="px-2 flex">
                  <div className="font-medium">Add New Subscriber</div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className=" py-4 w-full h-min">
        <div className="border-t ">
          <MaterialTable
            data={getTableData()}
            head={getTableHead()}
            headRenderColor="text-newGray-300"
            defaultOrder="desc"
            pageRows={size}
            onClickCB={(selection: number) => {
              setEdit({ display: true, vendorId: selection });
            }}
            totalData={totalElements}
            currentPage={page}
            paginationCB={(value: number) => {
              setPage(value);
            }}
            rowChangeCB={(value: number) => {
              setSize(value);
            }}
            loading={undefined}
            defaultOrderBy={undefined}
            multiSelect={undefined}
            searchText={undefined}
            setSelected={undefined}
          />
        </div>
        <MaterialModal
          open={edit.display}
          setOpen={() => setEdit({ display: false, vendorId: 0 })}
        >
          <EditVendor
            vendorId={edit.vendorId}
            setEdit={setEdit}
            onSuccess={() => {
              getVendors({ page, size }).then((data) => {
                setTableData(data.content);
              });
            }}
          />
        </MaterialModal>
      </div>
    </div>
  );
}
