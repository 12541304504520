import { useEffect, useState } from "react";

import moment from "moment";

import { alertError } from "../../../../actions/AlertActions";
import { getLeases, getUnitById } from "../../../../api/Api";
import { AddIconBlue } from "../../../../components/common/AppIcons";
import MaterialTable from "../../../../components/common/MaterialTable";

type LeaseHistoryProps = {
  setLeaseId: React.Dispatch<React.SetStateAction<number>>;
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>;
  unitId: number;
};

type LeaseResponse = {
  id: number;
  leaseTerms: string;
  startDate: string;
  endDate: string;
  otherTenants: [];
  primaryTenant: {};
};

export default function LeaseHistory({
  setLeaseId,
  setCurrentScreen,
  unitId,
}: LeaseHistoryProps) {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [currentLeaseId, setCurrentLeaseId] = useState(0);

  useEffect(() => {
    getUnitById(unitId).then((data) => {
      setCurrentLeaseId(data.currentLeaseId);
    });
    getLeases(page, size, unitId)
      .then((data) => {
        console.log({ data });

        setTableData(data.content);
        setTotalElements(data.totalElements);
      })
      .catch((_) =>
        alertError("Couldn't Connect to Server, Check your Connection")
      );
  }, [page, size, unitId]);

  const getDateColor = (date: string) => {
    var today = new Date();

    const myDate = new Date(date);
    if (myDate > today) return " bg-green-300 text-green-800";
    else if (myDate < today) return "bg-red-100 text-red-500";
    else return " bg-gray-200 text-black";
  };

  const getTableHead = () => {
    return [
      { id: "id", label: "Serial No." },
      { id: "leaseStart", label: "Lease Start" },
      { id: "leaseExpiry", label: "Lease Expiry" },
      { id: "term", label: " Term" },
      { id: "tenants", label: "Tenants" },
    ];
  };

  const getTableData = () => {
    let data = [];
    data = tableData?.map((item: LeaseResponse, index) => {
      return {
        id: item.id,
        rowStyle: item.id === currentLeaseId ? "bg-green-200" : "",
        data: [
          index + 1,
          {
            render: (
              <div
                className={`flex flex-row p-1 px-2 items-center rounded w-max text-sm font-normal bg-gray-200 text-black}`}
              >
                {item.startDate === null
                  ? "- - -"
                  : moment(item.startDate).format("MM-DD-YYYY")}
              </div>
            ),
          },
          {
            render: (
              <div
                className={`flex flex-row p-1 px-2 items-center rounded w-max text-sm font-normal ${getDateColor(
                  item.endDate
                )}`}
              >
                {item.endDate === null
                  ? "- - -"
                  : moment(item.endDate).format("MM-DD-YYYY")}
              </div>
            ),
          },
          item?.leaseTerms ?? "- - -",
          {
            render: (
              <div className="flex flex-row p-1 px-2 items-center bg-orange-100 rounded w-max text-sm font-normal">
                {(item?.otherTenants === null
                  ? 0
                  : item?.otherTenants?.length) +
                  (item?.primaryTenant !== null ? 1 : 0)}
              </div>
            ),
          },
        ],
      };
    });
    return data;
  };

  return (
    <div className="p-4">
      <div className="flex flex-row justify-between items-center">
        <div className="text-2xl font-semibold text-black">
          Lease Management
        </div>
        <button
          className="self-end  border w-max h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400"
          onClick={() => {
            setLeaseId(0);
            setCurrentScreen("rentLeaseDetails");
          }}
        >
          <div className="flex flex-row items-center ">
            <div>
              <AddIconBlue className="h-4 w-4" />
            </div>
            <div className="px-2 flex">
              <div className="font-medium">Add New Lease</div>
            </div>
          </div>
        </button>
      </div>

      <div className="mt-10">
        <MaterialTable
          data={getTableData()}
          head={getTableHead()}
          headRenderColor="text-newGray-300"
          defaultOrder="desc"
          pageRows={size}
          onClickCB={(selection: number) => {
            console.log({ selection });
            setLeaseId(selection);
            setCurrentScreen("rentLeaseDetails");
          }}
          totalData={totalElements}
          currentPage={page}
          paginationCB={(value: number) => {
            setPage(value);
          }}
          rowChangeCB={(value: number) => {
            setSize(value);
          }}
          loading={undefined}
          defaultOrderBy={undefined}
          multiSelect={undefined}
          searchText={undefined}
          setSelected={undefined}
        />
      </div>
    </div>
  );
}
