import React, { useEffect, useState } from "react";

import { Tab } from "@headlessui/react";
import { Link, navigate } from "raviger";

import { alertError, alertInfo } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import {
  UNSAFE_switchToIndividual,
  createPMCManagers,
  getBuildingsUnderCommercialClient,
  getBuildingsUnderIndividualClient,
  getContactDetails,
  getManagersPMC,
  getTicketsCommercial,
  getUnitsUnderHOA,
  newClientGet,
  unitsWithResidents,
} from "../../api/Api";
import { getTicketsIndividual } from "../../api/Api";
import {
  ClientBuildingIcon,
  ContactIcon,
  TicketBagIcon,
} from "../../components/clients/ClientAppIcons";
import NewListTickets from "../../components/clients/NewListTickets";
import NewMessagesScreen from "../../components/clients/NewMessagesScreen";
import RenderAddressComponent from "../../components/clients/RenderAddressComponent";
import { clientSubCategoryOptions } from "../../components/clients/forms/ClientFormFields";
import {
  LeftArrowWithTailIcon,
  PenIcon,
  SwitchIcon,
} from "../../components/common/AppIcons";
import HeadlessFilterSelect from "../../components/common/HeadlessFilterSelect";
import { SuspenseBoundary } from "../../components/common/core/SuspenseBoundary";
import {
  buildingTypes,
  clientSubCategories,
} from "../../components/common/form/constants";
import ListInvoices from "../../components/pipelines/components/ListInvoices";
import MapComponent from "../../components/scheduler/MapComponent";
import { useTitle } from "../../utils/RouterUtils";
import { properString, renderAddress } from "../../utils/StringUtils";
import ClientBuildingsTab from "./tabs/BuildingsTab";
import ClientSummaryTab from "./tabs/SummaryTab";

const ClientHomeTabs = [
  { label: "Summary", value: "summary" },
  { label: "Buildings", value: "buildings" },
  { label: "Tickets", value: "tickets" },
  { label: "Invoices", value: "invoice" },
  { label: "Messages", value: "messages" },
];

const getTabsForClient = (clientType) => {
  if (clientType === "INDIVIDUAL") {
    // Remove Summary Tab & Tickets Tab
    return ClientHomeTabs.filter((tab) => tab.value !== "summary");
  } else {
    return ClientHomeTabs;
  }
};

const fetchClientData = (clientId, clientType, callback) => {
  if (clientType === "COMMERCIAL") {
    newClientGet(clientId)
      .then((clientObj) =>
        callback({ client: { ...clientObj, clientVariant: "commercial" } })
      )
      .catch((_) => navigate("/clients"));
  } else {
    getContactDetails(clientId)
      .then((contactObj) =>
        callback({
          client: { ...contactObj, clientId, clientVariant: "individual" },
        })
      )
      .catch((_) => navigate("/clients"));
  }
};

const fetchTicketData = (clientId, type, callback) => {
  if (type === "INDIVIDUAL")
    getTicketsIndividual(clientId).then(({ content, totalElements }) =>
      callback({ tickets: content, ticketCount: totalElements })
    );
  else
    getTicketsCommercial(clientId).then(({ content, totalElements }) =>
      callback({ tickets: content, ticketCount: totalElements })
    );
};

const fetchBuildings = (clientId, type, callback) => {
  if (type === "COMMERCIAL")
    getBuildingsUnderCommercialClient(clientId).then((buildings) =>
      callback({ buildings })
    );
  else
    getBuildingsUnderIndividualClient(clientId).then((buildings) =>
      callback({ buildings })
    );
};
// Select Building Needs to be handled in Building Details Section

const fetchClientManagers = (clientId, callback) =>
  getManagersPMC(clientId).then((managers) => {
    callback({ managers });
  });

const fetchPMCManagers = (pmcClientId, callback) =>
  getManagersPMC(pmcClientId).then((pmcManagers) => {
    callback({ pmcManagers });
  });

const dataFetchReducer = (props, _state, setState, action) => {
  console.log("Reducing ", action, props);
  const updateClientCB = (data) =>
    setState((clientData) => ({ ...clientData, ...data }));
  switch (action) {
    case "init":
      setState(initialState);
      fetchClientData(props.clientId, props.type, (clientData) => {
        setState(clientData);
        // If the client has a managedBy, we fetch the PMC Managers
        if (clientData.client?.managedBy)
          fetchPMCManagers(
            clientData.client.managedBy.clientId,
            updateClientCB
          );
        fetchTicketData(props.clientId, props.type, updateClientCB);
        fetchBuildings(props.clientId, props.type, updateClientCB);
        fetchClientManagers(props.clientId, updateClientCB);
      });
      break;
    case "client":
      fetchClientData(props.clientId, props.type, updateClientCB);
      break;
    case "tickets":
      fetchTicketData(props.clientId, props.type, updateClientCB);
      break;
    case "buildings":
      fetchBuildings(props.clientId, props.type, updateClientCB);
      break;
    case "managers":
      fetchClientManagers(props.clientId, updateClientCB);
      break;
    case "pmcManagers":
      break;
    default:
      throw new Error();
  }
};

const initialState = {};

export const renderClientCategory = (subCategory) =>
  clientSubCategoryOptions().find((category) => category.value === subCategory)
    ?.label || "N/A";

export default function ClientHomePage({ clientId, tab, selectedId, type }) {
  // Contains client, tickets, buildings
  const [clientData, setClientData] = useState(initialState);
  const [state, setState] = useState({
    // geocodeLatitude: 0,
    // geocodeLongitude: 0,
    map: null,
  });
  const { withConfirmation } = useAppActions();

  const [filterBuildingId, setFilterBuildingId] = useState();
  useTitle(
    `${
      type === "COMMERCIAL" ? "Commercial" : "Individual"
    } Client | ${properString(tab)}`
  );

  const { editContact } = useAppActions();

  const remoteReduceClient = (action) =>
    dataFetchReducer(
      { clientId, type, managedById: clientData.client?.managedById },
      clientData,
      setClientData,
      action
    );

  useEffect(() => {
    console.log("Dispatching init", clientId, type);
    remoteReduceClient("init");
  }, [type, clientId]);

  // Effect for Setting Map Data; buildings tab and selectedId => [selectedBuilding.lat, selectedBuilding.lng] or clientData.buildings.map
  useEffect(() => {
    if (tab === "buildings" && selectedId && clientData.buildings) {
      const selectedBuilding = clientData.buildings.find(
        (building) => building.buildingId === Number(selectedId)
      );
      setState((state) => ({
        ...state,
        map: selectedBuilding
          ? [
              {
                lat: selectedBuilding.address.geocodeLatitude,
                lng: selectedBuilding.address.geocodeLongitude,
              },
            ]
          : [],
      }));
    } else {
      const buildings = clientData.buildings || [];
      setState((state) => ({
        ...state,
        map: buildings.map((building) => ({
          lat: building.address.geocodeLatitude,
          lng: building.address.geocodeLongitude,
        })),
      }));
    }
  }, [tab, selectedId, clientData.buildings]);

  const getPositions = (buildings) => {
    let PositionsList = [];
    buildings.map(
      (building) => (PositionsList = [...PositionsList, ...building.positions])
    );

    const uniquePositionList = Array.from(new Set(PositionsList));
    const DisplayPositionList = uniquePositionList.map((item) =>
      properString((item ?? "Unspecified").toLowerCase())
    );
    return DisplayPositionList.join(", ");
  };

  const tabs = getTabsForClient(type);

  const clientSubCategory = clientSubCategoryOptions().find(
    (category) => category.value === clientData.client?.subCategory
  )?.label;

  return (
    <div className="bg-white max-h-xscreen h-full w-full rounded-md border border-gray-300 p-4 overflow-auto">
      <div className="flex flex-col w-full md:flex-row-reverse justify-between">
        <div className="h-48 w-full md:w-6/12 pr-16">
          {state.map && <MapComponent data={state.map} />}
        </div>
        <div className="flex flex-col">
          <div>
            <Link
              href={`/clients`}
              className="p-2 flex flex-row items-center hover:bg-gray-200 rounded-md flex-grow-0 flex-shrink"
            >
              <div>
                <LeftArrowWithTailIcon className="h-3 w-3" />
              </div>
              <div className="px-1 text-sm text-newBlue-400"> All Clients</div>
            </Link>
          </div>
          <SuspenseBoundary
            waitFor={clientData.client}
            fallbackClassName={"h-12"}
          >
            <div className="flex flex-col text-black text-lg font-semibold py-4">
              {type === "COMMERCIAL" ? (
                // Header for Commercial Client
                <div className="flex gap-4">
                  <span>{clientData.client?.clientName}</span>
                  <button
                    className="ml-2 px-2 py-1 bg-gray-200 rounded-md text-sm font-semibold text-gray-600 hover:bg-gray-300"
                    onClick={() => {
                      withConfirmation({
                        title: "Danger: Switch to Individual Client",
                        description: `
                        Are you sure you want to switch to Individual Client?
                        This action cannot be undone.
                        `,
                        onConfirm: () => {
                          alertInfo(
                            "Switching to Individual Client",
                            undefined,
                            { loading: true }
                          );
                          UNSAFE_switchToIndividual(clientData.client.clientId)
                            .then(() => {
                              navigate("/clients");
                            })
                            .catch((_err) => {
                              alertError(
                                "Sorry, we couldn't switch this client to Individual Client. Please try again later."
                              );
                            });
                        },
                      });
                    }}
                  >
                    <SwitchIcon className="w-4 h-4" />
                  </button>
                </div>
              ) : (
                // Header for Individual Client
                <>
                  <div className="flex flex-row items-center">
                    <span>
                      {`${clientData.client?.firstName} ${clientData.client?.lastName}`}
                    </span>
                    <button
                      className="ml-2 p-1 bg-gray-200 rounded-md text-sm font-semibold text-gray-600 hover:bg-gray-300"
                      onClick={() => {
                        editContact(clientData.client, () =>
                          remoteReduceClient("client")
                        );
                      }}
                    >
                      <PenIcon className="h-3 w-3" />
                    </button>
                  </div>
                  {/* Manager of * */}
                  {clientData.client?.managerOfClient && (
                    <Link
                      href={`/commercial/${clientData.client?.managerOfClient.clientId}/summary`}
                      className="font-semibold text-sm text-gray-600"
                    >
                      {clientData.client?.managerOfClient.clientName}
                    </Link>
                  )}
                </>
              )}
              {type === "COMMERCIAL" ? (
                <RenderAddressComponent
                  className="text-gray-600 text-sm font-normal p-2"
                  address={clientData.client?.billingAddress}
                  primaryClient={{
                    clientName: clientData.client?.managedBy?.clientName,
                    clientId: clientData.client?.managedBy?.clientId,
                    contactId: clientData.client?.managedBy?.primaryContactId,
                  }}
                />
              ) : (
                // switch (clientData.buildings?.length)
                // | 0 => <div className="text-gray-800 text-sm p-2">No Buildings</div>
                // | 1 => <RenderAddressComponent className="text-gray-800 text-sm p-2" address={clientData.buildings[0].address} />
                // | _ => <div className="text-gray-800 text-sm p-2">Multiple Addresses</div>

                <div className="text-gray-600 text-sm font-normal p-2">
                  {clientData.buildings &&
                    (clientData.buildings.length === 0 ? (
                      "No Buildings"
                    ) : clientData.buildings?.length === 1 ? (
                      <RenderAddressComponent
                        primaryClient={{
                          clientName: clientData.buildings[0]?.hoa?.clientName,
                          clientId: clientData.buildings[0]?.hoa?.clientId,
                        }}
                        address={{
                          ...clientData.buildings[0].address,
                          propertyShortName:
                            clientData.buildings[0].propertyShortName,
                        }}
                        unitNumber={
                          clientData.buildings.length === 1
                            ? clientData.buildings[0].unitNumbers[0]
                            : null
                        }
                      />
                    ) : (
                      "Multiple Addresses"
                    ))}
                </div>
              )}
            </div>
          </SuspenseBoundary>

          <div className="flex flex-row items-center py-2 gap-4">
            <div className="flex flex-row items-center">
              <div className="flex flex-col items-center">
                <ClientBuildingIcon />
                <span className="text-xs text-gray-800 font-bold italic">
                  Class
                </span>
              </div>
              <div className="px-2 text-sm font-semibold">
                :{" "}
                {type === "COMMERCIAL"
                  ? clientSubCategory || "N/A"
                  : // For Non-Commercial Clients, when there are multiple buildings, we write "Multiple Buildings"
                  clientData.buildings?.length > 1
                  ? "Multiple Buildings"
                  : // When there is only one building, we write the building type
                  clientData.buildings?.length === 1
                  ? properString(
                      buildingTypes.find(
                        (type) =>
                          type.value === clientData.buildings[0].buildingType
                      )?.label || "N/A"
                    )
                  : // When there are no buildings, we write "No Buildings"
                    "No Buildings"}
              </div>
            </div>

            {
              // For Commercial Clients, we show the Number of Tickets
              type === "COMMERCIAL" ? (
                <div className="flex flex-row items-center">
                  <div>
                    <TicketBagIcon />
                  </div>
                  <div className="px-2 text-sm font-semibold">
                    No.of Tickets: {clientData.ticketCount}
                  </div>
                </div>
              ) : (
                // For Non-Commercial Clients, we show the Role of the Client (Owner/Tenant)
                <div className="flex flex-row items-center">
                  <div>
                    <ContactIcon />
                  </div>
                  <div className="px-2 text-sm font-semibold">
                    :{" "}
                    {clientData.buildings?.length > 0
                      ? getPositions(clientData.buildings ?? [])
                      : "No Buildings"}
                  </div>
                </div>
              )
            }
            {/* <div className="flex flex-row items-center">
              <div>
                <CashIcon />
              </div>
              <div className="px-2 text-sm">Client Value: {"{COUNT}"}</div>
            </div> */}
          </div>
        </div>
      </div>

      <SuspenseBoundary name="Client Home" waitFor={clientData.client}>
        <Tab.Group
          selectedIndex={ClientHomeTabs.findIndex((item) => item.value === tab)}
          onChange={(index) => {
            navigate(
              `/${clientData.client.clientVariant}/${clientId}/${ClientHomeTabs[index].value}`
            );
          }}
        >
          <div className="flex flex-row w-full border-b">
            <div className="p-4">
              <div className=" border-gray-200">
                <Tab.List className="-mb-px flex space-x-8" aria-label="Tabs">
                  {ClientHomeTabs.map((item, _index) => {
                    const disabled = !tabs.includes(item);
                    return (
                      <Tab
                        key={item.value}
                        disabled={disabled}
                        hidden={disabled}
                        className={"focus:outline-none"}
                      >
                        {({ selected }) => (
                          <span
                            className={
                              "cursor-pointer border-transparent text-base hover:text-gray-700  whitespace-nowrap py-4 px-1 border-b-2 font-medium " +
                              (selected
                                ? "text-newBlue-400 border-b-4 border-newBlue-400"
                                : "text-newGray-800")
                            }
                          >
                            {item.label}
                          </span>
                        )}
                      </Tab>
                    );
                  })}
                </Tab.List>
              </div>
            </div>
          </div>
          {/* Ensuring that the components are not rendered when a client switch happens */}
          {/* Otherwise internal effects will trigger redirects */}
          {Number(clientId) === Number(clientData.client?.clientId) && (
            <Tab.Panels>
              <Tab.Panel>
                <SuspenseBoundary
                  name="Client Summary"
                  waitFor={clientData.client}
                >
                  <ClientSummaryTab
                    clientData={clientData}
                    refreshCB={remoteReduceClient}
                  />
                </SuspenseBoundary>
              </Tab.Panel>
              <Tab.Panel>
                <SuspenseBoundary
                  name="Client Buildings"
                  waitFor={clientData.buildings && tab === "buildings"}
                >
                  <ClientBuildingsTab
                    clientData={clientData}
                    selectedId={selectedId}
                    refreshCB={remoteReduceClient}
                  />
                </SuspenseBoundary>
              </Tab.Panel>

              <Tab.Panel>
                <SuspenseBoundary
                  name="Client Tickets"
                  waitFor={clientData.tickets && tab === "tickets"}
                >
                  <div className="w-full">
                    <NewListTickets
                      id={clientId}
                      type={type}
                      buildings={clientData.buildings}
                    />
                  </div>
                </SuspenseBoundary>
              </Tab.Panel>
              <Tab.Panel>
                <SuspenseBoundary
                  name="Client Invoice"
                  waitFor={tab === "invoice"}
                >
                  <div className="flex flex-col">
                    {clientData.buildings?.length > 0 && (
                      <div className="self-end group inline-block relative my-4">
                        <HeadlessFilterSelect
                          placeholder={"Filter"}
                          onChange={(value) => {
                            setFilterBuildingId(value);
                          }}
                          options={clientData.buildings.map((building) => ({
                            title: building.shortName ?? "",
                            description: renderAddress(building.address),
                            value: building.buildingId,
                          }))}
                        />
                      </div>
                    )}
                    <ListInvoices
                      flow="CLIENT"
                      clientId={type === "INDIVIDUAL" ? undefined : clientId}
                      buildingId={filterBuildingId}
                      contactId={type === "INDIVIDUAL" ? clientId : undefined}
                    />
                  </div>
                </SuspenseBoundary>
              </Tab.Panel>
              <Tab.Panel>
                <SuspenseBoundary
                  name="Client Messages"
                  waitFor={clientData.buildings && tab === "messages"}
                >
                  <div className="w-full">
                    <NewMessagesScreen
                      prefillContact={
                        // If the client is an individual, we send the client object
                        // As a contact to be prefilled in the contacts list
                        type === "INDIVIDUAL" ? clientData.client : undefined
                      }
                      tickets={clientData.tickets}
                      clientId={clientId}
                      buildings={clientData.buildings}
                      type={type}
                      clientClass={clientData?.client?.subCategory}
                      selectedContactId={parseInt(selectedId)}
                    />
                  </div>
                </SuspenseBoundary>
              </Tab.Panel>
            </Tab.Panels>
          )}
        </Tab.Group>
      </SuspenseBoundary>
    </div>
  );
}
