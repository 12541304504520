import { useState, useEffect } from "react";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import { getNotifications, markNotificationAsRead } from "../../api/Api";
import MaterialTable from "../../components/common/MaterialTable";
import { useTitle } from "../../utils/RouterUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function NotificationsScreen() {
  const classes = useStyles();

  useTitle("Notifications | SquadHub");

  const [notificationType, seNotificationType] = useState("ALL");
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(25);

  const [totalElements, setTotalElements] = useState(0);
  const [isRead, setIsRead] = useState(undefined);

  const getTableHead = () => {
    return [
      { id: "message", label: "" },
      { id: "noOfDays", label: "" },
    ];
  };

  const calculateDays = (time) => {
    const date = new Date(time);
    return Math.ceil((Date.now() - date) / (1000 * 3600 * 24));
  };

  useEffect(() => {
    getNotifications({ page, size }, isRead)
      .then((data) => {
        setTotalElements(data.notifications.totalElements);

        setTableData(
          data.notifications.content.map((item) => {
            return {
              id: item.notificationId,
              data: [
                {
                  render: (
                    <>
                      <div className="gap-6">
                        {item.isRead ? (
                          <>
                            <div className="text-newGray-800 font-medium text-base">
                              {item.title}
                            </div>
                            <div className="text-newGray-500 font-normal text-sm">
                              {item.content}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-black font-medium text-base">
                              {item.title}
                            </div>
                            <div className="text-newGray-800 font-normal text-sm">
                              {item.content}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ),
                },
                {
                  render: (
                    <>
                      <div className="text-newGray-800">
                        {calculateDays(item.time)}
                        {calculateDays(item.time) > 1 ? " Days" : " Day"}
                      </div>
                    </>
                  ),
                },
              ],
              link: item.link,
              isRead: item.isRead,
            };
          })
        );
      })
      .catch((_) =>
        alertError("Couldn't Connect to Server, Check your Connection")
      );
  }, [page, size, isRead]);

  useEffect(() => {
    if (notificationType === "UNREAD") return setIsRead(false);
    if (notificationType === "READ") return setIsRead(true);
    setIsRead(undefined);
  }, [notificationType]);

  return (
    <div
      className={
        classes.root + " max-w-6xl mx-auto w-full gap-2 flex-col p-4 rounded-md"
      }
    >
      <div className="flex flex-row justify-between items-center">
        <Typography className="p-2 text-gray-800" variant="h4">
          All Notifications
        </Typography>
        <select
          id="countries"
          className="bg-gray-50 h-8 border border-newGray-200 text-black text-sm rounded py-1 px-1"
          onChange={(e) => {
            seNotificationType(e.target.value);
          }}
          defaultValue={notificationType}
        >
          <option value="ALL" selected={notificationType === "ALL"}>
            All Notifications
          </option>
          <option value="UNREAD" selected={notificationType === "UNREAD"}>
            Unread
          </option>
          <option value="READ" selected={notificationType === "READ"}>
            Read
          </option>
        </select>
      </div>
      <div className="py-4">
        <div className="border-t ">
          <MaterialTable
            data={tableData}
            head={getTableHead()}
            headRenderColor="text-newGray-300"
            defaultOrder="desc"
            pageRows={size}
            onClickCB={(selection) => {
              const selected = tableData.find(
                (notification) => notification.id === selection
              );
              !selected.isRead &&
                markNotificationAsRead(selection)
                  .then((_) => {})
                  .catch((_) => {});
              navigate(selected.link);
            }}
            totalData={totalElements}
            currentPage={page}
            paginationCB={(value) => {
              setPage(value);
            }}
            rowChangeCB={(value) => {
              setSize(value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
