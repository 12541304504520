import React, { useEffect, useState } from "react";

import { alertError, alertSuccess } from "../../../actions/AlertActions";
import { createPipeline, updatePipeline } from "../../../api/Api";

type CreatePipelineProps = {
  isEdit: boolean;
  pipelineId?: number;
  pipelineType?: string;
  onSubmit: () => void;
};

export default function CreatePipeline({
  isEdit,
  pipelineId,
  pipelineType,
  onSubmit,
}: CreatePipelineProps) {
  useEffect(() => {}, []);

  const [pipelineName, setPipelineName] = useState(isEdit ? pipelineType : "");

  return (
    <>
      <div className=" flex flex-col p-4  w-full rounded">
        <div className="mb-6 text-gray-900 text-xl font-medium">
          {isEdit ? `Edit Pipeline Name` : `Create New Pipeline`}
        </div>
        <div className="mb-6">
          <input
            className="appearance-none border border-newGray-300 rounded w-96 py-2 px-3 text-newGray-800 leading-tight focus:outline-none focus:shadow-outline"
            id="pipeline"
            type="text"
            placeholder="Enter pipeline name"
            onChange={(input) => setPipelineName(input.target.value)}
            value={pipelineName}
          />
        </div>

        <div className="flex flex-row">
          <button
            className="w-full rounded bg-newBlue-400 text-white text-md font-normal py-2 px-6"
            onClick={() => {
              isEdit
                ? updatePipeline(pipelineId, [
                    {
                      op: "replace",
                      path: "/pipelineType",
                      value: pipelineName,
                    },
                  ])
                    .then(() => {
                      onSubmit();
                      alertSuccess("Successfully updated Pipeline");
                    })
                    .catch((_) => alertError("Error updating Pipeline"))
                : createPipeline({
                    pipelineType: pipelineName,
                  })
                    .then(() => {
                      onSubmit();
                      alertSuccess("Successfully created Pipeline");
                    })
                    .catch((_) => alertError("Error creating Pipeline"));
            }}
          >
            {isEdit ? `Update Pipeline Name` : `Create Pipeline`}
          </button>
          <button
            className="flex justify-center items-center w-20 rounded border border-gray-900 py-2 px-6 ml-4"
            onClick={onSubmit}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
