import React, { useState } from "react";
import { useEffect } from "react";

import { TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useRecoilState } from "recoil";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import { createSR, getTicket, listSRs } from "../../api/Api";
import { deepUpdate } from "../../utils/StateUtils";
import { convertToAscendingDescendingDict } from "../../utils/StateUtils";
// import MaterialModal from "../common/MaterialModal";
import MaterialTable from "../common/MaterialTable";
// import ServiceRequestDetail from "./ServiceRequestDetail";
import NewServiceRequestPopup from "../common/NewServiceRequestPopup";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { JobTypesDict, formUpdateSRTicket } from "../common/form/constants";

// import NewCreateTicket from "../pipelines/NewCreateTicket";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function ServiceRequestScreen({ srId }) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [serviceRequests, setServiceRequests] = useState([]);
  const [forUpdate, setForUpdate] = useState();
  const [clientBuildings, setClientBuildings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({ status: "ALL" });
  const [refresh, setRefresh] = useState(true);

  const { openTicket } = useAppActions();

  const onChangeParams = (update) => {
    const { name, value } = update;
    setFilters((data) => deepUpdate(name, value, data));
  };
  const onChangeUpdate = (update) => {
    const { name, value } = update;
    setForUpdate((data) => deepUpdate(name, value, data));
  };
  const listSR = (filters) => {
    listSRs(filters.status ?? "ALL")
      .then((data) => {
        console.log("list sr called");

        setServiceRequests(data);

        setLoading(false);
      })
      .catch((error) => {
        alertError("Error getting Service Requests");
        setLoading(false);
      });
  };

  useEffect(() => console.log("Test log for develop"), []);

  useEffect(() => {
    if (srId) {
      serviceRequests.forEach((data) => {
        if (data.serviceRequestId === parseInt(srId)) {
          setForUpdate(data);
        }
      });
    }
  }, [serviceRequests]);

  useEffect(() => {
    listSR(filters);
  }, [filters]);

  return (
    <div
      className={
        classes.root + " max-w-6xl mx-auto w-full gap-2 flex-col p-4 rounded-md"
      }
    >
      <Typography className="p-2 text-gray-800" variant="h4">
        Service Requests
      </Typography>
      <div className="flex items-center gap-2 cursor-pointer">
        <TextField
          id="search"
          className="flex-1"
          label="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          variant="outlined"
        />
        <MaterialForm
          className="flex-shrink w-56"
          data={filters}
          renderArray={[
            {
              name: "status",
              inputType: "SELECT",
              options: [
                { label: "All", value: "ALL" },
                { label: "Created", value: "CREATED" },
                { label: "Scheduled", value: "SCHEDULED" },
              ],
              defaultValue: "ALL",
            },
          ]}
          onChange={onChangeParams}
        />
      </div>
      <MaterialTable
        key={filters.status}
        data={
          convertToAscendingDescendingDict(
            serviceRequests,
            "ticketId",
            "serviceRequestIndex",
            "desc"
          )?.map((row) => {
            return {
              id: row.serviceRequestId,
              data: [
                `${row.ticketId}-` +
                  (!row.isDraft
                    ? `SR${row.serviceRequestIndex}`
                    : `I${row.serviceRequestIndex}`),
                row.clientName,
                row.description,
                JobTypesDict[row.jobType],
                row.bookingStatus,
                row.propertyShortName || "-",
                {
                  render: row.preferredDateTime
                    ? new Date(row.preferredDateTime).toLocaleString()
                    : "-",
                  sortBy: row.preferredDateTime
                    ? new Date(row.preferredDateTime).getTime()
                    : 0,
                },
              ],
            };
          }) || []
        }
        head={[
          { id: "serviceRequestId", label: "Service Request Number" },
          { id: "clientName", label: "Client Name" },
          { id: "description", label: "Description" },
          { id: "jobType", label: "Job Type" },
          { id: "bookingStatus", label: "Status" },
          { id: "propertyShortName", label: "Property Short Name" },
          { id: "preferredDate", label: "Preferred Date" },
        ]}
        // defaultOrderBy={"serviceRequestId"}
        // defaultOrder="desc"
        onClickCB={(SRId) => {
          setForUpdate(
            serviceRequests.find((sr) => sr.serviceRequestId === SRId)
          );
        }}
        searchText={searchText}
      />
      {forUpdate && (
        <NewServiceRequestPopup
          data={forUpdate}
          renderArray={() => {
            return formUpdateSRTicket(forUpdate);
          }}
          backCB={() => {
            getTicket(forUpdate.ticketId).then((data) => {
              openTicket(data.ticketId);
              setForUpdate();
            });
          }}
          onChangeCB={onChangeUpdate}
          clearCB={() => {
            setForUpdate();
          }}
          updateCB={(_) => {
            createSR(forUpdate)
              .then((_) => {
                setRefresh((current) => !current);
                listSR(filters);
                alertSuccess("Successfully Updated Service Request");
              })
              .catch((_) => {
                alertError("Error Updating Service Request");
              });
          }}
        />
      )}
    </div>
  );
}
