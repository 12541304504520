import { useEffect, useState } from "react";

import ScheduleIcon from "@mui/icons-material/Schedule";
import { Button } from "@mui/material";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import {
  getTerritory,
  createAssociate,
  getAssociate,
  removeTPVContact,
  getMyVendor,
} from "../../api/Api";
import CreateAssociateContact from "../../components/associates/CreateAssociateContact";
import {
  DetailsIcon,
  ProfileRoundIcon,
} from "../../components/clients/ClientAppIcons";
import { AddIconBlue, SearchIcon } from "../../components/common/AppIcons";
import HeadlessDropdown from "../../components/common/HeadlessDropdown";
import MaterialModal from "../../components/common/MaterialModal";
import MaterialForm from "../../components/common/form/MaterialForm";
import { getISODateString } from "../../utils/DateUtils";
import { deepUpdate } from "../../utils/StateUtils";
import {
  capitalizeFirst,
  lowerCase,
  properString,
  renderPhone,
} from "../../utils/StringUtils";
import { SupplierContactsData } from "../suppliers/Service";
import { Skill } from "../vendors/ListSkills";
import { AssociateDetailsData, Territories } from "./Service";
import {
  formAssociate,
  formAssociateAddress,
  formAssociateFrom,
  formAssociateSchedule,
  metaData,
} from "./helper";

type AssociateDetailsProps = {
  associateId: number;
};

const timeFormatter = (time: string) => {
  const date = new Date("1970-01-01T" + time);
  return date.toLocaleTimeString();
};
const dateFormatter = (inputDate?: string) => {
  if (inputDate) {
    const date = new Date(inputDate);
    return date.toLocaleDateString();
  }
};

export const getSubCategories = (skillData: Skill[], skill: string) => {
  const selectedSkill = skillData.find((item) => item.skill === skill);
  return selectedSkill?.subCategories.map((item) => {
    return { label: properString(item), value: item };
  });
};

export default function AssociateDetails({
  associateId,
}: AssociateDetailsProps) {
  const [territories, setTerritories] = useState([]);
  const [skillData, setSkillData] = useState<Skill[]>([]);
  const [editDetails, setEditDetails] = useState(false);
  const [editMetadata, setEditMetadata] = useState(false);
  const [editWorkSchedule, setEditWorkSchedule] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [searchContactText, setSearchContactText] = useState("");
  const [selectedContact, setSelectedContact] =
    useState<SupplierContactsData>();

  const [data, setData] = useState<AssociateDetailsData>();

  useEffect(() => {
    getAssociate({ associateId: associateId })
      .then((data) => setData(data as AssociateDetailsData))
      .catch((_) => alertError("Error getting Associate Details"));
  }, [associateId]);
  useEffect(() => {
    getTerritory()
      .then((res) => {
        setTerritories((_) =>
          res.map((item: Territories) => ({
            value: item.name,
            label: properString(item.name),
          }))
        );
      })
      .catch(() => {
        alertError("Error getting territories");
      });
    getMyVendor()
      .then((data) => {
        setSkillData(data.categories as Skill[]);
      })
      .catch(() => alertError("Error while fetching vendor"));
  }, []);

  const getSkills = () => {
    return skillData.map((item) => {
      return { label: properString(item.skill), value: item.skill };
    });
  };

  const territoriesList = data?.territories?.map((item) =>
    properString(item.toLowerCase())
  );

  const subCategoryList = data?.subCategory?.map((item) =>
    properString(item.toLowerCase())
  );

  const addressInfoList = [
    {
      label: "Street Address 1",
      value: data?.address?.streetAddress1 ?? "---",
    },

    {
      label: "Street Address 2",
      value: data?.address?.streetAddress2 ?? "---",
    },
    {
      label: "City",
      value: data?.address?.city ?? "---",
    },
    {
      label: "State",
      value: data?.address?.state ?? "---",
    },
    {
      label: "Zip",
      value: data?.address?.zip ?? "---",
    },
  ];

  const metaDataList = [
    {
      label: "Contractor License",
      value: data?.metadata?.contractorLicense === "true" ? "Yes" : "No",
    },
    ...((data?.metadata?.contractorLicense ?? "") === "true"
      ? [
          {
            label: "Contractor License Number",
            value: data?.metadata?.contractorLicenseNumber ?? "---",
          },
        ]
      : []),
    ...((data?.metadata?.contractorLicense ?? "") === "true"
      ? [
          {
            label: "Contractor License Expiry",
            value: dateFormatter(data?.metadata?.contractorLicenseExpiry),
          },
        ]
      : []),

    {
      label: "Liability Insurance",
      value: data?.metadata?.liabilityInsurance === "true" ? "Yes" : "No",
    },
    ...((data?.metadata?.liabilityInsurance ?? "") === "true"
      ? [
          {
            label: "Liability Insurance Expiry",
            value: dateFormatter(data?.metadata?.liabilityInsuranceExpiry),
          },
        ]
      : []),
    {
      label: "Workers Compensation Insurance",
      value:
        data?.metadata?.workersCompensationInsurance === "true" ? "Yes" : "No",
    },
    ...((data?.metadata?.workersCompensationInsurance ?? "") === "true"
      ? [
          {
            label: "Workers Compensation Insurance Expiry",
            value: dateFormatter(
              data?.metadata?.workersCompensationInsuranceExpiry
            ),
          },
        ]
      : []),
  ];

  const fsoDetailsList = [
    {
      label: "Type",
      value:
        data?.type === "SQUAD_ASSOCIATE"
          ? "Squad Associate"
          : "Third Party Vendor",
    },
    {
      label: "First Name",
      value: data?.firstName ?? "---",
    },

    {
      label: "Last Name",
      value: data?.lastName ?? "---",
    },
    {
      label: "Territories",
      value: territories ? territoriesList?.join(", ") : "---",
    },

    {
      label: "Home City",
      value: data?.homeCity ?? "---",
    },
    {
      label: "Alias",
      value: data?.alias ?? "---",
    },
    {
      label: "Email",
      value: data?.email ?? "---",
    },
    {
      label: "Phone",
      value: data?.phone ?? "---",
    },
    {
      label: "Category",
      value: capitalizeFirst(data?.skill) ?? "---",
    },
    {
      label: "Sub category",
      value: subCategoryList ? subCategoryList?.join(", ") : "---",
    },
    {
      label: "Date of Birth",
      value: getISODateString(data?.dateOfBirth ?? ""),
    },
    {
      label: "Notify By",
      value: capitalizeFirst(data?.notifyBy ?? "") ?? "---",
    },
    {
      label: "Drivers License",
      value: data?.driversLicense ?? "---",
    },

    {
      label: "Drivers License Expiry",
      value: getISODateString(data?.driversLicenseExpiry ?? ""),
    },
  ];

  const workSchedule = [
    {
      label: "Workday Start",
      value: data?.workdayStart ? timeFormatter(data.workdayStart) : "--:--",
    },

    {
      label: "Workday End",
      value: data?.workdayEnd ? timeFormatter(data.workdayEnd) : "--:--",
    },
    {
      label: "Saturday Start",
      value: data?.saturdayStart ? timeFormatter(data.saturdayStart) : "--:--",
    },
    {
      label: "Saturday End",
      value: data?.saturdayEnd ? timeFormatter(data.saturdayEnd) : "--:--",
    },

    {
      label: "Sunday Start",
      value: data?.sundayStart ? timeFormatter(data.sundayStart) : "--:--",
    },
    {
      label: "Sunday End",
      value: data?.sundayEnd ? timeFormatter(data.sundayEnd) : "--:--",
    },
  ];

  const onChange = (update: {
    name: string;
    subOptions?: string[];
    value?: string;
  }) => {
    let { name, value } = update;

    if (name === "email") {
      value = lowerCase(value);
    }

    setData((data) => deepUpdate(name, value, data));
  };

  const handleSubmit = () => {
    createAssociate({
      ...data,
      subCategory: data?.subCategory,
    })
      .then(() => {
        alertSuccess("Successfully Updated Associate");
        getAssociate({ associateId: data?.associateId }).then((data) => {
          setData(data);
          setEditDetails(false);
          setEditMetadata(false);
          setEditWorkSchedule(false);
          setEditAddress(false);
        });
      })
      .catch((e) => {
        const errorMessage =
          e.readableMessage ?? data?.associateId
            ? "Error Updating Associate"
            : "Error Creating Associate";
        alertError(errorMessage);
      });
  };

  const filteredContactList = data?.contacts?.filter(
    (item) =>
      item.firstName?.toLowerCase().includes(searchContactText.toLowerCase()) ||
      item.lastName?.toLowerCase().includes(searchContactText.toLowerCase())
  );

  const { withConfirmation } = useAppActions();

  return (
    <div className="flex flex-col md:flex-row">
      <div>
        <div className="flex flex-col border border-newGray-1500 w-96 rounded self-start mt-4 ml-4">
          {editAddress ? (
            <div>
              <MaterialForm
                data={data}
                renderArray={formAssociateAddress()}
                onSubmit={handleSubmit}
                onChange={onChange}
              />
              <div className="flex flex-row-reverse w-full">
                <Button onClick={() => setEditAddress(false)} className="p-4">
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className="p-4 create-associate"
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-row justify-between items-center p-4 border-b border-newGray-1500">
                <div className="font-semibold text-sm">Address Info</div>
                <div
                  className="text-newBlue-400 font-semibold text-sm cursor-pointer"
                  onClick={() => {
                    setEditMetadata(false);
                    setEditAddress(true);
                    setEditDetails(false);
                    setEditWorkSchedule(false);
                  }}
                >
                  Edit
                </div>
              </div>
              <div className="flex flex-row p-4  items-center">
                <div className=" flex bg-newGray-500 rounded border border-newGray-1500 h-12 w-12 justify-center items-center mr-4">
                  <ProfileRoundIcon />
                </div>
                <div className="text-newGray-800 text-sm">
                  No image available
                </div>
              </div>
              {addressInfoList.map((item) => (
                <div className="mt-2 border-b p-1 px-4 border-newGray-1500">
                  <div className="font-semibold text-sm">{item.label}</div>
                  <div className="text-newGray-800 text-sm">{item.value}</div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="flex flex-col border border-newGray-1500 w-96 rounded self-start mt-4 ml-4">
          {editMetadata ? (
            <div>
              <MaterialForm
                data={data}
                renderArray={metaData(data ?? {})}
                onSubmit={handleSubmit}
                onChange={onChange}
              />
              <div className="flex flex-row-reverse w-full">
                <Button onClick={() => setEditMetadata(false)} className="p-4">
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className="p-4 create-associate"
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-row justify-between items-center p-4 border-b border-newGray-1500">
                <div className="font-semibold text-sm">Metadata</div>
                <div
                  className="text-newBlue-400 font-semibold text-sm cursor-pointer"
                  onClick={() => {
                    setEditMetadata(true);
                    setEditAddress(false);
                    setEditDetails(false);
                    setEditWorkSchedule(false);
                  }}
                >
                  Edit
                </div>
              </div>
              {metaDataList.map((item) => (
                <div className="mt-2 border-b p-1 px-4 border-newGray-1500">
                  <div className="font-semibold text-sm">{item.label}</div>
                  <div className="text-newGray-800 text-sm">{item.value}</div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="w-full">
        {editDetails ? (
          <div className="flex flex-row">
            <div className="w-96">
              <MaterialForm
                data={data}
                renderArray={formAssociate(data ?? {}, territories)}
                onSubmit={handleSubmit}
                onChange={onChange}
              />
            </div>
            <div className="w-96">
              <MaterialForm
                data={data}
                renderArray={formAssociateFrom(
                  data ?? {},
                  skillData,
                  getSkills()
                )}
                onSubmit={handleSubmit}
                onChange={onChange}
              />

              <div className="flex flex-row-reverse w-full">
                <Button onClick={() => setEditDetails(false)} className="p-4">
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className="p-4 create-associate"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col  border border-newGray-1500  rounded self-start mt-4 ml-4">
            <div className="flex flex-row justify-between items-center p-4 border-b border-newGray-1500">
              <div className="font-semibold text-sm">FSO Details</div>
              <div
                className="text-newBlue-400 font-semibold text-sm cursor-pointer"
                onClick={() => {
                  setEditMetadata(false);
                  setEditAddress(false);
                  setEditDetails(true);
                  setEditWorkSchedule(false);
                }}
              >
                Edit
              </div>
            </div>
            <div className="grid grid-flow-row-dense grid-rows-4 grid-cols-2 md:grid-cols-4">
              {fsoDetailsList.map((item) => (
                <div className="px-4 py-2 min-w-fit gap-4">
                  <div className="font-semibold text-sm ">{item.label}</div>
                  <div className="text-newGray-800 text-sm">{item.value}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        {data?.type === "SQUAD_ASSOCIATE" ? (
          <div className="flex flex-col justify-between md:w-128 mt-4 ml-4">
            {editWorkSchedule ? (
              <div className="w-80">
                <MaterialForm
                  data={data}
                  renderArray={formAssociateSchedule}
                  onSubmit={handleSubmit}
                  onChange={onChange}
                />
                <div className="flex flex-row-reverse w-full">
                  <Button
                    onClick={() => setEditWorkSchedule(false)}
                    className="p-4"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    className="p-4 create-associate"
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-row justify-between items-center ">
                  <div className="font-semibold text-sm ">Work Schedule</div>
                  <div
                    className="text-newBlue-400 font-semibold text-sm cursor-pointer mr-8"
                    onClick={() => {
                      setEditMetadata(false);
                      setEditAddress(false);
                      setEditDetails(false);
                      setEditWorkSchedule(true);
                    }}
                  >
                    Edit Schedule Hours
                  </div>
                </div>

                <div className="grid  grid-rows-3 grid-cols-1 md:grid-cols-2 mt-4">
                  {workSchedule.map((item) => (
                    <div className=" py-2 min-w-fit gap-4">
                      <div className="text-sm mb-2 text-newGray-800">
                        {item.label}
                      </div>
                      <div className="flex px-4 py-3 mr-8 border border-newGray-1500  rounded ">
                        <div className="flex w-full flex-row justify-between">
                          <div className="mr-auto text-sm font-semibold">
                            {item.value}
                          </div>
                          <ScheduleIcon />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="mt-10 ml-4">
            <div className="flex flex-col md:flex-row gap-2 justify-between items-start w-full">
              <div className="flex flex-row items-center justify-between gap-2 w-full">
                <div className="font-semibold text-sm ">Contacts</div>
                <button
                  className="border rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  <div className="flex flex-row items-center">
                    <div>
                      <AddIconBlue className="h-4 w-4" />
                    </div>
                    <div className="px-2">Add New Contact</div>
                  </div>
                </button>
              </div>
              <div className="flex flex-row">
                <div className="flex flex-row gap-2 items-center ">
                  <div>
                    <div className="flex flex-row items-center px-2 rounded bg-newGray-1400 ">
                      <div>
                        <SearchIcon />
                      </div>
                      <input
                        id="buildings_search"
                        className="focus:outline-none p-2 text-gray-700 bg-newGray-1400"
                        placeholder="Search..."
                        value={searchContactText}
                        onChange={(e) => {
                          setSearchContactText(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              {filteredContactList?.map((item) => (
                <div className="flex items-center md:flex-row border rounded mt-2 p-5  border-newGray-1500 justify-between mb-2 ">
                  <div className="flex flex-col w-full justify-between items-start md:items-center md:flex-row">
                    <div className="font-semibold text-sm w-2/7">{`${item.firstName} ${item.lastName}`}</div>
                    <div className="w-1/7">
                      {data?.primaryContactId === item.contactId && (
                        <div className="border rounded text-sm font-semibold px-2 py-1 bg-newOrange-400 text-newOrange-500 w-20">
                          Primary
                        </div>
                      )}
                    </div>

                    <div className="">{renderPhone(item.phone)}</div>
                    <div className="break-words">{item.email}</div>
                  </div>
                  <div className="flex pl-4 w-1/7 justify-end">
                    <HeadlessDropdown
                      value={"Options"}
                      options={["Edit", "Remove"]}
                      onChange={(value) => {
                        if (value === "Edit") {
                          setSelectedContact(item as SupplierContactsData);
                          setOpenModal(true);
                        }
                        if (value === "Remove") {
                          withConfirmation({
                            title: "Remove Contact",
                            description: `Are you sure you want to remove ${item.firstName} ${item.lastName}  
                          This action cannot be undone.`,
                            onConfirm: () =>
                              removeTPVContact(associateId, item.contactId)
                                .then((_res) => {
                                  alertSuccess("Contact removed successfully");
                                  getAssociate({
                                    associateId: associateId,
                                  }).then((data) =>
                                    setData(data as AssociateDetailsData)
                                  );
                                })
                                .catch((_err) => {
                                  alertError("Failed to remove Contact");
                                }),
                          });
                        }
                      }}
                    >
                      <div className="px-2 py-2 bg-gray-300 hover:bg-gray-400 rounded">
                        <DetailsIcon />
                      </div>
                    </HeadlessDropdown>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <MaterialModal
        open={openModal}
        setOpen={setOpenModal}
        label="new-user-modal"
        describedBy="create-new-user"
      >
        <CreateAssociateContact
          baseData={selectedContact}
          associateId={data?.associateId}
          successCB={() => {
            setOpenModal(false);
            getAssociate({ associateId: associateId }).then((data) =>
              setData(data as AssociateDetailsData)
            );
          }}
          onSubmitCB={undefined}
          renderFlexComponent={undefined}
        />
      </MaterialModal>
    </div>
  );
}
