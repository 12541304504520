// import { Input } from "postcss";
// import { DeleteIcon } from "../common/AppIcons";
// import { TextField } from "@material-ui/core";
// import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import React, { useState } from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import { Autocomplete } from "@material-ui/lab";
// const columnIndex = {
//   PRODUCT: 0,
//   DESCRIPTION: 1,
//   QTY: 2,
//   RATE: 3,
//   AMOUNT: 4,
//   ID: 5,
// };
import moment from "moment";

import { updateSr, updateMaterial } from "../../api/Api";
import { updateCurrencyInput } from "../../utils/StateUtils";
import {
  COL_INDEX,
  checkData, // MATERIAL_COL_INDEX,
} from "./EstimateFormUtils";

const productCategories = [
  "Appliances",
  "Carpentry",
  "Cleaning",
  "Drywall",
  "Flooring",
  "Handyman",
  "HVAC",
  "Landscape",
  "Locksmith",
  "Remodeling",
  "Electrician",
  "Plumbing",
  "Inspection",
  "Site Visit",
  "Material",
];

export default function EstimateTable({
  rows,
  headings,
  grid,
  changeData,
  changeMaterialData,
  multiselect,
  setSelected,
  columnIndex,
  invoicedLines,
  section,
  partialInvoice = false,
  setEstimateChangeDetect,
  isExternal,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [initRows, setInitRows] = useState(rows);

  const open = Boolean(anchorEl);

  const detectChange = (row_index, col_index, value) => {
    if (Number(initRows[row_index][col_index]) !== Number(value)) {
      setEstimateChangeDetect(true);
    } else {
      setEstimateChangeDetect(false);
    }
  };

  const updateServiceRequest = (srId, quantity, rate, amount) => {
    updateSr(srId, [
      {
        op: "replace",
        path: "/quantity",
        value: quantity,
      },
      {
        op: "replace",
        path: "/rate",
        value: rate,
      },
      {
        op: "replace",
        path: "/amount",
        value: amount,
      },
    ]);
  };

  const updateMaterialItem = (materialId, quantity, rate, amount) => {
    updateMaterial(materialId, [
      {
        op: "replace",
        path: "/quantity",
        value: quantity,
      },
      {
        op: "replace",
        path: "/rate",
        value: rate,
      },
      {
        op: "replace",
        path: "/amount",
        value: amount,
      },
    ]);
  };

  const rows_section = [
    { label: "Accepted", rows: rows?.filter((ele) => !ele.isDraft) },
    {
      label: "Change Order",
      rows: rows?.filter((ele) => ele.isDraft && ele.isChangeOrder),
    },
    {
      label: "Rejected",
      rows: rows?.filter((ele) => ele.isDraft && !ele.isChangeOrder),
    },
  ];

  const checkBoxLogic = () => {
    let status = checkData(rows);
    if (multiselect && !status) {
      if (partialInvoice) {
        if (section === "Accepted") {
          return true;
        } else {
          return false;
        }
      } else {
        if (section === "Accepted") {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  if (rows.length === 0) return <div />;
  else
    return (
      <div className={"shadow rounded overflow-auto w-full"}>
        <div className="flex flex-row w-full p-3 px-5 items-start">
          <span className="pr-5 py-2 font-semibold text-gray-500 text-sm w-12">
            #
          </span>
          <div
            className={
              "flex flex-grow w-full grid gap-2 " + (grid ?? "grid-cols-10")
            }
          >
            {headings.map((val, index) => (
              <span
                key={index}
                className={
                  "p-1 py-2 font-semibold text-gray-500 text-sm " +
                  (index === 1 ? "col-span-2" : "") +
                  " " +
                  (index > 1 ? "text-right" : "")
                }
              >
                {val}
              </span>
            ))}
          </div>
          <span className="p-2 pr-5 font-semibold text-gray-500 text-sm">
            &nbsp;&nbsp;&nbsp;
          </span>
        </div>
        <hr />
        {rows.map((row, row_index) => (
          <>
            <div
              className={
                "flex flex-row w-full items-center pl-1 " +
                (section === "Rejected" ? "bg-newGray-100 " : "")
              }
            >
              <span
                className={"p-2 text-gray-700 text-sm break-words w-12 mr-2 "}
              >
                {checkBoxLogic() ? (
                  <input
                    type="checkbox"
                    disabled={row[columnIndex.INVOICED]}
                    className="estimate_table_checkbox"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setSelected((selected) => {
                        if (checked) {
                          return [...selected, row];
                        } else {
                          return selected.filter(
                            (selected_row) =>
                              selected_row[columnIndex.ID] !==
                              row[columnIndex.ID]
                          );
                        }
                      });
                    }}
                  />
                ) : (
                  <span className="">{row_index + 1}</span>
                )}
              </span>
              <div
                className={
                  "w-full grid gap-2 items-center grid-cols-8 pr-2 " +
                  (section === "Rejected" ? "line-through" : "")
                }
              >
                <span className="p-1 py-2 break-all text-gray-700 text-sm  ">
                  {rows[row_index][COL_INDEX.PRODUCT]}
                </span>
                <span className="p-1 py-2 break-all text-gray-700 text-sm col-span-2 whitespace-pre-line">
                  {rows[row_index][COL_INDEX.PRODUCT] === "Inspection" ||
                  rows[row_index][COL_INDEX.PRODUCT] === "Site Visit"
                    ? ""
                    : rows[row_index][COL_INDEX.DESCRIPTION]}
                </span>
                {/* Qty Input */}
                <input
                  className={
                    "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right estimate-quantity " +
                    (section === "Rejected"
                      ? "bg-newGray-100 line-through"
                      : "")
                  }
                  disabled={
                    multiselect && !checkData(rows) ? true : false || isExternal
                  }
                  onChange={(e) => {
                    detectChange(row_index, COL_INDEX.QTY, e.target.value);
                    changeData(
                      row_index,
                      COL_INDEX.QTY,
                      e.target.value,
                      rows[row_index][COL_INDEX.ID]
                    );
                  }}
                  value={rows[row_index][COL_INDEX.QTY]}
                  onBlur={() => {
                    updateServiceRequest(
                      rows[row_index][COL_INDEX.ID],
                      rows[row_index][COL_INDEX.QTY],
                      rows[row_index][COL_INDEX.RATE],
                      rows[row_index][COL_INDEX.RATE] *
                        rows[row_index][COL_INDEX.QTY]
                    );
                  }}
                />
                {/* Rate Input */}
                <input
                  className={
                    "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right caret-transparent estimate-rate " +
                    (section === "Rejected"
                      ? "bg-newGray-100 line-through"
                      : "")
                  }
                  onBlur={() => {
                    updateServiceRequest(
                      rows[row_index][COL_INDEX.ID],
                      rows[row_index][COL_INDEX.QTY],
                      rows[row_index][COL_INDEX.RATE],
                      rows[row_index][COL_INDEX.RATE] *
                        rows[row_index][COL_INDEX.QTY]
                    );
                  }}
                  onKeyUp={(e) => {
                    detectChange(row_index, COL_INDEX.QTY, e.target.value);
                    const newValue = updateCurrencyInput(
                      e,
                      rows[row_index][COL_INDEX.RATE]
                    );
                    changeData(
                      row_index,
                      COL_INDEX.RATE,
                      newValue,
                      rows[row_index][COL_INDEX.ID]
                    );
                  }}
                  disabled={
                    multiselect && !checkData(rows) ? true : false || isExternal
                  }
                  value={
                    "$" +
                    (rows[row_index][COL_INDEX.RATE] ||
                    rows[row_index][COL_INDEX.RATE] === 0
                      ? rows[row_index][COL_INDEX.RATE]
                      : "")
                  }
                />
                {/* Amount Span */}
                <span className="p-1 py-2 break-all text-gray-700 text-sm  text-right">
                  $
                  {Number(
                    rows[row_index][COL_INDEX.QTY] *
                      rows[row_index][COL_INDEX.RATE]
                  ).toFixed(2)}
                </span>
                <span className="p-1 py-2 break-all text-gray-700 text-sm  text-right">
                  {rows[row_index][COL_INDEX["ACCEPTED BY"]]}
                </span>
                <span className="p-1 py-2 break-all text-gray-700 text-sm  text-right">
                  {rows[row_index][COL_INDEX["ACCEPTED ON"]]
                    ? moment(
                        rows[row_index][COL_INDEX["ACCEPTED ON"]],
                        "DD/MM/YYYY"
                      ).format("MM/DD/YYYY")
                    : ""}
                </span>
              </div>
              <div className="py-6">
                <a
                  href={
                    invoicedLines.find(
                      (line) => line.id === row[columnIndex.ID]
                    )
                      ? process.env.REACT_APP_ESTIMATE +
                        invoicedLines.find(
                          (line) => line.id === row[columnIndex.ID]
                        ).txnId
                      : undefined
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={
                    invoicedLines.find(
                      (line) => line.id === row[columnIndex.ID]
                    )
                      ? ""
                      : "invisible"
                  }
                >
                  <CheckCircleOutlineIcon
                    style={{ color: "green", marginLeft: "5px" }}
                  />
                </a>
              </div>
            </div>

            {row[COL_INDEX.MATERIALS].length > 0 && (
              <div className="w-full flex flex-col p-3 px-2 opacity-70">
                <div className="text-gray-600 font-semibold text-sm pt-1 w-full">
                  Materials
                </div>
                {row[COL_INDEX.MATERIALS].map((material, material_index) => {
                  return (
                    <div
                      className={
                        "flex flex-row w-full items-center pl-1 " +
                        (section === "Rejected" ? "bg-newGray-100" : "")
                      }
                    >
                      <span className="p-2 text-gray-700 text-sm break-words w-12 mr-2">
                        {row_index + 1}.{material_index + 1}
                      </span>
                      <div
                        className={
                          "w-full grid gap-2 items-center grid-cols-8 pr-2  " +
                          (section === "Rejected" ? "line-through" : "")
                        }
                      >
                        <span className="p-1 py-2 break-all text-gray-700 text-sm  ">
                          {material.name}
                        </span>
                        <span className="p-1 py-2 break-all text-gray-700 text-sm col-span-2 ">
                          {material.description}
                        </span>
                        <input
                          className={
                            "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right material-quantity " +
                            (section === "Rejected" ? "bg-newGray-100" : "")
                          }
                          value={material.quantity ?? ""}
                          onChange={(e) => {
                            changeMaterialData(row[8], material_index, {
                              quantity: e.target.value,
                            });
                          }}
                          onBlur={() => {
                            updateMaterialItem(
                              material.materialId,
                              material.quantity,
                              material.rate,
                              Number(material.quantity) * Number(material.rate)
                            );
                          }}
                          disabled={multiselect ? true : false}
                        />
                        <input
                          className={
                            "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right caret-transparent material-rate " +
                            (section === "Rejected" ? "bg-newGray-100" : "")
                          }
                          disabled={multiselect ? true : false}
                          value={"$" + (material.rate ? material.rate : "")}
                          onKeyUp={(e) => {
                            const newRate = updateCurrencyInput(
                              e,
                              material.rate
                            );
                            changeMaterialData(row[8], material_index, {
                              rate: newRate,
                            });
                          }}
                          onBlur={() => {
                            updateMaterialItem(
                              material.materialId,
                              material.quantity,
                              material.rate,
                              Number(material.quantity) * Number(material.rate)
                            );
                          }}
                        />
                        <span className="p-1 py-2 break-all text-gray-700 text-sm  text-right">
                          $
                          {Number(material.rate * material.quantity).toFixed(2)}
                        </span>
                        <div></div>
                        <div></div>
                      </div>
                      <div>
                        <span className={"invisible"}>
                          <CheckCircleOutlineIcon
                            style={{ color: "green", marginLeft: "5px" }}
                          />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        ))}
      </div>
    );
}
