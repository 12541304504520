import { React, useEffect, useState } from "react";

import MergeTypeIcon from "@mui/icons-material/MergeType";
import { Button } from "@mui/material";
import { navigate, useQueryParams } from "raviger";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import {
  getCommericalClients,
  searchIndividualClients,
  mergeClients,
} from "../../api/Api";
import { clientClassOptions } from "../../components/clients/forms/ClientFormFields";
import { AddIconBlue } from "../../components/common/AppIcons";
import HeadlessFilterSelect from "../../components/common/HeadlessFilterSelect";
import MaterialModal from "../../components/common/MaterialModal";
import MaterialTable from "../../components/common/MaterialTable";
import { useTitle } from "../../utils/RouterUtils";
import { properString, renderPhone } from "../../utils/StringUtils";
import { renderAddress } from "../../utils/StringUtils";

export default function NewListClients() {
  const [{ search }] = useQueryParams();

  const [tab, setTab] = useState(search ? "COMMERCIAL" : "INDIVIDUAL");
  const [clientsData, setClientsData] = useState([]);
  const [clientClass, setClientClass] = useState("ALL");

  const [searchText, setSearchText] = useState(search ?? "");
  const [page, setPage] = useState();
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [mergeTo, setMergeTo] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);

  useTitle(`${properString(tab)} Clients | SquadHub`);

  useEffect(() => {
    setPage(0);
    setClientsData([]);
    getTableData();
  }, [tab]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPage(0);
      getTableData();
    }, 300);
    return () => clearTimeout(timeout);
  }, [searchText]);

  useEffect(() => {
    getTableData(page);
  }, [page, size, clientClass]);

  const onSearch = (value) => {
    setSearchText(value);
  };
  const [multiSelect, setMultiSelect] = useState(false);
  const [selected, setSelected] = useState([]); // clientIds
  const [mergeModalOpen, setMergeModalOpen] = useState(false);

  const getTableHead = () => {
    switch (tab) {
      case "INDIVIDUAL":
        return [
          { id: "clientName", label: "Client Name" },
          { id: "email", label: "Email" },
          { id: "phone", label: "Phone" },
          { id: "address", label: "Address" },
        ];
      default:
        return [
          { id: "clientName", label: "Client Name" },
          { id: "address", label: "Address" },
          { id: "clientClass", label: "Managed By" },
          { id: "city", label: "City" },
        ];
    }
  };

  useEffect(() => {
    getCommericalClients("ALL", "", 0, 100).then((data) => {
      setClientOptions((_existingData) => [
        ...data.content.map((item) => {
          return {
            id: item.clientId,
            data: [
              item.clientName,
              renderAddress(item),
              clientClassOptions.find(
                (option) => option.value === item.clientClass
              )?.label,
              item.city || "",
            ],
          };
        }),
      ]);
    });
  }, []);

  const getTableData = () => {
    switch (tab) {
      case "INDIVIDUAL":
        searchIndividualClients(searchText, page, size)
          .then((data) => {
            setClientsData((_existingData) =>
              data.content.map((item) => {
                let address = "No Address";
                const uniqueBuildings = item.buildings
                  // filter out duplicate buildings using buildingId
                  .filter(
                    (building, index, self) =>
                      index ===
                      self.findIndex(
                        (t) => t.buildingId === building.buildingId
                      )
                  );
                if (uniqueBuildings.length > 1) {
                  address = {
                    render: "Multiple Addresses",
                    sortBy: uniqueBuildings
                      .map(({ address }) => renderAddress(address))
                      .join(","),
                  };
                } else if (uniqueBuildings.length === 1) {
                  address = renderAddress(uniqueBuildings[0].address);
                }
                return {
                  id: item.contactId,
                  data: [
                    `${item.firstName} ${item.lastName}`,
                    item.email,
                    renderPhone(item.phone),
                    address,
                  ],
                };
              })
            );
            setTotalElements(data.totalElements);
          })
          .catch((_err) => {
            console.log(_err);
            alertError(
              "Sorry, we are unable to populate Individual Clients at this time."
            );
          });
        break;
      default:
        getCommericalClients(clientClass, searchText, page, size)
          .then((data) => {
            setClientsData((_existingData) => [
              ...data.content.map((item) => {
                return {
                  id: item.clientId,
                  data: [
                    item.clientName,
                    renderAddress(item),
                    clientClassOptions.find(
                      (option) => option.value === item.clientClass
                    )?.label,
                    item.city || "",
                  ],
                };
              }),
            ]);
            setTotalElements(data.totalElements);
          })
          .catch((_err) => {
            console.log(_err);
            alertError(
              "Sorry, we are unable to populate Commercial Clients at this time."
            );
          });
        break;
    }
  };
  return (
    <div className="bg-white h-full w-full p-4 overflow-auto">
      <div className="flex flex-row justify-between items-center px-4 py-4">
        <div className="font-semibold text-lg">Clients</div>
        <div className="flex flex-row items-center">
          <div>
            <button
              className="text-white bg-newBlue-400 rounded px-2 py-1.5"
              onClick={() => {
                navigate(`/clients/new`);
              }}
            >
              <div className="flex flex-row items-center">
                <div>
                  <AddIconBlue className="h-4 w-4" />
                </div>
                <div className="flex gap-1 px-2">
                  <span className="hidden md:inline">Add</span>
                  <span className="">New</span>
                  <span className="hidden md:inline">Client</span>
                </div>
              </div>
            </button>
          </div>
          {tab === "COMMERCIAL" && (
            <div className="px-2">
              <button
                className="border border-newBlue-400 text-newBlue-400 rounded px-2 py-1"
                disabled={multiSelect && selected.length <= 1}
                onClick={() => {
                  if (multiSelect) {
                    setMergeModalOpen(true);
                  } else {
                    setMultiSelect(true);
                    alertInfo("Please select clients to merge");
                  }
                }}
              >
                <div className="flex flex-row items-center">
                  <div>
                    <MergeTypeIcon />
                  </div>
                  <div className="px-2">Merge</div>
                </div>
              </button>
            </div>
          )}
          {multiSelect && (
            <div className="px-2">
              <button
                className="border border-newBlue-400 text-newBlue-400 rounded px-2 py-1"
                onClick={() => {
                  setMultiSelect(false);
                  setSelected([]);
                }}
              >
                <div className="flex flex-row items-center">
                  <div className="px-2">Cancel</div>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col border ">
        <div className="flex flex-row w-full border-b">
          <div className="px-4">
            <div className=" border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {[
                  { label: "Individual Clients", value: "INDIVIDUAL" },
                  { label: "Commercial Clients", value: "COMMERCIAL" },
                ].map((item) => (
                  <button
                    key={item.value}
                    className={
                      "border-transparent hover:text-gray-700  whitespace-nowrap py-4 px-1 border-b-2 text-sm " +
                      (tab === item.value
                        ? "text-newBlue-400 border-b-4 border-newBlue-400"
                        : "text-newGray-800")
                    }
                    onClick={() => {
                      setTab(item.value);
                    }}
                  >
                    {item.label}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between py-4 px-4">
          <div className="relative w-3/12">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-newGray-300 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-800"
              placeholder="Search..."
              required
              value={searchText}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
          </div>
          {tab === "COMMERCIAL" && (
            <div>
              <HeadlessFilterSelect
                placeholder="Filter"
                onChange={(value) => {
                  setClientsData([]);
                  setClientClass(value);
                }}
                value={clientClass}
                options={[
                  {
                    title: "All",
                    value: "ALL",
                    description: "Show All Commercial Clients",
                  },
                  {
                    title: "PMC",
                    value: "PMC",
                    description: "Show Management Companies",
                  },
                  {
                    title: "HOA",
                    value: "HOA_OWNER",
                    description: "Show Home Owners Associations",
                  },
                ]}
              />
            </div>
          )}
        </div>
        <div className="py-4">
          <div className="border-t ">
            <MaterialTable
              data={clientsData}
              head={getTableHead()}
              currentPage={page || 0}
              totalData={totalElements}
              headRenderColor="text-newGray-300"
              defaultOrderBy={"createdAt"}
              defaultOrder="desc"
              pageRows={size}
              onClickCB={(selection) => {
                if (tab === "COMMERCIAL") {
                  navigate(`/commercial/${selection}/summary`);
                } else {
                  navigate(`/individual/${selection}/buildings`);
                }
              }}
              paginationCB={(value) => {
                console.log("Pagination CB", value);
                setPage(value);
              }}
              rowChangeCB={(value) => {
                setSize(value);
              }}
              selected={selected}
              setSelected={setSelected}
              multiSelect={multiSelect}
            />
          </div>
        </div>
      </div>
      <MaterialModal
        open={mergeModalOpen}
        setOpen={() => {
          setMergeModalOpen(false);
          setMultiSelect(false);
          setSelected([]);
          setMergeTo(null);
        }}
      >
        <span> Choose Address for the Merged Building</span>

        <div className="px-4 pt-6 pb-2">
          <div className="w-full flex-1 mr-4">
            <select
              className="w-full bg-white my-2 p-1 flex border border-newGray-700 rounded"
              value={mergeTo}
              name="skill"
              onChange={(input) => setMergeTo(Number(input.target.value))}
            >
              <option selected>Select...</option>
              {clientOptions.map((item) => {
                if (selected.find((data) => data === item.id))
                  return <option value={item.id}>{item.data[0]}</option>;
              })}
            </select>
          </div>
          <div className="flex w-full mt-4 justify-end">
            <Button
              color="primary"
              disabled={!mergeTo}
              className="p-4 focus:outline-none"
              onClick={(_) => {
                mergeClients({
                  primaryClientId: mergeTo,
                  clientIds: selected,
                })
                  .then((_) => {
                    alertSuccess("Successfully Merged Clients");
                    setSelected([]);
                    setMultiSelect(false);
                    setMergeModalOpen(false);
                    getTableData();
                    navigate(`commercial/${mergeTo}/summary`);
                  })
                  .catch((err) => {
                    err.message
                      ? alertError(err.message)
                      : alertError("Error Merging Clients");
                    setSelected([]);
                    setMultiSelect(false);
                    setMergeModalOpen(false);
                  });
              }}
            >
              Merge
            </Button>
          </div>
        </div>
      </MaterialModal>
    </div>
  );
}
