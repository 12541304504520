import React from "react";

import { Avatar } from "@mui/material";
import { Link, navigate, usePath } from "raviger";
import { useRecoilState } from "recoil";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import {
  removeContact,
  updateResidentPosition,
  updateResidentUnit,
} from "../../api/TypedAPI";
import { globalComponentStateAtom } from "../../store/GlobalComponent";
import { conditionalArrayEntry } from "../../utils/ObjectUtills";
import { properString } from "../../utils/StringUtils";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { PersonWithIcon, ProfileIcon, SwitchIcon } from "../common/AppIcons";
import HeadlessDropdown from "../common/HeadlessDropdown";
import {
  CaretRight,
  DeleteBin,
  MessageIcon,
  ThreeDotsIcon,
} from "./ClientAppIcons";

export default function ListUnitMember({
  buildingId,
  contact,
  refreshCB,
  buildingUnits,
}) {
  const [_, setGlobalComponentState] = useRecoilState(globalComponentStateAtom);

  const isMobile = useMediaQuery("(max-width: 640px)");

  const path = usePath();
  const basePath = path.split("/").slice(0, 3).join("/");

  const { withConfirmation, selectFromChoices, closeChoiceSelector } =
    useAppActions();

  const editContact = (e) => {
    e && e.stopPropagation();
    setGlobalComponentState((state) => ({
      ...state,
      contactData: {
        ...contact,
      },
      contactSuccessCB: refreshCB,
    }));
  };
  const contactMessagingPath = `${basePath}/messages/${contact.contactId}`;
  const onSwitch = (_e) => {
    const targetPosition = contact.position === "OWNER" ? "TENANT" : "OWNER";

    const switchPositionConfirmation = () =>
      withConfirmation({
        title: "Change Position",
        description: `Are you sure you want to switch ${contact.firstName} ${contact.lastName}'s position to ${targetPosition}?`,
        onConfirm: () => {
          alertInfo("Switching position...", undefined, {
            loading: true,
          });
          updateResidentPosition(contact.buildingContactId, targetPosition)
            .then((_res) => {
              refreshCB();
              closeChoiceSelector();
              alertSuccess("Position switched successfully");
            })
            .catch((_err) => {
              alertError("Failed to switch position");
            });
        },
      });

    const switchUnitsConfirmation = () =>
      selectFromChoices({
        title: "Switch Units",
        description: "Select the unit you would like to switch to",
        choices: buildingUnits.map((unit) => ({
          label: unit,
          value: unit,
        })),
        callback: async (unitNumber) => {
          alertInfo(`Switching to unit ${unitNumber}...`, undefined, {
            loading: true,
          });
          updateResidentUnit(contact.buildingContactId, unitNumber)
            .then((_res) => {
              refreshCB();
              closeChoiceSelector();
              alertSuccess("Unit switched successfully");
            })
            .catch((_err) => {
              alertError("Failed to switch unit");
            });
        },
      });

    if (buildingUnits.length === 0) {
      switchPositionConfirmation();
    } else {
      selectFromChoices({
        title: "Switch Resident Role/Units",
        description: `Would you like to switch the resident's role or units?`,
        choices: [
          {
            label: "Switch Role",
            callback: switchPositionConfirmation,
          },
          {
            label: "Switch Units",
            callback: () => {
              switchUnitsConfirmation();
            },
          },
        ],
      });
    }
  };

  const onDelete = (e) => {
    e && e.stopPropagation();
    withConfirmation({
      title: "Unlink Resident",
      description: `Are you sure you want to unlink ${contact.firstName} ${contact.lastName}?
    This will remove them from the unit and they will no longer be able to access the building.
    
    This action cannot be undone.`,
      onConfirm: () => {
        // TODO: Add API call to unlink resident
        removeContact(buildingId, {
          contactId: contact.contactId,
          unitNumber: contact.unitNumber,
        })
          .then((_res) => {
            alertSuccess("Resident unlinked successfully");
            refreshCB();
          })
          .catch((_err) => {
            alertError("Failed to unlink resident");
          });
      },
    });
  };

  const showDelete =
    buildingId &&
    (contact.position === "OWNER" || contact.position === "TENANT");

  const contactMenuOptions = [
    {
      label: "Edit Contact",
      value: "editContact",
      callback: editContact,
    },
    {
      label: "Open Messaging",
      value: "openMessaging",
      callback: () => navigate(contactMessagingPath),
    },
    {
      label: "Switch Role/Units",
      value: "switchRoleUnits",
      callback: onSwitch,
    },
    ...conditionalArrayEntry(showDelete, {
      label: "Remove Contact",
      value: "removeContact",
      callback: onDelete,
    }),
  ];

  return (
    // Maybe Switch to Grid with Child Optimizations
    <div className="p-2 flex w-full justify-between items-center hover:bg-gray-300">
      <div className="flex items-center">
        <Link
          href={`/individual/${contact.contactId}/buildings`}
          className="flex items-center"
        >
          <div className="px-1">
            <button>
              <Avatar
                src={contact.profilePicture}
                variant="square"
                className="h-8 w-8 rounded"
              ></Avatar>
            </button>
          </div>
          <div className="px-1 text-sm">
            <span>{`${contact.firstName} ${contact.lastName}`}</span>
          </div>
        </Link>
        <div className="px-1 gap-2 hidden md:flex">
          <button onClick={editContact}>
            <PersonWithIcon className="h-8 w-8 p-2 bg-white rounded-md hover:bg-indigo-800 hover:text-white" />
          </button>
          <Link href={contactMessagingPath}>
            <MessageIcon className="h-8 w-8 bg-white rounded-md hover:bg-indigo-800 hover:text-white" />
          </Link>
          {/* Group Hover */}
        </div>
      </div>
      <div className="items-end">
        <div className="flex gap-2 md:pl-28">
          {contact.position === "MANAGER" ? (
            <>
              <span className="bg-newViolet-200 text-newViolet-300 inline-flex items-center rounded px-2 py-1 text-sm font-medium">
                Manager
              </span>
              {contact.hiredBy && (
                <span className="bg-pink-100 text-pink-600 inline-flex items-center rounded px-2 py-1 text-sm font-medium">
                  Hired By:{" "}
                  {`${contact.hiredBy.firstName} ${contact.hiredBy.lastName}`}
                </span>
              )}
            </>
          ) : (
            <>
              {contact.position === "OWNER" ? (
                <span className="bg-newPink-100 text-newBlue-600 inline-flex items-center rounded px-2 py-1 text-sm font-medium">
                  Owner
                </span>
              ) : contact.position === "PRIMARY_TENANT" ? (
                <span className="bg-newOrange-600 text-newOrange-500 inline-flex items-center rounded px-2 py-1 text-sm font-medium">
                  Tenant
                </span>
              ) : (
                <span className="bg-newGray-200 text-newGray-600 inline-flex items-center rounded px-2 py-1 text-sm font-medium">
                  {properString(contact.position)}
                </span>
              )}
              <button onClick={onSwitch}>
                <SwitchIcon className="p-2 h-8 w-8 bg-white rounded-md hover:bg-indigo-800 hover:text-white hidden md:block" />
              </button>
            </>
          )}
        </div>
      </div>
      <HeadlessDropdown
        className="md:hidden"
        value={"Options"}
        options={contactMenuOptions}
        onChange={(value) =>
          contactMenuOptions.find((option) => option.value === value).callback()
        }
      >
        <div className="px-2 py-2 bg-gray-300 hover:bg-gray-400 rounded">
          <ThreeDotsIcon />
        </div>
      </HeadlessDropdown>

      <div className="flex items-center gap-4 justify-end px-4">
        {showDelete && (
          <button
            onClick={onDelete}
            className="hidden md:block px-2 py-2 border bg-white text-red-500 border-newGray-200 rounded hover:bg-red-600 hover:text-white"
          >
            <DeleteBin className="h-4 w-4 " />
          </button>
        )}
      </div>
    </div>
  );
}
