import { useEffect, useState } from "react";

import { alertError, alertSuccess } from "../../../actions/AlertActions";
import {
  createUnit,
  getBuildingUnits,
  getUnits,
  newBuildingGet,
  updateUnit,
} from "../../../api/Api";
import {
  CopyIcon,
  PetsAllowedIcon,
  PetsNotAllowedIcon,
  Tick,
} from "../../../components/common/AppIcons";
import MaterialModal from "../../../components/common/MaterialModal";
import MaterialForm from "../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../utils/StateUtils";
import CopyUnitDetails from "./CopyUnitDetails";
import { UnitData } from "./NewUnitScreen";

type UnitGeneralInfoProps = {
  onBackClick: () => void;
  buildingId: number;
  setCurrentScreen: React.Dispatch<
    React.SetStateAction<"generalInfo" | "uploadPhoto" | "unitAmenities">
  >;
  setUnitId: React.Dispatch<React.SetStateAction<number>>;
  unitData: UnitData;
  setUnitData: React.Dispatch<React.SetStateAction<UnitData>>;
  unitId: number;
};

export default function UnitGeneralInfo({
  onBackClick,
  buildingId,
  setCurrentScreen,
  setUnitId,
  unitData,
  setUnitData,
  unitId,
}: UnitGeneralInfoProps) {
  const [unitOptions, setUnitOptions] = useState([]);

  const onChange = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setUnitData((data) => deepUpdate(name, value, data));
  };
  const [type, setType] = useState("petsAllowed");
  const [showPopup, setShowPopup] = useState(false);

  const [petPolicy, setPetPolicy] = useState({
    largeDogs: false,
    smallDogs: false,
    cats: false,
    description: "",
  });

  const [feePolicy, setFeePolicy] = useState({
    oneTimeFee: "",
    monthlyFee: "",
  });

  const onChangePetPolicy = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setPetPolicy((data) => deepUpdate(name, value, data));
  };
  const onChangeFeePolicy = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setFeePolicy((data) => deepUpdate(name, value, data));
  };

  useEffect(() => {
    newBuildingGet(buildingId).then((building) => {
      if (building?.profile?.petPolicy !== null) {
        setType(
          building.profile?.petPolicy?.petsAllowed
            ? "petsAllowed"
            : "petsNotAllowed"
        );
        setPetPolicy(building.profile?.petPolicy);
      }
      if (building.profile?.feePolicy !== null)
        setFeePolicy(building.profile?.feePolicy);
    });
    getBuildingUnits(buildingId).then((data) => {
      getUnits(0, 100, buildingId, "", "")
        .then((units) => {
          const occupiedUnits = units.content.map(
            (item: { unitNumber: string }) => item.unitNumber
          );

          const availableUnits = data.value.filter(
            (item: string) => !occupiedUnits.includes(item)
          );
          setUnitOptions((_) =>
            availableUnits.map((item: string) => ({ label: item, value: item }))
          );
        })
        .catch((_) =>
          alertError("Couldn't Connect to Server, Check your Connection")
        );
    });
  }, [buildingId]);

  const onSave = () => {
    const unitRequest = {
      buildingId: buildingId,
      unitNumber: unitData.unitNumber,
      unitDetails: {
        marketRent: unitData.marketRent,
        unitSize: unitData.unitSize,
        beds: unitData.beds,
        bath: unitData.bath,
        description: unitData.description,
        overridePetPolicy: unitData.overridePetPolicy,
        overrideFeePolicy: unitData.overrideFeePolicy,
        petPolicy: {
          petsAllowed: type === "petsAllowed",
          largeDogs: petPolicy.largeDogs,
          smallDogs: petPolicy.smallDogs,
          cat: petPolicy.cats,
          description: petPolicy.description,
        },
        feePolicy: feePolicy,
      },
    };

    if (unitId === 0) {
      createUnit(unitRequest)
        .then((data) => {
          setUnitId(data.id);
          setCurrentScreen("uploadPhoto");
        })
        .catch(() => {
          alertError("Error occurred while Creating Unit");
        });
    } else {
      updateUnit(unitId, [
        {
          op: "replace",
          path: "/unitNumber",
          value: unitData.unitNumber,
        },
        {
          op: "replace",
          path: "/unitDetails/marketRent",
          value: unitData.marketRent,
        },
        {
          op: "replace",
          path: "/unitDetails/unitSize",
          value: unitData.unitSize,
        },
        {
          op: "replace",
          path: "/unitDetails/beds",
          value: unitData.beds,
        },
        {
          op: "replace",
          path: "/unitDetails/bath",
          value: unitData.bath,
        },
        {
          op: "replace",
          path: "/unitDetails/description",
          value: unitData.description,
        },
        {
          op: "replace",
          path: "/unitDetails/overrideFeePolicy",
          value: unitData.overrideFeePolicy,
        },
        {
          op: "replace",
          path: "/unitDetails/overridePetPolicy",
          value: unitData.overridePetPolicy,
        },
        {
          op: "replace",
          path: "/unitDetails/petPolicy",
          value: {
            petsAllowed: type === "petsAllowed",
            largeDogs: petPolicy.largeDogs,
            smallDogs: petPolicy.smallDogs,
            cat: petPolicy.cats,
            description: petPolicy.description,
          },
        },
        {
          op: "replace",
          path: "/unitDetails/petPolicy",
          value: feePolicy,
        },
      ])
        .then(() => {
          alertSuccess("Successfully saved Unit");
          setCurrentScreen("uploadPhoto");
        })
        .catch(() => {
          alertError("Error while saving unit");
        });
    }
  };

  return (
    <div className="flex flex-col items-center self-center mt-4 w-3/4">
      <div className="text-2xl font-semibold text-black">General Info</div>
      <div className="mt-10">
        <div className="flex flex-row items-center">
          <MaterialForm
            className="w-full flex flex-row mr-4"
            data={unitData}
            onChange={onChange}
            fieldClassName="ml-2"
            renderArray={[
              {
                name: "unitNumber",
                inputType: unitOptions.length > 0 ? "SELECT" : "TEXT",
                options: unitOptions,
                variant: "OUTLINED",
              },
              {
                name: "marketRent",
                inputType: "NUMBER",
                variant: "OUTLINED",
                label: "Market Rent($)",
              },
              {
                name: "unitSize",
                inputType: "NUMBER",
                variant: "OUTLINED",
                label: "Unit Size (sq.ft)",
              },
              { name: "beds", inputType: "NUMBER", variant: "OUTLINED" },
              { name: "bath", inputType: "NUMBER", variant: "OUTLINED" },
            ]}
          />
          <button className="outline-none" onClick={() => setShowPopup(true)}>
            <CopyIcon />
          </button>
        </div>
        <MaterialForm
          className="mt-6"
          data={unitData}
          onChange={onChange}
          fieldClassName="w-full"
          renderArray={[
            { name: "description", variant: "OUTLINED", inputType: "AREA" },
          ]}
        />

        <MaterialForm
          className=" mt-6"
          data={unitData}
          onChange={onChange}
          fieldClassName=" mr-20"
          renderArray={[
            {
              name: "overridePetPolicy",
              inputType: "CHECKBOX",
              variant: "OUTLINED",
            },
          ]}
        />
        {unitData.overridePetPolicy && (
          <div className="flex flex-col w-full justify-center items-center mt-10">
            <div className="text-xl font-semibold mb-4">Pet Policy</div>
            <div className="flex flex-row justify-start items-center mb-4">
              <button
                className="flex flex-col justify-center items-center relative mr-8  p-4 bg-newGray-1400 w-60 h-32"
                onClick={() => setType("petsAllowed")}
              >
                <PetsAllowedIcon className="h-12 w-12" />
                <div className="text-sm text-newGray-800 font-normal">
                  Pets Allowed
                </div>
                {type === "petsAllowed" && (
                  <div className="flex absolute top-2 right-2">
                    <div className="flex items-center justify-center bg-green-400 w-6 h-6  rounded-full">
                      <Tick />
                    </div>
                  </div>
                )}
              </button>
              <button
                className="flex flex-col justify-center relative  items-center py-4 bg-newGray-1400 w-60 h-32"
                onClick={() => {
                  setType("petsNotAllowed");
                  setPetPolicy({
                    largeDogs: false,
                    smallDogs: false,
                    cats: false,
                    description: petPolicy.description,
                  });
                }}
              >
                <PetsNotAllowedIcon className="h-12 w-12" />
                <div className="text-sm text-newGray-800 font-normal">
                  Pets Not Allowed
                </div>

                {type === "petsNotAllowed" && (
                  <div className="flex absolute top-2 right-2">
                    <div className="flex items-center justify-center bg-green-400 w-6 h-6  rounded-full">
                      <Tick />
                    </div>
                  </div>
                )}
              </button>
            </div>
            {type === "petsAllowed" && (
              <div className="mt-10 w-1/2">
                <MaterialForm
                  className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-3 mt-3"
                  data={petPolicy}
                  onChange={onChangePetPolicy}
                  fieldClassName="mr-2"
                  renderArray={[
                    {
                      name: "largeDogs",
                      inputType: "CHECKBOX",
                      label: "Large Dogs",
                    },
                    {
                      name: "smallDogs",
                      inputType: "CHECKBOX",
                      label: "Small Dogs",
                    },
                    {
                      name: "cats",
                      inputType: "CHECKBOX",
                      label: "Cats",
                    },
                  ]}
                />
              </div>
            )}

            <MaterialForm
              className="mt-6 w-1/2"
              data={petPolicy}
              onChange={onChangePetPolicy}
              fieldClassName="w-full"
              renderArray={[
                { name: "description", variant: "OUTLINED", inputType: "AREA" },
              ]}
            />
          </div>
        )}
        <MaterialForm
          className=" mt-2"
          data={unitData}
          onChange={onChange}
          fieldClassName=" mr-20"
          renderArray={[
            {
              name: "overrideFeePolicy",
              inputType: "CHECKBOX",
              variant: "OUTLINED",
            },
          ]}
        />
        {unitData.overrideFeePolicy && (
          <MaterialForm
            className="flex justify-evenly w-full  mt-3"
            data={feePolicy}
            onChange={onChangeFeePolicy}
            fieldClassName="mr-2"
            renderArray={[
              {
                name: "oneTimeFee",
                variant: "OUTLINED",
                inputType: "NUMBER",
                label: "One Time fee($)",
              },
              {
                name: "monthlyPetFee",
                variant: "OUTLINED",
                inputType: "NUMBER",
                label: "Monthly Pet Fee($)",
              },
            ]}
          />
        )}
      </div>

      <div className="flex flex-row self-end mt-4 mr-4 ">
        <button
          className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
          bg-gray-300
          text-gray-800 duration-200 ease-in-out 
          transition"
          onClick={onBackClick}
        >
          Cancel
        </button>
        <button
          className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
          bg-newBlue-400  w-40
          text-white border-newBlue-400
          border"
          onClick={onSave}
        >
          Save & Continue
        </button>
      </div>
      <MaterialModal
        open={showPopup}
        setOpen={(_) => {
          setShowPopup((current) => !current);
        }}
        label="assign-modal"
        describedBy="assign-ticket"
      >
        <CopyUnitDetails
          buildingId={buildingId}
          onChange={setUnitData}
          onSuccess={() => {
            setShowPopup(false);
          }}
        />
      </MaterialModal>
    </div>
  );
}
