// import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import SnoozeIcon from "@mui/icons-material/Snooze";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useRecoilState } from "recoil";

import { alertError } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import {
  createTicket,
  getPipelines,
  getTodo,
  markTodo,
  updateTicket,
} from "../../api/Api";
import { getTerritory, snoozeTicket } from "../../api/Api";
import { getFormattedDate } from "../../utils/DateUtils";
import { properString } from "../../utils/StringUtils";
import {
  DocumentIconTodo,
  DocumentKanbanIcon,
  EditIcon,
  HourGlass,
  InvoiceIcon,
  PersonWithIcon,
  Tick,
} from "../common/AppIcons";
import MaterialModal from "../common/MaterialModal";

// import NewCreateTicket from "./NewCreateTicket";

const formatInvoiceStatus = (status) => {
  if (!status) return "No Invoice";
  if (status === "NOT_INVOICED") {
    return "Not Invoiced";
  } else if (status === "PARTIALLY_INVOICED") {
    return "Partially Invoiced";
  } else {
    return "Invoiced";
  }
};

export default function Todo() {
  const [tickets, setTickets] = useState([]);
  const [pipelines, setPipelines] = useState([]);
  const [editNote, setEditNote] = useState(false);
  const [description, setDescription] = useState({});
  const [snoozeModal, setSnoozeModal] = useState(false);
  const [snoozeDays, setSnoozeDays] = useState(2);
  const [ticketId, setTicketId] = useState();
  const [territories, setTerritories] = useState([]);

  const { openTicket } = useAppActions();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    getTerritory().then((res) => {
      let temp = [];
      res.map((item) => {
        temp.push({ value: item.name, label: properString(item.name) });
      });
      setTerritories(temp);
    });
  }, []);

  const handleClose = (days) => {
    setSnoozeDays(days);

    setAnchorEl(null);
  };

  const init = () => {
    getTodo()
      .then(setTickets)
      .catch((_err) => {
        alertError("Could not fetch TODOs");
      });
    getPipelines().then((pipelines) => {
      setPipelines(pipelines);
    });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (tickets.length > 0) {
      let doc = {};
      tickets.map((item) => {
        doc[item.ticketId] = item.notes;
      });
      setDescription(doc);
    }
  }, [tickets]);

  return (
    <>
      <div className=" bg-white h-full p-2 rounded-xl mx-auto overflow-auto">
        <p className="text-xl font-bold">To Do</p>

        <div>
          <ul className="list-disc list-inside flex flex-col gap-2 mt-6">
            {tickets.map((ticket) => (
              <div
                className="rounded p-6"
                style={{ borderColor: "#C4C4C4", borderWidth: "1px" }}
                onClick={(_) => {
                  openTicket(ticket.ticketId, () => {
                    getTodo().then(setTickets);
                  });
                }}
              >
                <div className="cursor-pointer	">
                  {/* <p
                    className="text-lg font-bold"
                    style={{ color: "rgba(25, 118, 210, 1)" }}
                  >
                    {ticket.title}
                  </p> */}
                  <div className="flex items-center">
                    <div className="flex-row w-full px-1">
                      <span
                        className="inline-flex items-center rounded py-0.5 text-lg font-bold"
                        style={{ color: "rgba(25, 118, 210, 1)" }}
                      >
                        {ticket.title}
                      </span>
                    </div>

                    <div className="flex flex-end justify-end w-full">
                      <strong>
                        <span
                          className="inline-flex items-center rounded px-2 py-0.5 text-xs font-medium"
                          style={{ color: "rgba(211, 47, 47, 1)" }}
                        >
                          #T{ticket.ticketId}
                        </span>
                      </strong>
                    </div>
                  </div>

                  {/* Label Badge */}

                  <div className="flex items-center py-2">
                    <div className="flex-row">
                      <PersonWithIcon className="mx-1" />
                    </div>
                    <div className="flex-row px-1">
                      <p
                        className="text-sm"
                        style={{ color: "rgba(75, 85, 99, 1)" }}
                      >
                        {ticket.clientName}
                      </p>
                    </div>
                    <div className="px-3 flex flex-row items-center">
                      <div className="flex-row px-1">
                        <HourGlass className="mx-1" />
                      </div>
                      <div className="flex-row px-1">
                        <p style={{ color: "rgba(75, 85, 99, 1)" }}>
                          {getFormattedDate(ticket.deadline)}
                        </p>
                      </div>
                      {new Date() > new Date(Date.parse(ticket.deadline)) && (
                        <div className="flex-row px-3">
                          <SnoozeIcon
                            style={{
                              color: "red",
                              transform: "scale(0.8)",
                            }}
                            className="mx-1"
                            onClick={(event) => {
                              setSnoozeModal(true);
                              setTicketId(ticket.ticketId);

                              event.stopPropagation();
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex-col py-1">
                    {editNote ? (
                      <div className="flex flex-row gap-x-1 w-3/4 items-center">
                        <textarea
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white w-2/4"
                          id="inline-full-name"
                          type="text"
                          value={description[ticket.ticketId]}
                          rows={"1"}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          onChange={(event) => {
                            event.persist();

                            setDescription((current) => {
                              return {
                                ...current,
                                [ticket.ticketId]: event.target.value,
                              };
                            });

                            event.stopPropagation();
                          }}
                        />
                        <button
                          className="bg-green-500 hover:bg-green-700 text-white font-bold h-9 w-9 rounded float-right flex items-center justify-center"
                          onClick={(e) => {
                            let data = tickets.find((item) => {
                              if (item.ticketId === ticket.ticketId) {
                                return item;
                              }
                            });
                            data["notes"] = description[ticket.ticketId];
                            updateTicket(data).then((_data) => {
                              setEditNote(false);
                            });

                            e.stopPropagation();
                          }}
                        >
                          <Tick className="h-2 w-2" />
                        </button>
                        <button
                          className="bg-red-500 hover:bg-red-700 text-white font-bold h-9 w-9 rounded float-right flex items-center justify-center"
                          onClick={(e) => {
                            let data = tickets.find((item) => {
                              if (item.ticketId === ticket.ticketId) {
                                return item;
                              }
                            });
                            setDescription((current) => {
                              return {
                                ...current,
                                [ticket.ticketId]: data.notes,
                              };
                            });
                            setEditNote(false);
                            e.stopPropagation();
                          }}
                        >
                          <CloseIcon style={{ transform: "scale(0.8)" }} />
                        </button>
                      </div>
                    ) : (
                      <span className="inline-flex items-center rounded-md px-2 py-1 bg-gray-100 text-xs font-normal">
                        <DocumentIconTodo className="h-6 w-6" />
                        <span className="px-4 font-normal">
                          {ticket.notes || "No Notes"}
                        </span>
                        <span className="px-3">
                          <div
                            onClick={(event) => {
                              setEditNote(true);

                              event.stopPropagation();
                            }}
                          >
                            <EditIcon className="h-6 w-6" />
                          </div>
                        </span>
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      "w-full flex flex-row flex-wrap gap-2 items-center justify-between" +
                      (editNote ? "py-4" : "py-1")
                    }
                  >
                    <div className="flex flex-row flex-wrap items-center gap-2">
                      <span
                        className="inline-flex items-center rounded px-2 py-0.5 text-xs font-normal"
                        style={{
                          color: "#23AF2A",
                          backgroundColor: "#D4EAD7",
                          // opacity: "0.15"
                        }}
                      >
                        {
                          territories.find(
                            (territory) => territory.value === ticket.territory
                          )?.label
                        }
                      </span>

                      <span
                        className="inline-flex items-center rounded px-2 py-0.5 text-xs font-normal"
                        style={{
                          color: "rgba(75, 85, 99, 1)",
                          backgroundColor: "rgba(2, 136, 209, 0.19)",
                          // opacity: "0.15"
                        }}
                      >
                        {ticket.pipelineName}
                      </span>

                      <span
                        className="inline-flex items-center rounded px-2 py-0.5 text-xs font-normal"
                        style={{
                          color: "rgba(120, 6, 208, 1)",
                          backgroundColor: "rgba(146, 40, 229, 0.12)",
                          // opacity: "0.15"
                        }}
                      >
                        {ticket.stageName}
                      </span>
                      {ticket.estimateStatus ? (
                        <span
                          className="inline-flex items-center rounded px-2 py-0.5 text-xs font-normal"
                          style={{
                            color: "rgba(71, 46, 202, 1)",
                            backgroundColor: "rgba(123, 97, 255, 0.12)",
                          }}
                        >
                          <DocumentKanbanIcon color={"rgba(71, 46, 202, 1)"} />{" "}
                          &nbsp; {ticket.estimateStatus}
                        </span>
                      ) : (
                        <span
                          className="inline-flex items-center rounded px-2 py-1 text-xs font-normal"
                          style={{
                            color: "rgba(71, 46, 202, 1)",
                            backgroundColor: "rgba(123, 97, 255, 0.12)",
                          }}
                        >
                          <DocumentKanbanIcon className="h-6 w-6" /> &nbsp;
                          {"No Estimate"}
                        </span>
                      )}

                      <span
                        className="inline-flex items-center rounded px-2 py-1 text-xs font-normal"
                        style={{
                          color: "rgba(211, 47, 47, 1)",
                          backgroundColor: "rgba(255, 114, 98, 0.19)",
                          // opacity: "0.15"
                        }}
                      >
                        <InvoiceIcon className="h-6 w-6" /> &nbsp;{" "}
                        {formatInvoiceStatus(ticket.invoiceStatus)}
                      </span>
                    </div>

                    <Button
                      onClick={(event) => {
                        markTodo(ticket.ticketId, false).then((_) => {
                          init();
                        });
                        event.stopPropagation();
                      }}
                      style={{
                        color: "rgba(255, 255, 255, 1)",
                        backgroundColor: "rgba(25, 118, 210, 1)",
                        // opacity: "0.15"
                      }}
                    >
                      <Tick className="h-4 w-4" />{" "}
                      <span className="px-1">Mark As Done</span>
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </ul>
        </div>
      </div>
      {/* <MaterialModal
        open={updateTicket !== undefined}
        setOpen={(_) => setUpdateTicket()}
        label="new-user-modal"
        describedby="create-new-user"
        containerClassName="md:w-8/12 max-h-screen overflow-y-auto"
      >
        {updateTicket && (
          <NewCreateTicket
            baseData={updateTicket}
            onSuccessCB={(closeModal = true) => {
              closeModal && setUpdateTicket();
              getTodo().then(setTickets);
            }}
          />
        )}
      </MaterialModal> */}
      {snoozeModal && (
        <MaterialModal
          open={snoozeModal ? true : false}
          setOpen={(_) => setSnoozeModal()}
          label="snooze-modal"
          describedby="snooze-modal"
        >
          <p>
            Snooze for{" "}
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              {snoozeDays}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={(_) => {
                setAnchorEl(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {[...Array(10)].map((item, index) => (
                <MenuItem onClick={(_) => handleClose(index + 1)}>
                  {index + 1}
                </MenuItem>
              ))}
            </Menu>
            days
          </p>

          <Button
            onClick={() => {
              snoozeTicket(ticketId, snoozeDays)
                .then(() => {
                  init();
                  setSnoozeModal(false);
                })
                .catch(() => {});
            }}
            color="primary"
            className="float-right p-4"
          >
            Snooze
          </Button>
          <Button
            onClick={(_) => setSnoozeModal(false)}
            className="float-right p-4"
          >
            Cancel
          </Button>
        </MaterialModal>
      )}
    </>
  );
}
