import { useEffect, useState } from "react";

import { alertError, alertInfo } from "../../../actions/AlertActions";
import {
  fileUpload,
  newBuildingGet,
  updateBuilding,
  updateUnit,
} from "../../../api/Api";
import { ImagePlaceholder } from "../../../components/clients/ClientAppIcons";
import { CrossIcon } from "../../../components/common/AppIcons";
import { uploadEncodedFile } from "./helper";

type UploadUnitPhotoProps = {
  unitId: number;
  setCurrentScreen: React.Dispatch<
    React.SetStateAction<"generalInfo" | "uploadPhoto" | "unitAmenities">
  >;
  imageUrls: string[];
  setImageUrls: React.Dispatch<React.SetStateAction<string[]>>;
  buildingId: number;
};
export default function UploadUnitPhoto({
  unitId,
  setCurrentScreen,
  imageUrls,
  setImageUrls,
  buildingId,
}: UploadUnitPhotoProps) {
  const [propertyImageUrls, setPropertyImageUrls] = useState<string[]>([]);

  useEffect(() => {
    newBuildingGet(buildingId).then((data) => {
      if (data.profile !== null) {
        setPropertyImageUrls(data.profile.photos ?? []);
      }
    });
  }, [buildingId]);
  const uploadImage = (data: any) =>
    fileUpload(data)
      .then((res) => {
        console.log("Success!", res.value);
        setImageUrls([...imageUrls, res.value]);
      })
      .catch((failure) => {
        console.log("Failure", failure);
      });

  const onDelete = (image: string) => {
    const filteredImages = imageUrls.filter((item) => item !== image);
    setImageUrls(filteredImages);
  };

  const handleChooseImage = (event: { target: { files: any } }) => {
    alertInfo("Uploading image");
    let files = event.target.files;
    Array.from(files).forEach((file) => {
      console.log("MIME:" + file.type);
      let reader = new FileReader();
      reader.onload = uploadEncodedFile(uploadImage, file);
      reader.readAsDataURL(file);
    });
  };

  const uploadPropertyImage = (data: any) =>
    fileUpload(data)
      .then((res) => {
        console.log("Success!", res.value);
        setPropertyImageUrls([...propertyImageUrls, res.value]);
      })
      .catch((failure) => {
        console.log("Failure", failure);
      });

  const onDeletePropertyImage = (image: string) => {
    const filteredImages = imageUrls.filter((item) => item !== image);
    setPropertyImageUrls(filteredImages);
  };

  const handleChoosePropertyImage = (event: { target: { files: any } }) => {
    alertInfo("Uploading image");
    let files = event.target.files;
    Array.from(files).forEach((file) => {
      console.log("MIME:" + file.type);
      let reader = new FileReader();
      reader.onload = uploadEncodedFile(uploadPropertyImage, file);
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="flex flex-col items-center self-center mt-4 w-3/4">
      <div className="mt-4">
        <div className="text-xl font-semibold text-black">
          Upload Unit Photos
        </div>
        <div className="mt-4 mx-auto max-w-xs">
          <label className="flex w-full cursor-pointer appearance-none items-center justify-center rounded-md border-2 bg-newGray-700 p-6 transition-all hover:border-primary-300">
            <div className="space-y-1 text-center">
              <div className="mx-auto inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100">
                <ImagePlaceholder />
              </div>
              <div className=" text-xs text-gray-600 font-medium text-primary-500 hover:text-primary-700">
                Upload images/videos of the Unit
              </div>
            </div>
            <input
              id="upload_image"
              type="file"
              className="hidden"
              onChange={handleChooseImage}
            />
          </label>
        </div>
        <div className="grid grid-flow-row-dense grid-rows-1 grid-cols-4 mt-3">
          {imageUrls.map((item) => {
            return (
              <div className="flex rounded h-32 w-32 m-2 justify-center items-center overflow-hidden bg-newGray-700 relative">
                {item.match(/\.(jpeg|jpg|gif|png|webp)$/) ? (
                  <img className="object-scale-down" src={item} alt="" />
                ) : (
                  <video controls style={{ maxWidth: "100%" }}>
                    <source src={item} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                <button
                  className="absolute top-2 right-2"
                  onClick={() => onDelete(item)}
                >
                  <CrossIcon />
                </button>
              </div>
            );
          })}
        </div>

        <div className="text-xl font-semibold text-black w-max mt-8">
          Upload Property Photos
        </div>
        <div className="mt-4">
          <div className="mx-auto max-w-xs">
            <label className="flex w-full cursor-pointer appearance-none items-center justify-center rounded-md border-2 bg-newGray-700 p-6 transition-all hover:border-primary-300">
              <div className="space-y-1 text-center">
                <div className="mx-auto inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100">
                  <ImagePlaceholder />
                </div>
                <div className=" text-xs text-gray-600 font-medium text-primary-500 hover:text-primary-700">
                  Upload images/videos of the property
                </div>
              </div>
              <input
                id="upload_image"
                type="file"
                className="hidden"
                onChange={handleChoosePropertyImage}
              />
            </label>
          </div>
          <div className="grid grid-flow-row-dense grid-rows-1 grid-cols-4 mt-3">
            {propertyImageUrls.map((item) => {
              return (
                <div className="flex rounded h-32 w-32 m-2 justify-center items-center overflow-hidden bg-newGray-700 relative">
                  {item.match(/\.(jpeg|jpg|gif|png|webp)$/) ? (
                    <img className="object-scale-down" src={item} alt="" />
                  ) : (
                    <video controls style={{ maxWidth: "100%" }}>
                      <source src={item} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  <button
                    className="absolute top-2 right-2"
                    onClick={() => onDeletePropertyImage(item)}
                  >
                    <CrossIcon />
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex flex-row-reverse justify-self-end mt-4 mr-4 ">
          <button
            className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
      bg-newBlue-400  w-40
      text-white border-newBlue-400
      border"
            onClick={() => {
              updateUnit(unitId, [
                {
                  op: "replace",
                  path: "/photos",
                  value: imageUrls,
                },
              ])
                .then(() => {
                  updateBuilding(buildingId, [
                    {
                      op: "replace",
                      path: "/profile/photos",
                      value: propertyImageUrls,
                    },
                  ])
                    .then(() => {
                      setCurrentScreen("unitAmenities");
                    })
                    .catch(() => {
                      alertError("Error while saving photos");
                    });
                })
                .catch(() => {
                  alertError("Error while saving photos");
                });
            }}
          >
            Save & Continue
          </button>
          <button
            className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
      bg-gray-300
      text-gray-800 duration-200 ease-in-out 
      transition"
            onClick={() => setCurrentScreen("generalInfo")}
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
}
