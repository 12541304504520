import { useEffect, useState } from "react";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../../actions/AlertActions";
import { fileUpload, newBuildingGet, updateBuilding } from "../../../api/Api";
import {
  CrossIcon,
  ImagePlaceholder,
  PetsAllowedIcon,
  PetsNotAllowedIcon,
  Tick,
} from "../../../components/common/AppIcons";
import MaterialForm from "../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../utils/StateUtils";
import { amenitiesForm, uploadEncodedFile } from "./helper";

type ProfileDetailsProps = {
  buildingId: number;
};

export default function ProfileDetails({ buildingId }: ProfileDetailsProps) {
  const [selectAll, setSelectAll] = useState({
    allSelected: false,
  });
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [type, setType] = useState("petsAllowed");

  const [amenities, setAmenities] = useState({
    BIKE_PATHS: false,
    BOATING: false,
    CLUBHOUSE: false,
    CONTROLLED_ACCESS: false,
    COURTYARD: false,
    FITNESS_CENTER: false,
    GOLF_COURSE: false,
    LAUNDRY_FACILITIES: false,
    "PLAYGROUND/PARK": false,
    POOL: false,
    PUBLIC_TRANSPORTATION: false,
    RESERVED_PARKING: false,
    "SPA/JACUZZI": false,
    STORAGE: false,
    TENNIS_COURT: false,
  });

  const [feeDetails, setFeeDetails] = useState({
    daysAfterDueDate: 0,
    frequency: "",
    type: "",
    amount: 0,
  });
  const [petPolicy, setPetPolicy] = useState({
    largeDogs: false,
    smallDogs: false,
    cats: false,
    description: "",
  });

  const [feePolicy, setFeePolicy] = useState({
    oneTimeFee: "",
    monthlyFee: "",
  });

  useEffect(() => {
    newBuildingGet(buildingId).then((data) => {
      if (data.profile !== null) {
        setImageUrls(data.profile.photos ?? []);
        if (data.profile.amenities !== null)
          setAmenities(data.profile.amenities);
        if (data.profile.petPolicy !== null) {
          setType(
            data.profile.petPolicy.petsAllowed
              ? "petsAllowed"
              : "petsNotAllowed"
          );
          setPetPolicy(data.profile.petPolicy);
        }
        if (data.profile.feePolicy !== null)
          setFeePolicy(data.profile.feePolicy);
        if (data.profile.lateFeesDetails !== null)
          setFeeDetails(data.profile.lateFeesDetails);
        if (data.profile.unitAmenities !== null)
          setUnitAmenities(data.profile.unitAmenities);
      }
    });
  }, [buildingId]);

  const onChangeAmenities = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setAmenities((data) => deepUpdate(name, value, data));
  };

  const onChangePetPolicy = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setPetPolicy((data) => deepUpdate(name, value, data));
  };

  const onChangeFeePolicy = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setFeePolicy((data) => deepUpdate(name, value, data));
  };

  const onChangeSelectAll = (update: { name: string; value: boolean }) => {
    const { name, value } = update;

    setSelectAll((data) => deepUpdate(name, value, data));

    setAmenities({
      BIKE_PATHS: value,
      BOATING: value,
      CLUBHOUSE: value,
      CONTROLLED_ACCESS: value,
      COURTYARD: value,
      FITNESS_CENTER: value,
      GOLF_COURSE: value,
      LAUNDRY_FACILITIES: value,
      "PLAYGROUND/PARK": value,
      POOL: value,
      PUBLIC_TRANSPORTATION: value,
      RESERVED_PARKING: value,
      "SPA/JACUZZI": value,
      STORAGE: value,
      TENNIS_COURT: value,
    });
  };

  const [unitAmenities, setUnitAmenities] = useState({
    AIR_CONDITIONING: false,
    "BALCONY/PATIO": false,
    "CABLE/SATELLITE_TV": false,
    CARPETED_FLOORS: false,
    CEILING_FAN: false,
    DISHWASHER: false,
    ELECTRIC_RANGE: false,
    FIREPLACE: false,
    FURNISHED: false,
    GARAGE: false,
    GARBAGE_DISPOSAL: false,
    GAS_RANGE: false,
    HARDWOOD_FLOORS: false,
    "HIGH-SPEED_INTERNET": false,
    INTRUSION_ALARM: false,
    MICROWAVE: false,
    PETS_ALLOWED: false,
    REFRIGERATOR: false,
    UTILITIES_PAID: false,
    VAULTED_CEILINGS: false,
    "WALK-IN_CLOSETS": false,
    "WASHER/DRYER_HK-UP": false,
    "WASHER/DRYER_IN_UNIT": false,
    WATERFRONT_PROPERTY: false,
    WHEELCHAIR_ACCESS: false,
  });

  const [unitSelectAll, unitSetSelectAll] = useState({
    allSelected: false,
  });

  const onChangeUnitSelectAll = (update: { name: string; value: boolean }) => {
    const { name, value } = update;

    unitSetSelectAll((data) => deepUpdate(name, value, data));

    setUnitAmenities({
      AIR_CONDITIONING: value,
      "BALCONY/PATIO": value,
      "CABLE/SATELLITE_TV": value,
      CARPETED_FLOORS: value,
      CEILING_FAN: value,
      DISHWASHER: value,
      ELECTRIC_RANGE: value,
      FIREPLACE: value,
      FURNISHED: value,
      GARAGE: value,
      GARBAGE_DISPOSAL: value,
      GAS_RANGE: value,
      HARDWOOD_FLOORS: value,
      "HIGH-SPEED_INTERNET": value,
      INTRUSION_ALARM: value,
      MICROWAVE: value,
      PETS_ALLOWED: value,
      REFRIGERATOR: value,
      UTILITIES_PAID: value,
      VAULTED_CEILINGS: value,
      "WALK-IN_CLOSETS": value,
      "WASHER/DRYER_HK-UP": value,
      "WASHER/DRYER_IN_UNIT": value,
      WATERFRONT_PROPERTY: value,
      WHEELCHAIR_ACCESS: value,
    });
  };

  const uploadImage = (data: any) => {
    alertInfo("Uploading Image");
    fileUpload(data)
      .then((res) => {
        console.log("Success!", res.value);
        setImageUrls([...imageUrls, res.value]);
        alertInfo("Image Uploaded, Please save before leaving this page");
      })
      .catch((failure) => {
        console.log("Failure", failure);
      });
  };

  const onDelete = (image: string) => {
    const filteredImages = imageUrls.filter((item) => item !== image);
    setImageUrls(filteredImages);
  };

  const onChangeUnitAmenities = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setUnitAmenities((data) => deepUpdate(name, value, data));
  };

  const handleChooseImage = (event: { target: { files: any } }) => {
    let files = event.target.files;
    Array.from(files).forEach((file) => {
      console.log("MIME:" + file.type);
      let reader = new FileReader();
      reader.onload = uploadEncodedFile(uploadImage, file);
      reader.readAsDataURL(file);
    });
  };

  const onChange = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setFeeDetails((data) => deepUpdate(name, value, data));
  };

  const onSave = () => {
    updateBuilding(buildingId, [
      {
        op: "replace",
        path: "/profile/photos",
        value: imageUrls,
      },
      {
        op: "replace",
        path: "/profile/amenities",
        value: amenities,
      },
      {
        op: "replace",
        path: "/profile/petPolicy",
        value: {
          petsAllowed: type === "petsAllowed",
          largeDogs: petPolicy.largeDogs,
          smallDogs: petPolicy.smallDogs,
          cats: petPolicy.cats,
          description: petPolicy.description,
        },
      },
      {
        op: "replace",
        path: "/profile/feePolicy",
        value: feePolicy,
      },
      {
        op: "replace",
        path: "/profile/lateFeesDetails",
        value: feeDetails,
      },
      {
        op: "replace",
        path: "/profile/unitAmenities",
        value: unitAmenities,
      },
    ])
      .then(() => {
        alertSuccess("Successfully saved property");
      })
      .catch(() => {
        alertError("Error while saving Property");
      });
  };

  return (
    <div className="flex flex-col w-full p-4 items-center justify-center">
      <div className="flex flex-col items-center self-center ">
        <div className="text-2xl font-semibold text-black">Property Info</div>
      </div>
      <div className="flex flex-col items-center self-center my-4 w-3/4">
        <div className="text-xl font-semibold text-black">
          Upload Property Photos
        </div>
        <div className="mt-10">
          <div className="mx-auto max-w-xs">
            <label className="flex w-full cursor-pointer appearance-none items-center justify-center rounded-md border-2 bg-newGray-700 p-6 transition-all hover:border-primary-300">
              <div className="space-y-1 text-center">
                <div className="mx-auto inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100">
                  <ImagePlaceholder />
                </div>
                <div className=" text-xs text-gray-600 font-medium text-primary-500 hover:text-primary-700">
                  Upload images/videos of the property
                </div>
              </div>
              <input
                id="upload_image"
                type="file"
                className="hidden"
                onChange={handleChooseImage}
              />
            </label>
          </div>
          <div className="grid grid-flow-row-dense grid-rows-1 grid-cols-4 mt-3">
            {imageUrls.map((item) => {
              return (
                <div className="flex rounded h-32 w-32 m-2 justify-center items-center overflow-hidden bg-newGray-700 relative">
                  {item.match(/\.(jpeg|jpg|gif|png|webp)$/) ? (
                    <img className="object-scale-down" src={item} alt="" />
                  ) : (
                    <video controls style={{ maxWidth: "100%" }}>
                      <source src={item} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  <button
                    className="absolute top-2 right-2"
                    onClick={() => onDelete(item)}
                  >
                    <CrossIcon />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full justify-center items-center mt-10">
        <div className="text-xl font-semibold mb-4">Pet Policy</div>
        <div className="flex flex-row justify-start items-center mb-4">
          <button
            className="flex flex-col justify-center items-center relative mr-8  p-4 bg-newGray-1400 w-60 h-32"
            onClick={() => setType("petsAllowed")}
          >
            <PetsAllowedIcon className="h-12 w-12" />
            <div className="text-sm text-newGray-800 font-normal">
              Pets Allowed
            </div>
            {type === "petsAllowed" && (
              <div className="flex absolute top-2 right-2">
                <div className="flex items-center justify-center bg-green-400 w-6 h-6  rounded-full">
                  <Tick />
                </div>
              </div>
            )}
          </button>
          <button
            className="flex flex-col justify-center relative  items-center py-4 bg-newGray-1400 w-60 h-32"
            onClick={() => {
              setType("petsNotAllowed");
              setPetPolicy({
                largeDogs: false,
                smallDogs: false,
                cats: false,
                description: petPolicy.description,
              });
            }}
          >
            <PetsNotAllowedIcon className="h-12 w-12" />
            <div className="text-sm text-newGray-800 font-normal">
              Pets Not Allowed
            </div>

            {type === "petsNotAllowed" && (
              <div className="flex absolute top-2 right-2">
                <div className="flex items-center justify-center bg-green-400 w-6 h-6  rounded-full">
                  <Tick />
                </div>
              </div>
            )}
          </button>
        </div>
        {type === "petsAllowed" && (
          <div className="mt-10 w-1/2">
            <MaterialForm
              className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-3 mt-3"
              data={petPolicy}
              onChange={onChangePetPolicy}
              fieldClassName="mr-2"
              renderArray={[
                {
                  name: "largeDogs",
                  inputType: "CHECKBOX",
                  label: "Large Dogs",
                },
                {
                  name: "smallDogs",
                  inputType: "CHECKBOX",
                  label: "Small Dogs",
                },
                {
                  name: "cats",
                  inputType: "CHECKBOX",
                  label: "Cats",
                },
              ]}
            />
          </div>
        )}
        <MaterialForm
          className="mt-6 w-1/2"
          data={petPolicy}
          onChange={onChangePetPolicy}
          fieldClassName="w-full"
          renderArray={[
            { name: "description", variant: "OUTLINED", inputType: "AREA" },
          ]}
        />
      </div>
      <div className="text-xl font-semibold text-black mt-8">Fee Policy</div>

      <div className="w-1/2 ">
        <MaterialForm
          className="flex justify-evenly w-full  mt-3"
          data={feePolicy}
          onChange={onChangeFeePolicy}
          fieldClassName="mr-2"
          renderArray={[
            {
              name: "oneTimeFee",
              variant: "OUTLINED",
              inputType: "NUMBER",
              label: "One Time Fee($)",
            },
            {
              name: "monthlyPetFee",
              variant: "OUTLINED",
              inputType: "NUMBER",
              label: "Monthly Pet fee($)",
            },
          ]}
        />
      </div>
      <div className="text-xl font-semibold text-black mt-8">
        Late Fee Details
      </div>
      <div className="my-4">
        <MaterialForm
          className="w-full flex flex-row "
          data={feeDetails}
          onChange={onChange}
          fieldClassName="mr-4"
          renderArray={[
            {
              name: "daysAfterDueDate",
              variant: "OUTLINED",
              inputType: "NUMBER",
              label: "Days Overdue Trigger",
            },
            {
              name: "frequency",
              inputType: "SELECT",
              variant: "OUTLINED",

              options: [
                { label: "One-Time", value: "One-Time" },
                { label: "Daily", value: "Daily" },
                { label: "Weekly", value: "Weekly" },
                { label: "Bi-Weekly", value: "Bi-Weekly" },
                { label: "Monthly", value: "Monthly" },
              ],
            },
            {
              name: "type",
              variant: "OUTLINED",
              label: "Fee Type",
            },
            {
              name: "amount",
              inputType: "NUMBER",
              variant: "OUTLINED",
              label: "Amount ($)",
            },
          ]}
        />
      </div>
      <div className="text-xl font-semibold text-black mt-8">
        Property Amenities
      </div>
      <div className="">
        <MaterialForm
          className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-3 mt-3"
          data={selectAll}
          onChange={onChangeSelectAll}
          fieldClassName="mr-2"
          renderArray={[
            {
              name: "allSelected",
              inputType: "CHECKBOX",
              label: "Select All",
            },
          ]}
        />

        <div className="mt-10 ">
          <MaterialForm
            className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-3 mt-3"
            data={amenities}
            onChange={onChangeAmenities}
            fieldClassName="mr-2"
            renderArray={[
              {
                name: "BIKE_PATHS",
                inputType: "CHECKBOX",
                label: "Bike Paths",
              },
              {
                name: "BOATING",
                inputType: "CHECKBOX",
                label: "Boating",
              },
              {
                name: "CLUBHOUSE",
                inputType: "CHECKBOX",
                label: "Clubhouse",
              },
              {
                name: "CONTROLLED_ACCESS",
                inputType: "CHECKBOX",
                label: "Controlled Access",
              },
              {
                name: "COURTYARD",
                inputType: "CHECKBOX",
                label: "Courtyard",
              },
              {
                name: "FITNESS_CENTER",
                inputType: "CHECKBOX",
                label: "Fitness Center",
              },
              {
                name: "GOLF_COURSE",
                inputType: "CHECKBOX",
                label: "Golf Course",
              },
              {
                name: "LAUNDRY_FACILITIES",
                inputType: "CHECKBOX",
                label: "Laundry Facilities",
              },
              {
                name: "PLAYGROUND/PARK",
                inputType: "CHECKBOX",
                label: "Playground/Park",
              },
              {
                name: "POOL",
                inputType: "CHECKBOX",
                label: "Pool",
              },
              {
                name: "PUBLIC_TRANSPORTATION",
                inputType: "CHECKBOX",
                label: "Public Transportation",
              },
              {
                name: "RESERVED_PARKING",
                inputType: "CHECKBOX",
                label: "Reserved Parking",
              },
              {
                name: "SPA/JACUZZI",
                inputType: "CHECKBOX",
                label: "Spa/Jacuzzi",
              },
              {
                name: "STORAGE",
                inputType: "CHECKBOX",
                label: "Storage",
              },
              {
                name: "TENNIS_COURT",
                inputType: "CHECKBOX",
                label: "Tennis Court",
              },
            ]}
          />
        </div>
      </div>
      <div className="text-xl font-semibold text-black mt-8">
        Unit Amenities
      </div>

      <div className="">
        <MaterialForm
          className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-3 mt-3"
          data={unitSelectAll}
          onChange={onChangeUnitSelectAll}
          fieldClassName="mr-2"
          renderArray={[
            {
              name: "allSelected",
              inputType: "CHECKBOX",
              label: "Select All",
            },
          ]}
        />
        <MaterialForm
          className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-3 mt-3"
          data={unitAmenities}
          onChange={onChangeUnitAmenities}
          fieldClassName="mr-2"
          renderArray={amenitiesForm()}
        />
      </div>
      <div className="flex flex-row self-end mt-10 mr-4 ">
        <button
          className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
            bg-gray-300
            text-gray-800 duration-200 ease-in-out 
            transition"
          onClick={() => {}}
        >
          Cancel
        </button>
        <button
          className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
            bg-newBlue-400  w-40
            text-white border-newBlue-400
            border"
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </div>
  );
}
