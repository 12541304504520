import { useRef, useState } from "react";

import Multiselect from "multiselect-react-dropdown";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { updateVendor } from "../../api/Api";
import { getSubCategory } from "../../components/associates/CreateAssociate";
import { CrossIcon } from "../../components/common/AppIcons";
import { properString } from "../../utils/StringUtils";
import { Skill } from "./ListSkills";

type EditSkillsProps = {
  vendorId: number;
  selectedSkill: Skill;
  skills: Skill[];
  isEdit: boolean;
  setEdit: React.Dispatch<
    React.SetStateAction<{
      display: boolean;
      skill: string;
      vendorId: number;
      isEdit: boolean;
    }>
  >;
  onSuccess: () => void;
};

type ListValue = {
  label: string;
  value: string;
};

export default function EditSkills({
  selectedSkill,
  vendorId,
  setEdit,
  skills,
  onSuccess,
  isEdit,
}: EditSkillsProps) {
  const [skill, setSkill] = useState<Skill>(selectedSkill);

  const subCategories = (skill: string) => {
    return [...getSubCategory(skill)];
  };

  const multiRef = useRef<Multiselect>(null);

  const handleSubmit = (value: ListValue[]) => {
    const temp = value.map((item) => item.value);

    setSkill({ skill: skill.skill, subCategories: temp });
  };

  const handleChange = (value: any) => {
    skill.skill = value;
    setSkill({ skill: skill.skill, subCategories: [] });
  };

  const getCategories = () => {
    const currentSkills = skills;
    const filteredSkill = currentSkills.filter(
      (item) => item.skill !== selectedSkill.skill
    );
    return [...filteredSkill, skill];
  };

  const getSelectedValues = () => {
    return skill?.subCategories?.map((item) => {
      return { label: properString(item), value: item };
    });
  };

  const skillOptions = [
    { label: "Handyman", value: "HANDYMAN" },
    { label: "Electrician", value: "ELECTRICIAN" },
    { label: "Plumber", value: "PLUMBER" },
    { label: "Specialist", value: "SPECIALIST" },
  ];

  return (
    <div className=" border-b-4 border-newGray-700 pb-4 mt-2 w-96 ">
      <div className="font-semibold text-newGray text-xl leading-8 h-6  mt-3">
        {`${isEdit ? "Edit " : "New "}`}Category
      </div>
      {!isEdit ? (
        <>
          <div className="font-medium text-newGray text-base leading-8 h-6  mt-3">
            Category
          </div>

          <div className="w-full flex-1 mr-4">
            <select
              className="w-full bg-white my-2 p-1 flex border border-newGray-700 rounded"
              value={skill.skill}
              name="skill"
              onChange={(input) => handleChange(input.target.value)}
            >
              <option selected>Select...</option>
              {skillOptions.map((item) => {
                if (!skills.find((data) => data.skill === item.value))
                  return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </div>
        </>
      ) : (
        <>
          <div className="font-medium text-newGray text-base leading-8 h-6  mt-3">
            Category
          </div>

          <div className="font-normal text-newGray text-base leading-8 h-6  mt-3">
            {properString(skill.skill)}
          </div>
        </>
      )}
      <div className="font-medium text-newGray text-base leading-8 h-6  mt-3">
        Sub Category
      </div>
      <div className="w-full flex-1 mr-4 ">
        <Multiselect
          ref={multiRef}
          key={skill.skill}
          options={subCategories(skill.skill)}
          selectedValues={getSelectedValues()}
          className="w-full mt-4"
          displayValue="label"
          showCheckbox
          onSelect={(selected) => {
            handleSubmit(selected);
          }}
          onRemove={(selected) => {
            handleSubmit(selected);
          }}
          customCloseIcon={<CrossIcon />}
          avoidHighlightFirstOption
          style={{
            optionContainer: {
              height: 200,
            },
            chips: {
              background: "rgba(240, 242, 251, 1)",
              justifyContent: "space-between",
              color: "rgba(18, 37, 157, 1)",
              borderRadius: 5,
            },
          }}
        />
      </div>
      <div
        className={`${
          multiRef.current?.isVisible ? "mt-52" : "mt-4"
        }  flex flex-row-reverse mt-4 w-full`}
      >
        <button
          className="text-base  ml-4  flex justify-center items-center px-4 py-2 rounded font-medium  
          bg-newBlue-400 
          text-white border-newBlue-400
          border"
          onClick={() => {
            updateVendor(vendorId, [
              {
                op: "replace",
                path: "/categories",
                value: getCategories(),
              },
            ])
              .then(() => {
                alertSuccess("Category updated successfully");
                setEdit({
                  display: false,
                  vendorId: vendorId,
                  skill: "",
                  isEdit: false,
                });
                onSuccess();
              })
              .catch(() => {
                alertError("Error while updating Category");
              });
          }}
        >
          {isEdit ? "Update" : "Create"}
        </button>
        <button
          className="text-base flex justify-center items-center px-4 py-2 rounded font-medium cursor-pointer 
          bg-white
          text-newBlue-400 border-newBlue-400
          border duration-200 ease-in-out 
          transition"
          onClick={() => {
            setEdit({
              display: false,
              vendorId: vendorId,
              skill: "",
              isEdit: false,
            });
            onSuccess();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
