import { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import { alertInfo } from "../../../actions/AlertActions";
import {
  getBuildingManagersNewApi,
  getBuildingUnits,
  unitsWithResidents,
  getMyVendor,
} from "../../../api/Api";
import { SearchIcon } from "../../../components/clients/ClientAppIcons";
import RenderUnits from "../../../components/clients/RenderUnits";
import HeadlessFilterSelect from "../../../components/common/HeadlessFilterSelect";
import { globalComponentStateAtom } from "../../../store/GlobalComponent";

const fetchUnitData = (buildingId, callback) => {
  // Convert Units, Residents, and Managers into a dictionary of {[unitNumber]: [...residents, ...managers]}
  unitsWithResidents(buildingId)
    .then((unitContacts) => {
      getBuildingManagersNewApi(buildingId)
        .then((managerData) => {
          callback((_data) => {
            const reducerBase = managerData.reduce((acc, managerBase) => {
              const manager = {
                ...managerBase,
                position: "MANAGER",
              };
              const unitNumberKey = manager.unitNumber || "Building";

              // Hide Building Level Managers
              if (unitNumberKey === "Building") {
                return acc;
              }

              if (acc[unitNumberKey])
                return {
                  ...acc,
                  [unitNumberKey]: [...acc[unitNumberKey], manager],
                };
              else return { ...acc, [unitNumberKey]: [manager] };
            }, {});
            return Object.entries(unitContacts).reduce(
              (acc, [unitNumber, residents]) => {
                const unitNumberKey =
                  unitNumber === "" ? "Building" : unitNumber;
                if (acc[unitNumberKey])
                  return {
                    ...acc,
                    [unitNumberKey]: [...residents, ...acc[unitNumberKey]],
                  };
                else return { ...acc, [unitNumberKey]: residents };
              },
              reducerBase
            );
          });
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

const fetchBuildingUnits = (buildingId, callback) => {
  getBuildingUnits(buildingId)
    .then((units) => {
      callback(units.value);
    })
    .catch((err) => {
      console.log(err);
    });
};

const addEmptyString = (arr) => {
  if (arr.includes("")) return arr;
  return ["", ...arr];
};

export default function UnitsAndResidents({
  buildingId,
  buildingData,
  refreshCB,
}) {
  const [unitContacts, setUnitContacts] = useState([]);
  const [buildingUnits, setBuildingUnits] = useState([]);
  const [vendorType, setVendorType] = useState("");

  const [_, setGlobalComponentState] = useRecoilState(globalComponentStateAtom);

  useEffect(() => {
    getMyVendor().then((data) => {
      setVendorType(data.type);
    });
  }, []);

  useEffect(() => {
    setUnitContacts({});
    fetchUnitData(buildingId, setUnitContacts);
    fetchBuildingUnits(buildingId, setBuildingUnits);
  }, [buildingId]);

  const filteredUnits = Object.keys(unitContacts).filter((item) => {
    if (buildingData.unitNumbers.length === 0) return true;
    if (buildingData.unitNumbers.includes("")) return true;
    return addEmptyString(buildingData.unitNumbers).some((unit) => {
      if (unit === "") return item === "Building";
      return item === unit;
    });
  });

  return (
    <>
      <div className="flex flex-row justify-between items-center py-4">
        <div>
          <span>Units & Residents</span>
          {/* Add Resident Button */}
          {vendorType === "CONTRACTOR" && (
            <button
              className="ml-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => {
                setGlobalComponentState((state) => ({
                  ...state,
                  contactData: {
                    buildingId: buildingId,
                    streetAddress1: buildingData.address.streetAddress1,
                  },
                  contactSuccessCB: () => {
                    fetchUnitData(buildingId, setUnitContacts);
                    refreshCB();
                    setGlobalComponentState((state) => ({
                      ...state,
                      contactData: undefined,
                      contactSuccessCB: undefined,
                    }));
                  },
                }));
              }}
            >
              Add Resident
            </button>
          )}
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div>
            <HeadlessFilterSelect
              value={""}
              onChange={(e) => {
                alertInfo(
                  "This feature is not yet available in this version of SquadHub"
                );
              }}
              options={[
                { title: "Show All", value: "" },
                { title: "Under Management", value: "UNDER_MANAGEMENT" },
                {
                  title: "Not Under Management",
                  value: "NOT_UNDER_MANAGEMENT",
                },
                { title: "Has an Owner", value: "HAS_OWNER" },
                { title: "Has a Tenant", value: "HAS_TENANT" },
              ]}
              placeholder="Filter by"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between items-center rounded border-gray-300 border">
        {filteredUnits.map((unit) => (
          <RenderUnits
            key={unit}
            name={unit}
            buildingId={buildingId}
            unitContacts={unitContacts[unit]}
            buildingUnits={buildingUnits}
            refreshCB={() => {
              fetchUnitData(buildingId, setUnitContacts);
              refreshCB();
            }}
          />
        ))}
      </div>
    </>
  );
}
