import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { TimesCircleIcon } from '../../clients/ClientAppIcons'

export default function Slideover({
    open,
    onClose,
    children
}: {
    open: boolean
    onClose: () => void
    children: React.ReactNode
}) {

    return (
        <Transition.Root show={open} as={Fragment} unmount={false}>
            <Dialog as="div" className="relative z-10" onClose={onClose} unmount={false}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed bottom-0 flex max-w-full">
                            <Transition.Child
                                as={Fragment}
                                unmount={false}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-y-full"
                                enterTo="translate-y-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-y-0"
                                leaveTo="translate-y-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen">

                                    <div className="flex h-112 flex-col overflow-y-auto bg-white py-6 shadow-xl">
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">{children}</div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
