import { useEffect, useState } from "react";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import {
  bulkCreateZipCodes,
  createZipCode,
  deleteAllZipCodes,
  deleteZipCode,
  getZipCodes,
} from "../../api/Api";
import {
  CrossIcon,
  DownloadIcon,
  UploadIcon,
} from "../../components/common/AppIcons";
import { properString } from "../../utils/StringUtils";
import ZipCodeFile from "../vendors/utils/ZipCodes.csv";

type EditTerritoryProps = {
  territoryId: number;
  territoryName: string;
};

type ZipData = {
  id: number;
  code: number;
  territoryId: number;
};

export default function EditTerritory({
  territoryId,
  territoryName,
}: EditTerritoryProps) {
  const [zipCodes, setZipCodes] = useState<string[]>([]);
  const [upload, setUpload] = useState(false);
  const [file, setFile] = useState();

  const [code, setCode] = useState("");
  useEffect(() => {
    getZipCodes(territoryId).then((data) => {
      const dataZips = data.map((item: ZipData) => item.code);
      setZipCodes(dataZips);
    });
  }, [territoryId]);

  const addZipCode = (code: string) => {
    if (code.length === 5 && /^\d+$/.test(code)) {
      createZipCode(territoryId, { code: code })
        .then(() => {
          alertSuccess("Successfully added Zip code");
          getZipCodes(territoryId).then((data) => {
            const dataZips = data.map((item: ZipData) => item.code);
            setZipCodes(dataZips);
          });
          setCode("");
        })
        .catch(() => alertError("Error while creating Zip code"));
    } else alertError("Please enter a valid Zip code");
  };

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const fileReader = new FileReader();
  const deleteZip = (code: string) => {
    deleteZipCode(territoryId, code)
      .then(() => {
        const temp = zipCodes;
        const removedList = temp.filter((item) => item !== code);
        setZipCodes(removedList);
        alertSuccess("Deleted Zip code");
      })
      .catch(() => alertError("Error while deleting Zip code"));
  };

  const getRows = (length: number) => {
    const count = length / 4;
    return Math.ceil(count);
  };

  const csvFileToArray = (string: string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const header = csvHeader.map((item) =>
      item.split("\r")[0].toLocaleLowerCase()
    );

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = header.reduce((object, header, index) => {
        object[header] = values[index].split("\r")[0];
        return object;
      }, {});
      return obj;
    });

    const csvItems: number[] = [];
    array.forEach((item) => {
      csvItems.push(Number(item.zipcode));
    });

    bulkCreateZipCodes(territoryId, { zipcodes: csvItems })
      .then(() => {
        getZipCodes(territoryId).then((data) => {
          const dataZips = data.map((item: ZipData) => item.code);
          setZipCodes(dataZips);
        });

        alertSuccess("Successfully created Zip codes");
      })
      .catch(() => alertError("Error while creating Zip codes"));
  };

  const handleOnSubmit = (e) => {
    setUpload(false);
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        csvFileToArray(csvOutput);
      };
      const temp = fileReader.readAsText(file);
    }
  };

  const deleteAllZips = () => {
    deleteAllZipCodes(territoryId)
      .then(() => {
        getZipCodes(territoryId).then((data) => {
          const dataZips = data.map((item: ZipData) => item.code);
          setZipCodes(dataZips);
        });

        alertSuccess("Successfully deleted Zip codes");
      })
      .catch(() => alertError("Error while deleting Zip codes"));
  };

  return (
    <div>
      <div className="flex flex-row justify-start ">
        <a
          href={ZipCodeFile}
          download="ZipCodes"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="flex w-52 min-w-max items-center justify-center text-sm rounded border border-newGray-800 px-2 h-12 mr-4">
            <DownloadIcon />
            <div className="ml-2">Download CSV Template</div>
          </button>
        </a>
        {upload ? (
          <div className="flex flex-col  ml-20">
            <input
              type={"file"}
              id={"csvFileInput"}
              accept={".csv"}
              onChange={handleOnChange}
            ></input>
            <button
              className="flex w-20 items-center justify-center rounded border border-newBlue-400 mt-2 text-newBlue-400 text-md font-medium"
              onClick={handleOnSubmit}
            >
              Submit
            </button>
          </div>
        ) : (
          <button
            className="flex w-32 items-center text-sm justify-between rounded border border-newGray-800 h-12 px-2"
            onClick={() => setUpload(true)}
          >
            <UploadIcon />
            Upload CSV
          </button>
        )}
      </div>
      <div>
        <div className="flex flex-row justify-between items-center mt-3">
          <div className="font-medium text-newGray text-base leading-8 h-6">
            Territory
          </div>
          <button
            className="rounded border font-normal text-sm text-newGray-800 border-newGray-800 px-2 focus:outline-none"
            onClick={() => deleteAllZips()}
          >
            Delete All Zipcodes
          </button>
        </div>
        <div className="font-normal text-newGray text-sm leading-8 h-6  mt-3">
          {properString(territoryName)}
        </div>
        <div className="font-medium text-newGray text-base leading-8 h-6  mt-3">
          Zipcodes
        </div>
        <div className="w-full flex-1 mr-4 ">
          <div className="bg-white my-2 p-1 flex border border-newGray-700 rounded ">
            <input
              className="p-1 px-2 appearance-none outline-none w-full text-newGray-800"
              name="zipCode"
              type="text"
              value={code}
              onChange={(input) => setCode(input.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  addZipCode(code);
                }
              }}
            />
          </div>
          <div
            className={`grid grid-flow-row-dense grid-rows-${getRows(
              zipCodes.length
            )} grid-cols-4 mt-3`}
          >
            {zipCodes.map((item) => (
              <div className="flex flex-row  justify-between bg-newBlue-500 m-1 p-1 rounded">
                <div className=" text-sm text-newBlue-400 font-normal">
                  {item}
                </div>
                <button
                  className="focus:outline-none"
                  onClick={() => {
                    deleteZip(item);
                  }}
                >
                  <CrossIcon />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
