import React from "react";

import CommentIcon from "@mui/icons-material/Comment";
import { Avatar, Badge } from "@mui/material";
import { Typography } from "@mui/material";
import { Link, navigate } from "raviger";

import { startAssociateCall } from "../../actions/VoiceActions";
import { MessageIcon, TelePhone } from "../common/AppIcons";
import PopOverText from "../common/PopOverText";
import { JobTypesDict } from "../common/form/constants";

const PopOverContent = ({ associate, currentJob = [] }) => {
  return (
    <div className="flex w-full bg-white p-3 max-w-sm flex-col">
      <div className="flex items-center w-full text-sm">
        <div className="text-gray-900 flex w-full items-center font-medium ">
          <Avatar className="w-8 h-8" src={associate.profilePic}>
            {associate.firstName[0]}
          </Avatar>

          <span className="px-2 font-semibold text-gray-700">
            {associate.firstName + " " + associate.lastName}
          </span>

          <div className="flex ml-auto pl-8 font-semibold text-gray-700">
            {/* SR #{associate.serviceRequestId} */}
          </div>
        </div>
      </div>
      <div className="flex mt-2 pl-1 gap-2">
        <Link
          href={`/associates/${associate.associateId}/messages`}
          className="outline-none focus:outline-none"
        >
          <CommentIcon className="text-lg text-gray-700" />
        </Link>
      </div>

      <div className="flex flex-col w-full">
        {currentJob.length > 0 && (
          <Typography className="my-2 font-semibold" variant="caption">
            Current Job
          </Typography>
        )}
        {currentJob.length > 0 &&
          currentJob.map((el) => (
            <span className="text-xs text-gray-600">{el.description}</span>
          ))}
        <Typography className="my-2 font-semibold" variant="caption">
          Categories
        </Typography>
        <span className="text-xs text-gray-600">{associate.skill}</span>
      </div>
    </div>
  );
};

export default function AssociateTab({ associate, currentJob }) {
  return (
    <td
      className="px-2 group py-1 border-b border-r sticky left-0 bg-white z-10 whitespace-nowrap cursor-pointer border-newGray-500 items-center"
      colSpan="6"
    >
      <PopOverText
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="text-sm text-gray-700"
        text={<PopOverContent associate={associate} currentJob={currentJob} />}
      >
        <div className="flex items-center">
          <Link
            href={`/associates/${associate.associateId}/details`}
            className="flex  gap-2"
          >
            <Badge
              // Bottom Left
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              // Do not Transform
              overlap="circular"
              badgeContent={associate.finishedAppointmentCount}
              color="primary"
            >
              <Avatar className="h-8 w-8" src={associate.profilePic}>
                {associate.firstName[0]}
              </Avatar>
            </Badge>
            <div className="flex flex-col">
              <span
                className="text-normal text-black my-auto"
                style={{ fontWeight: 500 }}
              >{`${associate.firstName} ${associate.lastName}`}</span>
              <span className="text-xs pt-1  text-newGray-300 ">
                {JobTypesDict[associate.skill]}
              </span>
            </div>
          </Link>
          <div
            className="ml-auto bg-white flex flex-row "
            onClick={() => {
              navigate(`associates/${associate.associateId}/messages`);
            }}
          >
            <div className="flex">
              <MessageIcon className="text-base" />
            </div>
            <div
              className="flex px-3"
              onClick={() => {
                startAssociateCall({
                  number: associate.phone,
                });
              }}
            >
              <TelePhone className="text-base" />
            </div>
          </div>
        </div>
      </PopOverText>
    </td>
  );
}
