import { useEffect, useState } from "react";



import { useAppActions } from "../../../../../actions/AppActions";
import {
  getAllProspects,
  getLeaseById,
  getUnitById,
  setListingId,
} from "../../../../../api/Api";
import { AddIconBlue } from "../../../../../components/common/AppIcons";
import MaterialModal from "../../../../../components/common/MaterialModal";
import MaterialTable from "../../../../../components/common/MaterialTable";
import { properString } from "../../../../../utils/StringUtils";
import NewProspect from "./NewProspect";


type ApplicationListingProps = {
  unitId: number;
  leaseId?: number;
  setCurrentApplicationTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrentProspect: React.Dispatch<React.SetStateAction<number>>;
  setScreeningTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
};

type ProspectData = {
  id: number;
  firstName: string;
  lastName: string;
  screeningStatus: string;
  type: string;
  incomeDetails: {
    employmentDetails: {
      monthlyIncome: number;
    }[];
  };
  miscellaneous: {
    pets: {
      name: string;
    }[];
  };
};

export function ApplicationListing({
  setCurrentApplicationTab,
  setCurrentProspect,
  unitId,
  leaseId,
  setScreeningTab,
  setCurrentTab,
}: ApplicationListingProps) {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const [tableData, setTableData] = useState([]);

  const [ticketId, setTicketId] = useState(0);
  const { withConfirmation } = useAppActions();

  useEffect(() => {
    if (leaseId !== undefined && leaseId !== null && leaseId !== 0) {
      getLeaseById(leaseId).then((lease) => {
        if (lease.listingId !== null) {
          setTicketId(lease.listingId);
          getAllProspects(page, size, lease.listingId).then((data) => {
            setTableData(data.content);
            setTotalElements(data.totalElements);
          });
        } else {
          withConfirmation({
            title: "Confirmation",
            description: `Would you like to list this Unit and proceed with screening process`,
            onConfirm: () => {
              setListingId(leaseId).then(() => {
                getLeaseById(leaseId).then((lease) => {
                  if (lease.listingId !== null) {
                    setTicketId(lease.listingId);
                  }
                });
                setNewProspect(true);
              });
            },
            onClose: () => {
              setCurrentTab("rentLeaseDetails");
            },
          });
        }
      });
    } else
      getUnitById(unitId).then((data) => {
        if (data.currentLeaseId !== null) {
          getLeaseById(data.currentLeaseId).then((lease) => {
            if (lease.listingId !== null) {
              setTicketId(lease.listingId);
              getAllProspects(page, size, lease.listingId).then((data) => {
                setTableData(data.content);
                setTotalElements(data.totalElements);
              });
            } else {
              withConfirmation({
                title: "Confirmation",
                description: `Would you like to list this Unit and proceed with screening process`,
                onConfirm: () => {
                  setListingId(data.currentLeaseId).then(() => {
                    getLeaseById(data.currentLeaseId).then((lease) => {
                      if (lease.listingId !== null) {
                        setTicketId(lease.listingId);
                      }
                    });
                    setNewProspect(true);
                  });
                },
                onClose: () => {
                  setCurrentTab("rentLeaseDetails");
                },
              });
            }
          });
        }
      });
  }, [unitId, page, size, leaseId]);

  const getTableHead = () => {
    return [
      { id: "rank", label: "Serial No." },
      { id: "name", label: "Name" },
      { id: "type", label: "Type" },
      { id: "salary", label: "Salary" },
      { id: "pets", label: "Pets" },
      { id: "screened", label: "Screened" },
    ];
  };

  const getTableData = () => {
    let data = [];
    data = tableData?.map((item: ProspectData, index) => {
      return {
        id: item.id,
        rowStyle: item.type === "TENANT" ? "bg-green-200" : "",
        data: [
          index + 1,
          {
            render: (
              <div className="">{`${item.firstName} ${item.lastName}`}</div>
            ),
          },
          {
            render: (
              <div className="flex flex-row p-1 px-2 items-center bg-newBlue-500 rounded-full w-max text-sm font-normal text-newBlue-400">
                {item.type ? properString(item.type ?? "") : "Applicant"}
              </div>
            ),
          },
          `$${item?.incomeDetails?.employmentDetails[0]?.monthlyIncome ?? 0}`,
          `${item.miscellaneous?.pets?.length ? "Yes" : "No"}`,
          {
            render: (
              <div
                className={`flex flex-row p-1 px-2 items-center rounded-full w-max text-sm font-normal 
              ${
                item.screeningStatus === "SCREENED"
                  ? " bg-green-300 text-green-700"
                  : "bg-red-200 text-red-600"
              }`}
              >
                {properString(item.screeningStatus ?? "")}
              </div>
            ),
          },
        ],
      };
    });
    return data;
  };

  const [newProspect, setNewProspect] = useState(false);
  return (
    <div className="flex flex-col w-full">
      <button
        className={`${
          tableData.length > 0 ? "self-end" : ""
        }  border w-max h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400`}
        onClick={() => setNewProspect(true)}
      >
        <div className="flex flex-row items-center ">
          <div>
            <AddIconBlue className="h-4 w-4" />
          </div>
          <div className="px-2 flex">
            <div className="font-medium">Add New Prospect</div>
          </div>
        </div>
      </button>

      {tableData.length > 0 && (
        <div className="mt-4">
          <MaterialTable
            data={getTableData()}
            head={getTableHead()}
            headRenderColor="text-newGray-300"
            defaultOrder="desc"
            pageRows={size}
            onClickCB={(selection: number) => {
              setCurrentProspect(selection);
              setCurrentApplicationTab("applicationDetails");
            }}
            totalData={totalElements}
            currentPage={page}
            paginationCB={(value: number) => {
              setPage(value);
            }}
            rowChangeCB={(value: number) => {
              setSize(value);
            }}
            loading={undefined}
            defaultOrderBy={undefined}
            multiSelect={undefined}
            searchText={undefined}
            setSelected={undefined}
          />
        </div>
      )}

      <MaterialModal open={newProspect} setOpen={() => setNewProspect(false)}>
        <NewProspect
          ticketId={ticketId}
          onCloseProspect={() => {
            setNewProspect(false);
          }}
          onSave={() => {
            if (leaseId !== undefined) {
              getLeaseById(leaseId).then((lease) => {
                if (lease.listingId !== null) {
                  setTicketId(lease.listingId);
                  getAllProspects(page, size, lease.listingId).then((data) => {
                    setTableData(data.content);
                    setTotalElements(data.totalElements);
                  });
                }
              });
            }
          }}
        />
      </MaterialModal>
    </div>
  );
}