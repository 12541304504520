import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { navigate } from "raviger";

import { alertError, alertInfo, alertSuccess } from "../actions/AlertActions";
import { useAppActions } from "../actions/AppActions";
import {
  subscribeTopic,
  unsubscribeTopic,
} from "../actions/NotificationActions";
import {
  getCSRs,
  getPipelineTickets,
  getPipelines, // getTicketSRs
  getTerritory, // estimateAccept,
  getTicket,
  moveTicket,
  getCurrentUser,
} from "../api/Api";
import ListProperties from "../components/clients/ListProperties";
import MaterialModal from "../components/common/MaterialModal";
import SelectHeadings from "../components/common/SelectHeading";
import { make as MaterialForm } from "../components/common/form/MaterialForm.bs";
import { fetchTicketContacts } from "../components/pipelines/EstimateForm";
import MarkAsAcceptedModal from "../components/pipelines/MarkAsAcceptedModal";
import Board from "../components/pipelines/NewKanbanBoard";
import CreateSR from "../components/service/CreateSR";
import SelectSupervisor from "../components/settings/SelectSupervisor";
import dispatcher from "../dispatcher";
import ActiveUserStore from "../store/ActiveUserStore";
import { useTitle } from "../utils/RouterUtils";
import { deepUpdate } from "../utils/StateUtils";
import { convertToAscendingDescendingDict } from "../utils/StateUtils";
import { properString } from "../utils/StringUtils";

const loadTickets = (pipelineId, territory, ownerId, callBack) => {
  pipelineId &&
    territory &&
    getPipelineTickets(pipelineId, territory, ownerId)
      .then((data) => {
        // callBack(data);
        callBack(
          convertToAscendingDescendingDict(data, "deadline", "asc", "string")
        );
      })
      .catch((_) => alertError("Couldn't Load Tickets"));
};

export default function KanbanScreen({ ticketId, showEstimate, invoiceId }) {
  useTitle("Ticket Pipeline | SquadHub");

  console.log({ invoiceId });
  // Used to Store Current Pipeline and Territory

  const [current, setCurrent] = useState({
    territory: "",
    owner: -2,
  });
  const [vendorType, setVendorType] = useState("");
  const isPMC = vendorType === "PMC";
  const [tickets, setTickets] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const [activeStages, setActiveStages] = useState([]);
  const [updateTicket, setUpdateTicket] = useState(ticketId);
  const [pipelines, setPipelines] = useState([]);
  const [selectSupervisorFor, setSelectSupervisorFor] = useState();
  const [serviceRequest, setServiceRequest] = useState();
  const [activeUser, setActiveUser] = useState({});

  const [headings, setHeadings] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [wonDrag, setWonDrag] = useState("");
  const [ticketContacts, setTicketContacts] = useState([]);
  const [markCompletedData, setMarkCompletedData] = useState({});

  const [ticketData, setTicketData] = useState(null);
  const [estimateAcceptModal, setEstimateAcceptModal] = useState(false);

  const [territories, setTerritories] = useState([]);

  const [supervisors, setSupervisors] = useState([]);

  const { openTicket, createSimpleTicket, openInvoice } = useAppActions();

  useEffect(() => {
    if (territories.length > 0) {
      setCurrent((prev) => ({
        ...prev,
        territory: territories[0]?.value,
      }));
    }
  }, [territories]);

  const handleAction = (action) => {
    action.actionType === "NOTIFICATION" &&
      action.topic.includes("/sales") &&
      loadTickets(
        current.pipelineId,
        current.territory,
        current.owner,
        setTickets
      );
  };

  useEffect(() => {
    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;
    getCurrentUser().then((data) => {
      subscribeTopic({
        topic: `/notifications/${data.vendorId}/sales`,
      });
      setVendorType(data.vendor.type);
      return () =>
        unsubscribeTopic({
          topic: `/notifications/${data.vendorId}/sales`,
        });
    });
  }, []);

  useEffect(() => {
    let filterData = [];

    let initFiltered = tickets;

    if (searchText) {
      var refinedSearch = searchText.toLowerCase();

      filterData = initFiltered.filter((ticket) => {
        if (ticket.ticketId.toString().includes(refinedSearch)) {
          return true;
        }
        if (ticket.title.toLowerCase().includes(refinedSearch)) {
          return true;
        }
        if (ticket.clientName.toLowerCase().includes(refinedSearch)) {
          return true;
        }
      });

      setFiltered(filterData);
    } else {
      setFiltered(initFiltered);
    }
  }, [searchText]);

  const move = (ticketId, stageId, owner) => {
    alertInfo("Moving Ticket");
    moveTicket({ ticketId, stageId, owner })
      .then(() => {
        loadTickets(
          current.pipelineId,
          current.territory,
          current.owner,
          setTickets
        );
        alertSuccess("Ticket Moved");
      })
      .catch((err) => {
        if (
          err.readableMessage ===
          "Cannot move Ticket without SRs or Estimate to Closed Won!"
        ) {
          const ticket = tickets.find((t) => t.ticketId === Number(ticketId));
          setServiceRequest({
            ...ticket,
            ...ticket.ticketDetails,
          });
        } else if (
          err.readableMessage === "Please Mark the Estimate as Accepted first."
        ) {
          alertError(err.readableMessage);
          fetchTicketContacts(ticketId, setTicketContacts);
          // get the ticket data
          getTicket(ticketId).then((data) => {
            setTicketData(data);
            setEstimateAcceptModal(true);
          });
          // set state to render estimate
        } else if (err.readableMessage) {
          alertError(err.readableMessage);
        } else {
          alertError("Transition not allowed");
        }
      });
  };

  useEffect(() => {
    setActiveUser(ActiveUserStore.getActiveUser());
    ActiveUserStore.on("change", () => {
      setActiveUser(ActiveUserStore.getActiveUser());
    });
    getTerritory().then((data) => {
      let temp = [];
      data.map((item) => {
        temp.push({
          value: item.name,
          label: properString(item.name),
        });
      });
      setTerritories(temp);
    });
  }, []);

  useEffect(() => {
    console.log("Effect Triggered");
    if (ticketId) {
      openTicket(ticketId, () => {
        loadTickets(
          current.pipelineId,
          current.territory,
          current.owner,
          setTickets
        );
      });
    }

    setFiltered(tickets);
  }, [tickets, ticketId]);

  useEffect(() => {
    if (invoiceId) {
      openInvoice(invoiceId);
    }
  }, [invoiceId]);

  useEffect(() => {
    let head = [];
    pipelines
      .sort((a, b) => a.level - b.level)
      .map((pipeline) => {
        head.push({ label: pipeline.pipelineType, value: pipeline.pipelineId });
      });
    setHeadings(head);
  }, [pipelines]);

  useEffect(() => {
    current &&
      current.pipelineId &&
      loadTickets(
        current.pipelineId,
        current.territory,
        current.owner,
        setTickets
      );
  }, [current.territory, current.owner, current.pipelineId]);

  const pipelineLoad = () => {
    getPipelines().then((pipelines) => {
      setPipelines(pipelines);
      //isFinal not being received from BE
      setActiveStages(
        pipelines.reduce((acc, cur) => {
          return [
            ...acc,
            ...cur.stages
              .filter((stage) => !stage.isFinal)
              .map((stage) => stage.stageId),
          ];
        }, [])
      );
      const defaultPipeline = pipelines[0];
      setCurrent((current) => ({
        ...current,
        pipelineId: defaultPipeline.pipelineId,
        pipelineType: defaultPipeline.pipelineType,
        stages: defaultPipeline.stages,
      }));
    });
  };

  useEffect(() => {
    pipelineLoad();
  }, []);

  useEffect(() => {
    getCSRs().then((data) => {
      supervisors.push(
        { value: -2, label: "Anyone" },
        { value: -1, label: "Unassigned" }
      );
      data.map((item) => {
        supervisors.push({
          value: item.id,
          label: `${item.firstName} ${item.lastName}`,
        });
      });
    });
  }, []);

  const isAdmin = ActiveUserStore.isSuperAdvisor();

  const onDragEnd = (result) => {
    console.log(JSON.stringify(result, null));
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    const ticket = tickets.find(
      (t) => t.ticketId === Number(result.draggableId)
    );
    const targetStage = current.stages.find(
      (board) => board.stageId === Number(dInd)
    );
    if (sInd === dInd) {
      // const items = reorder(state[sInd], source.index, destination.index);
      // const newState = [...state];
      // newState[sInd] = items;
      // setState(newState);
    } else if (
      targetStage.triggerSelectors &&
      targetStage.triggerSelectors.includes("ASSIGN_TO_SUPERVISOR") &&
      !ticket.isOwnerBySupervisor
    ) {
      setSelectSupervisorFor({
        ...ticket,
        item: result.draggableId,
        toBoard: dInd,
      });
    } else {
      move(result.draggableId, dInd);

      if (targetStage.stage === "Closed Won") {
        setWonDrag({
          draggableId: result.draggableId,
          dInd: dInd,
        });
      }

      if (targetStage.action === "SERVICE_REQUEST") {
        const ticket = tickets.find(
          (t) => t.ticketId === Number(result.draggableId)
        );

        // setServiceRequest({
        //   ...ticket,
        //   ...ticket.ticketDetails
        // });
      }

      if (targetStage.stage === "Site Visit Required") {
        const ticket = tickets.find(
          (t) => t.ticketId === Number(result.draggableId)
        );

        setServiceRequest({
          jobType: "SITE_VISIT",
          ...ticket,
          ...ticket.ticketDetails,
        });
      }
    }
  };

  return (
    <div>
      {/* <AppBar
        position="static"
        className="bg-gray-100 text-gray-700  rounded-t-md"
      > */}

      {/* </AppBar> */}
      <div className="bg-white p-2 px-6 w-full">
        <br />
        <SelectHeadings
          gap={20} //in px. optional
          headings={headings} //shown below
          selected={current.pipelineId} //which tab is selected
          onChange={(value) => {
            console.log(value);
            setCurrent((current) => {
              const pipeline = pipelines.find(
                (p) => p.pipelineId === Number(value)
              );
              return {
                ...current,
                pipelineId: pipeline.pipelineId,
                pipelineType: pipeline.pipelineType,
                stages: pipeline.stages,
              };
            });
          }}
          width_array={[180, 180, 180, 180, 180, 180, 180]} //optional. can use this to give each heading it's own size
          className="relative top-px flex flex-grow w-full" //optional
        />

        <div className="flex justify-end mt-2">
          {/* Create New Ticket button */}
          {current.pipelineType !== "Vacant Unit" && (
            <button
              onClick={() => {
                createSimpleTicket();
              }}
              className="text-white bg-newBlue-400 rounded h-12 px-2 py-1.5"
            >
              <div className="flex flex-row items-center">
                <div>
                  <svg
                    className="h-4 w-4"
                    viewBox="0 0 12 12"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.25 6.75H6.75V11.25H5.25V6.75H0.75V5.25H5.25V0.75H6.75V5.25H11.25V6.75Z"></path>
                  </svg>
                </div>
                <div className="flex gap-1 px-2">
                  <span className="hidden md:inline">Add</span>
                  <span className="">New</span>
                  <span className="hidden md:inline">Ticket</span>
                </div>
              </div>
            </button>
          )}

          <div className="flex-row py-3 px-2">
            <div className="input-group flex flex-wrap items-stretch">
              <input
                type="search"
                className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="button-addon2"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="flex-row">
            <MaterialForm
              className="flex gap-2 w-96"
              data={current ?? {}}
              renderArray={
                isAdmin || isPMC
                  ? [
                      {
                        name: "owner",
                        label: "Owner",
                        inputType: "SELECT",
                        options: supervisors,
                      },
                      {
                        name: "territory",
                        label: "Territory",
                        inputType: "SELECT",
                        options: [
                          ...(territories ?? []),
                          { value: "UNSET", label: "Unspecified" },
                        ],
                      },
                    ]
                  : [
                      {
                        name: "territory",
                        label: "Territory",
                        inputType: "SELECT",
                        options: [
                          ...(territories ?? []),
                          { value: "UNSET", label: "Unspecified" },
                        ],
                      },
                    ]
              }
              onSubmit={(_) => {}}
              onChange={({ name, value }) => {
                setCurrent((current) => {
                  return deepUpdate(name, value, current);
                });
              }}
            />
          </div>
        </div>
        <div className="flex overflow-auto">
          <DragDropContext onDragEnd={onDragEnd}>
            {current?.stages
              ?.sort((a, b) => a.level - b.level)
              ?.map((board) => (
                <Droppable
                  key={`${board.stageId}`}
                  droppableId={`${board.stageId}`}
                >
                  {(provided, snapshot) => (
                    <Board
                      territory={current.territory}
                      provided={provided}
                      snapshot={snapshot}
                      board={board}
                      data={filtered}
                      pipeline={current.pipelineType}
                      updateTicket={(ticket) => {
                        if (current.pipelineType === "Vacant Unit") {
                          navigate(
                            `/building/${ticket.buildingId}/units/${ticket.unitId}/lease/${ticket.leaseId}/screening`
                          );
                        } else openTicket(ticket.ticketId);
                      }}
                      pipelineLoad={pipelineLoad}
                    />
                  )}
                </Droppable>
              ))}
          </DragDropContext>
        </div>
      </div>
      {selectSupervisorFor && (
        <MaterialModal
          open={selectSupervisorFor !== undefined}
          setOpen={(_) => setSelectSupervisorFor()}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <SelectSupervisor
            baseData={{ user: selectSupervisorFor.owner }}
            territory={current.territory}
            onSubmitCB={(userId) => {
              move(
                selectSupervisorFor.item,
                selectSupervisorFor.toBoard,
                userId
              );
              setSelectSupervisorFor();
            }}
          />
        </MaterialModal>
      )}
      <MaterialModal
        open={serviceRequest !== undefined}
        setOpen={(_) => setServiceRequest()}
        label="new-user-modal"
        describedby="create-new-user"
      >
        {serviceRequest &&
          (serviceRequest.buildingId ? (
            <CreateSR
              baseData={serviceRequest}
              successCB={(_) => {
                if (wonDrag) move(wonDrag.draggableId, wonDrag.dInd);
                setServiceRequest();
              }}
            />
          ) : (
            <ListProperties
              clientId={serviceRequest.clientId}
              selectProperty={(buildingId) =>
                setServiceRequest({
                  ...serviceRequest,
                  buildingId: Number(buildingId),
                })
              }
            />
          ))}
      </MaterialModal>

      {estimateAcceptModal && (
        <MarkAsAcceptedModal
          visibleVar={estimateAcceptModal}
          clearCB={() => {
            setEstimateAcceptModal(false);
          }}
          data={ticketData}
        />
      )}
    </div>
  );
}

//TODO

//render component while moving enable multi option when opened

//enable multiClick when mark as accepted

//call the endpoint with new payload

//accepted name and date changes
