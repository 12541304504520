import { Fragment } from "react";

import { VendorStages } from "./CreateVendor";

type StepperComponentProps = {
  stages: VendorStages;
};

const MAX_STAGES = 4;

const computeWidthClassName = (stages: any[]) => {
  switch (stages.length) {
    case 1:
      return "w-1/4";
    case 2:
      return "w-1/2";
    case 3:
      return "w-3/4";
    default:
      return "w-full";
  }
};

export default function StepperComponent({ stages }: StepperComponentProps) {
  const renderStages = stages.filter((stage) => stage.display);
  return (
    <div className="flex justify-center mx-4 p-4">
      <div
        className={"flex items-center " + computeWidthClassName(renderStages)}
      >
        {renderStages.map((step, index) => {
          return (
            <Fragment key={index}>
              <div key={index} className="relative">
                <div
                  className={`flex justify-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 ${
                    step.active
                      ? "bg-newBlue-400 text-white"
                      : "bg-newGray-700 text-newGray-800"
                  }`}
                >
                  {index + 1}
                </div>
                <div
                  className={`absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium ${
                    step.active ? "text-black" : "text-newGray-800"
                  }`}
                >
                  {step.name}
                </div>
              </div>
              {index < renderStages.length - 1 && (
                <div
                  className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                    step.active ? "border-newBlue-400" : "border-newGray-700"
                  }`}
                ></div>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

// Archived Component

//   <div
//     className={`flex justify-center  rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 ${
//       stages.stage1
//         ? "bg-newBlue-400 text-white"
//         : "bg-newGray-700 text-newGray-800"
//     }`}
//   >
//     1
//   </div>
//   <div
//     className={`absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium ${
//       stages.stage1 ? "text-black" : "text-newGray-800"
//     }`}
//   >
//     Subscriber Details
//   </div>
// </div>
// <div
//   className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
//     stages.stage2 ? "border-newBlue-400" : "border-newGray-700"
//   }`}
// ></div>
// <div className="flex items-center text-white relative">
//   <div
//     className={`flex justify-center  rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 ${
//       stages.stage2
//         ? "bg-newBlue-400 text-white"
//         : "bg-newGray-700 text-newGray-800"
//     }`}
//   >
//     2
//   </div>
//   <div
//     className={`absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium ${
//       stages.stage2 ? "text-black" : "text-newGray-800"
//     }`}
//   >
//     Add Category
//   </div>
// </div>
// <div
//   className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
//     stages.stage3 ? "border-newBlue-400" : "border-newGray-700"
//   }`}
// ></div>
// <div className="flex items-center text-newGray-800 relative">
//   <div
//     className={`flex justify-center  rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 ${
//       stages.stage3
//         ? "bg-newBlue-400 text-white"
//         : "bg-newGray-700 text-newGray-800"
//     }`}
//   >
//     3
//   </div>
//   <div
//     className={`absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium ${
//       stages.stage3 ? "text-black" : "text-newGray-800"
//     }`}
//   >
//     Add Zipcodes
//   </div>
// </div>
// <div
//   className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
//     stages.stage4 ? "border-newBlue-400" : "border-newGray-700"
//   }`}
// ></div>
// <div className="flex items-center text-newGray-800 relative">
//   <div
//     className={`flex justify-center  rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 ${
//       stages.stage4
//         ? "bg-newBlue-400 text-white"
//         : "bg-newGray-700 text-newGray-800"
//     }`}
//   >
//     4
//   </div>
//   <div
//     className={`absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium ${
//       stages.stage4 ? "text-black" : "text-newGray-800"
//     }`}
//   >
//     Admin Setup
//   </div>
// </div>
