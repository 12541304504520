import { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";

export interface HeadlessDropdownObjectOption {
  key?: string;
  label: string;
  value: string;
}

export interface HeadlessDropdownCustomObjectOption {
  key?: string;
  label: React.ReactNode;
  value: string;
}

export type HeadlessDropdownSimpleOption = string;

export type HeadlessDropdownOption =
  | HeadlessDropdownSimpleOption
  | HeadlessDropdownObjectOption
  | HeadlessDropdownCustomObjectOption;

const getAlignClass = (align?: string) => {
  switch (align) {
    case "bottom":
      return "origin-bottom-right bottom-0";
    default:
      return "origin-top-right right-0";
  }
};

export default function HeadlessDropdown({
  className,
  value,
  align,
  options,
  onChange,
  children,
}: {
  className?: string;
  value: string;
  align?: "top" | "bottom";
  options: HeadlessDropdownOption[];
  onChange: (value: string) => void;
  children: React.ReactNode;
}) {
  return (
    <Menu as="div" className={`relative inline-block text-left ${className}`}>
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          {children}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            "absolute mt-2 w-56 divide-y divide-gray-100 rounded-md" +
            getAlignClass(align) +
            " bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
          }
        >
          {options.map((option) => {
            const active = option === value;
            let optionKey: string;
            let renderOption: string | React.ReactNode;
            let optionValue: string;
            if (typeof option === "object") {
              optionKey = option.key || option.value;
              renderOption = option.label;
              optionValue = option.value;
            } else {
              optionKey = option;
              renderOption = option;
              optionValue = option;
            }
            return (
              <div key={optionKey} className="px-1 py-1 ">
                <Menu.Item>
                  <button
                    className={`${
                      active
                        ? "bg-indigo-600 text-white"
                        : "text-gray-900 hover:bg-indigo-300 hover:text-white"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => onChange(optionValue)}
                  >
                    {renderOption}
                  </button>
                </Menu.Item>
              </div>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
