import Multiselect from "multiselect-react-dropdown";



import { getSubCategory } from "../../components/associates/CreateAssociate";
import { AddIconBlue, CrossIcon } from "../../components/common/AppIcons";
import { properString } from "../../utils/StringUtils";
import { VendorStages } from "./CreateVendor";

type Category = {
  skill: string;
  subCategory: string;
  subCategories: string[];
};

type AddCategoryProps = {
  nextStage: () => void;
  prevStage: () => void;
  category: Category[];
  setCategory: React.Dispatch<React.SetStateAction<Category[]>>;
};

type ListValue = {
  label: string;
  value: string;
};

export default function AddCategory({
  category,
  setCategory,
  nextStage,
  prevStage,
}: AddCategoryProps) {
  const onAddCategory = () => {
    setCategory([
      ...category,
      { skill: "", subCategories: [], subCategory: "" },
    ]);
  };

  const onDeleteSkill = (index: number) => {
    const rows = [...category];
    rows.splice(index, 1);
    setCategory(rows);
  };

  const handleChange = (index: number, name: string, value: any) => {
    const list = [...category];
    list[index][name] = value;
    list[index]["subCategories"] = [];

    setCategory(list);
  };

  console.log({ category });

  const handleSubmit = (index: number, value: ListValue[]) => {
    const list = [...category];

    const temp = value.map((item) => item.value);

    list[index]["subCategories"] = temp;
    setCategory(list);
  };

  const subCategories = (skill: string) => {
    return [...getSubCategory(skill)];
  };

  const getSelectedValues = (index: number) => {
    return category[index]["subCategories"].map((item) => {
      return { label: properString(item), value: item };
    });
  };

  const skillOptions = [
    { label: "Handyman", value: "HANDYMAN" },
    { label: "Electrician", value: "ELECTRICIAN" },
    { label: "Plumber", value: "PLUMBER" },
    { label: "Specialist", value: "SPECIALIST" },
  ];

  return (
    <div className="mt-20 mx-20">
      <div className="">
        {category.map((data, index) => {
          return (
            <div
              className="border-b-4 border-newGray-700 pb-4 mt-2"
              key={index}
            >
              <div className="flex flex-row justify-between items-center">
                <div className="font-medium text-newGray text-base leading-8 h-6  mt-3">
                  Category
                </div>
                <button
                  className="rounded border border-newGray-800 px-2 focus:outline-none"
                  onClick={() => onDeleteSkill(index)}
                >
                  Delete
                </button>
              </div>
              <div className="w-full flex-1 mr-4">
                <select
                  className="w-full bg-white my-2 p-1 flex border border-newGray-700 rounded"
                  value={category[index]["skill"]}
                  name="skill"
                  onChange={(input) =>
                    handleChange(index, input.target.name, input.target.value)
                  }
                >
                  <option selected>Select...</option>
                  {skillOptions.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
              </div>

              <div className="font-medium text-newGray text-base leading-8 h-6  mt-3">
                Sub Category
              </div>
              <div className="w-full flex-1 mr-4">
                <Multiselect
                  key={data.skill}
                  options={subCategories(data.skill)}
                  selectedValues={getSelectedValues(index)}
                  className="w-full mt-4"
                  displayValue="label"
                  showCheckbox
                  avoidHighlightFirstOption
                  onSelect={(selected) => {
                    handleSubmit(index, selected);
                  }}
                  onRemove={(selected) => {
                    handleSubmit(index, selected);
                    console.log(selected);
                  }}
                  customCloseIcon={<CrossIcon />}
                  style={{
                    chips: {
                      background: "rgba(240, 242, 251, 1)",
                      justifyContent: "space-between",
                      color: "rgba(18, 37, 157, 1)",
                      borderRadius: 5,
                    },
                    optionContainer: {
                      boxShadow: "1px 2px 9px #cbd5e0",
                    },
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <button
        className="border rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 mt-8"
        onClick={() => {
          onAddCategory();
        }}
      >
        <div className="flex flex-row items-center w-36">
          <div>
            <AddIconBlue className="h-4 w-4" />
          </div>
          <div className="px-2 flex">
            <div className="font-medium">Add Another Category</div>
          </div>
        </div>
      </button>
      <div className="my-2 text-sm font-normal text-gray-700">
        Note:Adding Categories is optional for Management Companies, you can
        submit the form without setting up Categories{" "}
      </div>
      <div className="flex-auto flex flex-row-reverse mt-28">
        <button
          className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
          bg-newBlue-400  w-80
          text-white border-newBlue-400
          border"
          onClick={() => nextStage()}
        >
          Continue
        </button>
        <button
          className="text-base flex justify-center items-center px-4 py-2 rounded font-medium cursor-pointer 
          bg-white
          text-newBlue-400 border-newBlue-400
          border duration-200 ease-in-out 
          transition"
          onClick={() => prevStage()}
        >
          Go Back
        </button>
      </div>
    </div>
  );
}