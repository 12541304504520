import React, { useEffect, useState } from "react";

import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { alertError } from "../../actions/AlertActions";
import { clientTickets, getClient, getPipelines } from "../../api/Api";
import { renderAddress } from "../../utils/StringUtils";
import MaterialModal from "../common/MaterialModal";
import MaterialTable from "../common/MaterialTable";
import SRTicketFilter from "../common/SRTicketFilter";
import NewCreateTicket from "../pipelines/NewCreateTicket";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function ListTickets({
  clientId,
  data,
  buildings,
  defaultBuilding,
  onClickCB,
  newTicket = true,
}) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [updateTicket, setUpdateTicket] = useState();
  const [tickets, setTickets] = useState(data || []);
  const [pipelines, setPipelines] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState();
  const [selectedEstimateStatus, setSelectedEstimateStatus] = useState();

  useEffect(() => {
    console.log("building filter changed");
  }, [selectedBuilding]);

  useEffect(() => {
    getPipelines().then((pipelines) => {
      setPipelines(pipelines);
    });
  }, []);

  const buildingChangeCB = (buildingId, propertyName) => {
    setSelectedBuilding({ id: buildingId, name: propertyName });
  };
  const estimateChangeCB = (status) => {
    setSelectedEstimateStatus(status);
  };

  const fetchTickets = (clientId, setTickets) => {
    clientTickets(clientId)
      .then((tickets) => {
        setTickets(tickets);
        fetchBuildings(clientId);
      })
      .catch((_) => alertError("Couldn't Populate Tickets"));
  };

  const fetchBuildings = (clientId) => {
    getClient(clientId)
      .then((client) =>
        setTickets((tickets) =>
          tickets.map((ticket) => {
            let address = "-";
            if (ticket.buildingId)
              renderAddress(
                client.buildings.find(
                  (building) => building.buildingId === ticket.buildingId
                ).address
              );
            return {
              ...ticket,
              address: address,
            };
          })
        )
      )
      .catch((_) => alertError("Couldn't Populate Ticket Addresses"));
  };

  const EstimateStatus = {
    NOT_SENT: "Not Sent",
    ACCEPTED: "Accepted",

    UNSENT: "Unsent",
    SENT: "Sent",
    PARTIALLY_ACCEPTED: "Partially Accepted",
    REJECTED: "Rejected",
  };

  useEffect(() => {
    if (defaultBuilding) {
      let building = buildings.find((building) => {
        if (building.buildingId === defaultBuilding) {
          return building;
        }
      });
      if (building)
        setSelectedBuilding({
          name:
            building.address.streetAddress1 +
            " " +
            (building.address.streetAddress2
              ? building.address.streetAddress2
              : ""),
          id: building.buildingId,
        });
    }
  }, []);

  useEffect(() => {
    fetchTickets(clientId, setTickets);
  }, [clientId]);

  const getFilteredData = () => {
    let data = [];

    tickets
      ?.filter((ticket) => {
        //if there is no filter

        if (!selectedBuilding?.id && !selectedEstimateStatus) {
          return true;
        } else if (selectedBuilding && !selectedEstimateStatus) {
          //only building is selected and estimate status is not selected
          return ticket.buildingId === selectedBuilding.id;
        } else if (!selectedBuilding?.id && selectedEstimateStatus) {
          //when only the estimate status is selected

          return (
            ticket.estimatestatus ===
            (selectedEstimateStatus === "NULL" ? null : selectedEstimateStatus)
          );
        } else if (selectedBuilding && selectedEstimateStatus) {
          //when both the filters are selected

          return (
            ticket.buildingId === selectedBuilding.id &&
            ticket.estimatestatus ===
              (selectedEstimateStatus === "NULL"
                ? null
                : selectedEstimateStatus)
          );
        }

        // ticket.buildingId === parseInt(selectedBuilding.id);
      })
      .map((row) => {
        data.push({
          id: row.ticketId,
          data: [
            `T${row.ticketId}`,
            row.title,

            row.estimatestatus
              ? EstimateStatus[row.estimatestatus]
              : "No Estimate",
            row.address,
            `${
              pipelines.find(
                (pipeline) => pipeline.pipelineId === row.pipelineId
              )?.pipelineType
            }`,
            {
              render: `${new Date(row.createdAt).toLocaleString()}`,
              sortBy: new Date(row.createdAt).getTime(),
            },
          ],
        });
      });
    //   }
    // }
    return data;
  };

  return (
    <div
      className={classes.root + " max-w-6xl mx-auto w-full gap-2 flex-col p-2"}
    >
      <div className="flex items-center gap-2 cursor-pointer">
        <TextField
          id="search"
          className="flex-1"
          label="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          variant="outlined"
        />
        {newTicket && (
          <div onClick={(_) => setOpenModal(true)}>
            <AddCircleOutlineOutlined /> New Ticket
          </div>
        )}

        {buildings && (
          <SRTicketFilter
            buildings={buildings || []}
            defaultBuilding={defaultBuilding}
            selectedBuilding={selectedBuilding}
            selectedEstimateStatus={selectedEstimateStatus}
            buildingChangeCB={buildingChangeCB}
            estimateChangeCB={estimateChangeCB}
            display={["buildings", "estimate_status"]}
          />
        )}
      </div>

      <MaterialTable
        data={getFilteredData()}
        head={[
          { id: "ticketId", label: "Ticket Number" },
          { id: "title", label: "Title" },
          // { id: "description", label: "Description" },
          { id: "estimatestatus", label: "Estimate Status" },

          { id: "address", label: "Address" },
          { id: "pipeline", label: "Pipeline" },
          { id: "createdAt", label: "Created At" },
        ]}
        defaultOrderBy={"createdAt"}
        defaultOrder={"desc"}
        onClickCB={(ticketId) => {
          const ticketData = tickets.find(
            (ticket) => ticket.ticketId === ticketId
          );
          console.log("Ticket Data", ticketData);
          onClickCB ? onClickCB(ticketData) : setUpdateTicket(ticketData);
        }}
        searchText={searchText}
      />
      <MaterialModal
        open={openModal}
        setOpen={setOpenModal}
        containerClassName="md:w-8/12 max-h-screen overflow-y-auto"
      >
        <NewCreateTicket
          baseData={{ clientId }}
          onSuccessCB={(closeModal = true) => {
            closeModal && setOpenModal(false);
            fetchTickets(clientId, setTickets);
          }}
        />
      </MaterialModal>

      {updateTicket && (
        <MaterialModal
          open={updateTicket !== undefined}
          setOpen={(_) => {
            setUpdateTicket();
          }}
          containerClassName="md:w-8/12 max-h-screen overflow-y-auto"
        >
          <NewCreateTicket
            baseData={updateTicket}
            onSuccessCB={(closeModal = true) => {
              closeModal && setUpdateTicket();
              fetchTickets(clientId, setTickets);
            }}
          />
        </MaterialModal>
      )}
    </div>
  );
}
