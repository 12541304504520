import React, { useEffect, useState } from "react";

export default function DropDown({ options, onChangeCB, defaultValue }) {
  const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    setSelected(defaultValue ? defaultValue : options[0]);
  }, [defaultValue]);

  return (
    <div className="p-1">
      <div className="group inline-block relative">
        <button className="bg-gray-300 text-gray-700 font-semibold py-1.5 px-4 rounded inline-flex items-center">
          <span className="mr-1 text-sm">{selected.channel}</span>
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </button>
        <ul className="absolute hidden text-gray-700 group-hover:block">
          {options.map((option, _index) => (
            <li
              key={option.channel}
              className=""
              onClick={(_) => {
                onChangeCB(option.channel);
                setSelected(option);
              }}
            >
              <span
                className="rounded-t bg-gray-200 hover:bg-gray-400 py-1 px-4 block whitespace-no-wrap"
                id={option.channel}
              >
                {option.channel}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
