import React, { useEffect, useState } from "react";

import { Button, Typography } from "@mui/material";

import { alertError } from "../../actions/AlertActions";
import { schedule } from "../../api/Actions";
import { timeAfterNHours } from "../../utils/DateUtils";
import { getDateStep30 } from "../../utils/DateUtils";
import { deepUpdate } from "../../utils/StateUtils";
import { skillFilter } from "../associates/SkillFilter.bs";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { associateTypes } from "../common/form/constants";

// import moment from "moment";

export default function ScheduleForm({
  onSuccessCB,
  onCancelCB,
  baseData,
  associates,
  duration,
  jobType,
  setEditAppointment,
  materials,
}) {
  const [newSchedule, setNewSchedule] = useState({
    ...baseData,
  });

  useEffect(() => {
    if (materials > 0) {
      setNewSchedule((current) => {
        return {
          ...current,
          appointmentType: baseData.appointmentType
            ? baseData.appointmentType
            : "PICKUP_AND_SERVICE",
        };
      });
    }
  }, [baseData]);

  const onChange = (update) => {
    const { name, value } = update;
    if (name === "startTime") {
      setNewSchedule((data) => {
        const intermediate = deepUpdate(name, new Date(value), data);
        var datetime = new Date(value);
        datetime.setHours(datetime.getHours() + 2);
        return deepUpdate("endTime", datetime, intermediate);
      });
    }

    setNewSchedule((data) => deepUpdate(name, value, data));
  };

  useEffect(() => {
    setNewSchedule((current) => {
      return {
        ...current,
        startTime: baseData.startTime
          ? new Date(baseData.startTime)
          : getDateStep30(new Date()),
        endTime: baseData.endTime
          ? new Date(baseData.endTime)
          : getDateStep30(timeAfterNHours(new Date(), 2)),
      };
    });
  }, []);

  return (
    <div className="w-full lg:w-64">
      <Typography variant="h6">Schedule</Typography>

      <MaterialForm
        data={newSchedule}
        renderArray={[
          ...(materials > 0
            ? [
                {
                  name: "appointmentType",
                  inputType: "SELECT",
                  defaultValue:
                    baseData.appointmentType || "PICKUP_AND_SERVICE",
                  options: [
                    {
                      label: "Pickup and Service",
                      value: "PICKUP_AND_SERVICE",
                    },
                    { label: "Pickup", value: "PICKUP" },
                    { label: "Service", value: "SERVICE" },
                  ],
                },
              ]
            : []),
          {
            name: "associateId",
            inputType: "SELECT",
            variant: "MULTISELECT",
            options:
              jobType === "SITE_VISIT"
                ? associates.map((associate) => {
                    return {
                      label: `${associate.firstName} ${associate.lastName}`,
                      value: associate.associateId,
                      groupBy: associate.type,
                    };
                  })
                : associates
                    ?.filter((associate) => {
                      return (
                        skillFilter(associate.skill, jobType) ||
                        baseData.associateId.includes(associate.associateId)
                      );
                    })
                    ?.map((associate) => {
                      const associateType = associateTypes.find(
                        (associateType) =>
                          associateType.value === associate.type
                      );
                      return {
                        label: `${associate.firstName} ${associate.lastName}`,
                        value: associate.associateId,
                        tags: associate.subCategory,
                        groupBy: associateType?.label,
                        sortBy: associateType?.sortBy,
                      };
                    })
                    ?.sort((a, b) => {
                      return a.sortBy - b.sortBy;
                    }),
          },
          { name: "description", inputType: "AREA", variant: "OUTLINED" },

          {
            name: "startTime",
            inputType: "DATETIME",
            variant: "STEP30",
            // defaultValue: getDateStep30(new Date())
          },
          {
            name: "endTime",
            inputType: "DATETIME",
            variant: "STEP30",
            // defaultValue: getDateStep30(new Date())
          },
        ]}
        onChange={onChange}
      />
      <div className="relative flex flex-row mx-4">
        <div className="flex flex-row">
          <div className="flex items-center h-5">
            <input
              id="notify-client"
              aria-describedby="notify-client-helper-text"
              name="notify-client"
              type="checkbox"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              checked={newSchedule.shouldNotify === false ? false : true}
              onChange={() => {
                // setNotifyClient((current) => !current);
                setNewSchedule((current) => {
                  return deepUpdate(
                    "shouldNotify",
                    !current.shouldNotify,
                    current
                  );
                });
              }}
            />
          </div>
          <div className="ml-2 text-sm">
            <label
              htmlFor="notify-client"
              className="font-medium text-gray-700 whitespace-nowrap"
            >
              Notify Client
            </label>
          </div>
        </div>
        <div className="px-4 flex flex-row">
          <div className="flex items-center h-5">
            <input
              id="notify-client"
              aria-describedby="notify-client-helper-text"
              name="notify-client"
              type="checkbox"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              checked={
                newSchedule.isRemedial === false || !newSchedule.isRemedial
                  ? false
                  : true
              }
              onChange={() => {
                setNewSchedule((current) => {
                  return deepUpdate("isRemedial", !current.isRemedial, current);
                });
              }}
            />
          </div>
          <div className="ml-2 text-sm">
            <label
              htmlFor="notify-client"
              className="font-medium text-gray-700 whitespace-nowrap"
            >
              Is Remedial
            </label>
          </div>
        </div>
      </div>

      {baseData.associateId?.length > 0 ? (
        <>
          <Button
            onClick={(_) => {
              schedule(newSchedule, onSuccessCB);
            }}
            color="primary"
            className="float-right p-4"
          >
            Reschedule
          </Button>
        </>
      ) : (
        <Button
          onClick={(_) => {
            if (newSchedule.associateId?.length === 0) {
              alertError("No Associates Selected");
            } else if (new Date(newSchedule.endTime) < newSchedule.startTime) {
              alertError("End Time Should be Greater than Start Time");
            } else {
              schedule(newSchedule, onSuccessCB);
            }
          }}
          color="primary"
          className="float-right p-4 schedule-button"
        >
          Schedule
        </Button>
      )}
      <Button
        onClick={(_) => onCancelCB()}
        color="primary"
        className="float-right p-4"
      >
        Cancel
      </Button>
    </div>
  );
}
