import { useEffect, useState } from "react";

import Radio from "@mui/material/Radio";

import { alertInfo, alertSuccess } from "../../../../../actions/AlertActions";
import {
  fileUpload,
  getProspectById,
  listUsers,
  updateProspect,
} from "../../../../../api/Api";
import {
  AddIconBlue,
  UploadIcon,
} from "../../../../../components/common/AppIcons";
import MaterialModal from "../../../../../components/common/MaterialModal";
import MaterialForm from "../../../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../../../utils/StateUtils";
import { uploadEncodedFile } from "../../helper";

type ScreeningDetailsProps = {
  prospectId: number;
  setCurrentApplicationTab: React.Dispatch<React.SetStateAction<string>>;
};

type Document = {
  name: string;
  url?: string;
};

export function ScreeningDetails({
  prospectId,
  setCurrentApplicationTab,
}: ScreeningDetailsProps) {
  const [screeningDetails, setScreeningDetails] = useState({
    creditScore: 0,
    residentScore: 0,
    isIdentityVerified: false,
    identityVerifiedNotes: "",
    isIncomeVerified: false,
    incomeVerifiedNotes: "",
    isRentalHistoryVerified: false,
    rentalHistoryVerifiedNotes: "",
    isCriminalCheckVerified: false,
    criminalCheckVerifiedNotes: "",
    isEvictionCheckVerified: false,
    evictionCheckVerifiedNotes: "",
    isApproved: false,
    approvedByUserId: "",
  });

  const [userOptions, setUserOptions] = useState([]);

  const [documents, setDocuments] = useState<Document[]>([]);
  const [newDocument, setNewDocument] = useState({ display: false, name: "" });

  useEffect(() => {
    getProspectById(prospectId).then((data) => {
      if (data.screeningDetails !== null)
        setScreeningDetails(data.screeningDetails);
      setDocuments(data.screeningDetails?.serviceDetails?.documents ?? []);
    });

    listUsers().then((users) => {
      setUserOptions(
        users.map((user: any) => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: `${user.id}`,
          };
        })
      );
    });
  }, [prospectId]);

  const uploadFile = (data: any, index: number) =>
    fileUpload(data).then((res) => {
      console.log("Success!", res.value);
      documents[index].url = res.value;
      setDocuments(documents);
      updateProspect(prospectId, [
        {
          op: "replace",
          path: "/screeningDetails",
          value: {
            ...screeningDetails,
            serviceDetails: {
              documents: documents,
            },
          },
        },
      ]).then((data) => {
        if (
          data.screeningDetails !== null &&
          data.screeningDetails.serviceDetails !== null
        ) {
          setDocuments(data.screeningDetails?.serviceDetails?.documents ?? []);
        }
      });
      alertInfo("File Uploaded");
    });

  const handleFileUpload = (
    event: { target: { files: any } },
    index: number
  ) => {
    let files = event.target.files;
    alertInfo("Uploading File");
    Array.from(files).forEach((file) => {
      console.log("MIME:" + file.type);
      let reader = new FileReader();
      reader.onload = uploadEncodedFile(
        (data) => uploadFile(data, index),
        file
      );
      reader.readAsDataURL(file);
    });
  };

  const handleChange = (value: string | boolean, name: string) => {
    setScreeningDetails((data) => deepUpdate(name, value, data));
  };

  const onChangeInfo = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setScreeningDetails((data) => deepUpdate(name, value, data));
  };

  const onSave = () => {
    updateProspect(prospectId, [
      {
        op: "replace",
        path: "/screeningDetails",
        value: {
          ...screeningDetails,
          serviceDetails: {
            documents: documents,
          },
        },
      },
    ]).then(() => {
      setCurrentApplicationTab("screeningListing");
      alertSuccess("Prospect updated Successfully");
    });
  };

  return (
    <div className="w-full p-4 overflow-hidden">
      <div className="flex flex-col items-start self-center ">
        <div className="w-full">
          <div className="text-xl font-semibold text-gray-900 my-2">
            Manual Tenant Screening
          </div>
          <MaterialForm
            className="flex flex-row mt-4"
            data={screeningDetails}
            onChange={onChangeInfo}
            fieldClassName="w-60 mr-4"
            renderArray={[
              {
                name: "creditScore",
                variant: "OUTLINED",
                inputType: "NUMBER",
              },
              {
                name: "residentScore",
                variant: "OUTLINED",
                label: "Score (Max.5)",
                inputType: "NUMBER",
              },
            ]}
          />
        </div>
        <div className="w-full">
          <div className="text-xl font-semibold text-gray-900 my-2">
            Identity Verification
          </div>
          <div className="flex flex-row items-center mb-2">
            <Radio
              checked={screeningDetails.isIdentityVerified}
              onChange={() => handleChange(true, "isIdentityVerified")}
              value="yes"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <label className="text-sm font-normal mr-10">Pass</label>
            <Radio
              checked={!screeningDetails.isIdentityVerified}
              onChange={() => handleChange(false, "isIdentityVerified")}
              value="no"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              placeholder="test"
            />
            <label className="text-sm font-normal ">Fail</label>
          </div>
          <MaterialForm
            className=""
            data={screeningDetails}
            onChange={onChangeInfo}
            fieldClassName="w-full"
            renderArray={[
              {
                name: "isEverEvictedNotes",
                variant: "OUTLINED",
                inputType: "AREA",
                label: "Notes",
              },
            ]}
          />
        </div>
        <div className="w-full">
          <div className="text-xl font-semibold text-gray-900 my-2">
            Income Verification
          </div>
          <div className="flex flex-row items-center mb-2">
            <Radio
              checked={screeningDetails.isIncomeVerified}
              onChange={() => handleChange(true, "isIncomeVerified")}
              value="yes"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <label className="text-sm font-normal mr-10">Pass</label>
            <Radio
              checked={!screeningDetails.isIncomeVerified}
              onChange={() => handleChange(false, "isIncomeVerified")}
              value="no"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              placeholder="test"
            />
            <label className="text-sm font-normal ">Fail</label>
          </div>
          <MaterialForm
            className=""
            data={screeningDetails}
            onChange={onChangeInfo}
            fieldClassName="w-full"
            renderArray={[
              {
                name: "incomeVerifiedNotes",
                variant: "OUTLINED",
                inputType: "AREA",
                label: "Notes",
              },
            ]}
          />
        </div>
        <div className="w-full">
          <div className="text-xl font-semibold text-gray-900 my-2">
            Rental History
          </div>
          <div className="flex flex-row items-center mb-2">
            <Radio
              checked={screeningDetails.isRentalHistoryVerified}
              onChange={() => handleChange(true, "isRentalHistoryVerified")}
              value="yes"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <label className="text-sm font-normal mr-10">Pass</label>
            <Radio
              checked={!screeningDetails.isRentalHistoryVerified}
              onChange={() => handleChange(false, "isRentalHistoryVerified")}
              value="no"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              placeholder="test"
            />
            <label className="text-sm font-normal ">Fail</label>
          </div>
          <MaterialForm
            className=""
            data={screeningDetails}
            onChange={onChangeInfo}
            fieldClassName="w-full"
            renderArray={[
              {
                name: "rentalHistoryVerifiedNotes",
                variant: "OUTLINED",
                inputType: "AREA",
                label: "Notes",
              },
            ]}
          />
        </div>
        <div className="w-full">
          <div className="text-xl font-semibold text-gray-900 my-2">
            Criminal Check
          </div>
          <div className="flex flex-row items-center mb-2">
            <Radio
              checked={screeningDetails.isCriminalCheckVerified}
              onChange={() => handleChange(true, "isCriminalCheckVerified")}
              value="yes"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <label className="text-sm font-normal mr-10">Pass</label>
            <Radio
              checked={!screeningDetails.isCriminalCheckVerified}
              onChange={() => handleChange(false, "isCriminalCheckVerified")}
              value="no"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              placeholder="test"
            />
            <label className="text-sm font-normal ">Fail</label>
          </div>
          <MaterialForm
            className=""
            data={screeningDetails}
            onChange={onChangeInfo}
            fieldClassName="w-full"
            renderArray={[
              {
                name: "criminalCheckVerifiedNotes",
                variant: "OUTLINED",
                inputType: "AREA",
                label: "Notes",
              },
            ]}
          />
        </div>
        <div className="w-full">
          <div className="text-xl font-semibold text-gray-900 my-2">
            Eviction Check
          </div>
          <div className="flex flex-row items-center mb-2">
            <Radio
              checked={screeningDetails.isEvictionCheckVerified}
              onChange={() => handleChange(true, "isEvictionCheckVerified")}
              value="yes"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <label className="text-sm font-normal mr-10">Pass</label>
            <Radio
              checked={!screeningDetails.isEvictionCheckVerified}
              onChange={() => handleChange(false, "isEvictionCheckVerified")}
              value="no"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              placeholder="test"
            />
            <label className="text-sm font-normal ">Fail</label>
          </div>
          <MaterialForm
            className=""
            data={screeningDetails}
            onChange={onChangeInfo}
            fieldClassName="w-full"
            renderArray={[
              {
                name: "EvictionCheckVerifiedNotes",
                variant: "OUTLINED",
                inputType: "AREA",
                label: "Notes",
              },
            ]}
          />
        </div>
        <div>
          <div className="text-lg font-medium text-black my-4">
            Add Documents
          </div>

          {documents?.map((item, index) => {
            return (
              <div className="flex flex-row justify-between w-144 rounded border px-4 py-2 my-2 border-newGray-700">
                <div className="text-base font-medium text-black">
                  {item?.name}
                </div>
                {item.url === undefined || item.url === null ? (
                  <label className="flex  cursor-pointer bg-newBlue-500 items-center justify-center rounded border border-dashed border-newBlue-400 transition-all px-2 py-1">
                    <UploadIcon />
                    <div className="space-1 text-center">
                      <div className=" text-xs text-newBlue-400 font-medium  ">
                        Upload
                      </div>
                    </div>
                    <input
                      id="upload_image"
                      type="file"
                      className="hidden"
                      onChange={(event) => handleFileUpload(event, index)}
                    />
                  </label>
                ) : (
                  <div className="flex flex-row">
                    <div className=" px-2 py-1 bg-green-200 text-green-700 text-xs font-medium rounded">
                      <a href={item.url} target="_blank" rel="noreferrer">
                        View
                      </a>
                    </div>
                    <label className="flex  cursor-pointer bg-yellow-200 items-center justify-center rounded transition-all px-2 py-1 ml-2">
                      <div className="space-1 text-center">
                        <div className=" text-xs text-yellow-800 font-medium  ">
                          Upload revision
                        </div>
                      </div>
                      <input
                        id="upload_image"
                        type="file"
                        className="hidden"
                        onChange={(event) => handleFileUpload(event, index)}
                      />
                    </label>
                  </div>
                )}
              </div>
            );
          })}

          <button
            className="border w-max h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 my-4"
            onClick={() => setNewDocument({ display: true, name: "" })}
          >
            <div className="flex flex-row items-center ">
              <div>
                <AddIconBlue className="h-4 w-4" />
              </div>
              <div className="px-2 flex">
                <div className="font-medium">Create New Document</div>
              </div>
            </div>
          </button>
          <div className="text-lg font-medium text-black mt-4">Disclaimer</div>
          <div className="text-xs textGray-800 my-4">
            Rorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
            turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
            nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
            tellus elit sed risus. Maecenas eget condimentum velit, sit amet
            feugiat lectus. Class aptent taciti sociosqu ad litora torquent per
            conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
            enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex.
            Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum
            lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in
            elementum tellus.
          </div>
        </div>
        <div className="w-full">
          <MaterialForm
            className="flex flex-row mt-4"
            data={screeningDetails}
            onChange={onChangeInfo}
            fieldClassName="w-60"
            renderArray={[
              {
                name: "isApproved",
                variant: "OUTLINED",
                inputType: "CHECKBOX",
                label: "Approved By",
              },
              {
                name: "approvedByUserId",
                variant: "OUTLINED",
                inputType: "SELECT",
                options: userOptions,
                label: "Select User",
              },
            ]}
          />
        </div>
        <div className="flex flex-row self-end mt-4 mr-4 ">
          <button
            className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
            bg-gray-300
            text-gray-800 duration-200 ease-in-out 
            transition"
            onClick={() => setCurrentApplicationTab("screeningListing")}
          >
            Cancel
          </button>
          <button
            className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
            bg-newBlue-400  w-40
            text-white border-newBlue-400
            border"
            onClick={onSave}
          >
            Approve
          </button>
        </div>
      </div>
      <MaterialModal
        open={newDocument.display}
        setOpen={() => setNewDocument({ display: false, name: "" })}
      >
        <div className="flex flex-col items-end w-80">
          <MaterialForm
            className="w-80"
            data={newDocument}
            renderArray={[
              {
                name: "name",
                variant: "OUTLINED",
              },
            ]}
            onChange={(update: { name: string; value: string }) => {
              const { value } = update;
              setNewDocument({ display: true, name: value });
            }}
          />
          <button
            className="text-base mr-2  flex justify-center items-center px-4 py-2 rounded font-medium  
        bg-newBlue-400  w-40
        text-white border-newBlue-400
        border mt-4"
            onClick={() => {
              setDocuments([
                ...documents,
                { name: newDocument.name, url: undefined },
              ]);
              setNewDocument({ display: false, name: "" });
            }}
          >
            Save
          </button>
        </div>
      </MaterialModal>
    </div>
  );
}
