import { useEffect, useState } from "react";

import moment from "moment";

import { alertError } from "../../../actions/AlertActions";
import { getUnits } from "../../../api/Api";
import { AddIconBlue, SearchIcon } from "../../../components/common/AppIcons";
import MaterialTable from "../../../components/common/MaterialTable";
import MaterialForm from "../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../utils/StateUtils";

type UnitListingProps = {
  onTabChange: React.Dispatch<React.SetStateAction<string>>;
  setSelectedUnit: React.Dispatch<React.SetStateAction<number>>;
  buildingId: number;
};

type Deposit = {
  category: string;
  amount: number;
  description: string;
};

type UnitsResponse = {
  id: number;
  unitNumber: string;
  buildingId: number;
  photos: string[];
  status: string;
  unitDetails: {
    marketRent: number;
    unitSize: number;
    beds: number;
    bath: number;
    description: string;
    overridePetPolicy: boolean;
    overrideFeePolicy: boolean;
  };
  currentLease: {
    endDate: string;
    startDate: string;
    leaseTerms: number;
    otherTenants: [];
    primaryTenant: {};
    deposits: Deposit[];
    rentDetails: {
      amount: number;
    };
  };
};

export default function UnitListing({
  onTabChange,
  setSelectedUnit,
  buildingId,
}: UnitListingProps) {
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [filter, setFilter] = useState({
    selectedFilter: "ALL",
  });

  useEffect(() => {
    var filterText = "";
    const today = moment().format("YYYY-MM-DD");
    if (filter.selectedFilter === "EXPIRED")
      filterText = `&leaseExpiryBefore=${today}`;
    if (filter.selectedFilter === "ACTIVE")
      filterText = `&leaseExpiryAfter=${today}`;

    getUnits(page, size, buildingId, searchText, filterText)
      .then((data) => {
        setTableData(data.content);
        setTotalElements(data.totalElements);
      })
      .catch((_) =>
        alertError("Couldn't Connect to Server, Check your Connection")
      );
  }, [page, size, searchText, buildingId, filter.selectedFilter]);

  const getDateColor = (date: string) => {
    var today = new Date();

    const myDate = new Date(date);
    if (myDate > today) return " bg-green-200 text-green-800";
    else if (myDate < today) return "bg-red-100 text-red-500";
    else return " bg-gray-200 text-black";
  };

  const filterOptions = [
    { label: "All", value: "ALL" },
    { label: "Expired", value: "EXPIRED" },
    { label: "Active", value: "ACTIVE" },
  ];

  const getTableHead = () => {
    return [
      { id: "unitNumber", label: "Unit Number" },
      { id: "status", label: "Status" },
      { id: "tenants", label: "Tenants" },
      { id: "leaseExpiry", label: "Lease Expiry" },
      { id: "rent", label: "Rent" },
      { id: "term", label: "Term" },
      { id: "deposit", label: "Deposit" },
    ];
  };
  const onChangeFilter = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setFilter((data) => deepUpdate(name, value, data));
  };

  const getDeposit = (deposits: Deposit[]) => {
    var totalDeposit = 0;
    deposits.forEach((item) => (totalDeposit += item.amount));
    return totalDeposit;
  };

  const getTableData = () => {
    let data = [];
    data = tableData?.map((item: UnitsResponse) => {
      return {
        id: item.id,
        data: [
          {
            render: (
              <div className="flex flex-row p-1 items-center bg-newBlue-500 rounded w-max text-sm font-normal">
                {item.unitNumber}
              </div>
            ),
          },
          {
            render: (
              <div className="flex flex-row p-1 items-center bg-blue-200 rounded w-max text-sm font-normal">
                {item.status}
              </div>
            ),
          },
          {
            render: (
              <div className="flex flex-row p-1 px-2 items-center bg-orange-100 rounded w-max text-sm font-normal">
                {item.currentLease === null
                  ? 0
                  : (item.currentLease?.otherTenants === null
                      ? 0
                      : item.currentLease?.otherTenants?.length) +
                    (item.currentLease.primaryTenant !== null ? 1 : 0)}
              </div>
            ),
          },
          {
            render: (
              <div
                className={`flex flex-row p-1 px-2 items-center rounded w-max text-sm font-normal ${getDateColor(
                  item.currentLease?.endDate
                )}`}
              >
                {item.currentLease === null
                  ? "- - -"
                  : moment(item.currentLease?.endDate).format("MM-DD-YYYY")}
              </div>
            ),
          },
          `$${item.currentLease?.rentDetails?.amount ?? `0`}`,
          item.currentLease?.leaseTerms ?? "- - -",
          `$${getDeposit(item.currentLease?.deposits ?? [])}`,
        ],
      };
    });
    return data;
  };

  return (
    <div className="py-5">
      <div className="flex flex-row justify-between items-center">
        <div className="text-2xl font-semibold text-black">Unit Management</div>
        <div className="flex flex-row items-center">
          <p className="font-semibold text-newBlue-400 mr-2 ">
            <div className="relative w-40">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <SearchIcon />
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-800"
                placeholder="Search..."
                required
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
          </p>
          <MaterialForm
            className="flex-shrink w-40"
            data={filter}
            onChange={onChangeFilter}
            fieldClassName="mx-2"
            renderArray={[
              {
                name: "selectedFilter",
                inputType: "SELECT",
                options: filterOptions,
              },
            ]}
          />
          <button
            className="border h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 ml-4"
            onClick={() => onTabChange("newUnit")}
          >
            <div className="flex flex-row items-center w-24">
              <div>
                <AddIconBlue className="h-4 w-4" />
              </div>
              <div className="px-2 flex">
                <div className="font-medium">New Unit</div>
              </div>
            </div>
          </button>
        </div>
      </div>

      <div className=" mt-10">
        <MaterialTable
          data={getTableData()}
          head={getTableHead()}
          headRenderColor="text-newGray-300"
          defaultOrder="desc"
          pageRows={size}
          onClickCB={(selection: number) => {
            console.log({ selection });
            setSelectedUnit(selection);
            onTabChange("unitDetails");
          }}
          totalData={totalElements}
          currentPage={page}
          paginationCB={(value: number) => {
            setPage(value);
          }}
          rowChangeCB={(value: number) => {
            setSize(value);
          }}
          loading={undefined}
          defaultOrderBy={undefined}
          multiSelect={undefined}
          searchText={undefined}
          setSelected={undefined}
        />
      </div>
    </div>
  );
}
