import { useEffect, useReducer, useState } from "react";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { navigate } from "raviger";
import { useRecoilState } from "recoil";

import { alertError, alertInfo } from "../../actions/AlertActions";
import { useClientRef } from "../../actions/ClientRefActions";
import {
  createCommercialClient,
  createIndividualClient,
  createPMCManagers,
  createUnderHOA,
  createUnderIndividualClient,
  createUnderPMC,
} from "../../api/Api";
import {
  BuildingIcon,
  ClientBuildingIcon,
  CommercialClientIcon,
  ContactIcon,
  TimesCircleIcon,
} from "../../components/clients/ClientAppIcons";
import ClientTypeSelection from "../../components/clients/Create/ClientTypeSelection";
import CommercialTypeSelection from "../../components/clients/Create/CommercialTypeSelection";
import BuildingFormFields from "../../components/clients/forms/BuildingFormFields";
import ClientFormFields from "../../components/clients/forms/ClientFormFields";
import ContactFormFields from "../../components/clients/forms/ContactFormFields";
import { ClientIcon, InfoIcon } from "../../components/common/AppIcons";
import MaterialModal from "../../components/common/MaterialModal";
import MaterialTable from "../../components/common/MaterialTable";
import { SuspenseBoundary } from "../../components/common/core/SuspenseBoundary";
import { clientRefAtom } from "../../store/ClientRefState";
import { deepUpdate, recursiveDeepUpdate } from "../../utils/StateUtils";
import { useIndexedDB } from "../../utils/indexed-db";

const wizardInitialState = [
  {
    title: "Client Type",
    id: "client_type",
    icon: ClientIcon,
    state: "client_type",
    component: ClientTypeSelection,
    apiCallRequired: false,
    enabled: true,
    completed: false,
    selected: true,
  },
  {
    title: "Commercial Client",
    id: "commercial_client_type",
    icon: CommercialClientIcon,
    component: CommercialTypeSelection,
    state: "client.subCategory",
    decidedBy: "client_type",
    shouldEnable: (data) => data.client_type === "COMMERCIAL",
    apiCallRequired: false,
    enabled: false,
    completed: false,
    selected: false,
  },
  {
    title: "Client Details",
    id: "commercial_client_details",
    icon: ClientBuildingIcon,
    component: ClientFormFields,
    state: "client",
    decidedBy: "client_type",
    shouldEnable: (data) => data.client_type === "COMMERCIAL",
    apiCallRequired: true,
    enabled: false,
    completed: false,
    selected: false,
  },
  {
    title: "Contact Details",
    id: "contact_details",
    icon: ContactIcon,
    component: ContactFormFields,
    state: "contact",
    apiCallRequired: true,
    enabled: true,
    completed: false,
    selected: false,
  },
  {
    title: "Address",
    id: "building_details",
    icon: BuildingIcon,
    component: BuildingFormFields,
    shouldEnable: (data) => {
      console.log("Should enable building details", data);
      return data?.client?.subCategory !== "HOA";
    },
    state: "building",
    apiCallRequired: true,
    enabled: false,
    completed: false,
    selected: false,
  },
];

const wizardReducer = (state, action) => {
  console.log("Reducing ", action);
  switch (action.type) {
    case "next": {
      // Find the current selected tab, and set it to false
      // Find the next enabled tab, and set it to true
      const currentSelected = state.findIndex((tab) => tab.selected);
      const nextEnabled = state.findIndex(
        (tab, index) => index > currentSelected && tab.enabled
      );
      if (nextEnabled === -1) {
        alertError("You have reached the end of the wizard");
        return state;
      }
      return state.map((tab, index) => {
        if (index === currentSelected) {
          return { ...tab, selected: false };
        } else if (index === nextEnabled) {
          return { ...tab, selected: true };
        } else {
          return tab;
        }
      });
    }
    case "previous": {
      // Find the current selected tab, and set it to false
      // Find the previous enabled tab, and set it to true
      const currentSelected = state.findIndex((tab) => tab.selected);
      const previousEnabled = state.lastIndexOf(
        (tab, index) => index < currentSelected && tab.enabled && !tab.completed
      );
      if (previousEnabled === -1) {
        window.history.back();
        return state;
      }
      return state.map((tab, index) => {
        if (index === currentSelected) {
          return { ...tab, selected: false };
        } else if (index === previousEnabled) {
          return { ...tab, selected: true };
        } else {
          return tab;
        }
      });
    }
    case "enable": {
      return state.map((tab) => {
        if (tab.id === action.id) {
          return { ...tab, enabled: true };
        } else {
          return tab;
        }
      });
    }
    case "disable": {
      return state.map((tab) => {
        if (tab.id === action.id) {
          return { ...tab, enabled: false };
        } else {
          return tab;
        }
      });
    }
    case "select": {
      return state.map((tab) => {
        if (tab.id === action.id) {
          return { ...tab, selected: true };
        } else {
          return { ...tab, selected: false };
        }
      });
    }
    case "partial_save": {
      // Update all tabs before the specified action.id
      const index = state.findIndex((tab) => tab.id === action.id);
      return state.map((tab, i) => {
        if (i === index) {
          return { ...tab, completed: true, data: action.data };
        } else if (i <= index) {
          return { ...tab, completed: true };
        } else {
          return tab;
        }
      });
    }
    case "api_error": {
      // Find the first tab that has not been completed
      const unsavedForm = state.find((tab) => {
        console.log("Checking tab", tab);
        return !tab.completed && tab.enabled && tab.apiCallRequired;
      });
      if (!unsavedForm) {
        alertError("Failed to create client");
        return state;
      }
      switch (unsavedForm.id) {
        case "commercial_client_details":
          alertError("Failed to create client");
          break;
        case "contact_details":
          alertError("Failed to create contact");
          break;
        case "building_details":
          alertError("Failed to create building");
          break;
        default:
          alertError("Failed to create client");
      }
      return state.map((tab) => {
        if (tab.id === unsavedForm.id) {
          return { ...tab, selected: true };
        } else {
          return { ...tab, selected: false };
        }
      });
    }

    default:
      return state;
  }
};

const onSubmit = async (data, wizard, dispatch) => {
  // Individual: Contact -> Building
  // Commercial: Client -> Manager -> Building
  if (data.client_type === "COMMERCIAL") {
    // Commercial
    try {
      let clientId = wizard.find(
        (tab) => tab.id === "commercial_client_details"
      ).data;
      let buildingId;
      if (
        !wizard.find((tab) => tab.id === "commercial_client_type").completed
      ) {
        const payload = {
          ...data.client,
          // Since State is set by default, we need to set the address object to null
          // if the user has not entered an address
          billingAddress: data.client.billingAddress.streetAddress1
            ? data.client.billingAddress
            : null,
          clientClass:
            data.client.subCategory === "PROPERTY_MANAGEMENT" ||
            data.client.subCategory === "CONTRACTOR"
              ? "PMC"
              : "HOA_OWNER",
        };
        const clientResponse = await createCommercialClient(payload);
        clientId = clientResponse.clientId;
        buildingId = clientResponse.buildingId;

        dispatch({
          type: "partial_save",
          id: "commercial_client_details",
          data: clientId,
        });
      }
      let contactId = wizard.find((tab) => tab.id === "contact_details").data;
      console.log("Contact Data", data.contact);
      if (
        !wizard.find((tab) => tab.id === "contact_details").completed &&
        data.contact?.firstName != null &&
        data.contact?.firstName != ""
      ) {
        const contactPayload = {
          ...data.contact,
          buildingId,
        };
        const contactObj = await createPMCManagers(contactPayload, clientId);
        contactId = contactObj.contactId;
        dispatch({
          type: "partial_save",
          id: "contact_details",
          data: contactId,
        });
      }
      const createBuildingAPI =
        data.client.subCategory === "HOA" ? createUnderHOA : createUnderPMC;

      // If the building is under a PMC, skip the building creation if `data.building.address` is not set
      if (
        !(
          data.client.subCategory === "PROPERTY_MANAGEMENT" &&
          !data.building.address.streetAddress1
        ) &&
        data.client.subCategory !== "HOA"
        // The additional condition for HOA was added so that HOAs can skip building creation
        // It might be possible to Optimize this
      ) {
        await createBuildingAPI(
          {
            ...data.building,
            contacts: [{ contactId: contactId }],
          },
          clientId
        );
      }
      navigate(`/commercial/${clientId}/summary`);
    } catch (error) {
      dispatch({ type: "api_error" });
    }
  } else {
    // Individual
    console.log("Creating individual client", data);
    try {
      let contactId = wizard.find((tab) => tab.id === "contact_details").data;
      console.log("Contact ID preinitialized as ", contactId);
      if (!wizard.find((tab) => tab.id === "contact_details").completed) {
        const contactsObj = await createIndividualClient(data.contact);
        contactId = contactsObj.value;

        console.log("Contact ID updated as ", contactId);
        dispatch({
          type: "partial_save",
          id: "contact_details",
          data: contactId,
        });
      }
      if (data.building?.address?.streetAddress1)
        await createUnderIndividualClient(
          {
            ...data.building,
            position: "OWNER",
          },
          contactId
        );

      navigate(`/individual/${contactId}/buildings`);
    } catch (error) {
      dispatch({ type: "api_error" });
    }
  }
};

export default function CreateClientPage() {
  const [wizard, reduceWizard] = useReducer(wizardReducer, wizardInitialState);
  const [data, setData] = useState({});

  const currentTab = wizard.find((tab) => tab.selected);

  const [clientRef] = useRecoilState(clientRefAtom);
  const { clearClientRef } = useClientRef();

  const [recoverDraft, setRecoverDraft] = useState(false);
  const [recovered, setRecovered] = useState();

  const { add, getAll, deleteRecord } = useIndexedDB("client");

  // Prefill Phone Number/Email/Name if available in clientRef
  useEffect(() => {
    if (clientRef?.prefill) {
      const prefillData = Object.entries(clientRef.prefill).map(
        ([name, value]) => ({ name: `contact.${name}`, value })
      );
      setData((data) => recursiveDeepUpdate(prefillData, data));
    }
  }, [clientRef]);

  const getDrafts = () =>
    getAll().then((draft) => {
      setRecovered(draft);
    });

  useEffect(() => {
    getDrafts();
    return () => {
      setData((data) => {
        const toSave = JSON.stringify(data);
        data !== {} &&
          toSave.length > 50 &&
          add({ form: toSave }).then(
            (_event) => {
              console.log("Saved Client Details to Drafts");
            },
            (_error) => {
              alertError("Couldn't Save Client Details");
            }
          );
        return data;
      });
      clearClientRef();
    };
  }, []);

  const recoverClient = (index) => {
    // console.log(recovered[index]);
    setData({ ...JSON.parse(recovered[index].form), recovered: true });
    deleteRecord(recovered[index].id);
    setRecoverDraft(false);
  };

  useEffect(() => {
    // Manage enabled tabs based on data
    wizard.forEach((tab) => {
      if (tab.shouldEnable) {
        const shouldEnable = tab.shouldEnable(data);
        if (shouldEnable && !tab.enabled) {
          reduceWizard({ type: "enable", id: tab.id });
        } else if (!shouldEnable && tab.enabled) {
          reduceWizard({ type: "disable", id: tab.id });
        } else {
        }
      }
    });
  }, [data]);

  // Effect to update subCategory when clientClass is changed
  useEffect(() => {
    if (data.client?.subCategory) {
      setData((data) => ({
        ...data,
        client: {
          ...data.client,
          clientClass:
            data.client.subCategory === "PROPERTY_MANAGEMENT"
              ? "PMC"
              : "HOA_OWNER",
        },
      }));
    }
  }, [data.client?.subCategory]);

  if (!currentTab) {
    return <div>Something went wrong</div>;
  }

  const determineReadyToSubmit = () => {
    const allowedTabs = wizard.filter((tab) => tab.enabled);
    const tabIndex = allowedTabs.findIndex((tab) => tab === currentTab);
    if (tabIndex === allowedTabs.length - 1) {
      return true;
    } else {
      return false;
    }
  };

  const readyToSubmit = determineReadyToSubmit();

  const onProceed = () => {
    if (readyToSubmit) {
      onSubmit(data, wizard, reduceWizard);
    } else {
      setTimeout(() => reduceWizard({ type: "next" }), 200);
    }
  };

  return (
    <div className="flex flex-col gap-4 items-center mt-12 pb-6">
      <div className="flex flex-row gap-4">
        {wizard
          .filter((tab) => tab.enabled)
          .map((tab) => (
            <div
              key={tab.id}
              className={`flex flex-col text-center justify-center items-center cursor-pointer rounded-full h-20 w-20 bg-white
                ${
                  tab.completed
                    ? "text-green-400"
                    : tab.selected
                    ? "text-newBlue-400"
                    : "text-gray-800"
                }`}
              onClick={() => {
                if (tab.completed) {
                  alertInfo(
                    `${tab.title} has already been saved, you cannot edit it now`
                  );
                } else if (tab.enabled) {
                  reduceWizard({ type: "select", id: tab.id });
                }
              }}
            >
              <div>
                <tab.icon />
              </div>
              <div>
                {/* block is required for leading/line-height to work */}
                <span className="text-xs block leading-4 px-1">
                  {tab.title}
                </span>
              </div>
            </div>
          ))}
      </div>
      {/* 2 Column Flex div */}
      <div className="flex flex-row gap-4">
        {
          // ClientRef is reference material to look at when creating a client
          // If clientRef is specified, it is shown as a column on the left
          clientRef && (
            // Use all keys of clientRef.props as props for the component specified in clientRef.component
            <div className="max-w-md w-full">
              {/* Topbar with close button to close clientRef */}
              <div className="flex flex-row-reverse py-2">
                <button
                  className="text-gray-500 hover:text-gray-800 flex flex-row-reverse gap-1 items-center"
                  onClick={() => clearClientRef()}
                >
                  <TimesCircleIcon className="h-6 w-6" /> Close
                </button>
              </div>
              <clientRef.component {...clientRef.props} />
            </div>
          )
        }
        <div className="bg-white rounded-lg shadow-lg p-4">
          <SuspenseBoundary waitFor={recovered}>
            <RecoverBanner
              recoveredClients={recovered}
              setRecoverDraft={setRecoverDraft}
            />
          </SuspenseBoundary>
          <div className="flex flex-col gap-4 items-center">
            <div>
              <currentTab.component
                clientClass={
                  data.client?.subCategory ?? data.client?.clientClass
                }
                clientData={data.client}
                data={data[currentTab.state]}
                onChange={(event) => {
                  if (typeof event === "string") {
                    setData((data) =>
                      deepUpdate(currentTab.state, event, data)
                    );
                  } else {
                    const updateName = `${currentTab.state}.${event.name}`;
                    setData((data) =>
                      deepUpdate(updateName, event.value, data)
                    );
                  }
                }}
                onSkip={() => onProceed()}
                onNext={() =>
                  setTimeout(() => reduceWizard({ type: "next" }), 200)
                }
              />
            </div>
          </div>

          <div className="flex mt-2 justify-center flex-row">
            <div>
              <button
                className="h-12 w-20 rounded py-2 px-2 border border-newBlue-400 text-newBlue-400 text-sm"
                onClick={() => reduceWizard({ type: "previous" })}
              >
                Cancel
              </button>
            </div>
            <div className="px-4">
              <button
                className="h-12 w-36 bg-newBlue-400 rounded py-2 px-2 border border-newBlue-400 text-white text-sm"
                onClick={onProceed}
              >
                {readyToSubmit ? "Submit" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* TODO: Refactor this out of MaterialTable & Maybe MaterialModal too */}
      {recoverDraft && (
        <MaterialModal
          open={recoverDraft}
          setOpen={(_) => setRecoverDraft(false)}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <MaterialTable
            data={
              recovered?.map((draft, index) => {
                if (draft.form) {
                  const recoveredData = JSON.parse(draft.form);
                  return {
                    id: index,
                    data: [
                      `${index}`,
                      `${
                        recoveredData.clientName ??
                        `${recoveredData.contact?.firstName ?? "-"} ${
                          recoveredData.contact?.lastName ?? "-"
                        }`
                      }`,
                      <button
                        className="flex items-center hover:bg-blue-400 bg-dark text-white p-2 rounded-lg"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteRecord(draft.id).then(() => getDrafts());
                          setRecoverDraft(false);
                        }}
                      >
                        <DeleteForeverIcon /> Remove
                      </button>,
                    ],
                  };
                } else
                  return {
                    id: index,
                    data: [
                      `${index}`,
                      `Corrupt Data`,
                      <button
                        className="flex items-center hover:bg-blue-400 bg-dark text-white p-2 rounded-lg"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteRecord(draft.id).then(() => getDrafts());
                          setRecoverDraft(false);
                        }}
                      >
                        <DeleteForeverIcon /> Remove
                      </button>,
                    ],
                  };
              }) || []
            }
            head={[
              { id: "id", label: "ID" },
              { id: "clientName", label: "Client Name" },
              { id: "deleteIcon", label: "" },
            ]}
            defaultOrderBy={"id"}
            onClickCB={recoverClient}
            searchText={""}
            pageRows={5}
          />
        </MaterialModal>
      )}
    </div>
  );
}

function RecoverBanner({ recoveredClients, setRecoverDraft }) {
  return (
    <div className="rounded-md bg-blue-100 p-2 mb-2">
      <div className="flex">
        <div className="flex-shrink-0">
          <InfoIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          {recoveredClients ? (
            <>
              <p className="text-sm text-blue-700">
                You have {recoveredClients.length} draft(s) saved
              </p>
              <p className="mt-3 text-sm md:ml-6 md:mt-0">
                <button
                  onClick={() => {
                    setRecoverDraft(true);
                  }}
                  className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                >
                  Recover Draft
                  <span aria-hidden="true"> &rarr;</span>
                </button>
              </p>
            </>
          ) : (
            <p className="text-sm text-blue-700">No Drafts Found</p>
          )}
        </div>
      </div>
    </div>
  );
}
