import { useEffect } from "react";

import { navigate } from "raviger";

import BuildingInfo from "../../../components/clients/BuildingInfo";
import { ManagedByIcon } from "../../../components/clients/ClientAppIcons";
import ListBuilding from "../../../components/clients/ListBuildings";
import NewListTickets from "../../../components/clients/NewListTickets";
import { LeftArrowWithTailIcon } from "../../../components/common/AppIcons";
import CollapsibleSelector from "../../../components/common/core/CollapsibleSelector";
import { SuspenseBoundary } from "../../../components/common/core/SuspenseBoundary";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import UnitsAndResidents from "../../building/components/UnitsAndResidents";
import BuildingManagers from "../components/BuildingManagers";

export default function ClientBuildingsTab({
  clientData,
  selectedId,
  refreshCB,
}) {
  const selectedBuilding = clientData.buildings.find(
    (building) => building.buildingId === parseInt(selectedId)
  );

  const isMobile = useMediaQuery("(max-width: 640px)");

  useEffect(() => {
    // Check if screen width is less than 768px
    const mediaQuery = window.matchMedia("(max-width: 640px)");
    // If it is less than 768px, then navigate to the first building
    if (!mediaQuery.matches) {
      // Select the first building if no building is selected
      if (!selectedBuilding && clientData.buildings.length > 0) {
        navigate(
          `/${clientData.client.clientVariant}/${clientData.client.clientId}/buildings/${clientData.buildings[0].buildingId}`
        );
      }
    }
  }, [clientData, selectedBuilding, isMobile]);
  return (
    <div className="flex flex-col md:flex-row p-2 gap-2">
      <CollapsibleSelector
        collapseWhen={selectedBuilding && isMobile}
        collapseView={({ onClick }) => (
          <div
            className="p-2 flex flex-row items-center hover:bg-gray-200 rounded-md flex-grow-0 flex-shrink cursor-pointer"
            onClick={onClick}
          >
            <div>
              <LeftArrowWithTailIcon className="h-3 w-3" />
            </div>
            <div className="px-1 text-sm text-newBlue-400">Building List</div>
          </div>
        )}
        onExpand={() => {
          navigate(
            `/${clientData.client.clientVariant}/${clientData.client.clientId}/buildings`
          );
        }}
      >
        <ListBuilding
          clientData={clientData.client}
          buildings={clientData.buildings}
          selectedBuilding={selectedBuilding}
          buildingChangeCB={(building) => {
            navigate(
              `/${clientData.client.clientVariant}/${clientData.client.clientId}/buildings/${building.buildingId}`
            );
          }}
          refreshCB={() => {
            refreshCB("buildings");
            refreshCB("managers");
          }}
        />
      </CollapsibleSelector>
      <SuspenseBoundary
        waitFor={selectedBuilding}
        fallback={
          clientData.buildings.length === 0 ? (
            <div className="flex-1 flex flex-col justify-center items-center">
              <div className="text-2xl font-bold text-gray-500">
                No buildings found
              </div>
              <div className="text-gray-500">Add a building to get started</div>
            </div>
          ) : isMobile ? (
            <div className="flex-1 flex flex-col justify-center items-center">
              <div className="text-gray-500">
                Select a building to get started
              </div>
            </div>
          ) : (
            <div className="flex-1 flex flex-col justify-center items-center">
              <div className="text-2xl font-bold text-gray-500">Loading...</div>
            </div>
          )
        }
      >
        <BuildingInfo
          clientData={clientData?.client}
          type={"HOA"}
          selectedBuilding={selectedBuilding}
          refreshCB={() => {
            refreshCB("buildings");
          }}
        />
        <div className="flex flex-col w-full px-4">
          {/* {clientData.client.clientVariant === "commercial" &&
            clientData.client.managedBy && (
              <div>
                <div className="py-4">
                  <span className="font-semibold">Managed By</span>
                </div>
                <div className="rounded flex flex-col gap-2 h-48 items-center bg-newBlue-500 justify-center">
                  <div>
                    <ManagedByIcon />
                  </div>
                  <div
                    className="text-newBlue-400 py-2 cursor-pointer"
                    onClick={() => {
                      navigate(
                        `/commercial/${clientData.client.managedBy.clientId}/summary`
                      );
                    }}
                  >
                    Managed By: {clientData.client.managedBy.clientName}
                  </div>
                </div>
              </div>
            )} */}
          {clientData.client.clientVariant !== "individual" && (
            <div className="flex flex-col 2xl:flex-row gap-8">
              {clientData.pmcManagers && clientData.pmcManagers.length > 0 && (
                <BuildingManagers
                  clientId={clientData.client.clientId}
                  selectedBuilding={selectedBuilding}
                  managers={[...(clientData.pmcManagers || [])]}
                  refreshCB={() => {
                    refreshCB("buildings");
                    refreshCB("managers");
                  }}
                />
              )}
              <BuildingManagers
                managerTitle={
                  clientData.client.subCategory === "HOA"
                    ? "Board Members"
                    : clientData.client.subCategory === "BUILDING_OWNER"
                    ? "Landlord"
                    : "Managers"
                }
                clientId={clientData.client.clientId}
                selectedBuilding={selectedBuilding}
                managers={[...(clientData.managers || [])]}
                refreshCB={() => {
                  refreshCB("buildings");
                  refreshCB("managers");
                }}
              />
            </div>
          )}
          {selectedBuilding && (
            <UnitsAndResidents
              buildingData={selectedBuilding}
              buildingId={selectedBuilding.buildingId}
              refreshCB={() => {
                refreshCB("buildings");
              }}
            />
          )}

          <NewListTickets
            unitNumbers={selectedBuilding?.unitNumbers}
            type="BUILDING"
            id={selectedBuilding?.buildingId}
            refreshCB={() => refreshCB("client")}
          />
        </div>
      </SuspenseBoundary>
    </div>
  );
}
